import React from 'react'
import { ISubscribable } from 'src/api/api'
import { Box, Table2, useTable, Checkbox } from 'src/ui'
import CompanyNameHelper from 'src/connect/helpers/company-name-helper'

interface CompanyUserListTableProps {
  results: []
  isLoading: boolean
  selectedItems: ISubscribable[]
  setSelectedItems: any
  onRowClick: any
}

const CompanyUserListTable: React.FC<CompanyUserListTableProps> = ({
  results,
  isLoading,
  selectedItems,
  setSelectedItems,
  onRowClick,
}) => {
  let columns = React.useMemo(
    () => [
      {
        id: '_actions',
        Header: 'Selected',
        accessor: '_actions',
        disableSortBy: true,
        Cell: ({ row }) => {
          let item = row.original

          return (
            <Checkbox
              defaultChecked={
                selectedItems.find((selectedItem) => selectedItem.id === item.id) != null
              }
            />
          )
        },
      },
      {
        id: 'id',
        accessor: 'id',
        Header: 'ID',
        disableSortBy: true,
      },
      {
        id: '_company',
        accessor: '_company',
        Header: 'Company',
        disableSortBy: false,
        Cell: ({ row }) => {
          let item = row.original

          return <CompanyNameHelper company_id={item.company_id} />
        },
      },
      {
        id: 'full_name',
        accessor: 'full_name',
        Header: 'Full Name',
        disableSortBy: true,
      },
      {
        id: 'account_id',
        accessor: 'company_id_remote_id',
        Header: 'Account ID',
        disableSortBy: false,
      },
    ],
    [selectedItems]
  )
  let { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    data: results,
    tableOptions: {
      initialState: {
        sortBy: [],
      },
    },
    columns,
  })

  return (
    <>
      <Box fontWeight="bold" mt={5}>
        Results
      </Box>
      <Box bg="white" pt={3}>
        <Table2 {...getTableProps()} style={{ boxShadow: 'none' }} striped>
          <Table2.Header headerGroups={headerGroups} />
          <Table2.Body
            isLoading={isLoading}
            rows={rows}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            onRowClick={(item) => onRowClick(item, selectedItems, setSelectedItems)}
          />
        </Table2>
      </Box>
    </>
  )
}

export default CompanyUserListTable
