import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'

type ProofOfYield = {
  detailsIsEditing: boolean
  setDetailsIsEditing: Dispatch<SetStateAction<boolean>>
  gradeFactorsIsEditing: boolean
  setGradeFactorsIsEditing: Dispatch<SetStateAction<boolean>>
}

const ProofOfYieldContext = createContext<ProofOfYield>({
  detailsIsEditing: false,
  setDetailsIsEditing: () => {
    return
  },
  gradeFactorsIsEditing: false,
  setGradeFactorsIsEditing: () => {
    return
  },
})

export function ProofOfYieldContextProvider({ children }: { children: ReactNode }) {
  const [detailsIsEditing, setDetailsIsEditing] = useState(false)
  const [gradeFactorsIsEditing, setGradeFactorsIsEditing] = useState(false)

  const proofOfYieldContextValue = useMemo(
    () => ({
      detailsIsEditing,
      setDetailsIsEditing,
      gradeFactorsIsEditing,
      setGradeFactorsIsEditing,
    }),
    [detailsIsEditing, gradeFactorsIsEditing]
  )
  return (
    <ProofOfYieldContext.Provider value={proofOfYieldContextValue}>
      {children}
    </ProofOfYieldContext.Provider>
  )
}

export function useProofOfYieldContext(): ProofOfYield {
  return useContext(ProofOfYieldContext)
}
