import { UseToastOptions } from '@chakra-ui/react'
import { centreAPIs } from 'src/utils/api'
import { getEnvironment } from 'src/utils'

const COPY_EXCLUDED_KEYS = ['_revision', 'id', 'company_id', 'company_id_remote_id']

type CopyContext = {
  whitelist?: string[]
  companyConfig?: Partial<CompanyConfig>
  [key: string]: any
}

/**
 * Loads the `source` and `dest` values to display in the diff dialog.
 */
export const loadCopyDiff = async ({
  company,
  context,
  toast,
}: {
  company: Company
  context: CopyContext
  toast: (args: UseToastOptions) => void
}) => {
  let destinationEnvironment = getEnvironment() === 'production' ? 'UAT' : 'Production'
  let { location, whitelist } = context

  let [err, response] = await centreAPIs.otherEnvironment.getCentreElevatorsByCompanySlug({
    companySlug: company.slug,
  })

  if (err) {
    toast({ status: 'error', description: `Failed to load ${destinationEnvironment} location` })
    return
  }

  let destLocation = response.data.data.find((loc) => loc.remote_id === location.remote_id)

  return {
    source: [...Object.keys(location)]
      .sort((a, b) => (a < b ? -1 : 1))
      .filter((key) => !COPY_EXCLUDED_KEYS.includes(key))
      .reduce((acc, key) => ({ ...acc, [key]: location[key] }), {}),

    dest: destLocation
      ? [...Object.keys(destLocation)]
          .sort((a, b) => (a < b ? -1 : 1))
          .filter((key) => (whitelist ? whitelist.includes(key) : true))
          .filter((key) => !COPY_EXCLUDED_KEYS.includes(key))
          .reduce((acc, key) => ({ ...acc, [key]: destLocation[key] }), {})
      : {},
  }
}

/**
 * Runs the actual copy routine with error handlers and notifications.
 */
export const executeCopy = async ({
  company,
  context,
  toast,
}: {
  company: Company
  context: CopyContext
  toast: (args: UseToastOptions) => void
}) => {
  let { location } = context
  let destinationEnvironment = getEnvironment() === 'production' ? 'UAT' : 'Production'

  let [err, response] = await centreAPIs.otherEnvironment.getCentreElevatorsByCompanySlug({
    companySlug: company.slug,
  })

  if (err) {
    toast({ status: 'error', description: `Failed to load ${destinationEnvironment} location` })
    return
  }

  let destLocation = response.data.data.find((loc) => loc.remote_id === location.remote_id)

  let copySource = Object.keys(location)
    .filter((key) => !COPY_EXCLUDED_KEYS.includes(key))
    .reduce((acc, key) => ({ ...acc, [key]: location[key] }), {}) as Elevator

  if (destLocation) {
    let [updateErr, updatedLocation] = await centreAPIs.otherEnvironment.updateCentreElevator({
      elevator: { ...destLocation, ...copySource, _revision: destLocation._revision },
    })

    if (updateErr) {
      toast({ status: 'error', description: 'Failed to copy location' })
    } else {
      toast({ description: `Successfully copied location to ${destinationEnvironment}` })
    }

    return [updateErr, updatedLocation]
  }

  const [destCompanyErr, destCompany] = await centreAPIs.otherEnvironment.getCentreCompanyBySlug({
    slug: company.slug,
  })

  if (destCompanyErr) {
    toast({ status: 'error', description: 'Failed to load destination company' })
    return
  }

  // api will refuse the request if these are included
  delete copySource.id
  delete copySource.created_at
  delete copySource.updated_at

  let [createErr, createdLocation] = await centreAPIs.otherEnvironment.createCentreElevator({
    elevator: { ...copySource, company_id: destCompany.id },
  })

  if (createErr) {
    toast({ status: 'error', description: 'Failed to copy location' })
  } else {
    toast({ description: `Successfully copied location to ${destinationEnvironment}` })
  }

  return [createErr, createdLocation]
}
