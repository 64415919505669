import { reportingClient } from 'src/utils/api'

type Configuration = {
  createdAt: string
  id: string
  title: string
  enabled: boolean
  updatedAt: string
}

export type GetContractsConfigurationBySlugRequest = {
  slug: string
}

export type GetContractsConfigurationBySlugResponse = {
  data: Configuration
  type: string
}

export type ContractsUpsertConfigurationRequest = {
  title: string
  slug: string
  enabled: boolean
}

export type ContractsUpsertConfigurationResponse = {
  data: Configuration
  type: string
}

export type GetExportContractsConfigurationBySlugResponse = {
  title: string
  type: string
  enabled: boolean
}

export type GetExportContractsConfigurationBySlugRequest = {
  slug: string
}

export const contractsRequests = {
  ///////////////////////////////////////////////////////////////////////////////
  // Upsert Configuration
  ///////////////////////////////////////////////////////////////////////////////
  upsertConfiguration: async (params: ContractsUpsertConfigurationRequest) =>
    reportingClient.post<ContractsUpsertConfigurationResponse>(
      '/contracts/v1/ContractsUpsertConfiguration',
      params
    ),

  ///////////////////////////////////////////////////////////////////////////////
  // Get Configuration By Slug
  ///////////////////////////////////////////////////////////////////////////////
  getConfigurationBySlug: async (params: GetContractsConfigurationBySlugRequest) =>
    reportingClient.post<GetContractsConfigurationBySlugResponse>(
      '/contracts/v1/GetContractsConfigurationBySlug',
      params
    ),

  ///////////////////////////////////////////////////////////////////////////////
  // Get Export Configuration By Slug
  ///////////////////////////////////////////////////////////////////////////////
  getExportConfigBySlug: async (params: GetExportContractsConfigurationBySlugRequest) =>
    reportingClient.post<GetExportContractsConfigurationBySlugResponse>(
      '/contracts/v1/GetExportConfigBySlug',
      params
    ),
}
