import React from 'react'
import {
  Stack,
  Button,
  ChevronUpIcon,
  ChevronDownIcon,
  Row,
  Box,
  IconButton,
  Collapse,
  CloseIcon,
} from 'src/ui'

interface MappingPanelProps {
  header: string | React.ReactNode
  mappingCount: number
  bodyStyle?: any
  panelProps?: object
  headerProps?: object
  hideToggleIcon?: boolean
  defaultExpanded?: boolean
  expanded?: boolean
  onChange?: (...args: any) => void
  onDelete?: () => void
  children?: React.ReactNode
}

const MappingPanel: React.FC<MappingPanelProps> = ({
  children,
  bodyStyle,
  panelProps,
  headerProps,
  header,
  mappingCount,
  hideToggleIcon = false,
  defaultExpanded = false,
  expanded,
  onChange,
  onDelete,
}) => {
  let [innerExpanded, setInnerShow] = React.useState(defaultExpanded)
  const innerToggle = () => setInnerShow(!innerExpanded)

  let actualExpanded = typeof expanded !== 'undefined' ? expanded : innerExpanded
  const actualToggle = typeof onChange !== 'undefined' ? (e) => onChange(e, !expanded) : innerToggle

  return (
    <Box
      px={6}
      py={2}
      mb={3}
      borderRadius={3}
      bg="white"
      boxShadow="0 0px 3px 0 rgba(0,0,0,0.1), 0 0px 2px 0 rgba(0,0,0,0.06)"
      css={{
        '.chakra-collapse': {
          overflow: 'visible !important',
        },
      }}
      {...panelProps}
    >
      <Row
        justifyContent="space-between"
        alignItems="center"
        cursor="pointer"
        onClick={actualToggle}
        {...headerProps}
      >
        <Box flex={1}>{header}</Box>
        <Stack isInline>
          {!expanded && mappingCount === 1 && <Box mt="8px">{`${mappingCount} location`}</Box>}
          {!expanded && mappingCount !== 1 && <Box mt="8px">{`${mappingCount} locations`}</Box>}
          {expanded && onDelete && (
            <Box pt={1} px={4}>
              <Button
                size="sm"
                variant="ghost"
                width="100%"
                justifyContent="flex-start"
                onClick={() => {
                  onDelete()
                }}
              >
                <CloseIcon
                  mr={2}
                  cursor="pointer"
                  p={1}
                  rounded="8px"
                  background="#ACBCC6"
                  boxSize={4}
                  color="white"
                />
                Delete Map
              </Button>
            </Box>
          )}
          {!hideToggleIcon && (
            <IconButton
              icon={actualExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
              aria-label="Toggle"
              isRound
              fontSize="24px"
              variant="ghost"
              color="#8C9BA5"
            />
          )}
        </Stack>
      </Row>

      <Collapse py={2} in={actualExpanded} {...bodyStyle}>
        {children}
      </Collapse>
    </Box>
  )
}

export default MappingPanel
