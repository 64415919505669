import { CentreEnvironment, getDevEnvConfigById } from 'src/api/centre'
import { validEnvironment } from 'src/companies/routes'
import { updateCentreDevClientBaseUrl } from 'src/utils/api'
import { IS_PROD, IS_SANDBOX } from 'src/utils/config'
import {
  LS_KEYS,
  getLocalStorageKey,
  setLocalStorageKey,
  removeLocalStorageKey,
} from 'src/utils/local-storage'

export interface SettingsState {
  companySlug?: string
  centreAPIEndpoint?: string
  isSidebarOpen?: boolean
  showCompanySelector?: boolean
  showCompanySelectorDialog?: boolean
  showCentreEnvironment?: boolean
  showCompanyCreateDialog?: boolean
}

const Settings = {
  state: {
    companySlug: getLocalStorageKey<string>(LS_KEYS.COMPANY),
    isSidebarOpen: getLocalStorageKey<boolean>(LS_KEYS.SIDE_NAV, true),
    showCompanySelector: true,
    showCompanySelectorDialog: false,
    showCentreEnvironment: true,
    showCompanyCreateDialog: false,
    centreAPIEndpoint: getInitPersistedCentreEnv(),
  },

  reducers: {
    setState(state: SettingsState, payload: { [key: string]: any }): SettingsState {
      if (payload.companySlug !== undefined) {
        if (payload.companySlug) {
          setLocalStorageKey(LS_KEYS.COMPANY, payload.companySlug)
        } else {
          // `null` removes
          removeLocalStorageKey(LS_KEYS.COMPANY)
        }
      }

      if (payload.centreAPIEndpoint !== undefined) {
        if (!IS_PROD) {
          const selectedDevEnv = getDevEnvConfigById(payload.centreAPIEndpoint)

          if (selectedDevEnv) {
            updateCentreDevClientBaseUrl(selectedDevEnv.baseUrl)
          }
        }

        if (payload.centreAPIEndpoint) {
          setLocalStorageKey(LS_KEYS.ENV, payload.centreAPIEndpoint)
        } else {
          // `null` removes
          removeLocalStorageKey(LS_KEYS.ENV)
        }
      }

      return {
        ...state,
        ...payload,
      }
    },

    toggleSidebar(state: SettingsState): SettingsState {
      setLocalStorageKey(LS_KEYS.SIDE_NAV, !state.isSidebarOpen)

      return {
        ...state,
        isSidebarOpen: !state.isSidebarOpen,
      }
    },

    setCompanySlug(state: SettingsState, companySlug: string): SettingsState {
      setLocalStorageKey(LS_KEYS.COMPANY, companySlug)

      return {
        ...state,
        companySlug,
      }
    },
  },

  // effects: dispatch => ({}),
}

export default Settings

export function getInitPersistedCentreEnv() {
  const defaultCentreEnv = IS_PROD ? 'production' : IS_SANDBOX ? 'sandbox' : 'r1'
  const persistedEnv = getLocalStorageKey<CentreEnvironment>(LS_KEYS.ENV)
  const env: CentreEnvironment = validEnvironment(persistedEnv) ? persistedEnv : defaultCentreEnv

  if (persistedEnv !== env) setLocalStorageKey(LS_KEYS.ENV, env)

  return env
}
