import React, { Component } from 'react'
import { get } from 'lodash'
import { Dispatch } from 'src/store'
import { Button } from '@chakra-ui/react'
import { QueryClient, useQueryClient } from '@tanstack/react-query'
import {
  CreateTranslatorDialog,
  DeleteTranslatorDialog,
  StopTranslatorDialog,
  ReplayDialog,
} from 'src/translators/dialogs'
import { ResetSchedules } from 'src/translators/dialogs/reset-schedules'
import Translator from 'src/translators/translator'
import { STATUS, getCompanyToken, startReplay, resetSchedule } from 'src/utils/api'
import { translatorEnvironmentUrl } from 'src/translators/utils'
import { Box, Row, Placeholders } from 'src/ui'
import { useToast } from 'src/utils/toast'

interface TranslatorsProps {
  slug: string
  translators: any[]
  environments: Environment[]
  environment?: string
  company: Company
  features: string[]
  toast?: (any) => void
  queryClient: QueryClient
  saveTranslator: (
    translator,
    handlers: {
      onSuccess: () => void
      onError: () => void
    }
  ) => void
  deleteTranslator: (
    translator,
    handlers: {
      onSuccess: () => void
      onError: () => void
    }
  ) => void
  errorNotification: Dispatch['notifications']['createError']
  successNotification: Dispatch['notifications']['createSuccess']
}

interface TranslatorsState {
  resetDialog: any
  replayDialog: any
  createDialog: any
  deleteDialog: any
  stopDialog: any
}

class TranslatorList extends Component<TranslatorsProps, TranslatorsState> {
  state = {
    resetDialog: null,
    replayDialog: null,
    createDialog: null,
    deleteDialog: null,
    stopDialog: null,
  }

  handleCreateTranslator = (formValues) => {
    let translator = { name: formValues.name, company_slug: this.props.slug }
    let toast = this.props.toast
    const queryClient = this.props.queryClient

    this.props.saveTranslator(
      { translator },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({ queryKey: ['company-translators'] })
          toast({ description: 'Successfully saved translator' })
          this.setState({ createDialog: null })
        },
        onError: () => {
          toast({ status: 'error', description: 'Failed to save translator' })
        },
      }
    )
  }

  handleDeleteTranslator = (translator) => {
    let toast = this.props.toast
    const queryClient = this.props.queryClient

    this.props.deleteTranslator(
      { translator },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({ queryKey: ['company-translators'] })
          toast({ description: 'Successfully deleted translator' })
          this.setState({ deleteDialog: null })
        },
        onError: () => {
          toast({ status: 'error', description: 'Failed to delete translator' })
        },
      }
    )
  }

  handleStopTranslator = async () => {
    let { stopDialog } = this.state

    let [err] = await stopDialog.onConfirm()

    if (err) return

    this.setState({ stopDialog: null })
  }

  handleStartReplay = async (args) => {
    let { environment, company_slug, feature, endpoint, custom_url, custom_token } = args

    if (custom_url === '') {
      let noSubscription = checkForSubscription()

      if (noSubscription) {
        let noMatch = feature
        if (feature === undefined) {
          noMatch = company_slug
        }
        this.props.errorNotification({ message: 'No subscription found for ' + noMatch })
        return
      }
    }

    function checkForSubscription() {
      if (!endpoint.subscriptions.length) {
        return true
      } else if (feature === undefined) {
        for (const subscriptionCheck of endpoint.subscriptions) {
          if (
            subscriptionCheck.environment === environment &&
            subscriptionCheck.company_slug === company_slug
          ) {
            return false
          }
        }
        return true
      } else {
        let missingFeatureInEnvironment = true
        for (const thisSubscription of endpoint.subscriptions) {
          if (
            thisSubscription.environment === environment &&
            thisSubscription.company_slug === company_slug
          ) {
            for (const thisFeature of thisSubscription.features) {
              if (thisFeature === feature) {
                missingFeatureInEnvironment = false
              }
            }
          }
        }
        return missingFeatureInEnvironment
      }
    }

    let [replayErr] = await startReplay({
      environment_slug: environment,
      company_slug,
      feature,
      endpoint: {
        url: endpoint ? endpoint.url : custom_url,
        token: endpoint ? endpoint.token : custom_token,
        id: endpoint ? endpoint.id : null,
      },
    })

    if (replayErr) {
      this.props.errorNotification({ message: 'Failed to start replay' })
      return
    }

    this.props.successNotification({ message: 'Successfully started replay' })
  }

  handleResetSchedules = async ({ translator, environment_slug, schedules, resetDialogState }) => {
    this.setState((state) => ({ resetDialog: { ...state.resetDialog, status: STATUS.loading } }))

    let [tokenErr, tokenResponse] = await getCompanyToken({
      slug: this.props.company.slug,
      environment: environment_slug,
    })

    if (tokenErr || !get(tokenResponse, 'data.data.token')) {
      this.props.errorNotification({ message: 'Failed to get company environment token' })
      return
    }

    let api_token = tokenResponse.data.data.token
    let adapter_url = translatorEnvironmentUrl({ translator_id: translator.id, environment_slug })

    let results = await Promise.all(
      schedules.map((name) => resetSchedule({ adapter_url, api_token, name }))
    )

    // check each response tuple for an error
    let someFailures = results.reduce((acc, r) => acc || !!r[0], false)

    if (someFailures) {
      this.setState(
        (state) => ({ resetDialog: { ...state.resetDialog, status: STATUS.idle } }),
        () => this.props.errorNotification({ message: 'One or more resets requests failed' })
      )

      return
    }

    this.setState({ resetDialog: null }, () => {
      this.props.successNotification({ message: 'Successfully reset schedules' })
      resetDialogState()
    })
  }

  render() {
    let { resetDialog, replayDialog, createDialog, deleteDialog, stopDialog } = this.state
    let { translators, environments, company, features } = this.props

    return (
      <>
        <Row alignItems="center" justifyContent="flex-end" mb={3}>
          <Row alignItems="center">
            <Box px={2}>
              <Button size="sm" width="90px" onClick={() => this.setState({ replayDialog: true })}>
                Replay
              </Button>
            </Box>

            <Box px={2}>
              <Button
                size="sm"
                colorScheme="primary"
                onClick={() => this.setState({ createDialog: true })}
              >
                Create Translator
              </Button>
            </Box>
          </Row>
        </Row>

        {translators.length > 0 &&
          translators.map((translator: any) => (
            <Translator
              key={translator.id}
              translator={translator}
              environments={environments}
              showDeleteDialog={(translator) => this.setState({ deleteDialog: { translator } })}
              showStopDialog={({ translator, onConfirm }) =>
                this.setState({ stopDialog: { translator, onConfirm } })
              }
              showResetDialog={({ translator, translatorConfig, environment_slug, onConfirm }) => {
                this.setState({
                  resetDialog: {
                    translator,
                    translatorConfig,
                    environment_slug,
                    onConfirm,
                    status: STATUS.idle,
                  },
                })
              }}
            />
          ))}

        {translators.length === 0 && <Placeholders.EmptyState message="No translators found" />}

        <ResetSchedules
          isOpen={!!resetDialog}
          onClose={() => this.setState({ resetDialog: null })}
          onConfirm={this.handleResetSchedules}
          environments={environments}
          company={company}
          features={features}
          translator={resetDialog ? resetDialog.translator : undefined}
          translatorConfig={resetDialog ? resetDialog.translatorConfig : undefined}
          environment_slug={resetDialog ? resetDialog.environment_slug : undefined}
          status={resetDialog ? resetDialog.status : undefined}
        />

        <ReplayDialog
          isOpen={!!replayDialog}
          onClose={() => this.setState({ replayDialog: null })}
          onConfirm={this.handleStartReplay}
          environments={environments}
          company={company}
        />

        <CreateTranslatorDialog
          isOpen={!!createDialog}
          onClose={() => this.setState({ createDialog: null })}
          onConfirm={this.handleCreateTranslator}
        />

        <DeleteTranslatorDialog
          isOpen={!!deleteDialog}
          onClose={() => this.setState({ deleteDialog: null })}
          onConfirm={this.handleDeleteTranslator}
          translator={deleteDialog ? deleteDialog.translator : undefined}
        />

        <StopTranslatorDialog
          isOpen={!!stopDialog}
          onClose={() => this.setState({ stopDialog: null })}
          onConfirm={this.handleStopTranslator}
          translator={stopDialog ? stopDialog.translator : undefined}
        />

        {/* <CompanyTokens company={company} environments={environments} /> */}
      </>
    )
  }
}

const TranslatorListContainer = (props) => {
  let toast = useToast()
  const queryClient = useQueryClient()
  return <TranslatorList {...props} toast={toast} queryClient={queryClient} />
}

export default TranslatorListContainer
