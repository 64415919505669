import { IConnectCustomer } from 'src/api/api'

const baseUri = '/connect/customer'

export interface GetConnectCustomersRequest {}

export interface CreateConnectCustomerRequest {
  name: IConnectCustomer['name']
}

export interface UpdateConnectCustomerRequest {
  id: IConnectCustomer['id']
  _revision: IConnectCustomer['_revision']
  name: IConnectCustomer['name']
}

export interface DeleteConnectCustomerRequest {
  id: IConnectCustomer['id']
}

export const connectCustomerRequests = {
  ///////////////////////////////////////////////////////////////////////////////
  // GET
  ///////////////////////////////////////////////////////////////////////////////

  getConnectCustomers: (api) => async () => {
    try {
      let response = await api.get(`/connect/customers`)
      return [null, response]
    } catch (err) {
      return [err, null]
    }
  },

  ///////////////////////////////////////////////////////////////////////////////
  // CREATE
  ///////////////////////////////////////////////////////////////////////////////

  createConnectCustomer: (api) => async (params: Partial<IConnectCustomer>) => {
    try {
      const response = await api.post(baseUri, params)
      return [null, response]
    } catch (err) {
      return [err, null]
    }
  },

  ///////////////////////////////////////////////////////////////////////////////
  // UPDATE
  ///////////////////////////////////////////////////////////////////////////////

  updateConnectCustomer: (api) => async (params: Partial<IConnectCustomer>) => {
    try {
      const response = await api.put(`${baseUri}/${params.id}`, params)
      return [null, response]
    } catch (error) {
      return [error, true]
    }
  },

  ///////////////////////////////////////////////////////////////////////////////
  // DELETE
  ///////////////////////////////////////////////////////////////////////////////

  deleteConnectCustomer:
    (api) =>
    async ({ id }: DeleteConnectCustomerRequest) => {
      try {
        await api.delete(`${baseUri}/${id}`)
        return [null, true]
      } catch (err) {
        return [err, null]
      }
    },
}
