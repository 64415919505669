import { IOMSCompanyConfig } from 'src/api/api'
import { sequence } from './utils'

export const configs: IOMSCompanyConfig[] = []

export function createConfig(values: OfferManagementMapping): IOMSCompanyConfig {
  const id = sequence()
  return {
    company_id: values.company_id,
    futures_product_map_json: values.futures_product_map_json,
    id,
    _revision: `fake-revision-${id}`,
  }
}
