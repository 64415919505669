import React from 'react'
import { useConnectChannels } from 'src/api/queries/connect-channels'
import { Link, Route, useParams } from 'react-router-dom'
import { hasPermission } from 'src/utils/permissions'
import { Box, Button, Placeholders, Row, Table2, useTable } from 'src/ui'
import { ChannelForm } from 'src/connect/channels/channel-editor'
import * as queries from 'src/utils/queries'
import { AuthRoute, FlexRoutes } from 'src/routes'
import { getEnvironment } from 'src/utils'

const ConnectChannelsHeader = ({ showAdd = true, connectCustomerId }) => (
  <Row justifyContent="flex-end" height={8} mb={3}>
    <Box display="inline-block" mr={2}>
      <Button
        as={Link}
        to={
          !hasPermission('connect_customers_edit') ? '' : `/connect/${getEnvironment()}/customers`
        }
        size="sm"
        type="button"
        colorScheme="secondary"
        isDisabled={!hasPermission('connect_customers_edit')}
      >
        CUSTOMERS
      </Button>
    </Box>
    <Box>
      {showAdd && (
        <Button
          as={Link}
          to={
            !hasPermission('connect_channels_edit')
              ? ''
              : `/connect/${getEnvironment()}/customers/${connectCustomerId}/channels/new`
          }
          size="sm"
          type="button"
          colorScheme={!hasPermission('connect_channels_edit') ? 'secondary' : 'primary'}
          isDisabled={!hasPermission('connect_channels_edit')}
        >
          ADD
        </Button>
      )}
      {!showAdd && (
        <Button
          as={Link}
          to={
            !hasPermission('connect_channels_edit')
              ? ''
              : `/connect/${getEnvironment()}/customers/${connectCustomerId}/channels`
          }
          size="sm"
          type="button"
          colorScheme="secondary"
          isDisabled={!hasPermission('connect_channels_edit')}
        >
          CHANNELS
        </Button>
      )}
    </Box>
  </Row>
)

interface ConnectChannelsProps {
  connectCustomerId: string
}

const ConnectChannels: React.FC<ConnectChannelsProps> = () => {
  const { connectCustomerId: connectCustomerIdString } = useParams()
  const connectCustomerId = Number(connectCustomerIdString)

  let connectChannelsQuery = useConnectChannels(getEnvironment(), connectCustomerId)
  let connectChannels = connectChannelsQuery?.data

  if (queries.areAnyLoading(connectChannelsQuery)) return <Placeholders.LoadingState />
  if (queries.areAnyFailed(connectChannelsQuery)) return <Placeholders.FailedState />

  // relative to connect/:env/customers/:connectCustomerId/channels??
  return (
    <FlexRoutes>
      <Route
        path="/:connectChannelId/edit"
        element={
          <AuthRoute
            as={ChannelForm}
            connectChannels={connectChannels}
            header={<ConnectChannelsHeader showAdd={false} connectCustomerId={connectCustomerId} />}
          />
        }
      />
      <Route
        path="/new"
        element={
          <AuthRoute
            as={ChannelForm}
            connectChannels={null}
            header={<ConnectChannelsHeader showAdd={false} connectCustomerId={connectCustomerId} />}
          />
        }
      />
      <Route
        path="*"
        element={
          <AuthRoute
            as={ConnectChannelsTable}
            connectChannels={connectChannels}
            permissions={{
              edit: 'connect_channels_edit',
            }}
            header={<ConnectChannelsHeader showAdd={true} connectCustomerId={connectCustomerId} />}
            connectCustomerId={connectCustomerId}
          />
        }
      />
    </FlexRoutes>
  )
}

export default ConnectChannels

const ConnectChannelsTable = ({ connectChannels, permissions, header, connectCustomerId }) => {
  let columns = React.useMemo(
    () => [
      {
        id: 'id',
        accessor: 'id',
        Header: 'ID',
        disableSortBy: true,
      },
      {
        id: 'name',
        accessor: 'name',
        Header: 'Name',
        disableSortBy: false,
      },
      {
        id: 'description',
        accessor: 'description',
        Header: 'Description',
        disableSortBy: true,
      },
      {
        id: 'delivery_method',
        accessor: 'configuration.delivery_method',
        Header: 'Delivery Method',
        disableSortBy: true,
      },
      {
        id: 'subscribable_type',
        accessor: 'subscribable_type',
        Header: 'Subscription Type',
        disableSortBy: true,
      },
      {
        id: '_actions',
        Header: '',
        accessor: '_actions',
        disableSortBy: true,
        Cell: ({ row }) => {
          let connectChannel = row.original

          return (
            <Row justifyContent="flex-end">
              <Button
                as={Link}
                to={`/connect/${getEnvironment()}/customers/${connectCustomerId}/channels/${
                  connectChannel.id
                }/edit`}
                size="sm"
                variant="ghost"
                color="primary.500"
                aria-label="Edit Connect Channel"
                isDisabled={!hasPermission(permissions.edit)}
              >
                EDIT
              </Button>
              <Button
                as={Link}
                to={`/connect/${getEnvironment()}/customers/${connectCustomerId}/channels/${
                  connectChannel.id
                }/users`}
                size="sm"
                variant="ghost"
                color="primary.500"
                aria-label="View Users"
                isDisabled={
                  connectChannel.configuration.delivery_method !== 'portal' &&
                  connectChannel.configuration.delivery_method !== 'csvportal' &&
                  connectChannel.configuration.delivery_method !== 'webhookportal'
                }
              >
                USERS
              </Button>
              <Button
                as={Link}
                to={`/connect/${getEnvironment()}/customers/${connectCustomerId}/channels/${
                  connectChannel.id
                }/subscriptions`}
                size="sm"
                variant="ghost"
                color="primary.500"
                aria-label="View Subscriptions"
                isDisabled={!hasPermission(permissions.edit)}
              >
                SUBSCRIPTIONS
              </Button>
            </Row>
          )
        },
      },
    ],
    [permissions.edit, connectCustomerId]
  )

  let { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    data: connectChannels,
    tableOptions: {
      initialState: {
        sortBy: [],
      },
    },
    columns,
  })

  return (
    <>
      {header}

      {connectChannels.length === 0 ? (
        <Placeholders.EmptyState message="No channels found" />
      ) : (
        <Box bg="white" pt={3}>
          <Table2 {...getTableProps()} style={{ boxShadow: 'none' }} striped>
            <Table2.Header headerGroups={headerGroups} />
            <Table2.Body
              rows={rows}
              getTableBodyProps={getTableBodyProps}
              prepareRow={prepareRow}
            />
          </Table2>
        </Box>
      )}
    </>
  )
}
