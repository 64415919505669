import { RootState } from 'src/store'

const MESSAGE_DURATION_MS = 5000
const ERROR_MESSAGE_DURATION_MS = 10000

export interface NotificationsState {
  notifications: Notification[]
}

interface NotificationArgs {
  title?: string
  message: string
  options?: { [key: string]: any }
}

const Notifications = {
  state: {
    notifications: [],
  },

  reducers: {
    addNotification(state: NotificationsState, payload): NotificationsState {
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...payload,
            key: new Date().getTime() + Math.random(),
          },
        ],
      }
    },

    removeNotification(state: NotificationsState, { key }): NotificationsState {
      return {
        ...state,
        notifications: state.notifications.filter((n) => n.key !== key),
      }
    },
  },

  effects: (dispatch) => ({
    async createSuccess({ title, message, options = {} }: NotificationArgs, _rootState: RootState) {
      dispatch.notifications.addNotification({
        title,
        message,
        options: {
          variant: 'success',
          autoHideDuration: MESSAGE_DURATION_MS,
          ...options,
        },
      })
    },

    async createError({ message, options = {} }: NotificationArgs, _rootState: RootState) {
      dispatch.notifications.addNotification({
        title: 'Error',
        message,
        options: {
          variant: 'error',
          autoHideDuration: ERROR_MESSAGE_DURATION_MS,
          ...options,
        },
      })
    },
  }),
}

export default Notifications
