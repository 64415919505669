import { rest } from 'msw'
import { companies } from 'src/fixtures/companies'

export const handlers = (urls: string[]) => {
  return urls.reduce(
    (acc, url) => [
      ...acc,
      rest.get(`${url}/companies`, (_req, res, ctx) => res(ctx.json({ data: companies }))),
      rest.put(`${url}/company/:companySlug`, async (req, res, ctx) => {
        const requestBody = await req.json()
        const { companySlug } = req.params
        const index = companies.findIndex((company) => company.slug === companySlug)
        companies[index] = requestBody
        const statusText = `Succcessfully updated ${requestBody.name}.`
        return res(ctx.status(200), ctx.json({ data: requestBody, status: statusText }))
      }),
      rest.get(`${url}/company/:companySlug`, (req, res, ctx) => {
        const { companySlug } = req.params
        if (companySlug === 'undefined') return res(ctx.status(500))
        if (companySlug === 'testslug') {
          return res(ctx.status(200), ctx.json({ data: { name: 'test-company' } }))
        }
        const company = companies.find((company) => company.slug === companySlug)
        return res(ctx.status(200), ctx.json({ data: company }))
      }),
    ],
    []
  )
}
