import { UseToastOptions } from '@chakra-ui/react'
import { centreAPIs } from 'src/utils/api'
import { getEnvironment } from 'src/utils'

const COPY_EXCLUDED_KEYS = ['_revision', 'id', 'company_id', 'last_processed_agtrax_log_id']

type CopyContext = {
  agtraxProcessor: Partial<AgtraxProcessor>
}

/**
 * Loads the `source` and `dest` values to display in the diff dialog.
 */
export const loadCopyDiff = async ({
  company,
  context,
  toast: _toast,
}: {
  company: Company
  context: CopyContext
  toast: (args: UseToastOptions) => void
}) => {
  let [_destErr, destCompany] = await centreAPIs.otherEnvironment.getCentreCompanyBySlug({
    slug: company.slug,
  })

  let [_destProcessorErr, destProcessorResponse] =
    await centreAPIs.otherEnvironment.getCentreAgtraxProcessorByCompanyId({
      companyId: destCompany.id,
    })
  let destAgtraxProcessor = destProcessorResponse.data.data

  return {
    source: Object.keys(context.agtraxProcessor)
      .filter((key) => !COPY_EXCLUDED_KEYS.includes(key))
      .reduce((acc, key) => ({ ...acc, [key]: context.agtraxProcessor[key] }), {}),

    dest: destAgtraxProcessor
      ? Object.keys(destAgtraxProcessor)
          .filter((key) => !COPY_EXCLUDED_KEYS.includes(key))
          .reduce((acc, key) => ({ ...acc, [key]: destAgtraxProcessor[key] }), {})
      : null,
  }
}

/**
 * Runs the actual copy routine with error handlers and notifications.
 */
export const executeCopy = async ({
  company,
  toast,
}: {
  company: Company
  toast: (args: UseToastOptions) => void
}) => {
  let destAPI = getEnvironment() === 'production' ? 'uat' : 'production'

  // make calls to the api to get fresh revisions
  let [sourceErr, sourceAgtraxProcessorResponse] =
    await centreAPIs.getCentreAgtraxProcessorByCompanyId({
      companyId: company.id,
    })

  if (sourceErr) {
    toast({ status: 'error', title: 'Error', description: 'Failed to load source data' })
    return
  }

  let sourceAgtraxProcessor = sourceAgtraxProcessorResponse.data.data

  let copySource = Object.keys(sourceAgtraxProcessor)
    .filter((key) => !COPY_EXCLUDED_KEYS.includes(key))
    .reduce((acc, key) => ({ ...acc, [key]: sourceAgtraxProcessor[key] }), {})

  let [destErr, destCompany] = await centreAPIs.otherEnvironment.getCentreCompanyBySlug({
    slug: company.slug,
  })

  let [destProcessorErr, destProcessorResponse] =
    await centreAPIs.otherEnvironment.getCentreAgtraxProcessorByCompanyId({
      companyId: destCompany.id,
    })

  if (destErr || destProcessorErr) {
    toast({ status: 'error', title: 'Error', description: 'Failed to load destination data' })
    return
  }

  let destAgtraxProcessor = destProcessorResponse.data.data

  if (!destAgtraxProcessor || destAgtraxProcessor.length === 0) {
    // agtrax processor does not exist, create it
    let [createErr, createdAgtraxProcessor] =
      await centreAPIs.otherEnvironment.createCentreAgtraxProcessor({
        agtraxProcessor: {
          ...copySource,
          company_id: destCompany.id,
        },
      })

    if (createErr) {
      toast({ status: 'error', title: 'Error', description: 'Failed to create agtrax processor' })
      return [createErr, null]
    }

    toast({ description: `Successfully created agtrax processor in ${destAPI.toUpperCase()}` })

    return [null, createdAgtraxProcessor]
  }

  // agtrax processor exists, update its properties
  let [updateErr, updatedAgtraxProcessor] =
    await centreAPIs.otherEnvironment.updateCentreAgtraxProcessor({
      agtraxProcessor: {
        ...destAgtraxProcessor,
        ...copySource,
      },
    })

  if (updateErr) {
    toast({ status: 'error', title: 'Error', description: 'Failed to copy agtrax processor' })
    return [updateErr, null]
  }

  toast({ description: `Successfully copied agtrax processor to ${destAPI.toUpperCase()}` })

  return [null, updatedAgtraxProcessor]
}
