import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
  contractsRequests,
  ContractsUpsertConfigurationRequest,
  GetContractsConfigurationBySlugRequest,
  GetContractsConfigurationBySlugResponse,
  GetExportContractsConfigurationBySlugRequest,
  GetExportContractsConfigurationBySlugResponse,
} from 'src/api/contracts'

// Get Configuration By Slug
export const GET_CONTRACTS_CONFIGURATION_BY_SLUG_QUERY_KEY = 'get-contracts-configuration-by-slug'
export function useGetConfigurationBySlug(params: GetContractsConfigurationBySlugRequest) {
  return useQuery<GetContractsConfigurationBySlugResponse, AxiosError>({
    queryKey: [GET_CONTRACTS_CONFIGURATION_BY_SLUG_QUERY_KEY, params],
    queryFn: async () =>
      contractsRequests.getConfigurationBySlug(params).then((response) => response.data),
  })
}

// Get Export Configuration By Slug
export const GET_EXPORT_CONTRACTS_CONFIGURATION_BY_SLUG_QUERY_KEY =
  'get-export-contracts-configuration-by-slug'
export function useGetExportConfigurationBySlug(
  params: GetExportContractsConfigurationBySlugRequest
) {
  return useQuery<GetExportContractsConfigurationBySlugResponse, AxiosError>({
    queryKey: [GET_EXPORT_CONTRACTS_CONFIGURATION_BY_SLUG_QUERY_KEY, params],
    queryFn: async () =>
      contractsRequests.getExportConfigBySlug(params).then((response) => response.data),
  })
}

// Upsert Configuration
export const CONTRACTS_UPSERT_CONFIGURATION_MUTATION_KEY = 'contracts-upsert-configuration'
export function useUpsertConfiguration({
  slug,
}: Pick<ContractsUpsertConfigurationRequest, 'slug'>) {
  return useMutation({
    mutationKey: [CONTRACTS_UPSERT_CONFIGURATION_MUTATION_KEY, slug],
    mutationFn: async ({ enabled, title }: Omit<ContractsUpsertConfigurationRequest, 'slug'>) =>
      contractsRequests
        .upsertConfiguration({
          slug,
          enabled,
          title,
        })
        .then((response) => response.data),
  })
}
