import React from 'react'
import { connect } from 'react-redux'
import { useToast } from '@chakra-ui/react'
import { get } from 'lodash'
import { RootState, Dispatch } from 'src/store'

type NotificationsProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>

const Notifications: React.FC<NotificationsProps> = ({ notifications, removeNotification }) => {
  let toast = useToast()
  let [displayed, setDisplayed] = React.useState([])

  React.useEffect(() => {
    let fresh = notifications.filter((n) => !displayed.includes(n.key))

    if (fresh.length === 0) return

    fresh.forEach((n) => {
      toast({
        title: n.title,
        description: n.message,
        status: get(n, 'options.variant', 'success'),
        position: 'top-right',
        duration: get(n, 'options.autoHideDuration', 5000),
        isClosable: true,
      })

      setDisplayed((state) => [...state, n.key])
      removeNotification({ key: n.key })
    })
  }, [notifications, displayed, removeNotification, toast])

  return null
}

const mapState = ({ notifications }: RootState) => ({ notifications: notifications.notifications })

const mapDispatch = ({ notifications }: Dispatch) => ({
  removeNotification: notifications.removeNotification,
})

export default connect(mapState, mapDispatch)(Notifications)
