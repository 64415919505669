import { reportingClient } from 'src/utils/api'

type Configuration = {
  createdAt: string
  truckLicenseEnabled: boolean
  id: string
  title: string
  truckNameEnabled: boolean
  enabled: boolean
  truckDriverEnabled: boolean
  updatedAt: string
}

export type TicketsGradeFactor = {
  gradeName: string
  createdAt: string
  displayName: string
  displayOrder: number
  id: string
  updatedAt: string
}

type GradeFactorConfiguration = {
  gradeName: string
  displayName: string
  displayOrder: number
}

export type GetAllTicketsGradeFactorsRequest = {
  slug: string
}

export type GetTicketConfigurationBySlugRequest = {
  slug: string
}

export type GetTicketConfigurationBySlugResponse = {
  data: Configuration
  type: string
}

export type SyncTicketsGradeFactorsRequest = {
  gradeFactors: GradeFactorConfiguration[]
  slug: string
}

export type TicketsUpsertConfigurationRequest = {
  truckLicenseEnabled: boolean
  title: string
  truckNameEnabled: boolean
  slug: string
  enabled: boolean
  truckDriverEnabled: boolean
}

export type GetAllTicketsGradeFactorsResponse = {
  data: TicketsGradeFactor[]
  type: string
}

export type GetTicketsConfigurationBySlugResponse = { data: Configuration; type: string }

export type SyncTicketsGradeFactorsResponse = {
  data: TicketsGradeFactor[]
  type: string
}

export type TicketsUpsertConfigurationResponse = {
  data: Configuration
  type: string
}

export type TicketsIsEnabledForCompanySlugRequest = {
  slug: string
}

export type TicketsIsEnabledForCompanySlugResponse = {
  type: string
  enabled: boolean
}

export const ticketsRequests = {
  ///////////////////////////////////////////////////////////////////////////////
  // Get All Grade Factors
  ///////////////////////////////////////////////////////////////////////////////
  getAllGradeFactors: async (params: GetAllTicketsGradeFactorsRequest) =>
    reportingClient.post<GetAllTicketsGradeFactorsResponse>(
      '/tickets/v1/GetAllTicketsGradeFactors',
      params
    ),

  ///////////////////////////////////////////////////////////////////////////////
  // Get Configuration By Slug
  ///////////////////////////////////////////////////////////////////////////////
  getConfigurationBySlug: async (params: GetTicketConfigurationBySlugRequest) =>
    reportingClient.post<GetTicketConfigurationBySlugResponse>(
      '/tickets/v1/GetTicketConfigurationBySlug',
      params
    ),

  ///////////////////////////////////////////////////////////////////////////////
  // Sync Grade Factors
  ///////////////////////////////////////////////////////////////////////////////
  syncGradeFactors: async (params: SyncTicketsGradeFactorsRequest) =>
    reportingClient.post<SyncTicketsGradeFactorsResponse>(
      '/tickets/v1/SyncTicketsGradeFactors',
      params
    ),

  ///////////////////////////////////////////////////////////////////////////////
  // Upsert Configuration
  ///////////////////////////////////////////////////////////////////////////////
  upsertConfiguration: async (params: TicketsUpsertConfigurationRequest) =>
    reportingClient.post<TicketsUpsertConfigurationResponse>(
      '/tickets/v1/TicketsUpsertConfiguration',
      params
    ),

  ///////////////////////////////////////////////////////////////////////////////
  // Tickets Is Enabled
  ///////////////////////////////////////////////////////////////////////////////
  ticketsIsEnabledForCompanySlug: async (params: TicketsIsEnabledForCompanySlugRequest) =>
    reportingClient.post<TicketsIsEnabledForCompanySlugResponse>(
      '/tickets/v1/TicketsIsEnabledForCompanySlug',
      params
    ),
}
