import { useQuery, useMutation } from '@tanstack/react-query'
import { centreAPIs } from 'src/utils/api'
import { IConnectUser } from 'src/api/api'

export const useConnectUsers = (env, channelId) => {
  return useQuery({
    queryKey: ['connect-users', { env, channelId }],
    queryFn: async () => {
      let [err, response] = await centreAPIs.getConnectUsers({ channelId })
      if (err) throw err
      return response.data.data
    },
    cacheTime: 0,
  })
}

export const useConnectUserMutations = () => {
  let { mutateAsync: saveConnectUser } = useMutation({
    mutationFn: async (params: Partial<IConnectUser>) => {
      if (params.id && params._revision) {
        let [err, response] = await centreAPIs.updateConnectUser(params)
        if (err) throw err
        return [err, response]
      }

      let [err, response] = await centreAPIs.createConnectUser(params)
      if (err) throw err
      return [err, response]
    },
  })

  let { mutateAsync: deleteConnectUser } = useMutation({
    mutationFn: async ({ id }: { id: number }) => {
      let [err, response] = await centreAPIs.deleteConnectUser({ id })
      if (err) throw err
      return [err, response]
    },
  })

  return { saveConnectUser, deleteConnectUser }
}
