import { rest } from 'msw'
import {
  GetAllTicketsGradeFactorsResponse,
  GetTicketConfigurationBySlugResponse,
  TicketsIsEnabledForCompanySlugResponse,
  TicketsGradeFactor,
  SyncTicketsGradeFactorsRequest,
  SyncTicketsGradeFactorsResponse,
  TicketsUpsertConfigurationRequest,
  TicketsUpsertConfigurationResponse,
} from 'src/api/ticket-applications'
import { CONFIG } from 'src/utils/config'

export const handlers = [
  rest.post(
    `${CONFIG.reporting_api_url}/tickets/v1/GetAllTicketsGradeFactors`,
    async function (_req, res, ctx) {
      const response: GetAllTicketsGradeFactorsResponse = {
        data: [
          {
            createdAt: '2023-07-07T11:15:22Z',
            updatedAt: '2023-07-07T11:15:22Z',
            id: 'f6a5bd9f-e60c-40db-96a8-9a62f8eb5518',
            displayName: 'Mst',
            displayOrder: 0,
            gradeName: 'Moisture',
          },
        ],
        type: 'api.reporting.ticket.v1.response#TicketsGradeFactorsListResponse',
      }
      return res(ctx.json(response))
    }
  ),

  rest.post(
    `${CONFIG.reporting_api_url}/tickets/v1/GetTicketConfigurationBySlug`,
    async function (_req, res, ctx) {
      const response: GetTicketConfigurationBySlugResponse = {
        data: {
          createdAt: '2019-08-24T14:15:22Z',
          truckLicenseEnabled: true,
          id: 'f6a5bd9f-e60c-40db-96a8-9a62f8eb5519',
          title: 'Ticket Application',
          truckNameEnabled: false,
          enabled: true,
          truckDriverEnabled: true,
          updatedAt: '2019-08-24T14:15:22Z',
        },
        type: 'api.reporting.ticket.v1.response#TicketsConfigurationGetResponse',
      }
      return res(ctx.json(response))
    }
  ),

  rest.post(
    `${CONFIG.reporting_api_url}/tickets/v1/SyncTicketsGradeFactors`,
    async function (req, res, ctx) {
      const { gradeFactors } = (await req.json<SyncTicketsGradeFactorsRequest>()) ?? {}

      const data = gradeFactors.map<TicketsGradeFactor>((gradeFactorConfiguration) => ({
        createdAt: '2023-07-06T14:15:22Z',
        updatedAt: '2023-07-06T20:15:22Z',
        id: 'f6a5bd9f-e60c-40db-96a8-9a62f8eb5518',
        displayName: gradeFactorConfiguration.displayName,
        displayOrder: gradeFactorConfiguration.displayOrder,
        gradeName: gradeFactorConfiguration.gradeName,
      }))

      const response: SyncTicketsGradeFactorsResponse = {
        data: data,
        type: 'api.reporting.ticket.v1.response#TicketsGradeFactorsListResponse',
      }

      return res(ctx.json(response))
    }
  ),

  rest.post(
    `${CONFIG.reporting_api_url}/tickets/v1/TicketsIsEnabledForCompanySlug`,
    async function (_req, res, ctx) {
      const response: TicketsIsEnabledForCompanySlugResponse = {
        enabled: true,
        type: 'api.reporting.ticket.v1.response#TicketsConfigurationGetResponse',
      }
      return res(ctx.json(response))
    }
  ),

  rest.post(
    `${CONFIG.reporting_api_url}/tickets/v1/TicketsUpsertConfiguration`,
    async function (req, res, ctx) {
      const { enabled, title, truckLicenseEnabled, truckNameEnabled, truckDriverEnabled } =
        (await req.json<TicketsUpsertConfigurationRequest>()) ?? {}

      const response: TicketsUpsertConfigurationResponse = {
        data: {
          createdAt: '2019-08-24T14:15:22Z',
          truckLicenseEnabled,
          id: 'f6a5bd9f-e60c-40db-96a8-9a62f8eb5518',
          title,
          truckNameEnabled,
          enabled,
          truckDriverEnabled,
          updatedAt: '2019-08-24T14:15:22Z',
        },
        type: 'api.reporting.ticket.v1.response#TicketsConfigurationGetResponse',
      }

      return res(ctx.json(response))
    }
  ),
]
