import { removeAxiosHeader, removeAxiosAuthInterceptor } from 'src/utils/api'
import { CONFIG } from 'src/utils/config'

export interface AuthState {
  username: string
}

const AuthModel = {
  state: {
    username: null,
    ...CONFIG,
  },

  reducers: {
    clearAuth(_state: AuthState): AuthState {
      removeAxiosHeader('Authorization')
      removeAxiosAuthInterceptor()

      return {
        username: null,
      }
    },
  },
}

export default AuthModel
