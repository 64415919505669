import { rest } from 'msw'
import {
  GetAllGradeFactorsResponse,
  GetConfigurationBySlugResponse,
  GradeFactor,
  SyncGradeFactorsRequest,
  SyncGradeFactorsResponse,
  UpsertConfigurationRequest,
  UpsertConfigurationResponse,
} from 'src/api/proof-of-yield'
import { CONFIG } from 'src/utils/config'

export const handlers = [
  rest.post(
    `${CONFIG.reporting_api_url}/poy/v1/GetAllGradeFactors`,
    async function (_req, res, ctx) {
      const response: GetAllGradeFactorsResponse = {
        data: [
          {
            createdAt: '2023-07-07T11:15:22Z',
            updatedAt: '2023-07-07T11:15:22Z',
            id: 'f6a5bd9f-e60c-40db-96a8-9a62f8eb5518',
            displayName: 'Mst',
            displayOrder: 0,
            gradeName: 'Moisture',
          },
        ],
        type: 'api.poyreports.v1.response#GradeFactorsListResponse',
      }
      return res(ctx.json(response))
    }
  ),

  rest.post(
    `${CONFIG.reporting_api_url}/poy/v1/GetConfigurationBySlug`,
    async function (_req, res, ctx) {
      const response: GetConfigurationBySlugResponse = {
        data: {
          createdAt: '2023-07-06T14:15:22Z',
          updatedAt: '2023-07-06T14:15:22Z',
          id: 'f6a5bd9f-e60c-40db-96a8-9a62f8eb5518',
          title: 'Proof of Yield',
          enabled: true,
        },
        type: 'api.poyreports.v1.response#ConfigurationGetResponse',
      }
      return res(ctx.json(response))
    }
  ),

  rest.post(`${CONFIG.reporting_api_url}/poy/v1/SyncGradeFactors`, async function (req, res, ctx) {
    const { gradeFactors } = (await req.json<SyncGradeFactorsRequest>()) ?? {}

    const data = gradeFactors.map<GradeFactor>((gradeFactorConfiguration) => ({
      createdAt: '2023-07-06T14:15:22Z',
      updatedAt: '2023-07-06T20:15:22Z',
      id: 'f6a5bd9f-e60c-40db-96a8-9a62f8eb5518',
      displayName: gradeFactorConfiguration.displayName,
      displayOrder: gradeFactorConfiguration.displayOrder,
      gradeName: gradeFactorConfiguration.gradeName,
    }))

    const response: SyncGradeFactorsResponse = {
      data: data,
      type: 'api.poyreports.v1.response#GradeFactorsListResponse',
    }

    return res(ctx.json(response))
  }),

  rest.post(
    `${CONFIG.reporting_api_url}/poy/v1/UpsertConfiguration`,
    async function (req, res, ctx) {
      const { enabled, title } = (await req.json<UpsertConfigurationRequest>()) ?? {}

      const response: UpsertConfigurationResponse = {
        data: {
          createdAt: '2023-07-07T14:15:22Z',
          updatedAt: '2023-07-07T14:15:22Z',
          id: 'f6a5bd9f-e60c-40db-96a8-9a62f8eb5518',
          title,
          enabled,
        },
        type: 'api.poyreports.v1.response#ConfigurationGetResponse',
      }

      return res(ctx.json(response))
    }
  ),
]
