import React from 'react'
import { Box, Row, Button } from 'src/ui'
import { Formik, Form, Field } from 'formik'
import { UseMutateAsyncFunction } from '@tanstack/react-query'
import * as api from 'src/utils/api'
import { TextField, Select } from 'src/ui/formik'
import * as Yup from 'yup'
import { Table2 as Table } from 'src/ui'

interface NewMappingFormProps {
  company: Company
  setShowNewForm?: (val: boolean) => void
  availableLocations: Array<object>
  saveLocationConfig: UseMutateAsyncFunction<
    api.ApiResponse<OfferManagementMapping>,
    unknown,
    {
      locationConfig: OfferManagementMapping
      onSuccess?: () => void
    },
    unknown
  >
}

const NewMappingForm = ({
  availableLocations,
  saveLocationConfig,
  setShowNewForm,
  company,
}: NewMappingFormProps) => {
  const cashBidProductMapJSON = (array) => {
    let values = []
    array.forEach((commodity) => {
      let product = { name: commodity, cash: '', flat: '', basis: '', hedge: '' }
      values.push(product)
    })
    return values
  }

  const finalFormat = (array): Array<object> => {
    let cash_bid_product_map_json = []
    array.forEach((product) => {
      if (product.cash || product.flat || product.basis || product.hedge !== '') {
        cash_bid_product_map_json.push(product)
      }
    })
    return cash_bid_product_map_json
  }

  const FormSchema = Yup.object().shape({
    elevator_id: Yup.number().required('Required'),
    cqg_location_name: Yup.string().required('Required'),
    cash_bid_product_map_json: Yup.array()
      .of(
        Yup.object().shape({
          cash: Yup.string(),
          flat: Yup.string(),
          basis: Yup.string(),
          hedge: Yup.string(),
        })
      )
      .test('hasAMapping', 'You must set a mapping for a commodity to submit', (value) =>
        value.some((mapping) => mapping.cash || mapping.flat || mapping.basis || mapping.hedge)
      ),
  })

  const availableCommodities = [
    'Ethanol',
    'Corn',
    'Soybean',
    'Soybean Oil',
    'Soybean Meal',
    'Spring Wheat',
    'Wheat',
    'Hard Red Wheat',
    'Live Cattle',
    'Feeder Cattle',
    'Lean Hogs',
  ]
  return (
    <Formik
      initialValues={{
        company_id: company.id,
        elevator_id: '',
        cash_bid_product_map_json: cashBidProductMapJSON(availableCommodities),
        cqg_location_name: '',
      }}
      validateOnChange={true}
      validationSchema={FormSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true)
        values.cash_bid_product_map_json = finalFormat(values.cash_bid_product_map_json)

        await saveLocationConfig({
          locationConfig: {
            futures_product_map_json: null,
            created_at: null,
            updated_at: null,
            id: null,
            ...values,
          },
          onSuccess: () => {
            setShowNewForm(false)
            setSubmitting(false)
          },
        })
        //send them to the server
      }}
    >
      {({ errors, isSubmitting }) => (
        <Form>
          <Box py={2} mb={4} borderRadius={3} bg="white" boxShadow="md">
            <Row justifyContent="space-between" alignItems="center" px={6} cursor="pointer" mb={2}>
              <Box flex={1}>
                <Row width="100%" height="62px" justifyContent="space-between" alignItems="center">
                  <>
                    <Row alignItems="center" mb={5} mt={4}>
                      <Box fontSize="15px" mr={5} mt={6}>
                        <Field
                          name={`elevator_id`}
                          label={'Bushel Location Name'}
                          component={Select}
                          placeholder={'Choose Bushel Location'}
                          options={availableLocations.map((location: BushelLocation) => ({
                            value: location.id,
                            label: location.name,
                            key: location.id,
                          }))}
                        />
                      </Box>
                      <Box fontSize="15px" mt={6}>
                        <Field
                          label={'CQG Location Name'}
                          name={`cqg_location_name`}
                          value={`cqg_location_name`}
                          component={TextField}
                          placeholder={'Enter CQG Location Name'}
                        ></Field>
                      </Box>
                    </Row>
                    <p style={{ color: 'red' }}>{errors.cash_bid_product_map_json as string}</p>
                    <Box pr={2}>
                      <Button
                        key="cancel"
                        width="76px"
                        size="sm"
                        colorScheme="secondary"
                        mr={2}
                        onClick={() => setShowNewForm(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        key="save"
                        type="submit"
                        width="76px"
                        size="sm"
                        colorScheme="primary"
                        isDisabled={isSubmitting}
                        isLoading={isSubmitting}
                      >
                        Save
                      </Button>
                    </Box>
                  </>
                </Row>
              </Box>
              <Box></Box>
            </Row>

            <Table striped>
              <thead className="thead">
                <tr>
                  <th>Commodity</th>
                  <th>Cash</th>
                  <th>Flat</th>
                  <th>Basis</th>
                  <th>Hedge</th>
                </tr>
              </thead>
              <tbody>
                {availableCommodities.map((product, index) => (
                  <tr key={product}>
                    <td css={{ width: '200px' }}>
                      <Box>
                        <p>{product}</p>
                      </Box>
                    </td>
                    <td css={{ width: '250px' }}>
                      <Box flex={1} minWidth={0} height={'40px'} mt={1} mb={0}>
                        <Field
                          name={`cash_bid_product_map_json.${index}.cash`}
                          component={TextField}
                          size="sm"
                          placeholder={product}
                        />
                      </Box>
                    </td>
                    <td css={{ width: '250px' }}>
                      <Box flex={1} minWidth={0} height={'40px'} mt={1} mb={0}>
                        <Field
                          name={`cash_bid_product_map_json.${index}.flat`}
                          component={TextField}
                          size="sm"
                          placeholder={product}
                        />
                      </Box>
                    </td>
                    <td css={{ width: '250px' }}>
                      <Box flex={1} minWidth={0} height={'40px'} mt={1} mb={0}>
                        <Field
                          name={`cash_bid_product_map_json.${index}.basis`}
                          component={TextField}
                          size="sm"
                          placeholder={product}
                        />
                      </Box>
                    </td>
                    <td css={{ width: '250px' }}>
                      <Box flex={1} minWidth={0} height={'40px'} mt={1} mb={0}>
                        <Field
                          name={`cash_bid_product_map_json.${index}.hedge`}
                          component={TextField}
                          size="sm"
                          placeholder={product}
                        />
                      </Box>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default NewMappingForm
