import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { Box, Button, Column, ConfigCard, Dialog, FormRow, Stack } from 'src/ui'
import * as Yup from 'yup'
import { Field, Form, Formik, FormikProps } from 'formik'
import { TextField as FormikTextField } from 'src/ui/formik'
import { IConnectCustomer } from 'src/api/api'
import { useConnectCustomerMutations } from 'src/api/queries/connect-customers'
import { useToast } from 'src/utils/toast'
import { hasPermission } from 'src/utils/permissions'
import { getEnvironment } from 'src/utils'

interface ConnectCustomerFormProps {
  connectCustomers: IConnectCustomer[]
  header: () => void
}

interface CustomerFormProps {
  id: number
  name: string
  _revision?: string
}

const customerFormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
})

const textProps = {
  height: '62px',
  mb: null,
}

export const CustomerForm: React.FC<ConnectCustomerFormProps> = ({ connectCustomers, header }) => {
  const { connectCustomerId } = useParams()

  let { saveConnectCustomer, deleteConnectCustomer } = useConnectCustomerMutations()
  let toast = useToast()
  const queryClient = useQueryClient()
  let [isDeleting, setIsDeleting] = React.useState(false)
  let [isSubmitting, setIsSubmitting] = React.useState(false)
  let [showDeleteDialog, setShowDeleteDialog] = React.useState(false)

  let currentCustomer = connectCustomers?.find(
    (customer) => customer.id === parseInt(connectCustomerId)
  )

  const navigate = useNavigate()

  return (
    <>
      <Formik
        initialValues={{
          id: parseInt(connectCustomerId),
          name: currentCustomer?.name,
          _revision: currentCustomer?._revision,
        }}
        validationSchema={customerFormSchema}
        onSubmit={async ({ id, name, _revision }, _formikActions) => {
          setIsSubmitting(true)
          await saveConnectCustomer(
            {
              id,
              name,
              _revision,
            },
            {
              onError: (error) => {
                // @ts-ignore
                _formikActions.setErrors(error?.response?.data?.validation_messages)
                toast({
                  status: 'error',
                  description: `Failed to ${id ? 'update' : 'create'} customer`,
                })
              },
              onSuccess: () => {
                toast({
                  description: `Successfully ${id ? 'updated' : 'created'} customer `,
                })
              },
              onSettled: async (res) => {
                await queryClient.invalidateQueries({ queryKey: ['connect-customers'] })
                setIsSubmitting(false)
                // res is undefined onError, redirect when defined
                if (res) {
                  navigate(`/connect/${getEnvironment()}/customers`)
                }
              },
            }
          )
        }}
      >
        {(formikProps: FormikProps<CustomerFormProps>) => (
          <>
            {header}
            <ConfigCard
              header={
                <Box mb={6}>
                  <Button
                    width="76px"
                    size="sm"
                    mr={2}
                    colorScheme="secondary"
                    isDisabled={
                      !hasPermission('connect_customers_edit') ||
                      isDeleting ||
                      !currentCustomer ||
                      isSubmitting
                    }
                    onClick={() => {
                      setShowDeleteDialog(true)
                    }}
                  >
                    DELETE
                  </Button>
                  <Button
                    as={Link}
                    to={`/connect/${getEnvironment()}/customers`}
                    width="76px"
                    size="sm"
                    mr={2}
                    colorScheme="secondary"
                    isDisabled={isSubmitting}
                    isLoading={false}
                  >
                    CANCEL
                  </Button>
                  <Button
                    width="76px"
                    size="sm"
                    mr={2}
                    colorScheme="primary"
                    isDisabled={!hasPermission('connect_customers_edit') || isSubmitting}
                    isLoading={isSubmitting}
                    onClick={() => {
                      formikProps.submitForm()
                    }}
                  >
                    SAVE
                  </Button>
                </Box>
              }
            >
              <Form>
                <Stack spacing={2}>
                  <FormRow rowProps={{ mb: 5 }}>
                    <Field
                      name="name"
                      label="Name"
                      component={FormikTextField}
                      formControlProps={{ ...textProps }}
                    />
                  </FormRow>
                </Stack>
              </Form>
            </ConfigCard>
          </>
        )}
      </Formik>
      <Dialog
        title="Delete Connect Customer"
        isOpen={showDeleteDialog}
        onClose={() => setIsDeleting(false)}
        actions={
          <>
            <Button size="sm" variant="ghost" onClick={() => setShowDeleteDialog(false)} mr={2}>
              Cancel
            </Button>

            <Button
              size="sm"
              colorScheme="primary"
              isLoading={isDeleting}
              onClick={async () => {
                setIsDeleting(true)
                await deleteConnectCustomer(currentCustomer, {
                  onSuccess: async () => {
                    await queryClient.invalidateQueries({ queryKey: ['connect-customers'] })
                    toast({ description: 'Successfully deleted Connect Customer' })
                    navigate(`/connect/${getEnvironment()}/customers`)
                  },
                  onError: () => {
                    toast({ status: 'error', description: 'Failed to delete Connect Customer' })
                  },
                  onSettled: () => {
                    setShowDeleteDialog(false)
                  },
                })
              }}
            >
              Delete
            </Button>
          </>
        }
      >
        <Column>
          <Box>
            <Box>Are you sure you want to delete this Connect Customer</Box>

            {currentCustomer && (
              <ul>
                <li>{currentCustomer.name}</li>
              </ul>
            )}
          </Box>
        </Column>
      </Dialog>
    </>
  )
}
