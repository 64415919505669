import { get, intersection } from 'lodash'
import { getAuthUser } from 'src/utils/auth'
import { CONFIG } from './config'

type Roles = 'admin' | 'editor' | 'viewer' | 'billing_admin'

interface ResourceRules {
  [key: string]: Roles[]
}

const RESOURCE_RULES: ResourceRules = {
  company_selection: ['admin', 'editor', 'viewer'],

  // navigation
  nav_company: ['admin', 'editor', 'viewer'],
  nav_connect: ['admin', 'editor', 'viewer'],
  nav_translators: ['admin'],
  nav_endpoints: ['admin'],
  nav_reports: ['billing_admin'],

  // companies
  companies_edit: ['admin', 'editor'],

  // company properties
  company_properties_copy: ['admin', 'editor'],
  company_properties_edit: ['admin', 'editor'],

  // company (v1) translators
  company_v1_translators_copy: ['admin', 'editor'],
  company_v1_translators_edit: ['admin', 'editor'],

  // company config
  company_admin_create: ['admin'],
  company_config_copy: ['admin', 'editor'],
  company_config_edit: ['admin', 'editor'],

  // company cashbid
  company_cashbid_edit: ['admin', 'editor'],

  // company locations (elevators)
  company_locations_copy: ['admin', 'editor'],
  company_locations_edit: ['admin', 'editor'],

  // company positions
  company_positions_copy: ['admin', 'editor'],
  company_positions_edit: ['admin', 'editor'],

  // company commodities
  company_commodities_copy: ['admin', 'editor'],
  company_commodities_edit: ['admin', 'editor'],

  // connect
  connect_customers_edit: ['admin', 'editor'],
  connect_channels_edit: ['admin', 'editor'],
  connect_subscriptions_edit: ['admin', 'editor'],
  connect_users_edit: ['admin', 'editor'],

  // reports
  billing_reports: ['billing_admin'],

  //OMS
  offer_management_edit: ['admin', 'editor'],
}

///////////////////////////////////////////////////////////////////////////////

let AUTH_USER_ROLES: string[] = null

const loadUserRoles = () => {
  const authUser = getAuthUser()

  if (!authUser) {
    AUTH_USER_ROLES = JSON.parse(process.env.REACT_APP_USER_PERMISSIONS || '[]')
    console.log('[permissions] no cookie, using env.local roles', AUTH_USER_ROLES)
    return
  }

  let scopedRoles = get(authUser, CONFIG.keycloak_token_role_claim, null)

  if (scopedRoles) {
    console.log('[permissions] using scoped roles', scopedRoles)
    AUTH_USER_ROLES = scopedRoles
    return
  }

  console.warn('[permissions] unable to determine any roles')
}

export function getUserRoles(): string[] {
  const authUser = getAuthUser()
  return get(authUser, CONFIG.keycloak_token_role_claim, null)
}

export const hasPermission = (resource: string): boolean => {
  if (!!process.env.REACT_APP_MOCK_MODE) return true

  if (!AUTH_USER_ROLES) loadUserRoles()

  if (!RESOURCE_RULES[resource]) {
    console.warn(`[permissions] unknown resource '${resource}'`)
    return false
  }

  return intersection(RESOURCE_RULES[resource], AUTH_USER_ROLES).length > 0
}

export const hasUserRoles = (): boolean => {
  if (!!process.env.REACT_APP_MOCK_MODE) return true

  let authUser = getAuthUser()
  let scopedRoles = get(authUser, CONFIG.keycloak_token_role_claim, [])
  return (
    ['viewer', 'admin', 'billing_admin', 'editor'].filter((item) => scopedRoles.includes(item))
      .length > 0
  )
}
