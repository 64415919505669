import React, { useCallback, useState, memo } from 'react'
import { get } from 'lodash'
import { FastField, FieldArray, FormikProps } from 'formik'
import { Box, Column, Row, Panel, FormRow, IconButton, Tooltip, DeleteIcon, AddIcon } from 'src/ui'
import { TextField as FormikTextField, Switch as FormikSwitch } from 'src/ui/formik'
import { newPool, randKey } from 'src/translators/form/utils'

interface PoolProps {
  index: number
  expanded: boolean
  pool: TranslatorConfigPool
  toggleExpanded: (key: string, expanded: boolean) => void
  deletePool: (index: number) => void
}

const Pool = memo(function Pool({ index, expanded, pool, toggleExpanded, deletePool }: PoolProps) {
  let scopedFieldName = useCallback(
    (scopedFieldNameKey: string) => `pools[${index}].${scopedFieldNameKey}`,
    [index]
  )
  const { key, name } = pool

  return (
    <Panel
      expanded={expanded}
      onChange={(_e, isExpanded) => toggleExpanded(key, isExpanded)}
      header={
        <Row width="100%" justifyContent="space-between" alignItems="center">
          <Box>{name ? <strong>{name}</strong> : <em>new_pool</em>}</Box>
          <Box>
            <Tooltip label="Remove Pool" aria-label="Remove Pool" placement="left">
              <IconButton
                icon={<DeleteIcon />}
                aria-label="Delete"
                isRound
                variant="ghost"
                color="gray.600"
                onClick={(e) => {
                  e.stopPropagation()
                  deletePool(index)
                }}
              />
            </Tooltip>
          </Box>
        </Row>
      }
    >
      <Column width="100%" pr={1} pl={1}>
        <FormRow>
          <FastField name={scopedFieldName('name')} label="Pool Name" component={FormikTextField} />

          <Row justifyContent="center" />
        </FormRow>

        <FormRow>
          <FastField
            name={scopedFieldName('max_connections')}
            label="Max Connections"
            component={FormikTextField}
          />
          <FastField
            name={scopedFieldName('timeout')}
            label="Timeout (ms)"
            component={FormikTextField}
          />
        </FormRow>

        <FormRow>
          <FastField
            name={scopedFieldName('rate_limit_count')}
            label="Rate Limit Count"
            component={FormikTextField}
          />
          <FastField
            name={scopedFieldName('rate_limit_seconds')}
            label="Rate Limit (sec)"
            component={FormikTextField}
          />
        </FormRow>

        <Row justifyContent="space-between" alignItems="center" fontWeight="bold" mb={3}>
          <Box>JDBC Info</Box>

          <Box>
            <FastField
              name={scopedFieldName('jdbc_info.legacy_driver')}
              label="Legacy Driver"
              component={FormikSwitch}
              SwitchBoxProps={{ pl: 2 }}
            />
          </Box>
        </Row>

        <FormRow>
          <FastField
            name={scopedFieldName('jdbc_info.connection_url')}
            label="Connection URL"
            component={FormikTextField}
          />
          <FastField
            name={scopedFieldName('jdbc_info.fully_qualified_driver_name')}
            label="Fully Qualified Driver Name"
            component={FormikTextField}
          />
        </FormRow>

        <FormRow>
          <FastField
            name={scopedFieldName('jdbc_info.connection_address')}
            label="Connection Address"
            component={FormikTextField}
          />
          <FastField
            name={scopedFieldName('jdbc_info.connection_port')}
            label="Connection Port"
            component={FormikTextField}
          />
        </FormRow>

        <FormRow>
          <FastField
            name={scopedFieldName('jdbc_info.user_name')}
            label="Username"
            component={FormikTextField}
          />
          <FastField
            name={scopedFieldName('jdbc_info.password')}
            label="Password"
            component={FormikTextField}
          />
        </FormRow>
        <FormRow>
          <FastField
            name={scopedFieldName('jdbc_info.consul_service_name')}
            label="Consul Service Name"
            component={FormikTextField}
          />
        </FormRow>
      </Column>
    </Panel>
  )
})
///////////////////////////////////////////////////////////////////////////////

const hasErrors = ({ formikProps, index }: { formikProps: FormikProps<any>; index: number }) => {
  if (formikProps.submitCount === 0) return false
  let errs = get(formikProps.errors, `pools[${index}]`)
  if (!errs) return false
  return Object.keys(errs).length > 0
}

interface PoolListProps {
  formikProps: FormikProps<TranslatorConfigSchedule>
}

function PoolList({ formikProps }: PoolListProps) {
  const [expanded, setExpanded] = useState<string[]>(() =>
    formikProps.values.pools.map((p) => p.key)
  )

  const toggleExpanded = useCallback((key: string, isExpanded: boolean) => {
    isExpanded
      ? setExpanded((state) => [...state, key])
      : setExpanded((state) => state.filter((k) => k !== key))
  }, [])

  return (
    <FieldArray
      name="pools"
      render={(arrayHelpers) => (
        <Column width="100%" borderRadius={3} mb={5}>
          <Row
            justifyContent="space-between"
            alignItems="center"
            height="3.5rem"
            bg="#eee"
            borderRadius={3}
            fontWeight="bold"
            pr={2}
            pl={3}
            mb={3}
          >
            <Row alignItems="center">
              <Box>Pools</Box>
            </Row>

            <Row alignItems="center">
              <Box>
                <Tooltip label="Add Pool" aria-label="Add Pool" placement="top">
                  <IconButton
                    icon={<AddIcon />}
                    aria-label="Add Subscription"
                    isRound
                    variant="ghost"
                    color="gray.600"
                    onClick={() => {
                      let pool = {
                        ...newPool,
                        key: randKey(),
                        // usually only one, so default name if it's first
                        name: formikProps.values.pools.length === 0 ? 'default' : newPool.name,
                      }
                      arrayHelpers.unshift(pool)
                      setExpanded((state) => {
                        return [...state, pool.key]
                      })
                    }}
                  />
                </Tooltip>
              </Box>
            </Row>
          </Row>

          {formikProps.values.pools.map((pool, index) => (
            <Pool
              key={pool.key}
              index={index}
              pool={pool}
              expanded={expanded.includes(pool.key) || hasErrors({ formikProps, index })}
              toggleExpanded={toggleExpanded}
              deletePool={arrayHelpers.remove}
            />
          ))}

          {formikProps.values.pools.length === 0 && (
            <Row px={3}>
              <em>No pools added yet</em>
            </Row>
          )}
        </Column>
      )}
    />
  )
}

export default PoolList
