import React from 'react'
import { Box, Column, Dialog, Button } from 'src/ui'

interface DeleteDialogProps extends DialogProps {
  componentTitle: String
}

export const DeleteDialog: React.FC<DeleteDialogProps> = ({
  isOpen,
  onClose,
  onConfirm,
  componentTitle,
}) => {
  let [isLoading, setIsLoading] = React.useState(false)
  return (
    <Dialog
      title={`Delete ${componentTitle}`}
      isOpen={isOpen}
      onClose={onClose}
      actions={
        <>
          <Button size="sm" variant="ghost" mr={2} onClick={onClose}>
            Cancel
          </Button>

          <Button
            size="sm"
            colorScheme="primary"
            onClick={async () => {
              setIsLoading(true)
              await onConfirm()
              setIsLoading(false)
            }}
            isLoading={isLoading}
          >
            Delete
          </Button>
        </>
      }
    >
      <Column>
        <Box mb={3}>Are you sure you want to delete this {componentTitle.toLowerCase()}?</Box>
      </Column>
    </Dialog>
  )
}
