import { IAdminUser, IAgtraxProcessor, IAgtraxAPI, ICompanyMetadata } from 'src/api/api'
import { sequence } from './utils'

export const metadataOptions = {
  erp_type: [
    'ab_inbev',
    'agris_pervasive',
    'agris_sql',
    'agris_agtegra',
    'agtrax',
    'agvance_cloud',
    'agvance_on_prem',
    'agvantage',
    'cinch_intelligo',
    'dbc_smartsoft_oracle',
    'dbc_smartsoft_sql',
    'efc',
    'granular',
    'grossmans_flat_files',
    'grossmans_sql',
    'irely_i21',
    'irely_sql',
    'lansing',
    'reap',
    'riceland',
    'scoular',
    'tharaldson',
    'vertical_software',
  ],
  data_ingestion_type: ['flat_file', 'pull', 'push_partner'],
  translator_version: ['v1', 'v2', 'hybrid'],
}

export const metadata: ICompanyMetadata = {
  android_release_version: null,
  comments: null,
  company_id: 1,
  created_at: '2022-11-08T15:12:07+00:00',
  data_ingestion_type: null,
  erp_type: null,
  hubspot_company_id: null,
  id: 3,
  ios_release_version: null,
  translator_version: null,
  updated_at: '2022-11-08T15:12:07+00:00',
  _revision: `fake-revision-${sequence()}`,
}

export function createCmsUser(values?: any): IAdminUser {
  const id = sequence()
  return {
    id: id,
    first_name: values?.firstName ?? 'CMS Demo',
    last_name: values?.lastName ?? 'User',
    admin: values?.isAdmin ?? true,
    global_push: true,
    global_esign: true,
    data_viewer: true,
    reporting: true,
    payments: false,
    payment_history: false,
    last_login: null,
    created_at: '2022-04-19 17:15:26',
    updated_at: '2022-04-27 23:40:28',
    deleted_at: null,
    user_phones: [],
    user_emails: [
      {
        created_at: '2022-04-19 17:15:26',
        deleted_at: null,
        email: values?.email ?? 'testdevice+r1-bushelsalesdemo@bushelpowered.com',
        id: id * 3,
        primary: 1,
        type: 'cms',
        updated_at: '2022-09-19 20:03:25',
        user_id: id,
        verified: 1,
        _revision: `fake-revision-${id}`,
      },
    ],
  }
}

export const cmsUsers: IAdminUser[] = [createCmsUser()]

export const agtraxProcessor: IAgtraxProcessor = {
  company_id: 3,
  created_at: '2022-04-19T17:18:26+00:00',
  id: 3,
  last_processed_agtrax_log_id: 0,
  type: 'App\\Services\\CommandRunners\\Base',
  updated_at: '2022-04-19T17:18:26+00:00',
  _revision: `fake-revision-${sequence()}`,
}

export const agtraxApi: Partial<IAgtraxAPI> = {}

export const companyConfig: CompanyConfig = {
  authentication_alternate_fields: null,
  authentication_auto_email: false,
  authentication_auto_phone: false,
  authentication_strategy: 'bushel_identity_keycloak',
  authentication: 'Internal_1.0.0',
  basis_price_adjustments: null,
  bulk_contract: null,
  bulk_splits: null,
  bulk_tickets: null,
  cash_bids: '1.0.0',
  commodities: null,
  commodity_balances: '1.0.0',
  company: null,
  contracts: '2.0.0',
  data_push_partners: null,
  delivery_tickets: '1.0.0',
  esign: '1.0.0',
  failed_logins: null,
  field_ids: '1.0.0',
  futures: '1.0.0',
  account_id_selector: 'full_name',
  alternative_feature_names: {
    prepaid_contracts: 'Prepaids & Bookings',
  },
  authentication_alternate_identifier: null,
  blacklist_locations_remote_ids: null,
  brand_logo: null,
  cash_bids_disclaimer_image_link: {
    display_text: null,
    link: 'https://www.cmegroup.com',
  },
  cash_bids_disclaimer_image_link2: null,
  cash_bids_disclaimer_image_url: null,
  cash_bids_disclaimer_image_url2: null,
  cash_bids_disclaimer_link_one: null,
  cash_bids_disclaimer_link_two: null,
  cash_bids_disclaimer: 'Quotes delayed a minimum of ten minutes.',
  cash_bids_only_business_locations: false,
  cash_bids_require_login: false,
  commodity_denylist_outbound_tickets: [],
  contract_account_id_mapping: null,
  contract_contracted_location_detail: true,
  contract_delivery_location_detail: true,
  contract_header_location: 'contract',
  contract_type_display_format: 'type',
  cqg_company_name: 'HMS Demo',
  cqg_new_offer_terms:
    'By submitting this offer, you understand that you are entering into a binding offer to sell your grain to a buyer pursuant to a cash grain contract with the buyer at the prices set forth in the offer.',
  custom_entities_payload: null,
  custom_entities: null,
  custom_menu_links: [
    {
      display_text: 'Discount Schedule',
      link: 'https://bushelpowered.com/',
    },
  ],
  decimal_place_format: {
    price: 2,
    amount: 2,
    weight: 2,
    cash_bid: 2,
  },
  deduplicate_filters: false,
  delivery_ticket_comments_hide: false,
  delivery_ticket_loaded_at_time_hide: false,
  disable_make_offer: false,
  display_options: null,
  display_user_from_ticket: false,
  dtn_offer_account_identifier: 'phone_number',
  dtn_provider: false,
  enable_local_auth: false,
  esign_reminder: true,
  failed_login_description:
    "Sorry, we couldn't find any accounts that belong to you. Please contact {companyName} to update your account.",
  failed_login_details: null,
  favorite_locations_notifications: true,
  filter_out_zero_splits: true,
  futures_disclaimer_image_link2: null,
  futures_disclaimer_image_url:
    'https://d2yc2xkes0y1h8.cloudfront.net/scaletickets/xK9qjCVSpPUjgWSySQkbHSdFisGxNwV8W3UIQfvrmFveRvF5fGzuaRoGr66FK456.png',
  futures_disclaimer_image_url2: null,
  futures_disclaimer_url: 'https://www.cmegroup.com/',
  futures_disclaimer:
    "Market data provided by Bushel. Information is provided 'as is' and solely for informational purposes, not for trading purposes or advice, and is delayed per exchange requirements.",
  futures_sort_order:
    'corn,soybeans,ethanol_futures,soybean_oil,soybean_meal,spring_wheat,wheat,hard_red_wheat,live_cattle,feeder_cattle,lean_hogs,rough_rice,cotton,oats',
  has_translator: true,
  hide_account_identifier_on_ticket_detail: false,
  hide_bushel_branding: false,
  ingest_mapping: null,
  insights: null,
  internal_custom_weblinks: null,
  landing_feature: null,
  local_cash_bids: '1.0.0',
  login_description: null,
  meta: '1.0.0',
  name_sync: false,
  notifications: '1.0.0',
  offers: '1.0.0',
  outbound_tickets: '1.0.0',
  prepaid_contracts: '1.1.0',
  price_amendment_delivery_details: false,
  price_amendment_sort_method: 'by_sequence',
  primary_color: null,
  remarks_whitelist: null,
  reports_logged_in_users_custom_range: null,
  resource_links: null,
  resources: null,
  secondary_color: null,
  settlement_account_id_mapping: null,
  settlements: '1.0.0',
  show_comments_on_settlements: true,
  show_comments_on_tickets: true,
  show_contract_applied_amount_on_ticket_details: false,
  show_currency_on_contracts: false,
  show_generic_splitname_on_ticket_list: true,
  show_ticket_with_delivery_account_id: false,
  splits: null,
  sso_idp_hint: 'bushel-phone-oidc',
  sync_users_in_chunks: false,
  ticket_addendum_displaynames: {
    crop_year: {
      display_name: 'Crop Year',
      partner_key: 'crop_year',
    },
    'Field Name': {
      display_name: 'Field Name',
      partner_key: 'Field Name',
    },
    'Lot Number': {
      display_name: 'Lot Number',
      partner_key: 'Lot Number',
    },
    'Farm Name': {
      display_name: 'Farm Name',
      partner_key: 'Farm Name',
    },
  },
  ticket_addendum_whitelist_filters: 'Crop Year,Farm Name,Field Name,Lot Number',
  ticket_addendum_whitelist: 'Crop Year,Farm Name,Field Name,Lot Number',
  ticket_list_account_id_mapping: null,
  ticket_list_remarks: null,
  tickets: '1.0.0',
  trade: '1.0.0',
  transactions: '1.0.0',
  users: null,
  wait_time: null,
  weather: null,
  website: null,
  work_order_comments_hide: false,
  work_order_weather_data_hide: false,
  work_orders: '1.0.0',
  _revision: '41',
  price_later: null,
  ye_old_tickets: 'false',
  payments: null,
  ticket_application_status_source: null,
  ticket_ignore_timezone: false,
}

export const configurables: CompanyConfigurables = {
  '': {
    reports_logged_in_users_custom_range: { default: null, type: 'string' },
    _revision: { default: '0', type: 'string' },
  },
  features: {
    authentication: {
      label: 'Authentication',
      description: 'Login for the mobile apps',
      tech_description:
        "Setting this to the value 'unsupported' greys out the 'login' button from the mobile app side.",
      group: 'features',
      options: ['1.0.0', 'Agvantage_1.0.0', 'Agtrax_1.1.0', 'Internal_1.0.0', 'unsupported'],
      default: null,
      type: 'string',
    },
    authentication_strategy: {
      label: 'Authentication Strategy',
      description: 'Determine method used for user to login.',
      group: 'features',
      options: ['bushel_identity_keycloak'],
      default: null,
      type: 'string',
    },
    bulk_contract: {
      label: 'Bulk Contract',
      group: 'features',
      options: ['1.0.0', '1.1.0'],
      default: null,
      type: 'string',
    },
    bulk_splits: {
      label: 'Bulk Splits',
      group: 'features',
      options: ['1.0.0'],
      default: null,
      type: 'string',
    },
    bulk_tickets: {
      label: 'Bulk Tickets',
      group: 'features',
      options: ['1.0.0', '1.1.0'],
      default: null,
      type: 'string',
    },
    cash_bids: {
      label: 'Cash Bids',
      description: "The 'Cash Bids' feature on the apps' landing page",
      tech_description: "Shows the 'Cash Bids' feature on the apps' landing page",
      group: 'features',
      options: ['1.0.0', '2.0.0'],
      default: null,
      type: 'string',
      flaggable: true,
    },
    commodities: {
      label: 'Commodities',
      group: 'features',
      options: ['1.0.0'],
      default: null,
      type: 'string',
    },
    commodity_balances: {
      label: 'Commodity Balances',
      description: "The 'Commodity Balances' feature on the apps' landing page",
      tech_description: "Shows the 'Commodity Balances' feature on the apps' landing page",
      group: 'features',
      options: ['1.0.0', '1.1.0'],
      default: null,
      type: 'string',
    },
    company: {
      label: 'Company',
      group: 'features',
      options: ['1.0.0'],
      default: null,
      type: 'string',
    },
    contracts: {
      label: 'Contracts',
      description: "The 'Contracts' feature on the apps' landing page",
      tech_description: "Shows the 'Contracts' feature on the apps' landing page",
      group: 'features',
      options: ['2.0.0', '3.0.0'],
      default: null,
      type: 'string',
      flaggable: true,
    },
    data_push_partners: {
      label: 'Data Partners',
      description:
        'Push Partners show up in the settings screen of the app of logged in growers. When enabled for a user, data will be sent to Rain & Hail, FCSA, etc.',
      tech_description:
        'Push Partners show up in the settings screen of the app of logged in growers. When enabled for a user, data will be sent to Rain & Hail, FCSA, etc.',
      group: 'features',
      options: ['1.0.0'],
      default: null,
      type: 'string',
    },
    delivery_tickets: {
      label: 'Delivery Tickets',
      description:
        "Shows 'tickets' of products and quantities purchased for use at the producer's operation.",
      tech_description:
        'Shows the loaded date, owning party, and relevant products with respective purchased quantities associated with the order. Has no pricing information.',
      group: 'features',
      options: ['1.0.0'],
      default: null,
      type: 'string',
    },
    esign: {
      label: 'eSign',
      description: "The 'eSign' feature on the apps' landing page",
      tech_description: "Shows the 'eSign' feature on the apps’ landing page",
      group: 'features',
      options: ['1.0.0'],
      default: null,
      type: 'string',
    },
    failed_logins: {
      label: 'Failed Logins',
      description:
        'This is the failed logins report, which gets sent out every day. It shows all growers that have attempted to login without a registered phone number',
      tech_description:
        'Enables the failed logins report, which gets sent out every day. Only enable in Prod.',
      group: 'features',
      options: ['1.0.0', '1.1.0'],
      default: null,
      type: 'string',
    },
    field_ids: {
      label: 'Farm Fields',
      description: 'Enables the grower-defined field features on the tickets page',
      group: 'features',
      options: ['1.0.0'],
      default: null,
      type: 'string',
    },
    futures: {
      label: 'Futures',
      description: "The 'Futures' feature on the apps' landing page",
      tech_description: "Shows the 'Futures' feature on the apps' landing page",
      group: 'features',
      options: ['1.0.0', '2.0.0'],
      default: null,
      type: 'string',
    },
    insights: {
      label: 'Insights',
      description:
        'This tracks whether a company is setup with Insights, it currently DOES NOT effect actual functionality in Insights, which must be setup separately',
      tech_description:
        'This tracks whether a company is setup with Insights, it currently DOES NOT effect actual functionality in Insights, which must be setup separately',
      group: 'features',
      options: ['1.0.0'],
      default: null,
      type: 'string',
    },
    local_cash_bids: {
      label: 'Local Cash Bids',
      description: "Shows 'Cash Bids' in the CMS, allowing customers to enter their cash bids",
      tech_description: "Shows 'Cash Bids' in the CMS, allowing customers to enter their cash bids",
      group: 'features',
      options: ['1.0.0'],
      default: null,
      type: 'string',
    },
    meta: {
      label: 'Meta',
      group: 'features',
      options: ['1.0.0'],
      default: '1.0.0',
      type: 'string',
    },
    notifications: {
      label: 'Notifications',
      description: 'Push notifications to the apps',
      tech_description: 'Push notifications to the apps',
      group: 'features',
      options: ['1.0.0'],
      default: null,
      type: 'string',
    },
    offers: {
      label: 'Offers (OMS)',
      description:
        'There are different versions of the Offer Management System. Version 1.X.X is powered by DTN and version 2.X.X is powered by CQG. The CQG Version is what is known as the "Bushel OMS"',
      tech_description:
        'Turns the offer system on or off for a customer. Version 1.X.X is powered by DTN and version 2.X.X is powered by CQG. The CQG Version is what is known as the "Bushel OMS"',
      group: 'features',
      options: ['1.0.0', '2.0.0'],
      default: null,
      type: 'string',
      flaggable: true,
    },
    outbound_tickets: {
      label: 'Outbound Tickets',
      description:
        'Shows outbound tickets that producers use for feed products and other post-processor good tracking.',
      tech_description:
        'Shows the date, weights, shipping destination, and relevant products with respective purchased quantities and cost associated with the order. Has no splits (implied 100% in all cases).',
      group: 'features',
      options: ['1.0.0'],
      default: null,
      type: 'string',
      flaggable: true,
    },
    prepaid_contracts: {
      label: 'Prepaids & Bookings',
      description:
        'Shows Prepaids and/or Bookings. Version 1.0.0 shows Prepaids without a details screen while version 1.1.0 shows Prepaids and Bookings with both a list and detail screen.',
      tech_description:
        'Shows Prepaids and/or Bookings. prepaid-contracts/1.0.0 push command is deprecated. 1.1.0 includes additional info that can be provided in the booking-line-items push command.',
      group: 'features',
      options: ['1.0.0', '1.1.0'],
      default: null,
      type: 'string',
      flaggable: true,
    },
    price_later: {
      label: 'Price Later',
      description: "The 'Price Later' feature on the apps' landing page",
      tech_description: "Shows the 'Price Later' feature on the apps' landing page",
      group: 'features',
      options: ['1.0.0'],
      default: null,
      type: 'string',
    },
    resources: {
      label: 'Resources',
      group: 'features',
      options: ['1.0.0'],
      default: null,
      type: 'string',
    },
    settlements: {
      label: 'Settlements',
      description: "The 'Settlements' feature on the apps' landing page",
      tech_description: "Shows the 'Settlements' feature on the apps' landing page",
      group: 'features',
      options: ['1.0.0'],
      default: null,
      type: 'string',
    },
    splits: {
      label: 'Splits',
      group: 'features',
      options: ['1.0.0'],
      default: null,
      type: 'string',
    },
    tickets: {
      label: 'Tickets',
      description: "The 'Tickets' feature on the apps' landing page",
      tech_description: "Shows the 'Tickets' feature on the apps' landing page",
      group: 'features',
      options: ['1.0.0', '1.1.0'],
      default: null,
      type: 'string',
      flaggable: true,
    },
    trade: {
      label: 'Bushel Trade',
      description:
        'This tracks whether a company is setup with a Bushel Trade, it currently DOES NOT effect actual functionality for trade, which must be setup separately',
      tech_description:
        'This tracks whether a company is setup with a Bushel Trade, it currently DOES NOT effect actual functionality for trade, which must be setup separately',
      group: 'features',
      options: ['1.0.0'],
      default: null,
      type: 'string',
    },
    transactions: {
      label: 'Transactions',
      description: 'Shows financial transactions, such as invoices and delivery tickets',
      tech_description:
        'Shows a list view of various intermingled financial transactions. Each type of transaction has a unique detail view',
      group: 'features',
      options: ['1.0.0'],
      default: null,
      type: 'string',
    },
    users: {
      label: 'Users',
      group: 'features',
      options: ['1.0.0'],
      default: null,
      type: 'string',
    },
    wait_time: {
      label: 'Wait Time',
      group: 'features',
      options: ['1.0.0'],
      default: null,
      type: 'string',
    },
    weather: {
      label: 'Weather',
      description: "The 'Weather' feature on the apps' landing page",
      tech_description: "Shows the 'Weather' feature on the apps' landing page",
      group: 'features',
      options: ['1.0.0'],
      default: null,
      type: 'string',
    },
    website: {
      label: 'Bushel Website',
      description:
        'This tracks whether a company is setup with a Bushel Website, it currently DOES NOT effect actual functionality for the website, which must be setup separately',
      tech_description:
        'This tracks whether a company is setup with a Bushel Website, it currently DOES NOT effect actual functionality for the website, which must be setup separately',
      group: 'features',
      options: ['1.0.0'],
      default: null,
      type: 'string',
    },
    work_orders: {
      label: 'Work Orders',
      description:
        'Shows field application info, including blended products and rates, placement types, affected fields, and weather if available.',
      tech_description:
        'Shows the products, rates, fields, dates, and weather relevant to an application. Some ERPs refer to this as Blend Tickets.',
      group: 'features',
      options: ['1.0.0'],
      default: null,
      type: 'string',
    },
    custom_entities: {
      label: 'Custom Entities',
      description:
        'Allows customers to configure custom data to be displayed in the native and mobile web apps',
      tech_description:
        'Allows customers to configure custom data to be displayed in the native and mobile web apps',
      group: 'features',
      options: ['1.0.0'],
      default: null,
      type: 'string',
    },
    payments: {
      label: 'Payments',
      description: "The 'Payments' feature on the apps' landing page",
      tech_description: "Shows the 'Payments' feature on the apps' landing page",
      group: 'features',
      options: ['1.0.0'],
      default: null,
      type: 'string',
      flaggable: true,
    },
  },
  advanced: {
    basis_price_adjustments: {
      label: 'Basis Price Adjustments',
      description:
        'Additional costs to be calculated in contracts and price amendments basis price',
      tech_description:
        'The list of price adjustment price types to be added to the basis price of contracts and price amendments',
      group: 'advanced',
      options: null,
      default: null,
      type: 'string',
    },
    deduplicate_filters: {
      label: 'De-Duplicate Filter Options',
      group: 'advanced',
      options: null,
      default: '0',
      type: 'boolean',
    },
    authentication_alternate_fields: {
      label: 'Authentication Alternate Fields',
      group: 'advanced',
      options: null,
      default: null,
      type: 'json',
    },
    ingest_mapping: {
      label: 'Column name to display name mapping',
      group: 'advanced',
      options: null,
      default: null,
      type: 'json',
    },
    alternative_feature_names: {
      label: 'Alternative Feature Names',
      group: 'advanced',
      options: null,
      default: '{}',
      type: 'json',
    },
    cash_bids_disclaimer_image_link: {
      label: 'Cash Bids Disclaimer Image Link',
      group: 'advanced',
      options: null,
      default: null,
      type: 'json',
    },
    cash_bids_disclaimer_link_one: {
      label: 'Cash Bids Disclaimer Link One',
      group: 'advanced',
      options: null,
      default: null,
      type: 'json',
    },
    cash_bids_disclaimer_link_two: {
      label: 'Cash Bids Disclaimer Link Two',
      group: 'advanced',
      options: null,
      default: null,
      type: 'json',
    },
    contract_account_id_mapping: {
      label: 'Contract Account ID Mapping',
      group: 'advanced',
      options: null,
      default: null,
      type: 'json',
    },
    custom_menu_links: {
      label: 'Custom Menu Links',
      description: 'Navigation menu links which open in an external browser.',
      group: 'advanced',
      options: null,
      default: null,
      type: 'json',
      flaggable: true,
    },
    decimal_place_format: {
      label: 'Decimal Place Format',
      group: 'advanced',
      options: null,
      default: null,
      type: 'json',
    },
    display_options: {
      label: 'Display Options',
      group: 'advanced',
      options: null,
      default: null,
      type: 'json',
    },
    failed_login_details: {
      label: 'Failed Login Details',
      group: 'advanced',
      options: null,
      default: null,
      type: 'json',
    },
    internal_custom_weblinks: {
      label: 'Internal Custom Weblinks',
      description:
        'Navigation menu links placed between cash bids and futures, which open in an internal webview.',
      group: 'advanced',
      options: null,
      default: null,
      type: 'json',
    },
    login_description: {
      label: 'Login Description',
      group: 'advanced',
      options: null,
      default:
        '{\\"description\\":\\"To login, enter your cell phone number and tap \\\'next.\\\' You will receive a text with a code to enter on the next screen.\\",\\"link\\":{\\"label\\":null,\\"url\\":null}}',
      type: 'json',
    },
    remarks_whitelist: {
      label: 'Remarks Whitelist',
      group: 'advanced',
      options: null,
      default: null,
      type: 'json',
    },
    resource_links: {
      label: 'Resource Links',
      description:
        "Custom web links used at an app's discretion that do NOT show up in the navigation menu.",
      group: 'advanced',
      options: null,
      default: null,
      type: 'json',
    },
    settlement_account_id_mapping: {
      label: 'Settlement Account ID Mapping',
      group: 'advanced',
      options: null,
      default: null,
      type: 'json',
    },
    ticket_list_account_id_mapping: {
      label: 'Ticket List Account ID Mapping',
      group: 'advanced',
      options: null,
      default: null,
      type: 'json',
    },
    ticket_list_remarks: {
      label: 'Ticket List Remarks',
      group: 'advanced',
      options: null,
      default: null,
      type: 'json',
    },
    custom_entities_payload: {
      label: 'Custom Entities Payload',
      group: 'advanced',
      options: null,
      default: null,
      type: 'json',
    },
    ticket_addendum_displaynames: {
      label: 'Ticket Addendum Display Names',
      group: 'advanced',
      options: null,
      default: null,
      type: 'json_array',
      flaggable: true,
    },
    commodity_denylist_outbound_tickets: {
      label: 'Outbound Tickets Commodity Denylist',
      description:
        'Omit Outbound Tickets with the given Commodity IDs from being displayed in the mobile and web apps',
      tech_description:
        'Omit Outbound Tickets with the given Commodity IDs from being displayed in the mobile and web apps. Primarily used to exclude consumables.',
      group: 'advanced',
      options: null,
      default: '[]',
      type: 'json_array',
    },
  },
  custom: {
    sso_idp_hint: {
      label: 'Choose the default login idp for the app.',
      group: 'custom',
      options: null,
      default: 'bushel-phone-oidc',
      type: 'string',
    },
    authentication_alternate_identifier: {
      label: 'Authentication Alternate Identifier',
      group: 'custom',
      options: null,
      default: null,
      type: 'string',
    },
    blacklist_locations_remote_ids: {
      label: 'Blacklist Location Remote IDs',
      group: 'custom',
      options: null,
      default: null,
      type: 'string',
    },
    brand_logo: {
      label: 'Brand Logo',
      group: 'custom',
      options: null,
      default: null,
      type: 'string',
    },
    cash_bids_disclaimer: {
      label: 'Cash Bids Disclaimer',
      group: 'custom',
      options: null,
      default: 'Quotes delayed a minimum of ten minutes.',
      type: 'string',
    },
    cash_bids_disclaimer_image_link2: {
      label: 'Cash Bids Disclaimer Image Link Two',
      group: 'custom',
      options: null,
      default: null,
      type: 'string',
    },
    cash_bids_disclaimer_image_url: {
      label: 'Cash Bids Disclaimer Image Url',
      group: 'custom',
      options: null,
      default: null,
      type: 'string',
    },
    cash_bids_disclaimer_image_url2: {
      label: 'Cash Bids Disclaimer Image Url Two',
      group: 'custom',
      options: null,
      default: null,
      type: 'string',
    },
    cqg_company_name: {
      label: 'CQG Company Name',
      group: 'custom',
      options: null,
      default: null,
      type: 'string',
    },
    cqg_new_offer_terms: {
      label: 'CQG customized UI prompt when creating offer',
      group: 'custom',
      options: null,
      default:
        'By submitting this offer, you understand that you are entering into a binding offer to sell your grain to a buyer pursuant to a cash grain contract with the buyer at the prices set forth in the offer.',
      type: 'string',
    },
    contract_header_location: {
      label: 'Location In Contract Header',
      description: 'Selects which location to display in contract header',
      tech_description: 'The selected location field will display in the contract header',
      group: 'custom',
      options: ['contract', 'delivery'],
      default: 'contract',
      type: 'string',
    },
    contract_type_display_format: {
      label: 'Contract Type Display Format',
      description: "The format for displaying contract's type and/or subtype in the app.",
      tech_description: "The format for displaying contract's type and/or subtype in the app.",
      group: 'custom',
      options: ['type', 'type - subtype', 'subtype', 'subtype - type'],
      default: 'type',
      type: 'string',
    },
    dtn_offer_account_identifier: {
      label: 'DTN Offer Account Identifier',
      group: 'custom',
      options: null,
      default: 'phone_number',
      type: 'string',
    },
    failed_login_description: {
      label: 'Failed Login Description',
      group: 'custom',
      options: null,
      default:
        "Sorry, we couldn't find any accounts that belong to you. Please contact {companyName} to update your account.",
      type: 'string',
    },
    futures_disclaimer: {
      label: 'Futures Disclaimer',
      group: 'custom',
      options: null,
      default:
        "Market data provided by Bushel. Information is provided 'as is' and solely for informational purposes, not for trading purposes or advice, and is delayed per exchange requirements.",
      type: 'string',
    },
    futures_disclaimer_image_link2: {
      label: 'Futures Disclaimer Image Link 2',
      group: 'custom',
      options: null,
      default: null,
      type: 'string',
    },
    futures_disclaimer_image_url: {
      label: 'Futures Disclaimer Image Url',
      group: 'custom',
      options: null,
      default:
        'https://d2yc2xkes0y1h8.cloudfront.net/scaletickets/xK9qjCVSpPUjgWSySQkbHSdFisGxNwV8W3UIQfvrmFveRvF5fGzuaRoGr66FK456.png',
      type: 'string',
    },
    futures_disclaimer_image_url2: {
      label: 'Futures Disclaimer Image Url 2',
      group: 'custom',
      options: null,
      default: null,
      type: 'string',
    },
    futures_disclaimer_url: {
      label: 'Futures Disclaimer Url',
      group: 'custom',
      options: null,
      default: 'https://www.cmegroup.com/',
      type: 'string',
    },
    futures_sort_order: {
      label: 'Futures Sort Order',
      group: 'custom',
      options: null,
      default:
        'Corn,Ethanol,Soybean,Soybean Oil,Soybean Meal,Spring Wheat,Wheat,Hard Red Wheat,Live Cattle,Feeder Cattle,Lean Hogs',
      type: 'string',
    },
    landing_feature: {
      label: 'Landing Feature',
      group: 'custom',
      options: [
        'cash_bids',
        'commodity_balances',
        'contracts',
        'delivery_tickets',
        'esign',
        'futures',
        'settlements',
        'tickets',
        'transactions',
        'payments',
        'prepaid_contracts',
      ],
      default: null,
      type: 'string',
    },
    price_amendment_sort_method: {
      label: 'Price Amendment Sort Method',
      description: 'How price amendments should be sorted.',
      tech_description:
        'By Sequence is new->old. As Deliverable, is delivery dates, nearest->farthest, preceding any delivery-date-less old->new.',
      group: 'custom',
      options: ['by_sequence', 'as_deliverable'],
      default: 'by_sequence',
      type: 'string',
    },
    primary_color: {
      label: 'Primary Color',
      group: 'custom',
      options: null,
      default: null,
      type: 'string',
    },
    secondary_color: {
      label: 'Secondary Color',
      group: 'custom',
      options: null,
      default: null,
      type: 'string',
    },
    ticket_addendum_whitelist: {
      label: 'Ticket Addendum Whitelist',
      group: 'custom',
      options: null,
      default: null,
      type: 'string',
    },
    ticket_addendum_whitelist_filters: {
      label: 'Ticket Addendum Filter Whitelist',
      group: 'custom',
      options: null,
      default: null,
      type: 'string',
    },
    account_id_selector: {
      label: 'Account ID Data Selector',
      description:
        'Selects which data point to display in remote ID of any alternatively named models.',
      tech_description:
        "The selected column will change the display in a given model's Remote ID label. The property provided to the options group needs to be a valid property on the associated CompanyUser model.",
      group: 'custom',
      options: ['full_name', 'remote_id'],
      default: 'remote_id',
      type: 'string',
    },
    authentication_auto_email: {
      label: 'Authentication Auto Email',
      group: 'custom',
      options: ['1.0.0'],
      default: 'false',
      type: 'boolean',
    },
    authentication_auto_phone: {
      label: 'Authentication Auto Phone',
      group: 'custom',
      options: ['1.0.0'],
      default: 'false',
      type: 'boolean',
    },
    cash_bids_require_login: {
      label: 'Cash Bids Require Login',
      group: 'custom',
      options: null,
      default: 'false',
      type: 'boolean',
    },
    cash_bids_only_business_locations: {
      label: 'Cash Bids Only Business Locations',
      group: 'custom',
      options: null,
      default: 'false',
      type: 'boolean',
    },
    contract_contracted_location_detail: {
      label: 'Contracted Location In Details',
      description: 'Selects display of contracted location in contract details',
      tech_description:
        "If 'true', the contracted location field will display in the contract details screen",
      group: 'custom',
      options: null,
      default: 'false',
      type: 'boolean',
    },
    contract_delivery_location_detail: {
      label: 'Delivery Location In Details',
      description: 'Selects display of delivery location in contract details',
      tech_description:
        "If 'true', the delivery location field will display in the contract details screen",
      group: 'custom',
      options: null,
      default: 'false',
      type: 'boolean',
    },
    display_user_from_ticket: {
      label: 'Get name from tickets instead of splits',
      group: 'custom',
      options: null,
      default: 'false',
      type: 'boolean',
    },
    dtn_provider: {
      label: 'DTN Provider',
      group: 'custom',
      options: null,
      default: '0',
      type: 'boolean',
    },
    esign_reminder: {
      label: 'eSign Reminder Modal',
      group: 'custom',
      options: null,
      default: '1',
      type: 'boolean',
    },
    filter_out_zero_splits: {
      label: 'Filter Out Zero Splits',
      group: 'custom',
      options: null,
      default: 'false',
      type: 'boolean',
    },
    favorite_locations_notifications: {
      label: 'Notify Favorited Location Users?',
      description: 'Send notifications to non-authenticated apps that have favorited the location.',
      group: 'custom',
      options: null,
      default: 'false',
      type: 'boolean',
    },
    has_translator: {
      label: 'Has Translator',
      group: 'custom',
      options: null,
      default: 'true',
      type: 'boolean',
    },
    hide_account_identifier_on_ticket_detail: {
      label: 'Hide Account Identifier on Ticket Detail',
      group: 'custom',
      options: null,
      default: 'false',
      type: 'boolean',
    },
    hide_bushel_branding: {
      label: 'Hide Bushel Branding',
      group: 'custom',
      options: null,
      default: 'false',
      type: 'boolean',
    },
    name_sync: {
      label: 'Name Sync',
      group: 'custom',
      options: null,
      default: 'false',
      type: 'boolean',
    },
    price_amendment_delivery_details: {
      label: 'Price Amendment Delivery Details',
      description: 'Make contract delivery fields reactive to price amendment delivery data',
      tech_description:
        "If 'true', the contract delivery location and period fields will defer to price amendments where appropriate",
      group: 'custom',
      options: null,
      default: 'false',
      type: 'boolean',
    },
    show_comments_on_settlements: {
      label: 'Show Comments on Settlements',
      group: 'custom',
      options: null,
      default: 'true',
      type: 'boolean',
    },
    show_comments_on_tickets: {
      label: 'Show Comments on Tickets',
      group: 'custom',
      options: null,
      default: 'true',
      type: 'boolean',
    },
    show_contract_applied_amount_on_ticket_details: {
      label: 'Show Contract Applied Amount on Ticket Details',
      group: 'custom',
      options: null,
      default: 'false',
      type: 'boolean',
    },
    show_currency_on_contracts: {
      label: 'Show Currency On Contracts',
      group: 'custom',
      options: null,
      default: 'false',
      type: 'boolean',
    },
    show_generic_splitname_on_ticket_list: {
      label: 'Show Generic Split Name on Ticket List',
      group: 'custom',
      options: null,
      default: 'true',
      type: 'boolean',
    },
    show_ticket_with_delivery_account_id: {
      label: 'Show Ticket With Delivery Account ID',
      group: 'custom',
      options: null,
      default: 'false',
      type: 'boolean',
    },
    sync_users_in_chunks: {
      label: 'Sync Users in Chunks',
      group: 'custom',
      options: null,
      default: 'false',
      type: 'boolean',
    },
    ye_old_tickets: {
      label: 'Ye Old Tickets',
      group: 'custom',
      options: ['1.0.0'],
      default: 'false',
      type: 'boolean',
    },
    work_order_comments_hide: {
      label: 'Hide work order comments',
      group: 'custom',
      options: null,
      default: 'false',
      type: 'boolean',
    },
    work_order_weather_data_hide: {
      label: 'Hide work order weather data',
      group: 'custom',
      options: null,
      default: 'false',
      type: 'boolean',
    },
    delivery_ticket_comments_hide: {
      label: 'Hide delivery ticket comments',
      group: 'custom',
      options: null,
      default: 'false',
      type: 'boolean',
    },
    delivery_ticket_loaded_at_time_hide: {
      label: 'Format delivery ticket Loaded At date without time',
      group: 'custom',
      options: null,
      default: 'false',
      type: 'boolean',
    },
    disable_make_offer: {
      label: 'Hide the Cash Bids Make Offer Button',
      group: 'custom',
      options: null,
      default: 'false',
      type: 'boolean',
      flaggable: true,
    },
    enable_local_auth: {
      label: 'Local Authentication',
      description:
        'Turn on local authentication like FaceID, TouchID, biometric, or device passcode.',
      group: 'custom',
      options: null,
      default: 'false',
      type: 'boolean',

      flaggable: true,
    },
    statements: {
      label: 'Statements',
      description: 'Show statements page and functionality.',
      group: 'features',
      options: ['1.0.0'],
      default: null,
      type: 'string',
    },
  },
}
