import React from 'react'
import {
  Button,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  UnorderedList,
} from 'src/ui'
import { useContractsContext } from 'src/reports/contracts/context'

function ConfirmationModal({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
}: Readonly<{
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  isLoading: boolean
}>) {
  const { detailsIsEditing } = useContractsContext()

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        {detailsIsEditing ? (
          <>
            <ModalHeader>Unsaved Changes</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack>
                <Stack>
                  <Text>Updates to the following sections have not been saved:</Text>
                  <UnorderedList>{detailsIsEditing && <ListItem>Details</ListItem>}</UnorderedList>
                </Stack>
                <Text>
                  Please &lsquo;save&rsquo; prior to disabling or your updates will be lost.
                </Text>
              </Stack>
            </ModalBody>
          </>
        ) : (
          <>
            <ModalHeader>Disable Contracts Report</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>Are you sure you want to disable the Contracts Report?</Text>
            </ModalBody>
          </>
        )}
        <ModalFooter>
          <Button colorScheme="secondary" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="primary"
            onClick={onConfirm}
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            Disable
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ConfirmationModal
