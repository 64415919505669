import React from 'react'
import { css } from '@emotion/react'
import { useDebounce } from 'src/utils'
import { hasPermission } from 'src/utils/permissions'
import {
  Box,
  Row,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  SearchIcon,
  SmallCloseIcon,
} from 'src/ui'

export const LocationsHeader = ({
  setLocationFilter,
  showLoadCsvForm,
  setShowLoadCsvForm,
  setShowNewForm,
  handleExpandAll,
  handleCollapseAll,
}) => {
  let [inputValue, setInputValue] = React.useState('')
  let throttledValue = useDebounce(inputValue, 500)

  React.useEffect(() => {
    setLocationFilter(throttledValue || null)
  }, [throttledValue, setLocationFilter])

  return (
    <Row alignItems="flex-end" mb={4}>
      <Box position="relative" flex={1} maxWidth={500}>
        <InputGroup position="relative">
          <Input
            variant="flushed"
            size="md"
            placeholder="Search"
            borderColor="#8C9BA5"
            borderBottomWidth="1px"
            _focus={{ borderColor: '#8C9BA5' }}
            _placeholder={{ color: '#8C9BA5' }}
            value={inputValue || ''}
            onChange={(e) => setInputValue(e.target.value)}
            css={css`
              width: 100%;
              font-weight: 600;
            `}
          />

          <InputRightElement width="1rem">
            <Box color="#a2a2a2">
              {!!inputValue ? (
                <button tabIndex={-1} onClick={() => setLocationFilter(null)}>
                  <SmallCloseIcon fontSize="1.25rem" color="inherit" />
                </button>
              ) : (
                <SearchIcon fontSize="1rem" color="inherit" />
              )}
            </Box>
          </InputRightElement>
        </InputGroup>
      </Box>

      {false && (
        <>
          <Box flex={1} textAlign="right">
            <Button
              size="sm"
              type="button"
              width="150px"
              colorScheme="secondary"
              onClick={handleCollapseAll}
            >
              Collapse All
            </Button>
          </Box>

          <Box flex={1} textAlign="right">
            <Button
              size="sm"
              type="button"
              width="100px"
              colorScheme="secondary"
              onClick={handleExpandAll}
            >
              Expand All
            </Button>
          </Box>
        </>
      )}

      <Box flex={1} textAlign="right">
        <Box display="inline-block" mr={2}>
          {showLoadCsvForm ? (
            <Button size="sm" onClick={() => setShowLoadCsvForm(false)}>
              Close CSV Load
            </Button>
          ) : (
            <Button
              size="sm"
              colorScheme="primary"
              isDisabled={!hasPermission('company_locations_edit')}
              onClick={() => setShowLoadCsvForm(true)}
            >
              Load From CSV
            </Button>
          )}
        </Box>
        <Button
          size="sm"
          type="button"
          width="70px"
          colorScheme="primary"
          isDisabled={!hasPermission('company_locations_edit')}
          onClick={() => setShowNewForm(true)}
        >
          Add
        </Button>
      </Box>
    </Row>
  )
}
