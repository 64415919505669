import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Box, Column, Dialog, Button } from 'src/ui'
import { configTypeDisplayName } from './utils'
import { useToast } from 'src/utils/toast'

interface DeleteDialogProps {
  config?: CashBidConfig
  isOpen: boolean
  onClose: () => void
  onConfirm: (
    config: CashBidConfig,
    obs: {
      onSuccess: () => Promise<void>
      onError: () => void
    }
  ) => void
}

export const DeleteDialog: React.FC<DeleteDialogProps> = ({
  isOpen,
  onClose,
  onConfirm,
  config,
}) => {
  let [isLoading, setIsLoading] = React.useState(false)
  let toast = useToast()
  const queryClient = useQueryClient()

  return (
    <Dialog
      title="Delete Config"
      isOpen={isOpen}
      onClose={onClose}
      actions={
        <>
          <Button size="sm" variant="ghost" onClick={onClose} mr={2}>
            Cancel
          </Button>

          <Button
            size="sm"
            colorScheme="primary"
            isLoading={isLoading}
            onClick={async () => {
              setIsLoading(true)
              await onConfirm(config, {
                onSuccess: async () => {
                  await queryClient.invalidateQueries({ queryKey: ['centre-cashbid-configs'] })
                  toast({ description: 'Successfully deleted config' })
                },
                onError: () => {
                  toast({ status: 'error', description: 'Failed to delete config' })
                },
              })
              setIsLoading(false)
              onClose()
            }}
          >
            Delete
          </Button>
        </>
      }
    >
      <Column>
        <Box>
          <Box>Are you sure you want to delete this cash bid config?</Box>

          {config && (
            <ul>
              <li>{configTypeDisplayName(config)}</li>
            </ul>
          )}
        </Box>
      </Column>
    </Dialog>
  )
}
