import { IConnectChannel, IConnectUser } from 'src/api/api'

const baseUri = '/connect/user'

export interface GetConnectUsersRequest {
  channelId: IConnectChannel['id']
}

export interface DeleteConnectUserRequest {
  id: IConnectUser['id']
}

export const connectUserRequests = {
  ///////////////////////////////////////////////////////////////////////////////
  // GET
  ///////////////////////////////////////////////////////////////////////////////

  getConnectUsers:
    (api) =>
    async ({ channelId }: GetConnectUsersRequest) => {
      try {
        let response = await api.get(`/connect/users?connect_channel_id=${channelId}`)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  ///////////////////////////////////////////////////////////////////////////////
  // CREATE
  ///////////////////////////////////////////////////////////////////////////////

  createConnectUser: (api) => async (params: Partial<IConnectUser>) => {
    try {
      const response = await api.post(baseUri, params)
      return [null, response]
    } catch (err) {
      return [err, null]
    }
  },

  ///////////////////////////////////////////////////////////////////////////////
  // UPDATE
  ///////////////////////////////////////////////////////////////////////////////

  updateConnectUser: (api) => async (params: Partial<IConnectUser>) => {
    try {
      const response = await api.put(`${baseUri}/${params.id}`, params)
      return [null, response]
    } catch (error) {
      return [error, true]
    }
  },

  ///////////////////////////////////////////////////////////////////////////////
  // DELETE
  ///////////////////////////////////////////////////////////////////////////////

  deleteConnectUser:
    (api) =>
    async ({ id }: DeleteConnectUserRequest) => {
      try {
        await api.delete(`${baseUri}/${id}`)
        return [null, true]
      } catch (err) {
        return [err, null]
      }
    },
}
