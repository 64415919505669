import { rest } from 'msw'
import { commodities, createCommodity } from 'src/fixtures/commodities'
import { companies } from 'src/fixtures/companies'
import { getURLSearchParam } from 'src/fixtures/utils'

export const handlers = (urls: string[]) => {
  return urls.reduce(
    (acc, url) => [
      ...acc,
      rest.get(`${url}/commodities`, (req, res, ctx) => {
        const slug = getURLSearchParam(req.url.search)
        const companyId = companies.find((company) => company.slug === slug).id
        const filtered = commodities.filter((commodity) => commodity.company_id === companyId)
        return res(ctx.status(200), ctx.json({ data: filtered }))
      }),
      rest.post(`${url}/commodity`, async (req, res, ctx) => {
        const requestBody = await req.json()
        const newCommodity = createCommodity(requestBody)
        commodities.push(newCommodity)
        const statusText = `Successfully added with commodity: ${newCommodity.id} to the system.`
        return res(ctx.status(201), ctx.json({ data: newCommodity, status: statusText }))
      }),
      rest.put(`${url}/commodity/:id`, async (req, res, ctx) => {
        const requestBody = await req.json()
        const id = parseInt(req.params.id as string)
        const editedCommodity = createCommodity(requestBody)
        const index = commodities.findIndex((commodity) => commodity.id === id)
        commodities[index] = editedCommodity
        const statusText = `Successfully updated ${editedCommodity.remote_id}.`
        return res(ctx.status(200), ctx.json({ data: editedCommodity, status: statusText }))
      }),
      rest.delete(`${url}/commodity/:id`, (req, res, ctx) => {
        const id = parseInt(req.params.id as string)
        const index = commodities.findIndex((commodity) => commodity.id === id)
        commodities.splice(index, 1)
        return res(ctx.status(204))
      }),
    ],
    []
  )
}
