import React from 'react'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import * as api from 'src/utils/api'
import { centreAPIs } from 'src/utils/api'
import FuturesConfig from 'src/companies/oms/futures/futures-config'
import NewFuturesForm from 'src/companies/oms/futures/new-futures-form'
import { useToast } from 'src/utils/toast'
import { Placeholders } from 'src/ui'
import * as queries from 'src/utils/queries'

type FuturesIndexProps = {
  company: Company
  availableCommodities: Array<string>
}

const saveFuturesConfig = async ({
  futuresConfig,
}: {
  futuresConfig: OfferManagementMapping
  onSuccess?: () => void
}): api.ApiReturn<OfferManagementMapping> => {
  if (futuresConfig.id) {
    let [err, response] = await centreAPIs.updateCompanyFuturesConfig({
      futuresConfig,
    })
    if (err) throw err
    return [err, response]
  }
  let [err, response] = await centreAPIs.createCompanyFuturesConfig({
    futuresConfig: {
      ...futuresConfig,
    },
  })
  if (err) throw err
  return [err, response]
}

const FuturesIndex = ({ company, availableCommodities }: FuturesIndexProps) => {
  const companyFuturesConfig = useQuery({
    queryKey: ['futures-config', { companySlug: company.slug }],
    queryFn: async () => {
      let [err, response] = await centreAPIs.getCompanyFuturesConfig({ companySlug: company.slug })
      if (err) throw err
      return response.data.data
    },
  })

  const toast = useToast()
  const queryClient = useQueryClient()

  let { mutateAsync: saveFuturesConfigMutation } = useMutation({
    mutationFn: saveFuturesConfig,
    onSuccess: async (res_, { onSuccess }) => {
      await queryClient.invalidateQueries({ queryKey: ['futures-config'] })
      toast({ title: 'Success', description: 'Updated futures config' })
      if (onSuccess) onSuccess()
    },
    onError: (_res) => {
      toast({
        status: 'error',
        description: 'Failed to update futures config',
      })
    },
  })

  return (
    <>
      {queries.areAnyLoading(companyFuturesConfig) && <Placeholders.LoadingState mt={8} />}
      {queries.areAnyFailed(companyFuturesConfig) && <Placeholders.FailedState mt={8} />}
      {queries.areAllLoaded(companyFuturesConfig) && (
        <>
          {companyFuturesConfig.data.configs.length > 0 && (
            <FuturesConfig
              futuresConfig={companyFuturesConfig.data.configs[0]}
              saveFuturesConfig={saveFuturesConfigMutation}
              availableCommodities={availableCommodities}
            />
          )}
          {companyFuturesConfig.data.configs.length === 0 && (
            <NewFuturesForm
              company={company}
              saveFuturesConfig={saveFuturesConfigMutation}
              availableCommodities={availableCommodities}
            />
          )}
        </>
      )}
    </>
  )
}

export default FuturesIndex
