import { useToast } from 'src/utils/toast'

type CopyContext = {
  whitelist?: string[]
  companyConfig?: Partial<CompanyConfig>
  [key: string]: any
}

interface useCopyDiffArgs {
  company: Company
  context?: CopyContext
  setCopyDialog: (args: any) => void
  loadCopyDiff: (args: any) => any
  executeCopy: (args: any) => any
}

type startCopyArgs = {
  context?: { [key: string]: any }
}

export const useCopyDiff = ({
  company,
  context,
  setCopyDialog,
  loadCopyDiff,
  executeCopy,
}: useCopyDiffArgs) => {
  let toast = useToast()

  const startCopy = async (args: startCopyArgs = {}) => {
    let { context: moreContext } = args
    let mergedContext = { ...context, ...moreContext }

    let data = await loadCopyDiff({
      company,
      toast,
      context: mergedContext,
    })

    // once we have the `source` and `destination` objects to compare, "activate" the copy dialog

    setCopyDialog({
      oldValue: data.dest,
      newValue: data.source,
      errorMessage: data.errorMessage,
      // message: 'Optional message at top of the diff',
      onConfirm: () => executeCopy({ company, toast, context: mergedContext }),
    })
  }

  return { startCopy }
}
