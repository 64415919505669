import React from 'react'
import { Global, css } from '@emotion/react'
import { CSSReset } from '@chakra-ui/react'

import 'jsoneditor-react/es/editor.min.css'

export const GlobalStyles = () => (
  <>
    <CSSReset />

    <Global
      styles={css`
        ul {
          display: block;
          list-style-type: disc;
          margin-block-start: 1em;
          margin-block-end: 1em;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          padding-inline-start: 40px;
        }

        button {
          text-transform: uppercase;
        }
      `}
    />
  </>
)
