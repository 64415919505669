import { IConnectChannel, IConnectCustomer } from 'src/api/api'

const baseUri = '/connect/channel'

export interface GetConnectChannelsRequest {
  customerId: IConnectCustomer['id']
}

export interface CreateConnectChannelRequest {
  connect_channel_id: IConnectCustomer['id']
  name: IConnectChannel['name']
  description: IConnectChannel['description']
}

export interface UpdateConnectChannelRequest {
  id: IConnectChannel['id']
  _revision: IConnectChannel['_revision']
  name: IConnectChannel['name']
  description: IConnectChannel['description']
}

export interface DeleteConnectChannelRequest {
  id: IConnectChannel['id']
}

export const connectChannelRequests = {
  ///////////////////////////////////////////////////////////////////////////////
  // GET
  ///////////////////////////////////////////////////////////////////////////////

  getConnectChannels:
    (api) =>
    async ({ customerId }: GetConnectChannelsRequest) => {
      try {
        let response = await api.get(`/connect/channels?connect_customer_id=${customerId}`)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  ///////////////////////////////////////////////////////////////////////////////
  // CREATE
  ///////////////////////////////////////////////////////////////////////////////

  createConnectChannel: (api) => async (params: Partial<IConnectChannel>) => {
    try {
      const response = await api.post(baseUri, params)
      return [null, response]
    } catch (err) {
      return [err, null]
    }
  },

  ///////////////////////////////////////////////////////////////////////////////
  // UPDATE
  ///////////////////////////////////////////////////////////////////////////////

  updateConnectChannel: (api) => async (params: Partial<IConnectChannel>) => {
    try {
      const response = await api.put(`${baseUri}/${params.id}`, params)
      return [null, response]
    } catch (error) {
      return [error, true]
    }
  },

  ///////////////////////////////////////////////////////////////////////////////
  // DELETE
  ///////////////////////////////////////////////////////////////////////////////

  deleteConnectChannel:
    (api) =>
    async ({ id }: DeleteConnectChannelRequest) => {
      try {
        await api.delete(`${baseUri}/${id}`)
        return [null, true]
      } catch (err) {
        return [err, null]
      }
    },
}
