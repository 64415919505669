import { useQuery, useMutation } from '@tanstack/react-query'
import {
  brandingRequests,
  buildRequests,
  CreateBrandRequest,
  CreateBuildRequest,
  CreateMobileBuildRequest,
  UpdateBrandRequest,
  UpdateBuildRequest,
} from 'src/api/branding'

// Get Flavor By Slug
export const useGetFlavorBySlug = (params: { type?: string; slug: string }) =>
  useQuery({
    queryKey: ['get-flavor-by-slug', params],
    queryFn: () => brandingRequests.getFlavorBySlug(params),
  })

// Create Brand
export const useCreateBrandMutation = () =>
  useMutation({
    mutationFn: (params: CreateBrandRequest) => brandingRequests.createBrand(params),
  })

// Get All Brands
export const useGetAllBrands = (params: { type?: string } = {}) =>
  useQuery({
    queryKey: ['use-all-brands', params],
    queryFn: () => brandingRequests.getAllBrands(params),
  })

// Get Brand By Id
export const useGetBrandById = (params: { id: string; type?: string }) =>
  useQuery({
    queryKey: ['get-brand-by-id', params],
    queryFn: () => brandingRequests.getBrandbyId(params),
  })

// Get Brand By Slug
export const useGetBrandBySlug = (params: { slug: string; type?: string }) =>
  useQuery({
    queryKey: ['get-brand-by-slug', params],
    queryFn: () => brandingRequests.getBrandbySlug(params),
  })

// Get All Flavors
export const useGetAllFlavors = (params: { type?: string } = {}) =>
  useQuery({
    queryKey: ['get-all-flavors', params],
    queryFn: () => brandingRequests.getAllFlavors(params),
  })

// Delete Brand By Id
export const useDeleteBrandByIdMutation = () => {
  const { mutateAsync: deleteBrand } = useMutation((params: { id: string; type?: string }) =>
    brandingRequests.deleteBrandById(params)
  )
  return { deleteBrand }
}

// Update Brand
export const useUpdateBrandMutation = () =>
  useMutation({
    mutationFn: (params: UpdateBrandRequest) => brandingRequests.updateBrand(params),
  })

// Update Build
export const useUpdateBuildMutation = () =>
  useMutation({
    mutationFn: (params: UpdateBuildRequest) => buildRequests.updateBuild(params),
  })

// Get Build By Slug
export const useGetBuildBySlug = (params: { type?: string; slug: string }) =>
  useQuery({
    queryKey: ['get-build-by-slug', params],
    queryFn: () => buildRequests.getBuildBySlug(params),
  })

// Create Build
export const useCreateBuildMutation = () =>
  useMutation({
    mutationFn: (params: CreateBuildRequest) => buildRequests.createBuild(params),
  })

// Create Mobile Build
export const useCreateMobileBuildMutation = () =>
  useMutation({
    mutationFn: (params: CreateMobileBuildRequest) => buildRequests.createMobileBuild(params),
  })

// Delete Build By Id
export const useDeleteBuildByIdMutation = () => {
  const { mutateAsync: deleteBuildById } = useMutation({
    mutationFn: (params: { id: string; type?: string }) => buildRequests.deleteBuildById(params),
  })
  return { deleteBuildById }
}

// Get All Builds
export const useGetAllBuilds = (params: { type?: string } = {}) =>
  useQuery({
    queryKey: ['get-all-builds', params],
    queryFn: () => buildRequests.getAllBuilds(params),
  })

// Get Build By Id
export const useGetBuildById = (params: { id: string; type?: string }) =>
  useQuery({
    queryKey: ['get-build-by-id', params],
    queryFn: () => buildRequests.getBuildById(params),
  })
