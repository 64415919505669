import React from 'react'
import {
  FormControl as ChakraFormControl,
  FormLabel as ChakraFormLabel,
  FormErrorMessage as ChakraFormErrorMessage,
  Input,
  Textarea,
  Radio,
  RadioGroup,
} from '@chakra-ui/react'

const FormControl = (props) => <ChakraFormControl height="72px" mb={2} {...props} />

const FormLabel = (props) => (
  <ChakraFormLabel
    fontSize="sm"
    color="gray.500"
    css={{
      width: props.width || '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      paddingRight: 0,
    }}
    {...props}
  />
)

const FormErrorMessage = (props) => (
  <ChakraFormErrorMessage
    mt={1}
    css={{
      svg: {
        display: 'none',
      },
    }}
    {...props}
  />
)

export { FormControl, FormLabel, FormErrorMessage, Input, Radio, RadioGroup, Textarea }
