import moment from 'moment'

export const yearOptions = (featureDate) => {
  const years = []
  const markerDate = featureDate.clone()
  const currentDate = moment()

  while (markerDate.isSameOrBefore(currentDate)) {
    years.push(markerDate.format('YYYY'))
    markerDate.add(1, 'year')
  }

  return years.map((year) => ({
    value: parseInt(year),
    label: year,
  }))
}
