import { UseToastOptions } from '@chakra-ui/react'
import { centreAPIs } from 'src/utils/api'
import { getEnvironment } from 'src/utils'

type CopyContext = {
  centreTranslator: Partial<Translator>
}
const COPY_INCLUDED_KEYS = [
  'verification_feature_version',
  'company_user_feature_version',
  'url',
  'shared_key',
]

export const loadCopyDiff = async ({
  company,
  context,
}: {
  company: Company
  context: CopyContext
  toast: (args: UseToastOptions) => void
}) => {
  let destinationEnvironment = getEnvironment() === 'production' ? 'UAT' : 'Production'
  let [_destErr, destCompany] = await centreAPIs.otherEnvironment.getCentreCompanyBySlug({
    slug: company.slug,
  })

  if (!destCompany) {
    return {
      source: null,
      dest: null,
      errorMessage: `Company ${company.name} not found in ${destinationEnvironment}. Please create the company and try again`,
    }
  }

  if (!destCompany.translator_id) {
    return {
      source: null,
      dest: null,
      errorMessage: `Company ${company.name} does not have a Translator ID in ${destinationEnvironment}. Please create a Translator and try again.`,
    }
  }

  let [_destCentreTranslatorErr, destCentreTranslatorResponse] =
    await centreAPIs.otherEnvironment.getCentreTranslatorById({
      id: destCompany.translator_id,
    })

  let destCentreTranslator = destCentreTranslatorResponse?.data?.data

  return {
    source: Object.keys(context.centreTranslator)
      .filter((key) => COPY_INCLUDED_KEYS.includes(key))
      .reduce((acc, key) => ({ ...acc, [key]: context.centreTranslator[key] }), {}),
    dest: Object.keys(destCentreTranslator)
      .filter((key) => COPY_INCLUDED_KEYS.includes(key))
      .reduce((acc, key) => ({ ...acc, [key]: destCentreTranslator[key] }), {}),
  }
}

export const executeCopy = async ({
  company,
  toast,
}: {
  company: Company
  toast: (args: UseToastOptions) => void
}) => {
  let destinationEnvironment = getEnvironment() === 'production' ? 'UAT' : 'Production'

  let [sourceErr, sourceCentreTranslatorResponse] = await centreAPIs.getCentreTranslatorById({
    id: company.translator_id,
  })

  if (sourceErr) {
    toast({ status: 'error', description: 'Failed to load source data' })
    return
  }

  let sourceCentreTranslator = sourceCentreTranslatorResponse?.data?.data

  let [destErr, destCompany] = await centreAPIs.otherEnvironment.getCentreCompanyBySlug({
    slug: company.slug,
  })

  let [destCentreTranslatorErr, destCentreTranslatorResponse] =
    await centreAPIs.otherEnvironment.getCentreTranslatorById({
      id: destCompany.translator_id,
    })

  if ((destErr || destCentreTranslatorErr) && destCentreTranslatorErr.response.status !== 404) {
    toast({ status: 'error', description: 'Failed to load destination data' })
    return
  }

  let destCentreTranslator = destCentreTranslatorResponse?.data?.data

  let copySource = Object.keys(sourceCentreTranslator)
    .filter((key) => COPY_INCLUDED_KEYS.includes(key))
    .reduce((acc, key) => ({ ...acc, [key]: sourceCentreTranslator[key] }), {})

  if (!destCentreTranslator || destCentreTranslator.length === 0) {
    let [createErr, createdCentreTranslator] =
      await centreAPIs.otherEnvironment.createCentreTranslator({
        translator: copySource,
      })

    if (createErr) {
      toast({ status: 'error', description: 'Failed to create Centre Translator' })
      return [createErr, null]
    }

    toast({ description: `Successfully created Centre Translator in ${destinationEnvironment}` })

    return [null, createdCentreTranslator]
  }

  let [updateErr, updatedCentreTranslator] =
    await centreAPIs.otherEnvironment.updateCentreTranslator({
      translator: {
        ...destCentreTranslator,
        ...copySource,
      },
    })

  if (updateErr) {
    toast({ status: 'error', description: 'Failed to copy Centre Translator' })
    return [updateErr, null]
  }

  toast({ description: `Successfully copied Centre Translator to ${destinationEnvironment}` })

  return [null, updatedCentreTranslator]
}
