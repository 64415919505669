import { UseToastOptions } from '@chakra-ui/react'
import { centreAPIs } from 'src/utils/api'
import { getEnvironment } from 'src/utils'

const COPY_EXCLUDED_KEYS = ['_revision', 'id']

type CopyContext = {
  whitelist: string[]
  companyConfig: Partial<CompanyConfig>
}

/**
 * Loads the `source` and `dest` values to display in the diff dialog.
 */
export const loadCopyDiff = async ({
  company,
  context,
  toast,
}: {
  company: Company
  context: CopyContext
  toast: (args: UseToastOptions) => void
}) => {
  const { companyConfig, whitelist } = context

  const [err, response] = await centreAPIs.otherEnvironment.getCentreCompanyConfigBySlug({
    slug: company.slug,
  })

  const destinationEnvironment = getEnvironment() === 'production' ? 'UAT' : 'Production'

  if (err) {
    toast({
      status: 'error',
      title: 'Error',
      description: `Failed to load ${destinationEnvironment} config`,
    })
  }

  const destConfig = err ? null : response.data.config

  return {
    source: [...Object.keys(companyConfig)]
      .sort((a, b) => (a < b ? -1 : 1))
      .filter((key) => !COPY_EXCLUDED_KEYS.includes(key))
      .reduce((acc, key) => ({ ...acc, [key]: companyConfig[key] }), {}),

    dest: destConfig
      ? [...Object.keys(destConfig)]
          .sort((a, b) => (a < b ? -1 : 1))
          .filter((key) => whitelist.includes(key))
          .filter((key) => !COPY_EXCLUDED_KEYS.includes(key))
          .reduce((acc, key) => ({ ...acc, [key]: destConfig[key] }), {})
      : null,
  }
}

/**
 * Runs the actual copy routine with error handlers and notifications.
 */
export const executeCopy = async ({
  company,
  context,
  toast,
}: {
  company: Company
  context: CopyContext
  toast: (args: UseToastOptions) => void
}) => {
  const { companyConfig } = context
  const destinationEnvironment = getEnvironment() === 'production' ? 'UAT' : 'Production'

  const [err, response] = await centreAPIs.otherEnvironment.getCentreCompanyConfigBySlug({
    slug: company.slug,
  })

  if (err) {
    toast({
      status: 'error',
      title: 'Error',
      description: `Failed to load ${destinationEnvironment} config`,
    })
    return
  }

  const destConfig = response.data.config

  const copySource = Object.keys(companyConfig)
    .filter((key) => !COPY_EXCLUDED_KEYS.includes(key))
    .reduce((acc, key) => ({ ...acc, [key]: companyConfig[key] }), {})

  const [updateErr, updatedConfig] = await centreAPIs.otherEnvironment.updateCentreCompanyConfig({
    slug: company.slug,
    companyConfig: {
      ...copySource,
      _revision: destConfig._revision,
    },
  })

  if (updateErr) {
    toast({ status: 'error', description: 'Failed to copy company config' })
    return [updateErr, null]
  }

  toast({ description: `Successfully copied company config to ${destinationEnvironment}` })

  return [null, updatedConfig]
}
