import {
  BrandImage,
  CreateBrandRequest,
  CreateBuildRequest,
  CreateFlavorRequest,
  UpdateBrandRequest,
  UpdateBuildRequest,
  UpdateFlavorRequest,
} from 'src/api/branding'
import { companies } from './companies'
import { oneOf, optional } from './utils'

export const sampleImg = 'https://picsum.photos/300/200'

function initializeFlavors(): Flavor[] {
  return companies.reduce((prev, current) => {
    if (optional(true))
      prev.push(
        createFlavor({
          fullName: current.name,
          shortName: current.name,
          slug: current.slug,
        })
      )
    return prev
  }, [])
}
export const flavors: Flavor[] = initializeFlavors()

export function createFlavor(values: CreateFlavorRequest): Flavor {
  const company = companies.find((company) => company.slug === values.slug)
  return {
    createdAt: '2019-08-24T14:15:22Z',
    deletedAt: '2019-08-24T14:15:22Z',
    id: `7WN84FD43C9WN-${company.id}`,
    type: 'api.platform.branding.v1.response#FlavorResponse',
    updatedAt: '2019-08-24T14:15:22Z',
    ...values,
  }
}
export function updateFlavor(values: UpdateFlavorRequest): Flavor {
  const flavor = flavors.find((flavor) => flavor.id === values.id)
  Object.assign(flavor, values)
  return flavor
}

function initializeBrands(): Brand[] {
  return flavors.reduce((prev, current) => {
    if (optional(true))
      prev.push(
        createBrand({
          flavorId: current.id,
          colorPrimary: oneOf(['#123456', '#AA4499', '#F4BD27']),
          colorSecondary: oneOf(['#654321', '#33FF77', '#F27F20']),
          hideBushelBranding: oneOf([true, false]),
          iosAppIconImage: optionalImage(),
          iosSplashScreenImage: optionalImage(),
          launcherImage: optionalImage(),
          logoIconImage: optionalImage(),
          logoStackedImage: optionalImage(),
        })
      )
    return prev
  }, [])
}
function optionalImage(): BrandImage {
  return optional({ value: { mimeType: 'image/png', imageStringBase64: 'asdlifhalweguasgi' } })
}

export const brands: Brand[] = initializeBrands()

export function createBrand(values: CreateBrandRequest): Brand {
  const flavor = flavors.find((flavor) => flavor.id === values.flavorId)
  const companyId = companies.find((company) => company.slug === flavor.slug).id
  return {
    colorPrimary: values.colorPrimary,
    iosAppIconUrl: values.iosAppIconImage?.value ? sampleImg : null,
    flavorId: values.flavorId,
    logoIdUrl: values.logoIconImage?.value ? sampleImg : null,
    colorSecondary: values.colorSecondary,
    type: 'api.platform.branding.v1.response#BrandResponse',
    iosSplashScreenUrl: values.iosSplashScreenImage?.value ? sampleImg : null,
    createdAt: '2022-09-19T22:31:28.719155106Z',
    deletedAt: '2022-09-19T22:31:28.719155106Z',
    launcherUrl: values.launcherImage?.value ? sampleImg : null,
    logoStackedUrl: values.logoStackedImage?.value ? sampleImg : null,
    id: `4d2423f7-d6e3-452b-adf6-011e12c3bff1${companyId}`,
    hideBushelBranding: values.hideBushelBranding,
    updatedAt: '2022-09-19T22:31:28.719155106Z',
  }
}

export function updateBrand(values: UpdateBrandRequest): Brand {
  const index = brands.findIndex((brand) => brand.id === values.id)
  const brand = brands[index]
  brand.colorPrimary = values.colorPrimary
  brand.colorSecondary = values.colorSecondary
  brand.hideBushelBranding = values.hideBushelBranding
  if (values.iosAppIconImage) {
    brand.iosAppIconUrl = values.iosAppIconImage?.value ? sampleImg : null
  }
  if (values.iosSplashScreenImage) {
    brand.iosSplashScreenUrl = values.iosSplashScreenImage?.value ? sampleImg : null
  }
  if (values.launcherImage) {
    brand.launcherUrl = values.launcherImage?.value ? sampleImg : null
  }
  if (values.logoIconImage) {
    brand.logoIdUrl = values.logoIconImage?.value ? sampleImg : null
  }
  if (values.logoStackedImage) {
    brand.logoStackedUrl = values.logoStackedImage?.value ? sampleImg : null
  }
  return brand
}

function initializeBuilds(): Build[] {
  return flavors.reduce((prev, current) => {
    if (optional(true)) {
      const companyName = companies.find((company) => company.slug === current.slug).name
      prev.push(
        createBuild({
          flavorId: current.id,
          iosBundleId: `ag.bushel.scaletickets.${current.slug}`,
          androidBundleId: `ag.bushel.scaletickets.${current.slug}`,
          iosIssuerId: '516546518-e08d-433a-a6c2-aasdhglkdg1d',
          iosFastlaneUser: 'busheladmin2@bushelpowered.com',
          iosDisplayName: companyName,
          versionName: '3.10',
          iosCertTeamId: 'B8RFDSFSYXDM',
          iosKeyId: 'LPB75PSDFFD',
          iosProduceItcTeamName: companyName,
          iosFastlanePassword: 'JGZTzlLuUffdssggsaaf1',
          iosApiKey: `-----BEGIN PRIVATE KEY-----\nSDrikalASjogEAMBMGByqGSM49AgEGCCwerav3S53M49AwEHBHkwdwIBAQQgY63uCoOvr2Yez3In\nWTqvcjqO5hZc1ajChAiVBgQM/QegCgYI0DAQehRANCsdf3AFgasdf34@fT7VZLIdYbSlM4k\nan8Txco5if8fdQQDwoB7jjMY4qad4wl5ehgN9LDXIxzQQJrerJdTpTibrokP7dafggdsamCQEShW\nh4Ge32nFso8pD\n-----END PRIVATE KEY-----`,
        })
      )
    }
    return prev
  }, [])
}
export const builds: Build[] = initializeBuilds()

export function createBuild(values: CreateBuildRequest): Build {
  const flavor = flavors.find((flavor) => flavor.id === values.flavorId)
  const company = companies.find((company) => company.slug === flavor.slug)
  return {
    createdAt: '2022-09-19T22:31:28.719155106Z',
    deletedAt: '2022-09-19T22:31:28.719155106Z',
    id: `7WN3BR89HML0N-${company.id}`,
    updatedAt: '2022-09-19T22:31:28.719155106Z',
    type: 'api.platform.branding.v1.response#BuildResponse',
    ...values,
  }
}
export function updateBuild(values: UpdateBuildRequest): Build {
  const build = builds.find((build) => build.id === values.id)
  Object.assign(build, values)
  return build
}
