import { useQuery, useMutation } from '@tanstack/react-query'
import { centreAPIs } from 'src/utils/api'

export const useCentreCashBidConfigs = ({ companySlug }) => {
  return useQuery({
    queryKey: ['centre-cashbid-configs', { companySlug }],
    queryFn: async () => {
      let [err, response] = await centreAPIs.getCentreCashBidConfigs({ slug: companySlug })

      if (err) throw err
      return response.data.data
    },
    cacheTime: 0,
  })
}

export const useCentreElevators = ({ companyId }) => {
  return useQuery({
    queryKey: ['centre-evlevators-by-company-id', { companyId }],
    queryFn: async () => {
      let [err, response] = await centreAPIs.getCentreElevatorsByCompanyId({
        companyId,
      })

      if (err) throw err
      return response.data.data
    },
  })
}

export const useCentreCashBidConfigMutations = () => {
  let { mutateAsync: saveConfig } = useMutation({
    mutationFn: async (config: CashBidConfig) => {
      let [err, response] = await centreAPIs[
        !config.id ? 'createCentreCashBidConfigs' : 'updateCentreCashBidConfigs'
      ]({ config })
      if (err) throw err

      return [err, response]
    },
  })

  let { mutateAsync: deleteConfig } = useMutation({
    mutationFn: async (config: CashBidConfig) => {
      let [err, response] = await centreAPIs.deleteCentreCashBidConfigs({ config })
      if (err) throw err
      return [err, response]
    },
  })

  return { saveConfig, deleteConfig }
}
