import React from 'react'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { Formik, Form, Field, FormikProps } from 'formik'
import { Box, Placeholders, Stack, Button, Row } from 'src/ui'
import { TextField as FormikTextField, Select as FormikSelect } from 'src/ui/formik'
import { FormControl, FormLabel } from 'src/ui/form-controls'
import { isFaded, representValue } from 'src/companies/configurations/utils'
import * as queries from 'src/utils/queries'
import ConfigPanel, { PanelState } from 'src/companies/configurations/config-panel'
import { loadCopyDiff, executeCopy } from 'src/companies/translator/copy'
import { useCopyDiff } from 'src/companies/copy-utils'
import { Dispatch } from 'src/store'
import {
  useCentreTranslators,
  useSaveCentreTranslatorMutation,
} from 'src/api/queries/centre-translators'
import { useToast } from 'src/utils/toast'

const AUTH_VERIFICATION_FEATURE_VERSIONS = [
  '1.0.0',
  'Agvantage_1.0.0',
  'Agtrax_1.1.0',
  'Internal_1.0.0',
  'Scoular_1.0.0',
]
const COMPANY_USER_FEATURE_VERSIONS = ['1.0.0']

const CentreTranslatorSchema = Yup.object().shape({
  url: Yup.string().url().nullable(),
})

// FORM
///////////////////////////////////////////////////////////////////////////////

interface CentreTranslatorFormProps {
  formikProps: FormikProps<any>
  formData: {
    submittedAt: number
    isSubmitting: boolean
  }
}

const CentreTranslatorForm: React.FC<CentreTranslatorFormProps> = ({ formikProps, formData }) => {
  let { submittedAt } = formData
  let { submitForm } = formikProps

  React.useEffect(() => {
    if (!submittedAt) return
    submitForm()
  }, [submittedAt, submitForm])

  return (
    <Form>
      <Stack isInline spacing={4} align="center" height="80px" px={6}>
        <Box flex={1} minWidth={0}>
          <Field
            name="id"
            label="ID"
            InputProps={{ readOnly: true }}
            disabled={true}
            component={FormikTextField}
            formControlProps={{
              height: '62px',
              mb: null,
            }}
          />
        </Box>
        <Box flex={1} minWidth={0}>
          <Field
            id="verification_feature_version"
            name="verification_feature_version"
            label="Verification Feature Version"
            component={FormikSelect}
            formControlProps={{
              height: '62px',
              mb: null,
            }}
            options={AUTH_VERIFICATION_FEATURE_VERSIONS.map((version) => ({
              value: version,
              label: version,
            }))}
          />
        </Box>
        <Box flex={1} minWidth={0}>
          <Field
            id="company_user_feature_version"
            name="company_user_feature_version"
            label="Company User Feature Version"
            component={FormikSelect}
            formControlProps={{
              height: '62px',
              mb: null,
            }}
            options={COMPANY_USER_FEATURE_VERSIONS.map((version) => ({
              value: version,
              label: version,
            }))}
          />
        </Box>
      </Stack>
      <Stack isInline spacing={4} align="center" height="80px" px={6}>
        <Box flex={1} minWidth={0}>
          <Field
            id="url"
            name="url"
            label="URL"
            component={FormikTextField}
            formControlProps={{
              height: '62px',
              mb: null,
            }}
          />
        </Box>
      </Stack>
      <Stack isInline spacing={4} align="center" height="80px" px={6}>
        <Box flex={1} minWidth={0}>
          <Field
            id="shared_key"
            name="shared_key"
            label="Shared Key"
            component={FormikTextField}
            formControlProps={{
              height: '62px',
              mb: null,
            }}
          />
        </Box>
      </Stack>
    </Form>
  )
}

// CREATE
///////////////////////////////////////////////////////////////////////////////

interface CreateCentreTranslatorProps {
  setShowEditPanel: (string) => void
  setPanelState: (string) => void
  company: Company
}

const CreateCentreTranslator: React.FC<CreateCentreTranslatorProps> = ({
  setShowEditPanel,
  setPanelState,
  company,
}) => {
  return (
    <>
      <Stack>
        <Box flex={1} minWidth={0}>
          <Placeholders.FailedState message={`No Translator found for ${company.name}`} mt="0" />
        </Box>
        <Row justifyContent="center" mt={3}>
          <Button
            colorScheme="yellow"
            onClick={() => {
              setShowEditPanel(true)
              setPanelState('editing')
            }}
          >
            Create
          </Button>
        </Row>
      </Stack>
    </>
  )
}

// DETAIL
///////////////////////////////////////////////////////////////////////////////

interface CentreTranslatorDetailProps {
  centreTranslator: CentreTranslator
}

const CentreTranslatorDetail: React.FC<CentreTranslatorDetailProps> = ({ centreTranslator }) => {
  return (
    <>
      <Stack isInline spacing={4} align="center" height="80px" px={6}>
        <Box flex={1} minWidth={0}>
          <FormControl height="62px" mb={null}>
            <FormLabel title="ID" as="div">
              ID
            </FormLabel>
            <Box
              color={isFaded(centreTranslator.id) ? 'gray.400' : undefined}
              css={{
                minWidth: 0,
                overflow: 'auto',
                whiteSpace: 'nowrap',
                scrollbarWidth: 'none',
                '::-webkit-scrollbar': {
                  width: 0,
                  height: 0,
                },
              }}
            >
              {representValue(centreTranslator.id)}
            </Box>
          </FormControl>
        </Box>
        <Box flex={1} minWidth={0}>
          <FormControl height="62px" mb={null}>
            <FormLabel title="Verification Feature Version" as="div">
              Verification Feature Version
            </FormLabel>
            <Box
              color={
                isFaded(centreTranslator.verification_feature_version) ? 'gray.400' : undefined
              }
              css={{
                minWidth: 0,
                overflow: 'auto',
                whiteSpace: 'nowrap',
                scrollbarWidth: 'none',
                '::-webkit-scrollbar': {
                  width: 0,
                  height: 0,
                },
              }}
            >
              {representValue(centreTranslator.verification_feature_version)}
            </Box>
          </FormControl>
        </Box>
        <Box flex={1} minWidth={0}>
          <FormControl height="62px" mb={null}>
            <FormLabel title="Verification Feature Version" as="div">
              Company User Feature Version
            </FormLabel>
            <Box
              color={
                isFaded(centreTranslator.company_user_feature_version) ? 'gray.400' : undefined
              }
              css={{
                minWidth: 0,
                overflow: 'auto',
                whiteSpace: 'nowrap',
                scrollbarWidth: 'none',
                '::-webkit-scrollbar': {
                  width: 0,
                  height: 0,
                },
              }}
            >
              {representValue(centreTranslator.company_user_feature_version)}
            </Box>
          </FormControl>
        </Box>
      </Stack>
      <Stack isInline spacing={4} align="center" height="80px" px={6} bg="gray.50">
        <Box flex={1} minWidth={0}>
          <FormControl height="62px" mb={null}>
            <FormLabel title="URL" as="div">
              URL
            </FormLabel>
            <Box
              color={isFaded(centreTranslator.url) ? 'gray.400' : undefined}
              css={{
                minWidth: 0,
                overflow: 'auto',
                whiteSpace: 'nowrap',
                scrollbarWidth: 'none',
                '::-webkit-scrollbar': {
                  width: 0,
                  height: 0,
                },
              }}
            >
              {representValue(centreTranslator.url)}
            </Box>
          </FormControl>
        </Box>
      </Stack>
      <Stack isInline spacing={4} align="center" height="80px" px={6}>
        <Box flex={1} minWidth={0}>
          <FormControl height="62px" mb={null}>
            <FormLabel title="Shared Key" as="div">
              Shared Key
            </FormLabel>
            <Box
              color={isFaded(centreTranslator.shared_key) ? 'gray.400' : undefined}
              css={{
                minWidth: 0,
                overflow: 'auto',
                whiteSpace: 'nowrap',
                scrollbarWidth: 'none',
                '::-webkit-scrollbar': {
                  width: 0,
                  height: 0,
                },
              }}
            >
              {representValue(centreTranslator.shared_key)}
            </Box>
          </FormControl>
        </Box>
      </Stack>
    </>
  )
}

interface CompanyTranslatorProps {
  company: Company
  setCopyDialog: (args: any) => void
  loadCompanies: Dispatch['companies']['loadAll']
}

const CompanyTranslator: React.FC<CompanyTranslatorProps> = ({
  company,
  setCopyDialog,
  loadCompanies,
}) => {
  let [panelState, setPanelState] = React.useState<PanelState>('expanded')
  let [showEditPanel, setShowEditPanel] = React.useState(false)
  let [formData, setFormData] = React.useState({ submittedAt: null, isSubmitting: false })
  let { saveCentreTranslator } = useSaveCentreTranslatorMutation()
  let toast = useToast()

  let centreTranslatorsQuery: any = useCentreTranslators({
    id: company.translator_id,
  })

  let centreTranslator = centreTranslatorsQuery?.data || {
    id: null,
    verification_feature_version: null,
    company_user_feature_version: null,
    url: null,
    shared_key: null,
  }

  let centreTranslatorNotFound = centreTranslatorsQuery?.error?.response?.status === 404

  const stopEditing = () => {
    setPanelState('expanded')
    setFormData({ submittedAt: null, isSubmitting: false })
  }

  let { startCopy } = useCopyDiff({
    company,
    context: { centreTranslator: centreTranslator },
    loadCopyDiff,
    executeCopy,
    setCopyDialog,
  })

  return (
    <>
      {queries.areAnyLoading(centreTranslatorsQuery) && <Placeholders.LoadingState />}
      {queries.areAnyFailed(centreTranslatorsQuery) &&
        !centreTranslatorNotFound &&
        !showEditPanel && <Placeholders.FailedState />}
      {queries.areAnyFailed(centreTranslatorsQuery) &&
        centreTranslatorNotFound &&
        !showEditPanel && (
          <CreateCentreTranslator
            setShowEditPanel={setShowEditPanel}
            setPanelState={setPanelState}
            company={company}
          />
        )}
      {(queries.areAllLoaded(centreTranslatorsQuery) || showEditPanel) && (
        <ConfigPanel
          label="v1 Translator"
          formData={formData}
          expanded={true}
          panelState={panelState}
          onClickCopy={startCopy}
          editPermission="company_v1_translators_edit"
          copyPermission="company_v1_translators_copy"
          hideToggleIcon={true}
          onCancelEdit={stopEditing}
          onClickEdit={() => setPanelState('editing')}
          onSave={() => {
            setFormData((state) => ({ ...state, submittedAt: new Date().getTime() }))
          }}
        >
          {panelState !== 'editing' && (
            <CentreTranslatorDetail centreTranslator={centreTranslator} />
          )}
          {panelState === 'editing' && (
            <Formik
              initialValues={centreTranslator}
              validationSchema={CentreTranslatorSchema}
              onSubmit={async (values, _formikActions) => {
                setFormData((state) => ({ ...state, isSubmitting: true }))
                await saveCentreTranslator(
                  {
                    translator: {
                      ...values,
                      _revision: centreTranslator._revision,
                    },
                    companySlug: company.slug,
                  },
                  {
                    onSuccess: async () => {
                      toast({
                        description: 'Successfully saved Centre Translator',
                      })
                      await loadCompanies()
                    },
                    onError: () => {
                      toast({
                        status: 'error',
                        description: 'Failed to save Centre Translator',
                      })
                      stopEditing()
                    },
                  }
                )
              }}
            >
              {(formikProps: FormikProps<any>) => (
                <CentreTranslatorForm formikProps={formikProps} formData={formData} />
              )}
            </Formik>
          )}
        </ConfigPanel>
      )}
    </>
  )
}
const mapDispatch = ({ companies }: Dispatch) => ({
  loadCompanies: companies.loadAll,
})

export default connect(null, mapDispatch)(CompanyTranslator)
