import { reportingClient } from 'src/utils/api'

type Configuration = {
  createdAt: string
  id: string
  title: string
  enabled: boolean
  updatedAt: string
}

export type GradeFactor = {
  gradeName: string
  createdAt: string
  displayName: string
  displayOrder: number
  id: string
  updatedAt: string
}

type GradeFactorConfiguration = {
  gradeName: string
  displayName: string
  displayOrder: number
}

export type GetAllGradeFactorsRequest = {
  slug: string
}

export type GetConfigurationBySlugRequest = {
  slug: string
}

export type SyncGradeFactorsRequest = {
  gradeFactors: GradeFactorConfiguration[]
  slug: string
}

export type UpsertConfigurationRequest = {
  title: string
  slug: string
  enabled: boolean
}

export type GetAllGradeFactorsResponse = {
  data: GradeFactor[]
  type: string
}

export type GetConfigurationBySlugResponse = { data: Configuration; type: string }

export type SyncGradeFactorsResponse = {
  data: GradeFactor[]
  type: string
}

export type UpsertConfigurationResponse = {
  data: Configuration
  type: string
}

export const proofOfYieldRequests = {
  ///////////////////////////////////////////////////////////////////////////////
  // Get All Grade Factors
  // url: https://architecture.bushelops.com/bushel-interfaces/latest/#tag/api.poyreports.v1/operation/GetAllGradeFactors
  ///////////////////////////////////////////////////////////////////////////////
  getAllGradeFactors: async function (params: GetAllGradeFactorsRequest) {
    return reportingClient.post<GetAllGradeFactorsResponse>('/poy/v1/GetAllGradeFactors', params)
  },

  ///////////////////////////////////////////////////////////////////////////////
  // Get Configuration By Slug
  // url: https://architecture.bushelops.com/bushel-interfaces/latest/#tag/api.poyreports.v1/operation/GetConfigurationBySlug
  ///////////////////////////////////////////////////////////////////////////////
  getConfigurationBySlug: async function (params: GetConfigurationBySlugRequest) {
    return reportingClient.post<GetConfigurationBySlugResponse>(
      '/poy/v1/GetConfigurationBySlug',
      params
    )
  },

  ///////////////////////////////////////////////////////////////////////////////
  // Sync Grade Factors
  // url: https://architecture.bushelops.com/bushel-interfaces/latest/#tag/api.poyreports.v1/operation/SyncGradeFactors
  ///////////////////////////////////////////////////////////////////////////////
  syncGradeFactors: async function (params: SyncGradeFactorsRequest) {
    return reportingClient.post<SyncGradeFactorsResponse>('/poy/v1/SyncGradeFactors', params)
  },

  ///////////////////////////////////////////////////////////////////////////////
  // Upsert Configuration
  // url: https://architecture.bushelops.com/bushel-interfaces/latest/#tag/api.poyreports.v1/operation/UpsertConfiguration
  ///////////////////////////////////////////////////////////////////////////////
  upsertConfiguration: async function (params: UpsertConfigurationRequest) {
    return reportingClient.post<UpsertConfigurationResponse>('/poy/v1/UpsertConfiguration', params)
  },
}
