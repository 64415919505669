import axios from 'axios'
import { CONFIG } from 'src/utils/config'
import {
  configuratorClient,
  routerClient,
  ApiReturn,
  ApiDataBody,
  ApiResponse,
} from 'src/utils/api'

// CONFIG API REQUESTS
///////////////////////////////////////////////////////////////////////////////

export const getEnvironments = async (): ApiReturn<Environment[]> => {
  try {
    let response = await configuratorClient.get<ApiDataBody<Environment[]>>(
      '/api/v1/system/environments'
    )
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

// IMAGES | API REQUESTS
///////////////////////////////////////////////////////////////////////////////

export const getImageOptions = async (): ApiReturn<string[]> => {
  try {
    let response = await configuratorClient.get<ApiDataBody<string[]>>('/api/v1/images/options')
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

export const getImageVersions = async ({
  name,
  type,
  environment,
  limit,
}: {
  name?: string
  type?: string
  environment?: string
  limit?: number
}): ApiReturn<TranslatorImage[]> => {
  try {
    let response = await configuratorClient.get<ApiDataBody<TranslatorImage[]>>('/api/v1/images', {
      params: { name, type, environment, limit },
    })
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

// COMPANIES | API REQUESTS
///////////////////////////////////////////////////////////////////////////////

export const getCompanies = async (): ApiReturn<ConfiguratorCompany[]> => {
  try {
    let response =
      await configuratorClient.get<ApiDataBody<ConfiguratorCompany[]>>('/api/v1/companies')
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

type createCompanyArgs = {
  name: string
  slug: string
  region?: 'us-alpha' | 'ca-alpha'
}
export const createCompany = async (body: createCompanyArgs): ApiReturn<any> => {
  try {
    let response = await configuratorClient.post<ApiDataBody<any>>('/api/v1/companies', body)
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

export const getCompanyToken = async ({
  slug,
  environment,
}: {
  slug: string
  environment: string
}) => {
  try {
    let response = await configuratorClient.get(`/api/v1/companies/${slug}/token/${environment}`)
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

export const setCompanyToken = async ({
  slug,
  environment,
  token,
}: {
  slug: string
  environment: string
  token: string
}) => {
  try {
    let response = await configuratorClient.put(`/api/v1/companies/${slug}/token/${environment}`, {
      token,
    })
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

// TRANSLATORS | API REQUESTS
///////////////////////////////////////////////////////////////////////////////

export const getCompanyTranslators = async ({ slug }: { slug: string }) => {
  try {
    let response = await configuratorClient.get(`/api/v1/companies/${slug}/translators`)
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

export const createTranslator = async ({ translator }) => {
  let { name, company_slug } = translator

  try {
    let response = await configuratorClient.post('/api/v1/translators', { name, company_slug })
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

export const deleteTranslator = async ({ translator }) => {
  try {
    let response = await configuratorClient.delete(`/api/v1/translators/${translator.id}`)
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

export const getTranslatorSecrets = async ({ translatorID }) => {
  try {
    let response = await configuratorClient.get(`/api/v1/translators/${translatorID}/secrets`)
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

export const getSecretByID = async ({ translatorID, secretID }) => {
  try {
    let response = await configuratorClient.get(
      `/api/v1/translators/${translatorID}/secrets/${secretID}`
    )
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

export const createTranslatorSecret = async ({ translatorSecret }) => {
  try {
    let { id, name, translatorId, companySlug, value, createdAt, updatedAt } = translatorSecret

    let response = await configuratorClient.post(
      `/api/v1/translators/${translatorSecret.translatorId}/secrets`,
      {
        id,
        name,
        translatorId,
        companySlug,
        value,
        createdAt,
        updatedAt,
      }
    )
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

export const deleteTranslatorSecret = async ({ translatorID, secretID }) => {
  try {
    let response = await configuratorClient.delete(
      `/api/v1/translators/${translatorID}/secrets/${secretID}`
    )
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

export const updateTranslatorSecret = async ({ translatorSecret }) => {
  try {
    let { id, name, translatorId, companySlug, value, createdAt, updatedAt } = translatorSecret

    let response = await configuratorClient.put(
      `/api/v1/translators/${translatorId}/secrets/${id}`,
      {
        id,
        name,
        translatorId,
        companySlug,
        value,
        createdAt,
        updatedAt,
      }
    )
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

export const getTranslatorConfig = async ({ id }: { id: string }): ApiReturn<TranslatorConfig> => {
  try {
    let response = await configuratorClient.get<ApiDataBody<TranslatorConfig>>(
      `/api/v1/config/${id}`
    )
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

export const deleteTranslatorConfig = async ({ config }) => {
  try {
    let response = await configuratorClient.delete(`/api/v1/config/${config.id}`)
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

export const getLatestTranslatorConfigForEnvironment = async ({
  environment,
  translator_id,
}: {
  environment: string
  translator_id: string
}): ApiReturn<TranslatorConfig> => {
  try {
    let response = await configuratorClient.get<ApiDataBody<TranslatorConfig>>(
      `/api/v1/translators/${translator_id}/${environment}/latest-config`
    )
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

export const createTranslatorEnvironmentConfig = async ({
  translator_id,
  environment,
  config,
}): ApiReturn<any> => {
  try {
    let response = await configuratorClient.post<ApiDataBody<any>>(
      `/api/v1/translators/${translator_id}/${environment}/config`,
      config
    )

    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

export const getTranslatorDeployments = async ({
  translator_id,
  limit = 2,
}): ApiReturn<Deployment[]> => {
  try {
    let response = await configuratorClient.get<ApiDataBody<Deployment[]>>('/api/v1/deployments', {
      params: { translator_id, limit },
    })
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

export const getTranslatorConfigHistory = async ({
  translator_id,
  environment,
}): ApiReturn<TranslatorConfig[]> => {
  try {
    let response = await configuratorClient.get<ApiDataBody<TranslatorConfig[]>>(
      `/api/v1/translators/${translator_id}/${environment}/config`
    )
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

// ENDPOINTS | API REQUESTS
///////////////////////////////////////////////////////////////////////////////

export const getEndpoints = async (): Promise<ApiResponse<Endpoint[]>> => {
  try {
    let response = await configuratorClient.get<ApiDataBody<Endpoint[]>>('/api/v1/endpoints')
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

export const createEndpoint = async ({ endpoint }): ApiReturn<Endpoint> => {
  let { url, token } = endpoint

  try {
    let response = await configuratorClient.post<ApiDataBody<Endpoint>>('/api/v1/endpoints', {
      url,
      token,
    })
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

export const updateEndpoint = async ({ endpoint }): ApiReturn<Endpoint> => {
  let { id, url, token } = endpoint

  try {
    let response = await configuratorClient.put<ApiDataBody<Endpoint>>(`/api/v1/endpoints/${id}`, {
      url,
      token,
    })
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

export const deleteEndpoint = async ({ endpoint }) => {
  try {
    let response = await configuratorClient.delete(`/api/v1/endpoints/${endpoint.id}`)
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

export const addEndpointSubscription = async ({ endpoint, subscription }) => {
  let { company_slug, environment, features } = subscription

  try {
    let response = await configuratorClient.post(`/api/v1/endpoints/${endpoint.id}/subscription`, {
      company_slug,
      environment,
      features,
    })
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

export const removeEndpointSubscription = async ({ endpoint, subscription }) => {
  let { company_slug, environment, features } = subscription

  try {
    let response = await configuratorClient.delete(
      `/api/v1/endpoints/${endpoint.id}/subscription`,
      {
        data: { company_slug, environment, features },
      }
    )
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

export const getFeatures = async (): ApiReturn<any[]> => {
  try {
    let response = await configuratorClient.get<ApiDataBody<any[]>>('/api/v1/endpoints/features')
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

///////////////////////////////////////////////////////////////////////////////
// ROUTER API REQUESTS
///////////////////////////////////////////////////////////////////////////////

const routerEnvironmentUrl = ({ environment_slug }) =>
  `https://router.translator${
    // prod url does not have environment bit
    environment_slug === 'prod'
      ? ''
      : environment_slug === 'sand'
        ? '.sandbox'
        : `.${environment_slug}`
  }${
    // canada has its own subdomain segment
    CONFIG.region === 'ca-alpha' ? '.ca' : ''
  }.${CONFIG.domain_suffix}`

export const startReplay = async ({
  environment_slug,
  company_slug,
  feature,
  endpoint,
}): ApiReturn<any> => {
  try {
    let response = await routerClient.post<ApiDataBody<any>>(
      `${routerEnvironmentUrl({ environment_slug })}/api/v1/replay`,
      {
        push_location: endpoint.url,
        auth_token: endpoint.token,
        feature,
        endpoint_id: endpoint.id,
      },
      { headers: { 'X-Company-Slug': company_slug } }
    )
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

///////////////////////////////////////////////////////////////////////////////
// ADAPTER API REQUESTS
///////////////////////////////////////////////////////////////////////////////

export const resetSchedule = async ({ adapter_url, api_token, name }) => {
  try {
    let response = await axios.post(
      `${adapter_url}/v1/reset-schedule`,
      { name, reset_state: true },
      { headers: { Authorization: api_token } }
    )
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

export const getMetrics = async ({ adapter_url, api_token }) => {
  try {
    let response = await axios.get(`${adapter_url}/calculated-metrics`, {
      headers: { Authorization: api_token },
    })
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

export const getDetailMetrics = async ({ adapter_url, api_token, job_name, records_returned }) => {
  try {
    let response = await axios.get(`${adapter_url}/metrics/${job_name}/${records_returned}`, {
      headers: { Authorization: api_token },
    })
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}
