import React, { createContext, useContext } from 'react'
import {
  useMicroserviceCommodityVarieties,
  useMicroserviceCompanyCommodities,
} from 'src/api/queries/microservices/commodities'
import { IMicroserviceCommodity, IMicroserviceCommodityVariety } from 'src/api/api'

export type CommodityContextType = {
  company: Company
  loadNextPage: () => void
  commodities: IMicroserviceCommodity[]
  hasNextPage: boolean
  isFetching: boolean
  isFetchingNextPage: boolean
  commodityVarieties: IMicroserviceCommodityVariety[]
  error: {}
}

const CommodityContext = createContext<CommodityContextType>(null)

const CommodityProvider = ({ company, children }) => {
  const {
    data: commodities,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    error,
  } = useMicroserviceCompanyCommodities({
    tenant_id: company.tenant_id,
    datasource_id: company.datasource_id,
  })
  let commodityVarietiesQuery = useMicroserviceCommodityVarieties()
  let commodityVarieties = commodityVarietiesQuery?.data

  const loadNextPage = () => fetchNextPage()

  return (
    <CommodityContext.Provider
      value={{
        company,
        loadNextPage,
        commodities,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        commodityVarieties,
        error,
      }}
    >
      {children}
    </CommodityContext.Provider>
  )
}

const useCommodityContext = () => useContext(CommodityContext)

export { useCommodityContext, CommodityContext, CommodityProvider }
