// Get All Brands
import { useQuery } from '@tanstack/react-query'
import { getDatasourceById } from 'src/api/microservices/tenants'

export const useMicroserviceDatasource = ({ datasource_id }) =>
  useQuery({
    queryKey: ['datasource-by-id', { datasource_id }],
    queryFn: async () => {
      let [err, response] = await getDatasourceById({ datasource_id })

      if (err) throw err
      return response.data.data
    },
    cacheTime: 0,
  })
