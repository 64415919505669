import { oneOf } from './utils'
import { companies } from './companies'
import { IESignContractsReport, ILoggedInUsersReport } from 'src/api/api'

export function loggedInUsers(): ILoggedInUsersReport {
  return companies.reduce((prev, company) => {
    return {
      ...prev,
      [company.id]: {
        'logged-in-users-month-count': oneOf([0, 0, oneOf([3, 4, 5, 6, 7, 8, 9])]),
      },
    }
  }, {})
}

export function eSignContracts(): IESignContractsReport {
  return companies.reduce((prev, company) => {
    return {
      ...prev,
      [company.id]: {
        'esign-contracts-created-month-count': oneOf([0, 0, 1]),
        'esign-contracts-signed-month-count': oneOf([0, 0, 1]),
        'esign-contracts-unsigned-month-count': oneOf([0, 0, 1]),
      },
    }
  }, {})
}
