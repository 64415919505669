import { RequestHandler } from 'msw'
import { handlers as companyHandlers } from './company'
import { handlers as configurationsHandlers } from './configurations'
import { handlers as configuratorHandlers } from './configurator'
import { handlers as cashbidConfigHandlers } from './cashbid-config'
import { handlers as commodityHandlers } from './commodities'
import { handlers as elevatorHandlers } from './elevator'
import { handlers as translatorHandlers } from './translator'
import { handlers as positionHandlers } from './positions'
import { handlers as brandingHandlers } from './branding'
import { handlers as flagrHandlers } from './flagr'
import { handlers as OMSHandlers } from './offer-management'
import { handlers as reportsHandlers } from './reports'
import { handlers as connectHandlers } from './connect'
import { handlers as proofOfYieldHandlers } from 'src/mocks/proof-of-yield'
import { handlers as ticketAppReportHandlers } from 'src/mocks/ticket-applications'
import { handlers as contractsReportHandlers } from 'src/mocks/contracts'

export const rEnvironments = ['https://bushel-platform-centre-r1.dev.bushelops.com/api/internal']

export const testHandlers: RequestHandler[] = [
  ...companyHandlers(rEnvironments),
  ...configurationsHandlers(rEnvironments),
  ...configuratorHandlers,
  ...cashbidConfigHandlers(rEnvironments),
  ...elevatorHandlers(rEnvironments),
  ...translatorHandlers(rEnvironments),
  ...commodityHandlers(rEnvironments),
  ...positionHandlers(rEnvironments),
  ...brandingHandlers,
  ...flagrHandlers,
  ...OMSHandlers(rEnvironments),
  ...reportsHandlers(rEnvironments),
  ...connectHandlers(rEnvironments),
  ...proofOfYieldHandlers,
  ...ticketAppReportHandlers,
  ...contractsReportHandlers,
]
