import { handleApiError } from 'src/store/utils'
import { getEnvironments } from 'src/utils/api'
import { STATUS } from 'src/utils/api-status'

export interface EnvironmentsState {
  status: STATUS
  data: Environment[]
}

const Environments = {
  state: {
    status: STATUS.idle,
    data: [],
  },

  reducers: {
    setState(state: EnvironmentsState, payload) {
      return { ...state, ...payload }
    },
  },

  effects: (dispatch) => ({
    async loadAll() {
      dispatch.environments.setState({ status: STATUS.loading })

      let [err, data] = await getEnvironments()

      if (err) return handleApiError(dispatch, err)

      dispatch.environments.setState({
        status: STATUS.loaded,
        data: data.data.data,
      })
    },
  }),
}

export default Environments
