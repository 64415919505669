import { ICompany, IMicroserviceCommodity, IMicroserviceCommodityVariety } from 'src/api/api'
import { microservicesClient } from 'src/utils/api'

const baseUri = '/commodity'

export interface GetCommoditiesRequest {
  tenant_id: ICompany['tenant_id']
  datasource_id: ICompany['datasource_id']
  continuationToken: string
}

export interface UpdateCommodityRequest {
  sourceId: IMicroserviceCommodity['sourceId']
  sourceDisplayName?: string
  tenantId: ICompany['tenant_id']
  datasourceId: ICompany['datasource_id']
  displayName?: IMicroserviceCommodity['displayName']
  commodityVarietyId?: IMicroserviceCommodityVariety['commodityVarietyId']
}

//NEW WAY OF DOING REQUESTS WITH
///////////////////////////////////////////////////////////////////////////////
// GET
///////////////////////////////////////////////////////////////////////////////

export const getCommodities = async ({
  tenant_id,
  datasource_id,
  continuationToken,
}: GetCommoditiesRequest) => {
  try {
    const response = await microservicesClient.post(`${baseUri}/v1/GetAllTenantCommodities`, {
      datasourceIds: [datasource_id],
      tenantIds: [tenant_id],
      pagination: {
        token: continuationToken,
        sortPlan: [
          {
            column: 'sourceId',
            direction: 'asc',
          },
        ],
      },
    })
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

export const getCommodityVarieties = async () => {
  try {
    let response = await microservicesClient.post(`${baseUri}/v1/GetAllCommodityVarieties`, {})
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

///////////////////////////////////////////////////////////////////////////////
// CREATE or UPDATE
///////////////////////////////////////////////////////////////////////////////

export const createOrUpdateCommodity = async (params: UpdateCommodityRequest) => {
  return createOrUpdateCommodities([params])
}

export const createOrUpdateCommodities = async (params: UpdateCommodityRequest[]) => {
  try {
    const response = await microservicesClient.post(
      `${baseUri}/v1/CreateOrUpdateTenantCommodities`,
      { tenantCommodities: params }
    )
    return [null, response]
  } catch (error) {
    return [error, true]
  }
}
