import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
  GetAllGradeFactorsRequest,
  GetConfigurationBySlugRequest,
  GetConfigurationBySlugResponse,
  proofOfYieldRequests,
  SyncGradeFactorsRequest,
  UpsertConfigurationRequest,
} from 'src/api/proof-of-yield'

// Get All Grade Factors
export const GET_ALL_GRADE_FACTORS_QUERY_KEY = 'get-all-grade-factors'
export function useGetAllGradeFactors(params: GetAllGradeFactorsRequest) {
  return useQuery({
    queryKey: [GET_ALL_GRADE_FACTORS_QUERY_KEY, params],
    queryFn: async () =>
      proofOfYieldRequests.getAllGradeFactors(params).then((response) => response.data),
  })
}

// Get Configuration By Slug
export const GET_CONFIGURATION_BY_SLUG_QUERY_KEY = 'get-configuration-by-slug'
export function useGetConfigurationBySlug(params: GetConfigurationBySlugRequest) {
  return useQuery<GetConfigurationBySlugResponse, AxiosError>({
    queryKey: [GET_CONFIGURATION_BY_SLUG_QUERY_KEY, params],
    queryFn: async () =>
      proofOfYieldRequests.getConfigurationBySlug(params).then((response) => response.data),
  })
}

// Sync Grade Factors
const SYNC_GRADE_FACTORS_MUTATION_KEY = 'sync-grade-factors'
export function useSyncGradeFactors({ slug }: Pick<SyncGradeFactorsRequest, 'slug'>) {
  return useMutation({
    mutationKey: [SYNC_GRADE_FACTORS_MUTATION_KEY, slug],
    mutationFn: async ({ gradeFactors }: Omit<SyncGradeFactorsRequest, 'slug'>) =>
      proofOfYieldRequests
        .syncGradeFactors({ gradeFactors, slug })
        .then((response) => response.data),
  })
}

// Upsert Configuration
const UPSERT_CONFIGURATION_MUTATION_KEY = 'upsert-configuration'
export function useUpsertConfiguration({ slug }: Pick<UpsertConfigurationRequest, 'slug'>) {
  return useMutation({
    mutationKey: [UPSERT_CONFIGURATION_MUTATION_KEY, slug],
    mutationFn: async ({ enabled, title }: Omit<UpsertConfigurationRequest, 'slug'>) =>
      proofOfYieldRequests
        .upsertConfiguration({ slug, enabled, title })
        .then((response) => response.data),
  })
}
