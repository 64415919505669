import React, { FC } from 'react'
import { css } from '@emotion/react'
import { JsonEditor } from 'jsoneditor-react'
import { Box } from '@chakra-ui/react'
// import ace from 'brace'
// import 'brace/mode/json'
// import 'brace/theme/github'

const defaultHtmlElementProps = {
  className: 'jsoneditor-wrapper',
}

const Editor: FC<any> = ({
  value,
  onChange,
  onError,
  mode = 'text',
  height = 500,
  htmlElementProps = {},
}) => (
  <Box
    css={css`
      .jsoneditor-wrapper {
        height: ${typeof height === 'string' ? height : `${height}px`};

        & .jsoneditor {
          border: 1px solid #dfe3e6;
        }

        & .jsoneditor-menu {
          background-color: #f0f3f6;
          border-bottom: 1px solid #dfe3e6;
        }

        & textarea.jsoneditor-text {
          min-height: unset;
          padding: 6px;
        }

        & .jsoneditor-repair,
        .jsoneditor-sort,
        .jsoneditor-transform {
          display: none;
        }

        .jsoneditor-menu > button {
          background-color: #a0aec0;

          &:hover,
          &:active,
          &:focus {
            background-color: #718096;
          }
        }

        .jsoneditor-statusbar {
          margin-top: 0;
          background-color: #f0f3f6;
        }
      }
    `}
  >
    <JsonEditor
      mode={mode}
      value={value}
      htmlElementProps={{ ...htmlElementProps, ...defaultHtmlElementProps }}
      onChange={onChange}
      onError={onError}
    />
  </Box>
)

export default Editor
