import { IEndpoint, IEndpointSubscription } from 'src/api/api'
import { sequence } from 'src/fixtures/utils'

export const companies: ConfiguratorCompany[] = []
export function createCompany(values?: Partial<ConfiguratorCompany>): ConfiguratorCompany {
  return {
    name: values?.name ?? 'test ccompany',
    slug: values?.slug ?? 'testslug',
    created_at: '2022-11-10T10:02:47-06:00',
    updated_at: '2022-11-10T10:02:47-06:00',
    region: values?.region ?? 'us-alpha',
  }
}

export const endpoints: IEndpoint[] = []
export function createEndpoint(values?: Partial<IEndpoint>): IEndpoint {
  return {
    id: `endpoint-id-${sequence()}`,
    url: values?.url ?? 'https://bushelpowered.com',
    token: values?.token ?? '12345',
    subscriptions: [],
    created_at: '2022-11-10T10:55:59-06:00',
    updated_at: '2022-11-10T10:55:59-06:00',
    deleted_at: null,
    max_workers: 10,
    worker_timeout: 120,
    request_memory: 1024,
    limit_memory: 2048,
    redact_pii: true,
  }
}
export function createSubscription(values?: Partial<IEndpointSubscription>): IEndpointSubscription {
  return {
    company_slug: values?.company_slug ?? 'testslug',
    environment: values?.environment ?? 'dev',
    region: 'us-alpha',
    features: values?.features ?? ['contracts'],
  }
}

export const translators: Translator[] = []
export function createTranslator(values?: Partial<Translator>): Translator {
  return {
    id: `translator-id-${sequence()}`,
    name: values?.name ?? 'Test',
    company_slug: values?.company_slug ?? 'testslug',
    created_at: '2022-11-11T15:18:16-06:00',
    updated_at: '2022-11-11T15:18:16-06:00',
  }
}

export const secrets: TranslatorSecret[] = []
export function createSecret(values?: any): TranslatorSecret {
  return {
    id: `secret-id-${sequence()}`,
    name: values?.name ?? 'Key',
    translator_id: values?.translatorId ?? '636ebc18c9066b3411f5b5c2',
    company_slug: values?.companySlug ?? 'testslug',
    value: values?.value ?? 'Value',
    created_at: '2022-11-11T15:44:18-06:00',
    updated_at: '2022-11-11T15:44:18-06:00',
  }
}

export const features = [
  'account-phones',
  'account-sso-users',
  'addenda',
  'users',
  'booking-line-items',
  'cash-bids',
  'commodity',
  'commodity-balance',
  'contracts',
  'contract-list',
  'delivery-tickets',
  'delivery-ticket-products',
  'delivery-ticket-splits',
  'elevators',
  'farm-fields',
  'invoices',
  'invoice-comments',
  'invoice-line-items',
  'invoice-payments',
  'outbound-tickets',
  'pre-paids',
  'price-amendments',
  'pricing-histories',
  'pricing-lines',
  'products',
  'remarks',
  'tickets',
  'scale-tickets-list',
  'settlement-payment-adjustments',
  'settlement-payouts',
  'settlements',
  'settlement-list',
  'splits',
  'sso-user-accounts',
  'ticket-applications',
  'work-orders',
  'work-order-applications',
  'work-order-products',
]
