import React, { useState, useEffect } from 'react'
import { Box, Button, Placeholders, Row, Stack } from 'src/ui'
import { Field, Form, Formik, FormikProps } from 'formik'
import { TextAreaField as FormikTextAreaField, TextField as FormikTextField } from 'src/ui/formik'
import {
  useCreateBuildMutation,
  useGetBuildBySlug,
  useUpdateBuildMutation,
} from 'src/api/queries/branding'
import { FormControl, FormLabel } from 'src/ui/form-controls'
import { isFaded } from 'src/companies/configurations/utils'
import * as Yup from 'yup'
import { useQueryClient } from '@tanstack/react-query'
import { useErrorToast, useToast } from 'src/utils/toast'

interface BuildConfigPageProps {
  header: JSX.Element
  company: Company
  flavor: Flavor
}

export function BuildConfigPage({ header, company, flavor }: BuildConfigPageProps) {
  return (
    <>
      {header}
      <BuildConfig company={company} flavor={flavor} />
    </>
  )
}

const buildConfigFormSchema = Yup.object().shape({
  iosDisplayName: Yup.string().required('Required').nullable(),
  versionName: Yup.string().nullable(),
  iosBundleId: Yup.string().required('Required').nullable(),
  androidBundleId: Yup.string().required('Required').nullable(),
  iosFastlaneUser: Yup.string().email('Invalid email').nullable(),
  iosFastlanePassword: Yup.string().nullable(),
  iosApiKey: Yup.string().nullable(),
  iosKeyId: Yup.string().nullable(),
  iosIssuerId: Yup.string().nullable(),
  iosCertTeamId: Yup.string().nullable(),
  iosProduceItcTeamName: Yup.string().nullable(),
})

const CREATE_BUILD_INITIAL_VALUES = {
  iosDisplayName: null,
  versionName: null,
  iosBundleId: null,
  androidBundleId: null,
  iosFastlaneUser: null,
  iosFastlanePassword: null,
  iosApiKey: null,
  iosKeyId: null,
  iosIssuerId: null,
  iosCertTeamId: null,
  iosProduceItcTeamName: null,
}

interface BuildConfigProps {
  company: Company
  flavor: Flavor
}

function BuildConfig({ company, flavor }: BuildConfigProps) {
  const [isEditing, setIsEditing] = useState(false)
  const { slug } = company
  const toast = useToast()
  const errorToast = useErrorToast()
  const queryClient = useQueryClient()
  const buildConfigQuery = useGetBuildBySlug({ type: '', slug })
  const buildConfig: Build = buildConfigQuery?.data?.data?.data

  const { mutateAsync: updateBuild, isLoading: isUpdateBuildLoading } = useUpdateBuildMutation()
  const { mutateAsync: createBuild, isLoading: isCreateBuildLoading } = useCreateBuildMutation()

  const errorStatusCode = (buildConfigQuery?.error as any)?.response?.status

  useEffect(() => {
    if (!isEditing && errorStatusCode === 404) {
      setIsEditing(true)
    }
  }, [isEditing, errorStatusCode])

  if (buildConfigQuery.isLoading) return <Placeholders.LoadingState />

  if (!buildConfig && (buildConfigQuery?.error as any)?.response?.status !== 404)
    return <Placeholders.FailedState />

  return (
    <Formik
      initialValues={buildConfig ?? CREATE_BUILD_INITIAL_VALUES}
      validationSchema={buildConfigFormSchema}
      validateOnChange={false}
      enableReinitialize
      onSubmit={async (values) => {
        if (values.versionName === '') {
          values.versionName = null
        }
        buildConfig
          ? await updateBuild(
              {
                id: buildConfig?.id,
                ...values,
              },
              {
                onSuccess: (updatedConfig) => {
                  toast({ title: 'Success', description: 'Updated build config' })
                  queryClient.setQueryData(['get-build-by-slug', { type: '', slug }], updatedConfig)
                  setIsEditing(false)
                },
                onError: (err) => errorToast(err, 'update', 'build config'),
              }
            )
          : await createBuild(
              {
                flavorId: flavor?.id,
                ...values,
              },
              {
                onSuccess: (newConfig) => {
                  toast({ title: 'Success', description: 'Created build config' })
                  queryClient.setQueryData(['get-build-by-slug', { type: '', slug }], newConfig)
                  setIsEditing(false)
                },
                onError: (err) => errorToast(err, 'create', 'build config'),
              }
            )
      }}
    >
      {(formikProps: FormikProps<any>) => (
        <Form>
          <Box bg="white" my={8} py={2} borderRadius={3} boxShadow="md">
            <BuildConfigPanel
              company={company}
              buildConfig={buildConfig}
              isEditing={isEditing}
              onClickEdit={() => setIsEditing(true)}
              onCancelEdit={() => {
                formikProps.resetForm()
                setIsEditing(false)
              }}
              onSave={() => formikProps.submitForm()}
              isLoading={buildConfig ? isUpdateBuildLoading : isCreateBuildLoading}
            />

            {isEditing ? <BuildConfigForm /> : <BuildConfigDetail buildConfig={buildConfig} />}
          </Box>
        </Form>
      )}
    </Formik>
  )
}

interface BuildConfigPanelProps {
  company: Company
  buildConfig: Build
  isEditing: boolean
  onClickEdit: () => void
  onCancelEdit: () => void
  onSave: () => void
  isLoading: boolean
}

function BuildConfigPanel({
  company,
  buildConfig,
  isEditing,
  onClickEdit,
  onCancelEdit,
  onSave,
  isLoading,
}: BuildConfigPanelProps) {
  return (
    <Row width="100%" height="62px" justifyContent="space-between" alignItems="center" px={6}>
      <Row alignItems="center">
        <Box fontSize="18px">{company.name}</Box>
      </Row>

      {isEditing ? (
        <Row>
          {buildConfig && (
            <Button
              width="76px"
              size="sm"
              colorScheme="secondary"
              mr={2}
              onClick={() => onCancelEdit()}
            >
              CANCEL
            </Button>
          )}

          <Button
            width="76px"
            size="sm"
            colorScheme="primary"
            onClick={() => onSave()}
            isLoading={isLoading}
          >
            {buildConfig ? 'SAVE' : 'CREATE'}
          </Button>
        </Row>
      ) : (
        <Row>
          <Button width="76px" size="sm" colorScheme="primary" onClick={() => onClickEdit()}>
            EDIT
          </Button>
        </Row>
      )}
    </Row>
  )
}

interface BuildConfigDetailProps {
  buildConfig: Build
}

function BuildConfigDetail({ buildConfig }: BuildConfigDetailProps) {
  return (
    <>
      {formData.map((group, index) => {
        return (
          <Stack
            isInline
            spacing={4}
            minH="80px"
            py={2}
            px={6}
            key={index}
            bg={index % 2 !== 0 ? 'gray.50' : undefined}
          >
            {group.map((field, index) => {
              if (!field) return <Box key={index} flex={1} minWidth={0} />
              return (
                <FormControl height={null} mb={null} key={index}>
                  <FormLabel as="div" label={field.label}>
                    {field.label}
                  </FormLabel>
                  <BuildConfigDetailValue value={buildConfig?.[field.name]} type={field.type} />
                </FormControl>
              )
            })}
          </Stack>
        )
      })}
    </>
  )
}

interface BuildConfigDetailValueProps {
  value?: string
  type?: 'input' | 'textarea'
}

function BuildConfigDetailValue({ value, type = 'input' }: BuildConfigDetailValueProps) {
  return (
    <Box
      color={isFaded(value) ? 'gray.400' : undefined}
      css={{
        minWidth: 0,
        overflow: type === 'textarea' ? 'initial' : 'auto',
        whiteSpace: type === 'textarea' ? 'pre-wrap' : 'nowrap',
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
          width: 0,
          height: 0,
        },
      }}
    >
      {value ?? '–'}
    </Box>
  )
}

function BuildConfigForm() {
  return (
    <>
      {formData.map((group, index) => {
        return (
          <Stack isInline spacing={4} minH="80px" py={2} px={6} key={index}>
            {group.map((field, index) => {
              if (!field) return <Box key={index} flex={1} />
              if (field.type === 'input') {
                return (
                  <Field
                    key={index}
                    name={field.name}
                    label={field.label}
                    component={FormikTextField}
                    formControlProps={{
                      height: 'inherit',
                      mb: null,
                    }}
                  />
                )
              }
              if (field.type === 'textarea') {
                return (
                  <Field
                    key={index}
                    name={field.name}
                    label={field.label}
                    component={FormikTextAreaField}
                    InputProps={{
                      rows: 6,
                    }}
                    formControlProps={{
                      height: 'inherit',
                    }}
                  />
                )
              } else console.error('Unknown type: ', field.type)
              return null
            })}
          </Stack>
        )
      })}
    </>
  )
}

interface FormField {
  name: string
  label: string
  type: 'input' | 'textarea'
}

const formData: FormField[][] = [
  [
    { name: 'iosDisplayName', label: 'iOS Display Name', type: 'input' },
    { name: 'versionName', label: 'Version Name', type: 'input' },
  ],
  [
    { name: 'iosBundleId', label: 'iOS Bundle ID', type: 'input' },
    { name: 'androidBundleId', label: 'Android Bundle ID', type: 'input' },
  ],
  [
    { name: 'iosFastlaneUser', label: 'iOS Fastlane User', type: 'input' },
    { name: 'iosFastlanePassword', label: 'iOS Fastlane Password', type: 'input' },
  ],
  [{ name: 'iosApiKey', label: 'iOS API Key', type: 'textarea' }],
  [
    { name: 'iosKeyId', label: 'iOS Key ID', type: 'input' },
    { name: 'iosIssuerId', label: 'iOS Issuer ID', type: 'input' },
  ],
  [
    { name: 'iosCertTeamId', label: 'iOS Cert Team ID', type: 'input' },
    { name: 'iosProduceItcTeamName', label: 'iOS Produce ITC Team Name', type: 'input' },
  ],
]
