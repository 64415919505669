import { IConnectChannel, IConnectSubscription, ICreateConnectSubscription } from 'src/api/api'

const baseUri = '/connect/subscription'

export interface GetConnectSubscriptionsRequest {
  channelId: IConnectChannel['id']
}

export interface DeleteConnectSubscriptionRequest {
  id: IConnectSubscription['id']
}

export const connectSubscriptionRequests = {
  ///////////////////////////////////////////////////////////////////////////////
  // GET
  ///////////////////////////////////////////////////////////////////////////////

  getConnectSubscriptions:
    (api) =>
    async ({ channelId }: GetConnectSubscriptionsRequest) => {
      try {
        let response = await api.get(`/connect/subscriptions?connect_channel_id=${channelId}`)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  ///////////////////////////////////////////////////////////////////////////////
  // CREATE
  ///////////////////////////////////////////////////////////////////////////////

  createConnectSubscription: (api) => async (params: Partial<ICreateConnectSubscription>) => {
    try {
      const response = await api.post(baseUri, params)
      return [null, response]
    } catch (err) {
      return [err, null]
    }
  },

  ///////////////////////////////////////////////////////////////////////////////
  // DELETE
  ///////////////////////////////////////////////////////////////////////////////

  deleteConnectSubscription:
    (api) =>
    async ({ id }: DeleteConnectSubscriptionRequest) => {
      try {
        await api.delete(`${baseUri}/${id}`)
        return [null, true]
      } catch (err) {
        return [err, null]
      }
    },

  getCompanyUsersByPhone:
    (api) =>
    async ({ phonenumber, connect_channel_id }) => {
      try {
        let response = await api.get(
          `/connect/company-users?phonenumber=${phonenumber}&connect_channel_id=${connect_channel_id}`
        )
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },
}
