import React, { ReactNode } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalProps,
} from '@chakra-ui/react'

interface DialogProps extends ModalProps {
  isOpen: any
  title: string | ReactNode
  actions?: any
  maxW?: '100%'
}

function Dialog({ isOpen, title, actions, maxW, size, onClose, children }: DialogProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size || 'md'}>
      <ModalOverlay />

      <ModalContent top="24px" maxW={maxW}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>{children}</ModalBody>

        {actions && <ModalFooter>{actions}</ModalFooter>}
      </ModalContent>
    </Modal>
  )
}

export default Dialog

export const FormDialog: React.FC<Omit<DialogProps, 'actions'>> & {
  Body: typeof ModalBody
  Footer: typeof ModalFooter
} = (props) => {
  let { isOpen, title, maxW, size, onClose, children } = props

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size || 'md'}>
      <ModalOverlay />

      <ModalContent top="24px" maxW={maxW}>
        <ModalHeader>{title}</ModalHeader>
        {children}
      </ModalContent>
    </Modal>
  )
}

FormDialog.Body = ModalBody
FormDialog.Footer = ModalFooter
