import { microservicesClient } from 'src/utils/api'
import { ICompany } from 'src/api/api'

const baseUri = '/tenant'

export interface GetDatasourceByIdRequest {
  datasource_id: ICompany['datasource_id']
}

export const getDatasourceById = async ({ datasource_id }: GetDatasourceByIdRequest) => {
  try {
    let response = await microservicesClient.post(`${baseUri}/v1/GetDatasourceById`, {
      id: datasource_id,
    })
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}
