import { handleApiError } from 'src/store/utils'
import { RootState } from 'src/store'
import { STATUS } from 'src/utils/api-status'
import { getCompanies, createCompany } from 'src/utils/api'
import { CONFIG } from 'src/utils/config'

export interface CompaniesState {
  status: STATUS
  data: ConfiguratorCompany[]
}

const ConfiguratorCompanies = {
  state: {
    status: STATUS.idle,
    data: [],
  },

  reducers: {
    setState(state: CompaniesState, payload) {
      return { ...state, ...payload }
    },

    reset() {
      return {
        status: STATUS.idle,
        data: [],
      }
    },
  },

  effects: (dispatch) => ({
    async loadAll(_payload = {}, _rootState = {} as RootState) {
      dispatch.configuratorCompanies.setState({ status: STATUS.loading })

      let [err, response] = await getCompanies()
      if (err) return handleApiError(dispatch, err)

      dispatch.configuratorCompanies.setState({
        status: STATUS.loaded,
        data: response.data.data.filter((company) => company.region === CONFIG.region),
      })
    },

    async createCompany({ company }, _rootState = {} as RootState) {
      let [err, response] = await createCompany({
        ...company,
        region: CONFIG.region || 'us-alpha',
      })

      if (err) {
        handleApiError(dispatch, err)
        return [err, response]
      }

      return [err, response]
    },
  }),
}

export default ConfiguratorCompanies
