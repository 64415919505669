import { rest } from 'msw'
import { createCashBidConfig, cashBidConfigs } from 'src/fixtures/cashbid-config'
import { companies } from 'src/fixtures/companies'
import { getURLSearchParam } from 'src/fixtures/utils'

export const handlers = (urls: string[]) => {
  return urls.reduce(
    (acc, url) => [
      ...acc,
      rest.get(`${url}/cashbid-configs`, (req, res, ctx) => {
        const slug = getURLSearchParam(req.url.search)
        const companyId = companies.find((company) => company.slug === slug).id
        const responseData = cashBidConfigs.filter((config) => config.company_id === companyId)
        return res(ctx.status(200), ctx.json({ data: responseData }))
      }),
      rest.post(`${url}/cashbid-config`, async (req, res, ctx) => {
        const requestBody = await req.json()
        const newConfig = createCashBidConfig(requestBody)
        cashBidConfigs.push(newConfig)
        return res(ctx.status(201), ctx.json({ data: newConfig }))
      }),
      rest.put(`${url}/cashbid-config`, async (req, res, ctx) => {
        const requestBody = await req.json()
        const index = cashBidConfigs.findIndex((config) => config.id === parseInt(requestBody.id))
        cashBidConfigs[index] = createCashBidConfig({ ...cashBidConfigs[index], ...requestBody })
        return res(ctx.status(200))
      }),
      rest.delete(`${url}/cashbid-config/:id`, (req, res, ctx) => {
        const id = parseInt(req.params.id as string)
        const index = cashBidConfigs.findIndex((config) => config.id === id)
        cashBidConfigs.splice(index, 1)
        return res(ctx.status(204))
      }),
    ],
    []
  )
}
