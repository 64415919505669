import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Row from 'src/ui/row'
import {
  useTable as useReactTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useBlockLayout,
} from 'react-table'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Box,
  ButtonGroup,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  IconButton,
  Spinner,
} from 'src/ui'

const Table: any = styled.table(
  ({ theme, styles, striped }: any) => css`
    width: 100%;
    border-spacing: 0;
    border-radius: 3px;
    background-color: #fff;
    font-size: ${theme.fontSizes.sm};
    /* box-shadow: ${theme.shadows.md}; */

    thead tr th:first-of-type {
      border-top-left-radius: 3px;
    }

    thead tr th:last-of-type {
      border-top-right-radius: 3px;
    }

    tbody tr:last-of-type td:first-of-type {
      border-bottom-left-radius: 3px;
    }

    tbody tr:last-of-type td:last-of-type {
      border-bottom-right-radius: 3px;
    }

    thead tr th {
      background-color: white;
      color: ${theme.colors.gray[500]};
      font-size: 0.9em;
      font-weight: 500;
      text-align: left;
    }

    th {
      height: 40px;
      padding: 0 1.5rem;
    }

    td {
      height: 50px;
      padding: 0 1.5rem;
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    ${striped && stripedStyles({ theme })}
    ${!striped && nonStripedStyles({ theme })}

    td.loading-indicator {
      width: 100%;
      height: 150px;
      text-align: center;
      background: #fff;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    ${styles}
  `
)

const stripedStyles = ({ theme: _theme }) => css`
  tbody tr:nth-of-type(even) {
    background-color: #f9fafb;
  }
`
const nonStripedStyles = ({ theme }) => css`
  td {
    border-bottom: 1px solid ${theme.colors.gray[100]};
  }
`

const blockStripedStyles = ({ theme: _theme }) => css`
  .tbody .tr:nth-of-type(even) {
    background-color: #f9fafb;
  }
`
const blockNonStripedStyles = ({ theme }) => css`
  .td {
    border-bottom: 1px solid ${theme.colors.gray[100]};
  }
`

const BlockTable: any = styled.div(
  ({ theme, styles, striped }: any) => css`
    width: 100%;
    border-spacing: 0;
    border-radius: 3px;
    background-color: #fff;
    font-size: ${theme.fontSizes.sm};
    /* box-shadow: ${theme.shadows.md}; */

    .thead .tr .th:first-of-type {
      border-top-left-radius: 3px;
    }

    .thead .tr .th:last-of-type {
      border-top-right-radius: 3px;
    }

    .tbody .tr:last-of-type .td:first-of-type {
      border-bottom-left-radius: 3px;
    }

    .tbody .tr:last-of-type .td:last-of-type {
      border-bottom-right-radius: 3px;
    }

    .thead .tr .th {
      background-color: white;
      color: ${theme.colors.gray[500]};
      font-size: 0.9em;
      font-weight: 500;
      text-align: left;
    }

    .th,
    .td {
      display: flex;
      align-items: center;
    }

    .th {
      height: 40px;
      padding: 0 1rem;
      white-space: nowrap;
      overflow: hidden;
    }

    .td {
      height: 40px;
      padding: 0 1rem;
      white-space: nowrap;
      overflow: hidden;
    }

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    ${striped && blockStripedStyles({ theme })}
    ${!striped && blockNonStripedStyles({ theme })}


    .td.loading-indicator {
      width: 100%;
      height: 150px;
      text-align: center;
      background: #fff;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    ${styles}
  `
)

///////////////////////////////////////////////////////////////////////////////

const Header = ({ headerGroups }) => (
  <thead>
    {headerGroups.map((headerGroup) => (
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column) => (
          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
            {column.render('Header')}

            <span css={{ position: 'absolute', marginLeft: 8, marginTop: -2 }}>
              {column.isSorted ? (
                column.isSortedDesc ? (
                  <ChevronDownIcon w="1rem" h="1rem" />
                ) : (
                  <ChevronUpIcon w="1rem" h="1rem" />
                )
              ) : (
                ''
              )}
            </span>
          </th>
        ))}
      </tr>
    ))}
  </thead>
)

const BlockHeader = ({ headerGroups }) => (
  <div className="thead">
    {headerGroups.map((headerGroup) => (
      <div {...headerGroup.getHeaderGroupProps()} className="tr">
        {headerGroup.headers.map((column) => {
          let { style: _style, ...rest } = column.getHeaderProps(column.getSortByToggleProps())

          return (
            <div {...rest} className="th" css={{ width: column.width }}>
              {column.render('Header')}

              <span css={{ marginLeft: 8, marginTop: -2 }}>
                {column.isSorted ? (
                  column.isSortedDesc ? (
                    <ChevronDownIcon w="1rem" h="1rem" />
                  ) : (
                    <ChevronUpIcon w="1rem" h="1rem" />
                  )
                ) : (
                  ''
                )}
              </span>
            </div>
          )
        })}
      </div>
    ))}
  </div>
)

///////////////////////////////////////////////////////////////////////////////

const Body = ({ isLoading, rows, getTableBodyProps, prepareRow, onRowClick }) => {
  if (isLoading) {
    return (
      <tbody>
        <tr>
          <td colSpan={100} className="loading-indicator">
            <Spinner speed="0.65s" size="xl" />
          </td>
        </tr>
      </tbody>
    )
  }

  return (
    <tbody {...getTableBodyProps()}>
      {rows.map((row) => {
        prepareRow(row)

        return (
          <tr
            {...row.getRowProps()}
            onClick={onRowClick ? () => onRowClick(row.original) : undefined}
            css={
              onRowClick
                ? {
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#F7FAFC',
                    },
                  }
                : undefined
            }
          >
            {row.cells.map((cell) => {
              return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
            })}
          </tr>
        )
      })}
    </tbody>
  )
}

const BlockBody = ({ rows, getTableBodyProps, prepareRow }) => {
  return (
    <div {...getTableBodyProps()} className="tbody">
      {rows.map((row) => {
        prepareRow(row)

        return (
          <div {...row.getRowProps()} className="tr">
            {row.cells.map((cell) => {
              let { style: _style, ...rest } = cell.getCellProps()

              return (
                <div {...rest} className="td" css={{ width: cell.column.width }}>
                  {cell.render('Cell')}
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

///////////////////////////////////////////////////////////////////////////////

const Pagination = ({
  // mine
  totalCount,

  // react-table
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  pageIndex,
  pageSize,
}) => {
  return (
    <Row justifyContent="flex-end" alignItems="center" py={2}>
      <Box px={2} color="gray.600" fontWeight={500}>
        {totalCount} Items
      </Box>

      <Box px={2} color="gray.600" fontWeight={500}>
        {`Page ${pageIndex + 1} of ${pageOptions.length}`}
      </Box>

      <ButtonGroup spacing={1} color="gray.500">
        <IconButton
          variant="outline"
          size="sm"
          icon={<ArrowLeftIcon />}
          aria-label="First"
          fontSize="0.8rem"
          isDisabled={!canPreviousPage}
          onClick={() => gotoPage(0)}
        />

        <IconButton
          variant="outline"
          size="sm"
          icon={<ChevronLeftIcon />}
          aria-label="Previous"
          fontSize="1.5rem"
          isDisabled={!canPreviousPage}
          onClick={() => previousPage()}
        />

        <IconButton
          variant="outline"
          size="sm"
          icon={<ChevronRightIcon />}
          aria-label="Next"
          fontSize="1.5rem"
          isDisabled={!canNextPage}
          onClick={() => nextPage()}
        />

        <IconButton
          variant="outline"
          size="sm"
          icon={<ArrowRightIcon />}
          aria-label="First"
          fontSize="0.8rem"
          isDisabled={!canNextPage}
          onClick={() => gotoPage(pageCount - 1)}
        />
      </ButtonGroup>

      {false && (
        <>
          <span>
            | Go to page:
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              style={{ width: '100px' }}
            />
          </span>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </>
      )}
    </Row>
  )
}

///////////////////////////////////////////////////////////////////////////////

export const useTable = ({ data, columns, tableOptions }) => {
  let {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  } = useReactTable(
    {
      columns,
      data,
      ...tableOptions,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  let { pageIndex, pageSize } = state

  return {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows, // all rows
    page, // current page of rows
    state,
    globalFilter: {
      globalFilter: state.globalFilter,
      preGlobalFilteredRows,
      setGlobalFilter,
    },
    pagination: {
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      pageIndex,
      pageSize,
    },
    // mine
    totalCount: rows.length,
  }
}

export const useBlockTable = ({ data, columns, tableOptions }) => {
  let {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useReactTable(
    {
      columns,
      data,
      ...tableOptions,
    },
    useSortBy,
    usePagination,
    useBlockLayout
  )

  return {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows, // all rows
    page, // current page of rows
    pagination: {
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      pageIndex,
      pageSize,
    },
    // mine
    totalCount: rows.length,
  }
}

///////////////////////////////////////////////////////////////////////////////

Table.Block = BlockTable
Table.BlockHeader = BlockHeader
Table.BlockBody = BlockBody

Table.Header = Header
Table.Body = Body
Table.Pagination = Pagination

export default Table
