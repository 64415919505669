import React from 'react'
import { connect } from 'react-redux'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { get } from 'lodash'
import { PanelState } from 'src/companies/configurations/config-panel'
import { useLocations, usePositions } from 'src/api/queries/locations'
import { Dispatch } from 'src/store'
import LocationCard from 'src/companies/locations/location-card'
import LoadFromCsv from 'src/companies/locations/load-from-csv'
import { LocationsHeader } from 'src/companies/locations/header'
import { Column, Placeholders, Stack } from 'src/ui'
import { useCopyDiff } from 'src/companies/copy-utils'
import { loadCopyDiff, executeCopy } from 'src/companies/locations/copy'
import { useToast } from 'src/utils/toast'
import * as api from 'src/utils/api'
import { centreAPIs } from 'src/utils/api'
import * as queries from 'src/utils/queries'

const NEW_LOCATION: BushelLocation = {
  id: null,
  remote_id: '',
  name: 'New Elevator',
  address_line_1: '',
  address_line_2: '',
  city: '',
  state: '',
  zip_code: '',
  country_code: '',
  phone: '',
  fax: '',
  email: '',
  website: '',
  hidden: 0,
  company_id: null,
  cash_bids_hidden_at: null,
  push_notifications_hidden_at: null,
  business_type: null,
}

const filterLocations = (locations: BushelLocation[], filter: string): BushelLocation[] => {
  if (!filter) return locations

  return locations.filter((location) => {
    return (
      location.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
      location.remote_id.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    )
  })
}

///////////////////////////////////////////////////////////////////////////////

const sortLocations = (locations: BushelLocation[]): BushelLocation[] =>
  [...locations].sort(({ name: na }, { name: nb }) =>
    na.toLowerCase() < nb.toLowerCase() ? -1 : 1
  )

const Locations = ({ company, errorNotification, successNotification, setCopyDialog }) => {
  let toast = useToast()
  const queryClient = useQueryClient()
  let [locationFilter, setLocationFilter] = React.useState('')
  let [showNewForm, setShowNewForm] = React.useState(false)
  let [showLoadCsvForm, setShowLoadCsvForm] = React.useState(false)
  let [allPanelsPropertiesState, setAllPanelsPropertiesState] = React.useState<PanelState>(null)

  let { startCopy } = useCopyDiff({
    company,
    loadCopyDiff,
    executeCopy,
    setCopyDialog,
    context: {},
  })

  let locationQuery = useLocations({ companySlug: company.slug })
  let positionsQuery = usePositions({ companySlug: company.slug })

  const saveLocation = async ({
    location,
  }: {
    location: BushelLocation
    onSuccess?: () => void
  }): api.ApiReturn<BushelLocation> => {
    if (location.id) {
      let [err, response] = await centreAPIs.updateCentreElevator({
        elevator: location,
      })

      if (err) throw err

      return [err, response]
    }

    // api is un-happy about supplying this parameter on create
    delete location.id

    let [err, response] = await centreAPIs.createCentreElevator({
      elevator: {
        ...location,
        company_id: company.id,
      },
    })

    if (err) throw err

    return [err, response]
  }

  const bulkSaveLocations = async ({ locations: elevators }) => {
    let [err, response] = await centreAPIs.bulkCreateCentreElevator({
      elevators,
    })

    if (err) {
      toast({ status: 'error', description: 'Failed to save locations' })
      return [err, response]
    }

    let newElevators = get(response, 'data.data', [])
    toast({ description: `Created new ${newElevators.length} locations` })

    setShowLoadCsvForm(false)
    locationQuery.refetch()
    return [err, response]
  }

  let { mutateAsync: saveLocationMutation } = useMutation({
    mutationFn: saveLocation,
    onSuccess: async (_res, { location, onSuccess }) => {
      await queryClient.invalidateQueries({ queryKey: ['company-locations'] })
      toast({ description: `Successfully ${location.id ? 'updated' : 'created'} location` })

      if (onSuccess) onSuccess()
    },
    onError: (_res, { location }) => {
      toast({
        status: 'error',
        description: `Failed to ${location.id ? 'update' : 'create'} location`,
      })
    },
  })
  let locations: BushelLocation[] = filterLocations(
    get(locationQuery, 'data', []) || [],
    locationFilter
  )
  let positions: Position[] = get(positionsQuery, 'data', []) || []

  const toggleAllPanelState = (panelState: PanelState) => {
    setAllPanelsPropertiesState(panelState)
    setTimeout(() => setAllPanelsPropertiesState(null))
  }

  return (
    <Column mb={4}>
      {queries.areAnyLoading(locationQuery, positionsQuery) && <Placeholders.LoadingState />}
      {queries.areAnyFailed(locationQuery, positionsQuery) && <Placeholders.FailedState />}
      {queries.areAllLoaded(locationQuery, positionsQuery) && (
        <>
          <LocationsHeader
            showLoadCsvForm={showLoadCsvForm}
            setShowLoadCsvForm={setShowLoadCsvForm}
            setShowNewForm={setShowNewForm}
            setLocationFilter={setLocationFilter}
            handleExpandAll={() => toggleAllPanelState('expanded')}
            handleCollapseAll={() => toggleAllPanelState('collapsed')}
          />

          {showLoadCsvForm && (
            <LoadFromCsv
              bulkSaveLocations={bulkSaveLocations}
              company={company}
              errorNotification={errorNotification}
              successNotification={successNotification}
            />
          )}

          {!showNewForm && locationQuery.data && locations.length === 0 && (
            <Placeholders.EmptyState
              message={locationFilter ? 'No locations match filter' : 'No locations found'}
            />
          )}

          <Stack spacing={4}>
            {showNewForm && (
              <LocationCard
                key="new-location"
                location={NEW_LOCATION}
                positions={[]}
                setShowNewForm={setShowNewForm}
                saveLocation={saveLocationMutation}
                startCopy={() => null} // not used for new
                allPanelsPropertiesState={null}
                company={company}
              />
            )}

            {locations.length > 0 &&
              sortLocations(locations).map((location) => (
                <LocationCard
                  key={location.id}
                  location={location}
                  positions={positions.filter((p) => p.remote_elevator_id === location.remote_id)}
                  saveLocation={saveLocationMutation}
                  startCopy={startCopy}
                  allPanelsPropertiesState={allPanelsPropertiesState}
                  company={company}
                />
              ))}
          </Stack>
        </>
      )}
    </Column>
  )
}

const mapDispatch = ({ notifications }: Dispatch) => ({
  errorNotification: notifications.createError,
  successNotification: notifications.createSuccess,
})

export default connect(
  null, // mapState
  mapDispatch
)(Locations)
