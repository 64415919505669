import React, { useState } from 'react'
import { STATUS } from 'src/utils/api'
import { Box, Row, Column, Button, Checkbox, Dialog, Stack } from 'src/ui'

interface ResetSchedulesProps extends DialogProps {
  environments: Environment[]
  company: Company
  environment_slug: string
  features: string[]
  status: STATUS
  translator: Translator
  translatorConfig: TranslatorConfig
  onConfirm?: (args?: any) => Promise<any>
}

export function ResetSchedules({
  environment_slug,
  isOpen,
  onClose,
  onConfirm,
  status,
  translator,
  translatorConfig,
}: ResetSchedulesProps) {
  const [selectAll, setSelectAll] = useState(false)
  const [checked, setChecked] = useState<string[]>([])
  const [error, setError] = useState(null)

  const reset = () => {
    setSelectAll(false)
    setChecked([])
    setError(null)
  }

  const handleClose = () => {
    onClose()
    reset()
  }

  const handleSubmit = () => {
    if (checked.length === 0) {
      setError('Select at least one schedule')
      return
    }

    onConfirm({ translator, environment_slug, schedules: checked, resetDialogState: reset })
  }

  let config = translatorConfig ? translatorConfig.translator_config : null

  if (!config) return null

  let scheduleNames = Object.keys(config.schedules)

  return (
    <Dialog
      title="Reset Translator Schedules"
      isOpen={isOpen}
      onClose={handleClose}
      size="md"
      actions={
        <>
          <Button size="sm" variant="ghost" mr={2} onClick={handleClose}>
            Cancel
          </Button>

          <Box width={100}>
            <Button
              size="sm"
              colorScheme="primary"
              isLoading={status === STATUS.loading}
              onClick={handleSubmit}
            >
              Reset
            </Button>
          </Box>
        </>
      }
    >
      <Column>
        <Box pb={3} mb={1}>
          <Box>
            Select one or more <strong>{translator && translator.name}</strong> schedules to reset.
          </Box>
        </Box>

        {error && (
          <Box fontSize="sm" color="red.500" mb={2}>
            {error}
          </Box>
        )}

        <Row
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid #ccc"
          mb={1}
        >
          <Row px={2} alignItems="center">
            <Checkbox
              mb={2}
              isChecked={selectAll}
              isIndeterminate={checked.length > 0 && checked.length !== scheduleNames.length}
              onChange={(e) => {
                if (!config) return

                if (e.target.checked) {
                  setError(null)
                  setSelectAll(true)
                  setChecked(scheduleNames.map((name) => name))
                } else {
                  setError(null)
                  setSelectAll(false)
                  setChecked([])
                }
              }}
            >
              Select All
            </Checkbox>
          </Row>

          <Box px={2}>
            <Box fontSize="sm">
              {checked.length > 0 ? `Selected ${checked.length} / ` : null} {scheduleNames.length}{' '}
              schedules
            </Box>
          </Box>
        </Row>

        <Box maxHeight={300} overflow="scroll" px={2} borderBottom="1px solid #ccc">
          <Stack spacing={2}>
            {scheduleNames.map((name) => (
              <Checkbox
                key={name}
                isChecked={checked.includes(name)}
                value={name}
                onChange={(e) => {
                  let value = e.target.value
                  let isCheckboxChecked = e.target.checked

                  setError(null)
                  setSelectAll(!isCheckboxChecked || selectAll)
                  setChecked((previousChecked) =>
                    isCheckboxChecked
                      ? [...previousChecked, value]
                      : previousChecked.filter((c) => c !== value)
                  )
                }}
              >
                {name}
              </Checkbox>
            ))}
          </Stack>
        </Box>
      </Column>
    </Dialog>
  )
}
