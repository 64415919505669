import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button } from '@chakra-ui/react'
import { RootState } from 'src/store'
import { CreateCompanyDialog } from 'src/companies/dialogs'
import { Column, Row } from 'src/ui'

function CompanyCreateHelper({
  companies,
  createConfiguratorCompany,
  loadConfiguratorCompanies,
  loadTranslators,
  slug,
}) {
  const [isOpen, setIsOpen] = useState(false)

  let centreCompany = companies.find((c) => c.slug === slug)

  const handleSubmit = async (values) => {
    let [err] = await createConfiguratorCompany({ company: values })

    if (err) {
      console.error('failed to create company', err)
      return
    }

    loadConfiguratorCompanies()
    loadTranslators()
  }

  return (
    <>
      <Column mt={3} color="#999">
        <Row justifyContent="center">
          <Button size="sm" colorScheme="primary" onClick={() => setIsOpen(true)}>
            Add Company
          </Button>
        </Row>
      </Column>

      <CreateCompanyDialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirm={handleSubmit}
        company={centreCompany}
      />
    </>
  )
}

const mapState = ({ companies }: RootState) => ({
  companies: companies.data,
})
export default connect(mapState)(CompanyCreateHelper)
