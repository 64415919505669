import { rest } from 'msw'
import { elevators as elevatorList } from 'src/fixtures/bushel-location'
import { createPosition, positions } from 'src/fixtures/positions'
import { omit } from 'lodash'
import { IPosition } from 'src/api/api'

export const handlers = (urls: string[]) => {
  return urls.reduce(
    (acc, url) => [
      ...acc,
      rest.get(`${url}/positions`, async (req, res, ctx) => {
        return res(ctx.status(200), ctx.json({ data: positions }))
      }),
      rest.post(`${url}/position`, async (req, res, ctx) => {
        const requestBody = await req.json()
        const { elevators } = requestBody
        const newPositions: IPosition[] = []
        if (requestBody.remote_elevator_id) {
          // if created from locations tab
          const addToArray = createPosition(omit(requestBody, 'elevators'))
          newPositions.push(addToArray)
          positions.push(addToArray)
        } else {
          elevators.forEach((id) => {
            const index = elevatorList.findIndex((elevator) => elevator.id === id)
            const remote_elevator_id = elevatorList[index].remote_id
            const positionData = { ...omit(requestBody, 'elevators'), remote_elevator_id }
            const addToArray = createPosition(positionData)
            newPositions.push(addToArray)
            positions.push(addToArray)
          })
        }
        const newPositionIds: number[] = newPositions.map((position) => position.id)
        const statusText = `Successfully added positions: [${newPositionIds}] to the system.`
        return res(ctx.status(201), ctx.json({ data: newPositions, status: statusText }))
      }),
      rest.delete(`${url}/position`, async (req, res, ctx) => {
        const { ids } = await req.json()
        const newPositions = positions.filter((position) => !ids?.includes(position.id))
        positions.splice(0, positions.length, ...newPositions)
        return res(ctx.status(204))
      }),
      rest.delete(`${url}/position/:id`, async (req, res, ctx) => {
        const id = parseInt(req.params.id as string)
        const index = positions.findIndex((position) => position.id === id)
        positions.splice(index, 1)
        return res(ctx.status(204))
      }),
      rest.put(`${url}/positions`, async (req, res, ctx) => {
        const requestBody = await req.json()
        requestBody.ids.forEach((id) => {
          const position = positions.find((position) => position.id === id)
          Object.assign(position, omit(requestBody, 'ids'))
        })
        const statusText = `Successfully updated positions: [${requestBody.ids}].`
        return res(ctx.status(200), ctx.json({ data: positions, status: statusText }))
      }),
      rest.put(`${url}/position/:id`, async (req, res, ctx) => {
        const requestBody = await req.json()
        const id = parseInt(req.params.id as string)
        const position = positions.find((position) => position.id === id)
        Object.assign(position, omit(requestBody, 'remote_elevator_id'))
        const statusText = `Successfully updated position: ${id}.`
        return res(ctx.status(200), ctx.json({ data: positions, status: statusText }))
      }),
    ],
    []
  )
}
