import React, { FC } from 'react'
import { FieldArray, Field, FormikProps } from 'formik'
import { Button } from '@chakra-ui/react'
import { Box, Column, Row, FormRow, IconButton, Tooltip, DeleteIcon } from 'src/ui'
import { TextField as FormikTextField } from 'src/ui/formik'

interface ConsulProps {
  formikBag: FormikProps<TranslatorConfigSchedule>
}

const Consul: FC<ConsulProps> = ({ formikBag }) => (
  <FieldArray
    name="consul_services"
    render={(arrayHelpers) => (
      <Column width="100%" borderRadius={3} mb={5}>
        <Row justifyContent="space-between" alignItems="center" fontWeight="bold" mb={3}>
          <Row alignItems="center">
            <Box ml={2}>Consul Services</Box>
          </Row>

          <Box>
            <Button
              size="sm"
              colorScheme="primary"
              onClick={() => arrayHelpers.push({ key: '', value: '' })}
            >
              Add Service Alias
            </Button>
          </Box>
        </Row>

        {formikBag.values.consul_services.map((_, i) => (
          <FormRow key={i}>
            <Field name={`consul_services[${i}].key`} label="Alias" component={FormikTextField} />
            <Field
              name={`consul_services[${i}].value`}
              label="Service"
              component={FormikTextField}
            />
            <Box>
              <Tooltip
                label={'Remove Alias ' + formikBag.values.consul_services[i].key}
                aria-label="Remove Alias"
                placement="left"
              >
                <IconButton
                  icon={<DeleteIcon />}
                  aria-label="Delete"
                  isRound
                  variant="ghost"
                  color="gray.600"
                  onClick={(e) => {
                    e.stopPropagation()
                    arrayHelpers.remove(i)
                  }}
                />
              </Tooltip>
            </Box>
          </FormRow>
        ))}
      </Column>
    )}
  />
)

export default Consul
