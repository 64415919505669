import { useEffect } from 'react'
import { RootState, Dispatch } from 'src/store'
import { CentreEnvironment, VALID_CENTRE_ENVIRONMENTS } from 'src/api/centre'
import { IS_PROD, IS_SANDBOX } from 'src/utils/config'

export const DEFAULT_ENVIRONMENT = IS_PROD ? 'production' : IS_SANDBOX ? 'sandbox' : 'r1'
export const validEnvironment = (env: CentreEnvironment) => VALID_CENTRE_ENVIRONMENTS.includes(env)
export const findCompany = (slug: string, companies: Company[]) =>
  companies.find((c) => c.slug === slug)

interface EnvSyncProps {
  env: CentreEnvironment
  settings: RootState['settings']
  setSettingsState: Dispatch['settings']['setState']
  resetCompanies: Dispatch['companies']['reset']
}

export function useEnvSync({ env, settings, setSettingsState, resetCompanies }: EnvSyncProps) {
  const { centreAPIEndpoint } = settings

  useEffect(() => {
    if (env !== centreAPIEndpoint && validEnvironment(env)) {
      // environment changed via url, sync to settings
      setSettingsState({ centreAPIEndpoint: env })
      resetCompanies()
    }
  }, [env, centreAPIEndpoint, resetCompanies, setSettingsState])
}

interface SlugSyncProps {
  slug: string
  company: Company
  settings: RootState['settings']
  setSettingsState: Dispatch['settings']['setState']
}

export function useSlugSync({ slug, company, settings, setSettingsState }: SlugSyncProps) {
  const { companySlug } = settings

  useEffect(() => {
    if (slug !== companySlug && company) {
      // slug changed via url, sync to settings
      setSettingsState({ companySlug: company.slug })
    }

    if (companySlug && !company) {
      // invalid slug, reset settings to default
      setSettingsState({ companySlug: null })
    }
  }, [slug, companySlug, company, setSettingsState])
}
