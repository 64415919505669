import { UseToastOptions } from '@chakra-ui/react'
import { centreAPIs } from 'src/utils/api'
import { getEnvironment } from 'src/utils'

const COPY_EXCLUDED_KEYS = ['_revision', 'id', 'slug', 'translator_id', 'configView']

/**
 * Loads the `source` and `dest` values to display in the diff dialog.
 */
export const loadCopyDiff = async ({
  company,
  toast: _toast,
}: {
  company: Company
  toast: (args: UseToastOptions) => void
}) => {
  let [_destErr, destCompany] = await centreAPIs.otherEnvironment.getCentreCompanyBySlug({
    slug: company.slug,
  })

  return {
    source: Object.keys(company)
      .filter((key) => !COPY_EXCLUDED_KEYS.includes(key))
      .reduce((acc, key) => ({ ...acc, [key]: company[key] }), {}),

    dest: destCompany
      ? Object.keys(destCompany)
          .filter((key) => !COPY_EXCLUDED_KEYS.includes(key))
          .reduce((acc, key) => ({ ...acc, [key]: destCompany[key] }), {})
      : null,
  }
}

/**
 * Runs the actual copy routine with error handlers and notifications.
 */
export const executeCopy = async ({
  company,
  toast,
}: {
  company: Company
  toast: (args: UseToastOptions) => void
}) => {
  let destAPI = getEnvironment() === 'production' ? 'uat' : 'production'

  // make calls to the api to get fresh revisions
  let [sourceErr, sourceCompany] = await centreAPIs.getCentreCompanyBySlug({
    slug: company.slug,
  })

  if (sourceErr) {
    toast({ status: 'error', title: 'Error', description: 'Failed to load source data' })
    return
  }

  let copySource = Object.keys(sourceCompany)
    .filter((key) => !COPY_EXCLUDED_KEYS.includes(key))
    .reduce((acc, key) => ({ ...acc, [key]: sourceCompany[key] }), {})

  let [destErr, destCompany] = await centreAPIs.otherEnvironment.getCentreCompanyBySlug({
    slug: company.slug,
  })

  if (destErr) {
    // company does not exist, create it
    let [createErr, createdCompany] = await centreAPIs.otherEnvironment.createCentreCompany({
      company: {
        ...copySource,
        slug: company.slug,
        translator_id: null,
      },
    })

    if (createErr) {
      toast({ status: 'error', title: 'Error', description: 'Failed to create company' })
      return [createErr, null]
    }

    toast({ description: `Successfully created company in ${destAPI.toUpperCase()}` })

    return [null, createdCompany]
  }

  // company exists, update its properties
  let [updateErr, updatedCompany] = await centreAPIs.otherEnvironment.updateCentreCompany({
    company: {
      ...destCompany,
      ...copySource,
    },
  })

  if (updateErr) {
    toast({ status: 'error', title: 'Error', description: 'Failed to copy company' })
    return [updateErr, null]
  }

  toast({ description: `Successfully copied company to ${destAPI.toUpperCase()}` })

  return [null, updatedCompany]
}
