import React from 'react'
import { IConnectSubscription, ISubscribable } from 'src/api/api'
import { connect } from 'react-redux'
import { RootState } from 'src/store'
import { Box, Table2, useTable, Checkbox } from 'src/ui'

interface CompanyListTableProps {
  companies: RootState['companies']
  selectedItems: ISubscribable[]
  setSelectedItems: any
  onRowClick: any
  connectSubscriptions: IConnectSubscription[]
}

const CompanyListTable: React.FC<CompanyListTableProps> = ({
  companies,
  selectedItems,
  setSelectedItems,
  onRowClick,
  connectSubscriptions,
}) => {
  let isLoading = false
  let [tableData, setTableData] = React.useState(companies.data)

  let columns = React.useMemo(
    () => [
      {
        id: '_actions',
        Header: 'Selected',
        accessor: '_actions',
        disableSortBy: true,
        Cell: ({ row }) => {
          let item = row.original

          return (
            <Checkbox
              defaultChecked={
                selectedItems.find((selectedItem) => selectedItem.id === item.id) != null
              }
            />
          )
        },
      },
      {
        id: 'id',
        accessor: 'id',
        Header: 'ID',
        disableSortBy: true,
      },
      {
        id: 'name',
        accessor: 'name',
        Header: 'Company',
        disableSortBy: true,
      },
    ],
    [selectedItems]
  )

  let { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    data: tableData,
    tableOptions: {
      initialState: {
        sortBy: [{ id: 'name' }],
      },
    },
    columns,
  })

  React.useEffect(() => {
    // Filter out the companies that already have a subscription
    setTableData(
      companies.data.filter((comp) => {
        return connectSubscriptions.find((sub) => sub.subscribable.id === comp.id) == null
      })
    )
  }, [connectSubscriptions, companies])

  return (
    <>
      <Box fontWeight="bold" mt={5}>
        Results
      </Box>
      <Box bg="white" pt={3}>
        <Table2 {...getTableProps()} style={{ boxShadow: 'none' }} striped>
          <Table2.Header headerGroups={headerGroups} />
          <Table2.Body
            isLoading={isLoading}
            rows={rows}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            onRowClick={(item) => onRowClick(item, selectedItems, setSelectedItems)}
          />
        </Table2>
      </Box>
    </>
  )
}

const mapState = ({ companies }: RootState) => ({ companies })
export default connect(mapState)(CompanyListTable)
