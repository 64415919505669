import React from 'react'
import { Dispatch } from 'src/store'
import { FlexRoutes, AuthRoute } from 'src/routes'
import { NewForm, EditForm } from 'src/endpoints/form'
import EndpointsList from 'src/endpoints/list'
import { useEndpoints, useFeatures, useEndpointMutations } from 'src/api/queries/endpoints'
import * as queries from 'src/utils/queries'
import { Placeholders } from 'src/ui'
import { Route } from 'react-router-dom'

type EndpointsProps = {
  environments: Environment[]
  companies: ConfiguratorCompany[]
  setSettingsState: Dispatch['settings']['setState']
}

const Endpoints: React.FC<EndpointsProps> = ({ environments, companies, setSettingsState }) => {
  React.useEffect(() => {
    setSettingsState({ showCentreEnvironment: false })
  }, [setSettingsState])

  const endpointsQuery = useEndpoints()
  const featuresQuery = useFeatures()
  let { saveEndpoint, deleteEndpoint } = useEndpointMutations()

  if (queries.areAnyLoading(endpointsQuery, featuresQuery)) {
    return <Placeholders.LoadingState />
  }

  if (queries.areAnyFailed(endpointsQuery, featuresQuery)) {
    return <Placeholders.FailedState />
  }

  return (
    <FlexRoutes>
      <Route
        path="/new"
        element={
          <AuthRoute
            as={NewForm}
            environments={environments}
            companies={companies}
            endpoints={endpointsQuery.data.data}
            features={featuresQuery.data.data}
            saveEndpoint={saveEndpoint}
          />
        }
      />
      <Route
        path="/:id/edit"
        element={
          <AuthRoute
            as={EditForm}
            environments={environments}
            companies={companies}
            endpoints={endpointsQuery.data.data}
            features={featuresQuery.data.data}
            saveEndpoint={saveEndpoint}
          />
        }
      />
      <Route
        path="*"
        element={
          <AuthRoute
            as={EndpointsList}
            endpoints={endpointsQuery.data.data}
            features={featuresQuery.data.data}
            deleteEndpoint={deleteEndpoint}
          />
        }
      />
    </FlexRoutes>
  )
}

export default Endpoints
