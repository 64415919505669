import { rest } from 'msw'
import { getURLSearchParam } from 'src/fixtures/utils'
import {
  customers,
  createCustomer,
  channels,
  createChannel,
  subscriptions,
  createSubscriptions,
  companyUsers,
} from '../fixtures/connect'

export const handlers = (urls: string[]) => {
  return urls.reduce(
    (acc, url) => [
      ...acc,
      //customers
      rest.get(`${url}/connect/customers`, (_req, res, ctx) =>
        res(ctx.status(200), ctx.json({ data: customers }))
      ),
      rest.post(`${url}/connect/customer`, async (req, res, ctx) => {
        const requestBody = await req.json()
        const newCustomer = createCustomer(requestBody)
        customers.push(newCustomer)
        return res(ctx.status(201), ctx.json({ data: newCustomer }))
      }),
      rest.put(`${url}/connect/customer/:id`, async (req, res, ctx) => {
        const requestBody = await req.json()
        const index = customers.findIndex((customer) => customer.id === requestBody.id)
        customers[index] = requestBody
        return res(ctx.status(200), ctx.json({ data: requestBody }))
      }),
      rest.delete(`${url}/connect/customer/:id`, (req, res, ctx) => {
        const id = parseInt(req.params.id as string)
        const index = customers.findIndex((customer) => customer.id === id)
        customers.splice(index, 1)
        return res(ctx.status(204))
      }),
      //channels
      rest.get(`${url}/connect/channels`, (req, res, ctx) => {
        const customerId = parseInt(getURLSearchParam(req.url.search))
        const responseData = channels.filter(
          (channel) => channel.connect_customer_id === customerId
        )
        return res(ctx.status(200), ctx.json({ data: responseData }))
      }),

      rest.post(`${url}/connect/channel`, async (req, res, ctx) => {
        const requestBody = await req.json()
        const newChannel = createChannel(requestBody)
        channels.push(newChannel)
        return res(ctx.status(201), ctx.json({ data: newChannel }))
      }),
      rest.put(`${url}/connect/channel/:id`, async (req, res, ctx) => {
        const requestBody = await req.json()
        const id = parseInt(req.params.id as string)
        const editedChannel = createChannel(requestBody)
        const index = channels.findIndex((channel) => channel.id === id)
        channels[index] = editedChannel
        return res(ctx.status(201), ctx.json({ data: editedChannel }))
      }),
      rest.delete(`${url}/connect/channel/:id`, (req, res, ctx) => {
        const id = parseInt(req.params.id as string)
        const index = channels.findIndex((channel) => channel.id === id)
        channels.splice(index, 1)
        return res(ctx.status(204))
      }),
      //subscriptions
      rest.get(`${url}/connect/subscriptions`, (req, res, ctx) => {
        const channelId = parseInt(getURLSearchParam(req.url.search))
        const responseData = subscriptions.filter(
          (subscription) => subscription.connect_channel_id === channelId
        )
        return res(ctx.status(200), ctx.json({ data: responseData }))
      }),
      rest.post(`${url}/connect/subscription`, async (req, res, ctx) => {
        const requestBody = await req.json()
        const responseData = createSubscriptions(requestBody)
        return res(ctx.status(201), ctx.json({ data: responseData }))
      }),
      rest.delete(`${url}/connect/subscription/:id`, (req, res, ctx) => {
        const id = parseInt(req.params.id as string)
        const index = subscriptions.findIndex((subscription) => subscription.id === id)
        subscriptions.splice(index, 1)
        return res(ctx.status(204))
      }),
      rest.get(`${url}/connect/company-users`, (_req, res, ctx) =>
        res(ctx.status(200), ctx.json({ data: companyUsers }))
      ),
    ],
    []
  )
}
