import { useQuery, useMutation } from '@tanstack/react-query'
import { centreAPIs } from 'src/utils/api'

export const useLocations = ({ companySlug }) => {
  let { data, status, error, refetch, isFetching } = useQuery({
    queryKey: ['company-locations', { companySlug }],
    queryFn: async () => {
      let [err, response] = await centreAPIs.getCentreElevatorsByCompanySlug({
        companySlug,
      })

      if (err) throw err
      return response.data.data
    },
    cacheTime: 0,
  })

  return {
    data,
    status,
    error,
    refetch,
    isFetching,
  }
}

export const usePositions = ({ companySlug }) => {
  let { data, status, error, refetch, isFetching } = useQuery({
    queryKey: ['company-positions', { companySlug }],
    queryFn: async () => {
      let [err, response] = await centreAPIs.getPositions({ companySlug })

      if (err) throw err
      return response.data.data
    },
    cacheTime: 0,
  })

  return {
    data,
    status,
    error,
    refetch,
    isFetching,
  }
}

export const usePositionMutations = () => {
  let { mutateAsync: savePosition } = useMutation({
    mutationFn: async ({
      position,
      companyId,
      location,
    }: {
      position: Position
      location: BushelLocation
      companyId: number
    }) => {
      if (position.id) {
        let [err, response] = await centreAPIs.updatePosition({
          ...position,
        })

        if (err) throw err
        return [err, response]
      }

      let [err, response] = await centreAPIs.createPosition({
        ...position,
        company_id: companyId,
        remote_elevator_id: location.remote_id,
      })

      if (err) throw err
      return [err, response]
    },
  })

  let { mutateAsync: deletePosition } = useMutation({
    mutationFn: async ({ position }: { position: Position }) => {
      let [err, response] = await centreAPIs.deletePosition({
        id: position.id,
      })

      if (err) throw err

      return [err, response]
    },
  })

  return { savePosition, deletePosition }
}
