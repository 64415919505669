import React from 'react'
import { Box, Spinner, BoxProps } from '@chakra-ui/react'
import { Column } from 'src/ui'

interface PlaceholderProps extends BoxProps {
  message?: string | React.ReactNode
}

export const FailedState = ({ message = 'There was a problem loading data.', ...props }) => (
  <Centered {...props}>
    <Box fontSize="64px" width={160}>
      <img src="/images/empty-flat.svg" alt="empty-flat" />
    </Box>

    <Box color="#999" fontSize={16} py={4}>
      {message}
    </Box>
  </Centered>
)

const underConstructionFragment = (
  <>
    <Box as="span" fontSize="xl" fontWeight={500}>
      Under Construction
    </Box>
    <br />
    <Box as="span" fontSize="sm">
      Direct access requests to #web-mobile-product
    </Box>
  </>
)

export const UnderConstruction = ({
  message = underConstructionFragment,
  ...props
}: PlaceholderProps) => (
  <Centered {...props}>
    <Box fontSize="64px" width={160}>
      <img src="/images/empty-flat.svg" alt="empty-flat" />
    </Box>

    <Box color="#999" fontSize={16} py={4} textAlign="center">
      {message}
    </Box>
  </Centered>
)

export const LoadingState = (props) => (
  <Centered {...props}>
    <Spinner size="xl" speed="0.8s" thickness="3px" color="inherit" />
  </Centered>
)

export const EmptyState: React.FC<PlaceholderProps> = ({
  message = 'No results found',
  ...props
}) => (
  <Centered {...props}>
    <Box>
      <img src="/images/empty-wheat.svg" alt="empty-wheat" />
    </Box>

    <Box color="#999" fontSize={17} py={4}>
      {message}
    </Box>
  </Centered>
)

export const Centered = ({ children, ...props }) => (
  <Column alignItems="center" mt="25%" color="#aaa" {...props}>
    {children}
  </Column>
)
