export const sequence = (() => {
  let nextId = 0

  return () => {
    nextId++
    return nextId
  }
})()

export const oneOf = <T>(options: T[]) => options[Math.floor(Math.random() * options.length)]

export const optional = <T>(option: T) => oneOf([option, null])

export const arrayOf = <T>(build: () => T, count: number | [number, number] = [0, 6]) => {
  if (Array.isArray(count)) {
    const [min, max] = count
    count = Math.floor(Math.random() * (max - min)) + min
  }
  return new Array(count).fill(undefined).map(build)
}

export function getURLSearchParam(search: string): string {
  return search.substring(search.indexOf('=') + 1)
}
