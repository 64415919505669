import * as Yup from 'yup'

export const sortApiTypes = (types: typeof CASH_BID_TYPES) =>
  [...types].sort((a, b) => (a.label < b.label ? -1 : 1))

interface configTypeDisplayNameArgs {
  retrieved_from: 'local' | 'api'
  api_type: string
}

export const configTypeDisplayName = ({ retrieved_from, api_type }: configTypeDisplayNameArgs) => {
  if (retrieved_from === 'local') return 'Local'

  let found = CASH_BID_TYPES.find((type) => type.api_type === api_type)
  return found ? found.label : 'Select Config'
}

interface configTypeHasFieldArgs {
  retrieved_from: 'local' | 'api'
  api_type: string
  field: string
}

export const configTypeHasField = ({ retrieved_from, api_type, field }: configTypeHasFieldArgs) => {
  let findType = retrieved_from === 'local' ? 'local' : api_type
  let found = CASH_BID_TYPES.find((type) => type.api_type === findType)

  if (!found || !found.fields) return true

  return found.fields.includes(field)
}

export const ElevatorMappingSchema = Yup.object().shape({
  provider_location_id: Yup.string().required('Required'),
  locations: Yup.array().of(Yup.string().required('Required')),
})

export const CashBidConfigSchema = Yup.object().shape({
  api_type: Yup.string(),
  api_url: Yup.string()
    .matches(/^https:\/\/(.*)/, 'Must be a https url (e.g. "https://api.example.com")')
    .when(['retrieved_from', 'api_type'], ([retrieved_from, api_type], schema) =>
      configTypeHasField({ retrieved_from, api_type, field: 'api_url' })
        ? schema.required('Required')
        : schema.nullable()
    ),
  local_crop_ids: Yup.string()
    .matches(/^([\d])+(,[\d]+)*$/, 'Must be a CSV-list of IDs (e.g. "1,2,3")')
    .when('retrieved_from', ([value], schema) =>
      value === 'local' ? schema.required('Required') : schema.nullable()
    ),
  username: Yup.string().nullable(),
  password: Yup.string().nullable(),
  options: Yup.object().nullable(),
  elevator_mapping: Yup.array().of(ElevatorMappingSchema).nullable(),
})

export const CASH_BID_TYPES = [
  {
    api_type: 'App\\Services\\CashBidsService\\Agricharts',
    label: 'Agricharts',
    fields: ['api_url', 'elevator_mapping', 'options'],
  },
  {
    api_type: 'App\\Services\\CashBidsService\\DTN',
    label: 'DTN',
    fields: ['api_url', 'username', 'password', 'elevator_mapping', 'options'],
  },
  {
    api_type: 'App\\Services\\CashBidsService\\BushelFuturesService',
    label: 'Bushel',
    fields: ['api_url'],
  },
  {
    api_type: 'App\\Services\\CashBidsService\\DTN\\JsonApi',
    label: 'DTN JSON API',
    fields: ['elevator_mapping', 'options'],
  },
  {
    api_type: 'App\\Services\\CashBidsService\\DtnOfferService',
    label: 'DTN Offer',
    fields: ['api_url', 'username', 'password', 'elevator_mapping', 'options'],
  },
  {
    api_type: 'App\\Services\\CashBidsService\\DTNScraper',
    label: 'DTN Scraper',
    fields: ['api_url', 'username', 'password', 'elevator_mapping', 'options'],
  },
  {
    api_type: 'App\\Services\\CashBidsService\\DtnSoap\\DtnSoapService',
    label: 'DTN SOAP',
    fields: ['api_url', 'username', 'password', 'elevator_mapping', 'options'],
  },
  {
    api_type: 'App\\Services\\CashBidsService\\FarmCentric',
    label: 'Farm Centric',
    fields: ['api_url', 'username', 'password', 'elevator_mapping', 'options'],
  },
  {
    api_type: 'local',
    label: 'Local',
    fields: ['local_crop_ids', 'options'],
  },
]
