import React from 'react'
import { Field } from 'formik'
import { TextField, Select, Switch } from 'src/ui/formik'
import { Table2 as Table } from 'src/ui'

interface FuturesFormProps {
  futuresConfig: OfferManagementMapping
  availableCommodities: Array<string>
}

const FuturesForm = ({ futuresConfig, availableCommodities }: FuturesFormProps) => {
  const newFuturesProductMapJSON = (array, availableCommodities): Array<any> => {
    return availableCommodities.map((commodity) => {
      let configuredCommodity = array?.find((config) => config.name === commodity)

      if (configuredCommodity) {
        return {
          name: configuredCommodity.name,
          display_name: configuredCommodity.display_name,
          display: configuredCommodity.display,
          enabled: true,
        }
      }
      return { name: commodity, display_name: '', display: '', enabled: false }
    })
  }

  const display = newFuturesProductMapJSON(
    futuresConfig.futures_product_map_json,
    availableCommodities
  )

  return (
    <>
      <Table striped>
        <thead className="thead">
          <tr>
            <th>CQG Symbol</th>
            <th>Display Name</th>
            <th>Bid Type</th>
            <th>Enabled</th>
          </tr>
        </thead>
        <tbody>
          {display.map((product, index) => (
            <tr key={product.name}>
              <td css={{ width: '200px' }}>{product.name}</td>
              <td css={{ width: '250px', height: '40px' }}>
                <Field
                  flex={1}
                  minWidth={0}
                  name={`futures_product_map_json.${index}.display_name`}
                  component={TextField}
                  placeholder={product.display_name}
                  formControlProps={{
                    height: '40px',
                    mb: null,
                  }}
                />
              </td>
              <td css={{ width: '200px', height: '40px' }}>
                <Field
                  flex={1}
                  minWidth={0}
                  name={`futures_product_map_json.${index}.display`}
                  component={Select}
                  placeholder="Select Option"
                  formControlProps={{
                    height: '40px',
                    mb: null,
                  }}
                  options={[
                    { value: 'Trade', label: 'Trade' },
                    { value: 'Last Bid', label: 'Last Bid' },
                  ]}
                />
              </td>
              <td css={{ width: '200px' }}>
                <Field
                  name={`futures_product_map_json.${index}.enabled`}
                  component={Switch}
                  isChecked={product.enabled}
                  formControlProps={{
                    justifyContent: 'flex-start',
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export default FuturesForm
