import React, { useEffect } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { AuthRoute, FlexRoutes } from 'src/routes'
import LoggedInUsersReport from 'src/reports/logged-in-users'
import EsignReport from 'src/reports/esign'
import { Dispatch } from 'src/store'
import { CompaniesState } from 'src/store/models/companies'
import { SettingsState } from 'src/store/models/settings'
import { Placeholders } from 'src/ui'
import * as api from 'src/utils/api'
import ProofOfYield from 'src/reports/proof-of-yield'
import Tickets from 'src/reports/ticket-applications'
import Contracts from 'src/reports/contracts'
import { Navigate, Route } from 'react-router-dom'

type ReportsProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>

const ReportsIndex = ({ companies, loadCompanies, settings, setSettingsState }: ReportsProps) => {
  useEffect(() => {
    if (companies.status === api.STATUS.idle) loadCompanies()
    setSettingsState({ showCentreEnvironment: true })
  }, [companies.status, loadCompanies, setSettingsState])

  if (companies.status === api.STATUS.idle) return <Placeholders.LoadingState />
  if (companies.status === api.STATUS.loading) return <Placeholders.LoadingState />
  if (companies.status === api.STATUS.failed) return <Placeholders.FailedState />

  const defaultDate = moment().subtract(1, 'month').format('YYYY-MM')

  return (
    <FlexRoutes>
      <Route
        path="/"
        element={<Navigate to={`/reports/logged-in-users/${defaultDate}`} replace />}
      />
      <Route
        path="/logged-in-users"
        element={<Navigate to={`/reports/logged-in-users/${defaultDate}`} replace />}
      />
      <Route path="/esign" element={<Navigate to={`/reports/esign/${defaultDate}`} replace />} />
      <Route
        path="/contracts"
        element={<AuthRoute as={Contracts} companies={companies.data} settings={settings} />}
      />
      <Route
        path="/proof-of-yield"
        element={<AuthRoute as={ProofOfYield} companies={companies.data} settings={settings} />}
      />
      <Route
        path="/ticket-applications"
        element={<AuthRoute as={Tickets} companies={companies.data} settings={settings} />}
      />
      <Route
        path="/logged-in-users/:date"
        element={
          <AuthRoute
            as={LoggedInUsersReport}
            companies={companies.data}
            settings={settings}
            defaultDate={defaultDate}
          />
        }
      />
      <Route
        path="/esign/:date"
        element={
          <AuthRoute
            as={EsignReport}
            companies={companies.data}
            settings={settings}
            defaultDate={defaultDate}
          />
        }
      />
    </FlexRoutes>
  )
}

const mapState = (state: { companies: CompaniesState; settings: SettingsState }) => ({
  companies: state.companies,
  settings: state.settings,
})

const mapDispatch = ({ settings, companies }: Dispatch) => ({
  loadCompanies: companies.loadAll,
  setSettingsState: settings.setState,
})

export default connect(mapState, mapDispatch)(ReportsIndex)
