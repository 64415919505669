import React from 'react'
import * as Yup from 'yup'
import { Link, useParams } from 'react-router-dom'
import useComponentSize from '@rehooks/component-size'
import {
  Stack,
  Box,
  Row,
  Button,
  FormRow,
  Column,
  Checkbox,
  ConfigCard,
  Dialog,
  Placeholders,
} from 'src/ui'
import { Field, FieldArray, Form, Formik, FormikProps } from 'formik'
import { TextField as FormikTextField } from 'src/ui/formik'
import { ICompany, IPosition, ILocation } from 'src/api/api'
import { getEnvironment } from 'src/utils'
import { usePositionForm } from 'src/companies/positions/position-utils'

type CompanyPositionFormProps = {
  positions: IPosition[]
  company: ICompany
  locations: ILocation[]
  header?: (company: ICompany, showAdd?: boolean) => void
}

export interface PositionFormProps {
  remote_id: string
  display_name: string
  locationIds: ILocation['id'][]
}

const FormSchema = Yup.object().shape({
  display_name: Yup.string().required('Required'),
  remote_id: Yup.string().required('Required'),
})

const CompanyPositionForm: React.FC<CompanyPositionFormProps> = ({
  positions,
  company,
  locations,
  header,
}) => {
  const { id } = useParams()
  let stackRef = React.useRef(null)
  let stackSize = useComponentSize(stackRef)

  let [isSubmitting, setIsSubmitting] = React.useState(false)
  let [isDeleting, setIsDeleting] = React.useState(false)
  let [showDeleteDialog, setShowDeleteDialog] = React.useState(false)

  let currentPosition = positions.find((position) => position.id.toString() === id)

  const {
    createPositionHandler,
    updatePositionHandler,
    deletePositionHandler,
    chunkedLocations,
    locationColumnWidth,
    currentLocationIds,
  } = usePositionForm({
    currentPosition,
    company,
    locations,
    setIsSubmitting,
    setIsDeleting,
    positions,
    remote_id: currentPosition?.remote_id,
    display_name: currentPosition?.display_name,
    size: stackSize?.width,
  })

  return (
    <>
      <Formik
        initialValues={{
          display_name: currentPosition?.display_name,
          remote_id: currentPosition?.remote_id,
          locationIds: currentLocationIds,
        }}
        validationSchema={FormSchema}
        onSubmit={async (values: PositionFormProps) => {
          setIsSubmitting(true)
          currentPosition === undefined
            ? await createPositionHandler(values)
            : await updatePositionHandler(values)
          setIsSubmitting(false)
        }}
      >
        {(formikProps: FormikProps<PositionFormProps>) => (
          <Stack ref={stackRef}>
            <>{header}</>
            <ConfigCard
              header={
                <Box mb={6}>
                  <Button
                    width="76px"
                    size="sm"
                    mr={2}
                    colorScheme="secondary"
                    isDisabled={isSubmitting || !currentPosition}
                    onClick={() => {
                      setShowDeleteDialog(true)
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    as={Link}
                    to={`/companies/${getEnvironment()}/${company.slug}/positions`}
                    width="76px"
                    size="sm"
                    mr={2}
                    colorScheme="secondary"
                    isDisabled={isSubmitting}
                  >
                    CANCEL
                  </Button>
                  <Button
                    width="76px"
                    size="sm"
                    type="submit"
                    isLoading={isSubmitting}
                    mr={2}
                    colorScheme="primary"
                    onClick={() => {
                      formikProps.submitForm()
                    }}
                  >
                    Save
                  </Button>
                </Box>
              }
            >
              <Form>
                <Stack spacing={2}>
                  <FormRow rowProps={{ mb: 5 }}>
                    <Field
                      name="display_name"
                      label="Display Name"
                      component={FormikTextField}
                      formControlProps={{ height: '62px', mb: null }}
                    />

                    <Field
                      name="remote_id"
                      label="Remote ID"
                      component={FormikTextField}
                      formControlProps={{ height: '62px', mb: null }}
                    />
                  </FormRow>

                  {chunkedLocations.length > 0 ? (
                    <>
                      <Box color="gray.500" fontWeight={500} fontSize={14} mb={4}>
                        LOCATIONS
                      </Box>

                      <Row style={{ marginBottom: '0px' }}>
                        <Checkbox
                          key="selectall"
                          size="md"
                          onChange={(e) => {
                            if (e.target.checked) {
                              formikProps.setFieldValue(
                                'locationIds',
                                locations.map((location) => location.id)
                              )
                            } else {
                              formikProps.setFieldValue('locationIds', [])
                            }
                          }}
                        >
                          Select All
                        </Checkbox>
                      </Row>

                      <Row>
                        <FieldArray
                          name="locationIds"
                          render={(arrayHelpers) => (
                            <>
                              {chunkedLocations.map((chunk, i) => (
                                <Column key={i} width={locationColumnWidth} py={2}>
                                  {chunk.map((location) => (
                                    <Checkbox
                                      key={location.id}
                                      size="md"
                                      isChecked={formikProps.values.locationIds.includes(
                                        location.id
                                      )}
                                      mb={2}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          arrayHelpers.push(location.id)
                                        } else {
                                          let idx = formikProps.values.locationIds.indexOf(
                                            location.id
                                          )
                                          if (idx !== -1) arrayHelpers.remove(idx)
                                        }
                                      }}
                                    >
                                      {location.name}
                                    </Checkbox>
                                  ))}
                                </Column>
                              ))}
                            </>
                          )}
                        />
                      </Row>
                    </>
                  ) : (
                    <Row justifyContent="center">
                      <Placeholders.EmptyState message="No Locations Found" mt="0" />
                    </Row>
                  )}
                </Stack>
              </Form>
            </ConfigCard>
          </Stack>
        )}
      </Formik>
      <Dialog
        title="Delete Position"
        isOpen={showDeleteDialog}
        onClose={() => setIsDeleting(false)}
        actions={
          <>
            <Button
              size="sm"
              variant="ghost"
              onClick={() => {
                setShowDeleteDialog(false)
                setIsDeleting(false)
              }}
              mr={2}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              colorScheme="primary"
              isLoading={isDeleting}
              onClick={() => {
                deletePositionHandler()
              }}
            >
              Delete
            </Button>
          </>
        }
      >
        <Column>
          <Box>
            <Box>Are you sure you want to delete this position</Box>
            {currentPosition && (
              <ul>
                <li>{currentPosition.display_name}</li>
              </ul>
            )}
          </Box>
        </Column>
      </Dialog>
    </>
  )
}

export default CompanyPositionForm
