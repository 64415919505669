import React, { useState, useMemo, useCallback } from 'react'
import { Box, Button, Column, Placeholders, Row, Table2, useTable } from '../../ui'
import { EditDialog } from '../dialogs/edit-secret'
import { getSecretByID } from '../../utils/api'
import { DeleteDialog } from '../dialogs/delete-dialog'
import { useToast } from 'src/utils/toast'

interface SecretTableProps {
  secrets: TranslatorSecret[]
  translator: Translator
}

export const SecretTable = ({ secrets, translator }: SecretTableProps) => {
  let toast = useToast()
  let [isOpen, setIsOpen] = useState(false)
  let [deleteIsOpen, setDeleteIsOpen] = useState(false)
  let [editSecretID, setEditSecretID] = useState('')
  let [password, setPassword] = useState('')
  let [passwordName, setPasswordName] = useState('')
  let [valueList, setValueList] = useState([])
  const currentSecret = secrets.find((secret) => secret.id === editSecretID)
  const translatorIDSet = translator.id

  const getSecret = useCallback(
    async (translatorIDGet, secretIDGet) => {
      const translatorID = translatorIDGet
      const secretID = secretIDGet
      let [err, response] = await getSecretByID({ translatorID, secretID })
      if (response) {
        setPassword(response.data.data.value)
        setPasswordName(response.data.data.name)
        setIsOpen(true)
      } else {
        toast({ status: 'error', description: 'Something went wrong getting Secrets: ' + err })
      }
    },
    [toast]
  )

  const getValue = useCallback(
    async (translatorIDValue, secretIDValue) => {
      const translatorID = translatorIDValue
      const secretID = secretIDValue
      let [err, response] = await getSecretByID({ translatorID, secretID })
      if (response) {
        return response.data.data.value
      } else {
        toast({ status: 'error', description: 'Something went wrong getting Secrets: ' + err })
      }
    },
    [toast]
  )

  let columns = useMemo(
    () => [
      {
        id: 'name',
        accessor: 'name',
        Header: 'Key',
        disableSortBy: true,
      },
      {
        id: 'remote_id',
        accessor: (secret) => {
          const displayValue = valueList.find((item) => item.id === secret.id)
          return (
            <Box w="100%" maxW="700px">
              {displayValue ? displayValue.value : '***********'}
            </Box>
          )
        },
        Header: 'Value',
        disableSortBy: true,
      },
      {
        id: '_actions',
        accessor: '_actions',
        Header: '',

        Cell: ({ row }) => {
          let secret = row.original

          return (
            <Row justifyContent="flex-end">
              <Button
                size="sm"
                variant="ghost"
                color="primary.500"
                aria-label="Copy Commodity"
                onClick={() => {
                  setDeleteIsOpen(true)
                  setEditSecretID(secret.id)
                }}
              >
                Delete
              </Button>
              <Button
                size="sm"
                variant="ghost"
                color="primary.500"
                aria-label="Copy Commodity"
                onClick={async () => {
                  const exists = valueList.find((exVal) => exVal.id === secret.id)
                  if (exists) {
                    setValueList((state) => state.filter((item) => item.id !== secret.id))
                  } else {
                    const value = await getValue(translatorIDSet, secret.id)
                    setValueList((state) => [...state, { id: secret.id, value }])
                  }
                }}
              >
                {valueList.find((exVal) => exVal.id === secret.id) ? 'Hide' : 'View'}
              </Button>

              <Button
                size="sm"
                variant="ghost"
                color="primary.500"
                aria-label="Copy Commodity"
                onClick={() => {
                  const exists = valueList.find((exVal) => exVal.id === secret.id)
                  if (exists) {
                    setValueList((state) => state.filter((item) => item.id !== secret.id))
                  }
                  setEditSecretID(secret.id)
                  getSecret(translatorIDSet, secret.id)
                }}
              >
                Edit
              </Button>
            </Row>
          )
        },
      },
    ],
    [valueList, setValueList, translatorIDSet, getSecret, getValue]
  )

  let { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    data: secrets,
    tableOptions: {
      initialState: {
        sortBy: [],
      },
    },
    columns,
  })

  return (
    <>
      {secrets.length === 0 ? (
        <Placeholders.EmptyState message="No secrets found" />
      ) : (
        <Box bg="white" pt={3}>
          <Table2 {...getTableProps()} style={{ boxShadow: 'none' }} striped>
            <Table2.Header headerGroups={headerGroups} />
            <Table2.Body
              rows={rows}
              getTableBodyProps={getTableBodyProps}
              prepareRow={prepareRow}
            />
          </Table2>
          <Column width="100%">
            <EditDialog
              isOpen={isOpen}
              onClose={() => {
                setIsOpen(false)
              }}
              onConfirm={null}
              componentTitle={'Edit Secret: ' + passwordName}
              translator={translator}
              secret={currentSecret}
              password={password}
            />
          </Column>
          <Column width="100%">
            <DeleteDialog
              isOpen={deleteIsOpen}
              onClose={() => {
                setDeleteIsOpen(false)
              }}
              onConfirm={null}
              componentTitle="Delete Secret"
              translator={translator}
              secretDelete={currentSecret}
            />
          </Column>
        </Box>
      )}
    </>
  )
}
