import { rest } from 'msw'
import { createTranslator, translators } from 'src/fixtures/translator'
import { sequence } from 'src/fixtures/utils'

export const handlers = (urls: string[]) => {
  return urls.reduce(
    (acc, url) => [
      ...acc,
      rest.get(`${url}/translator/null`, (_req, res, ctx) =>
        res(
          ctx.status(404),
          ctx.json({ error: { code: 'Exception', message: 'Translator not found', more: [] } })
        )
      ),
      rest.get(`${url}/translator/:id`, (req, res, ctx) => {
        const id = parseInt(req.params.id as string)
        const translator = translators.find((translator) => translator.id === id)
        return res(ctx.status(200), ctx.json({ data: translator }))
      }),
      rest.put(`${url}/translator/:id`, async (req, res, ctx) => {
        const requestBody = await req.json()
        const id = parseInt(req.params.id as string)
        const index = translators.findIndex((translator) => translator.id === id)
        translators[index] = requestBody
        const statusText = `Successfully updated ${requestBody.url}.`
        return res(ctx.status(200), ctx.json({ data: req.body, status: statusText }))
      }),
      rest.post(`${url}/translator`, async (req, res, ctx) => {
        const requestBody = await req.json()
        const newTranslator = createTranslator({ ...requestBody, id: sequence() })
        translators.push(newTranslator)
        const statusText = `Successfully added with url: ${newTranslator.url} to the system.`
        return res(ctx.status(201), ctx.json({ data: newTranslator, status: statusText }))
      }),
    ],
    []
  )
}
