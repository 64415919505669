import React from 'react'
import { useToast as useChakraToast, UseToastOptions } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { Text, Box } from 'src/ui'

export const useToast = () => {
  let toast = useChakraToast()

  const handleToast = ({
    title,
    description,
    status = 'success',
    position = 'top-right',
    duration = status ? 5000 : 10000,
    isClosable = true,
    ...rest
  }: UseToastOptions) => {
    toast({
      title,
      description,
      status,
      position,
      duration,
      isClosable,
      ...rest,
    })
  }
  return React.useCallback(handleToast, [toast])
}

export function useErrorToast() {
  const toast = useToast()
  const handleToast = (error, requestType: 'create' | 'update' | 'save', object: string) => {
    const { message, response } = error as AxiosError
    const data = response.data as any
    const title = `${response?.status} Error: ${data?.message || message || 'Error'}`

    let description: React.ReactNode = `Failed to ${requestType} ${object}`

    if (response?.status === 422) {
      if (data?.validation) {
        description = Object.entries(data.validation).map((field) => (
          <Box key={field[0]}>
            <Text fontWeight="bold" pt="2">
              {field[0]}
            </Text>
            {(field[1] as Array<{ message: string; args: Object }>).map((error) => (
              <Text key={error.message}> - {error.message}</Text>
            ))}
          </Box>
        ))
      } else {
        description = data?.error?.message || description
      }
    }
    if (message === 'Network Error') {
      description = 'Make sure combined size of uploaded files does not exceed 787KB'
    }

    toast({ status: 'error', title, description, duration: 10000 })
  }
  return React.useCallback(handleToast, [toast])
}
