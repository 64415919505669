import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { RootState, Dispatch } from 'src/store'
import { FlexRoutes, AuthRoute } from 'src/routes'
import * as api from 'src/utils/api'
import { Placeholders } from 'src/ui'
import {
  CompaniesDefault,
  ValidatedBuildManagerRoutes,
  ValidatedCompanyBranding,
  ValidatedCompanyRoutes,
} from 'src/companies/routes'
import { Route } from 'react-router-dom'

export type CompaniesProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>

const Companies = ({
  settings,
  companies,
  loadCompanies,
  resetCompanies,
  setSettingsState,
}: CompaniesProps) => {
  useEffect(() => {
    if (companies.status === api.STATUS.idle) loadCompanies()
  }, [companies.status, loadCompanies])

  if (companies.status === api.STATUS.failed) return <Placeholders.FailedState />
  if (companies.status === api.STATUS.loading) return <Placeholders.LoadingState />
  if (companies.status === api.STATUS.idle) return <Placeholders.LoadingState />
  if (companies.status !== api.STATUS.loaded) return null

  return (
    // relative to /companies/*
    <FlexRoutes>
      <Route
        path="/:slug/build-manager/*"
        element={
          <AuthRoute
            as={ValidatedBuildManagerRoutes}
            settings={settings}
            companies={companies}
            loadCompanies={loadCompanies}
            setSettingsState={setSettingsState}
          />
        }
      />

      <Route
        path="/:slug/branding"
        element={
          <AuthRoute
            as={ValidatedCompanyBranding}
            settings={settings}
            companies={companies}
            loadCompanies={loadCompanies}
            setSettingsState={setSettingsState}
          />
        }
      />

      <Route
        path="/:env/:slug/*"
        element={
          <AuthRoute
            as={ValidatedCompanyRoutes}
            settings={settings}
            companies={companies}
            loadCompanies={loadCompanies}
            resetCompanies={resetCompanies}
            setSettingsState={setSettingsState}
          />
        }
      />

      <Route
        path="/:env"
        element={
          <AuthRoute
            as={CompaniesDefault}
            settings={settings}
            companies={companies}
            loadCompanies={loadCompanies}
            resetCompanies={resetCompanies}
            setSettingsState={setSettingsState}
          />
        }
      />

      <Route
        path="*"
        element={
          <AuthRoute
            as={CompaniesDefault}
            settings={settings}
            companies={companies}
            loadCompanies={loadCompanies}
            resetCompanies={resetCompanies}
            setSettingsState={setSettingsState}
          />
        }
      />
    </FlexRoutes>
  )
}

const mapState = ({ settings, companies }: RootState) => ({ settings, companies })
const mapDispatch = ({ settings, companies }: Dispatch) => ({
  setSettingsState: settings.setState,
  loadCompanies: companies.loadAll,
  resetCompanies: companies.reset,
})

export default connect(mapState, mapDispatch)(Companies)
