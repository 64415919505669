import React from 'react'
import { useCompanyCommodities } from 'src/api/queries/commodities'
import { Link, Route } from 'react-router-dom'
import { hasPermission } from 'src/utils/permissions'
import { Box, Button, Placeholders, Row, Table2, useTable } from 'src/ui'
import { CommodityForm } from 'src/companies/commodities/commodity-editor'
import * as queries from 'src/utils/queries'
import { AuthRoute, FlexRoutes } from 'src/routes'
import { getEnvironment } from 'src/utils'
import { useCopyDiff } from 'src/companies/copy-utils'
import { executeCopy, loadCopyDiff } from 'src/companies/commodities/copy'
import { IS_DEV } from 'src/utils/config'

const CommoditiesHeader = ({ company, showAdd = true }) => (
  <Row justifyContent="flex-end" height={8} mb={3}>
    <Box>
      {showAdd && (
        <Button
          as={Link}
          to={
            !hasPermission('company_commodities_edit')
              ? ''
              : `/companies/${getEnvironment()}/${company.slug}/commodities/new`
          }
          size="sm"
          type="button"
          colorScheme={!hasPermission('company_commodities_edit') ? 'secondary' : 'primary'}
          isDisabled={!hasPermission('company_commodities_edit')}
        >
          ADD
        </Button>
      )}
    </Box>
  </Row>
)

interface CompanyCommoditiesProps {
  company: Company
  setCopyDialog: (args: any) => void
}

const CompanyCommodities: React.FC<CompanyCommoditiesProps> = ({ company, setCopyDialog }) => {
  let commoditiesQuery = useCompanyCommodities({ companySlug: company.slug })
  let commodities = commoditiesQuery?.data

  let { startCopy } = useCopyDiff({
    company,
    loadCopyDiff,
    executeCopy,
    setCopyDialog,
    context: {
      commodities,
    },
  })

  if (queries.areAnyLoading(commoditiesQuery)) return <Placeholders.LoadingState />
  if (queries.areAnyFailed(commoditiesQuery)) return <Placeholders.FailedState />

  return (
    <FlexRoutes>
      <Route
        path="/:id/edit"
        element={
          <AuthRoute
            as={CommodityForm}
            commodities={commodities}
            company={company}
            header={<CommoditiesHeader company={company} showAdd={false} />}
          />
        }
      />
      <Route
        path="/new"
        element={
          <AuthRoute
            as={CommodityForm}
            commodities={null}
            company={company}
            header={<CommoditiesHeader company={company} showAdd={false} />}
          />
        }
      />
      <Route
        path="*"
        element={
          <AuthRoute
            as={CommoditiesTable}
            commodities={commodities}
            permissions={{
              copy: 'company_commodities_copy',
              edit: 'company_commodities_edit',
            }}
            company={company}
            header={<CommoditiesHeader company={company} showAdd={true} />}
            onCopy={startCopy}
          />
        }
      />
    </FlexRoutes>
  )
}

export default CompanyCommodities

const CommoditiesTable = ({ commodities, permissions, company, header, onCopy }) => {
  let [copyId, setCopyId] = React.useState()
  let columns = React.useMemo(
    () => [
      {
        id: 'display_name',
        accessor: 'display_name',
        Header: 'Display Name',
        disableSortBy: true,
      },
      {
        id: 'remote_id',
        accessor: 'remote_id',
        Header: 'Remote ID',
        disableSortBy: true,
      },
      {
        id: '_actions',
        Header: '',
        accessor: '_actions',
        disableSortBy: true,
        Cell: ({ row }) => {
          let commodity = row.original

          return (
            <Row justifyContent="flex-end">
              <Button
                size="sm"
                variant="ghost"
                color="primary.500"
                aria-label="Copy Commodity"
                isDisabled={
                  IS_DEV || !hasPermission(permissions.copy) || (copyId && copyId !== commodity.id)
                }
                isLoading={copyId === commodity.id}
                onClick={async () => {
                  setCopyId(commodity.id)
                  await onCopy({ context: { copyId: commodity.id } })
                  setCopyId(null)
                }}
              >
                Copy
              </Button>

              <Button
                as={Link}
                to={`/companies/${getEnvironment()}/${company.slug}/commodities/${
                  commodity.id
                }/edit`}
                size="sm"
                variant="ghost"
                color="primary.500"
                aria-label="Edit Commodity"
                isDisabled={!hasPermission(permissions.edit) || copyId}
              >
                EDIT
              </Button>
            </Row>
          )
        },
      },
    ],
    [permissions.copy, permissions.edit, company.slug, copyId, onCopy, setCopyId]
  )

  let { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    data: commodities,
    tableOptions: {
      initialState: {
        sortBy: [],
      },
    },
    columns,
  })

  return (
    <>
      {header}

      {commodities.length === 0 ? (
        <Placeholders.EmptyState message="No commodities found" />
      ) : (
        <Box bg="white" mb={4} pt={3} borderRadius={3} boxShadow="md">
          <Table2 {...getTableProps()} style={{ boxShadow: 'none' }} striped>
            <Table2.Header headerGroups={headerGroups} />
            <Table2.Body
              rows={rows}
              getTableBodyProps={getTableBodyProps}
              prepareRow={prepareRow}
            />
          </Table2>
        </Box>
      )}
    </>
  )
}
