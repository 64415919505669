import React from 'react'
import { Box, Row, Button, Collapse, IconButton, ChevronDownIcon, ChevronUpIcon } from 'src/ui'
import { hasPermission } from 'src/utils/permissions'
import { IS_DEV_OR_SANDBOX } from 'src/utils/config'

export type PanelState = 'collapsed' | 'expanded' | 'editing'

interface PanelProps {
  label: string | React.ReactNode
  bodyStyle?: any
  panelProps?: object
  hideToggleIcon?: boolean
  defaultExpanded?: boolean
  copyPermission: string
  editPermission: string
  controls?: React.ReactNode
  expanded?: boolean
  copyIsDisabled?: boolean
  saveIsDisabled?: boolean
  editIsDisabled?: boolean
  panelState: PanelState
  formData?: any
  onChange?: (...args: any) => void
  onClickCopy?: (...args: any) => void
  onClickEdit?: (...args: any) => void
  onCancelEdit?: (...args: any) => void
  onSave?: (...args: any) => void
  children?: React.ReactNode
}

const ConfigPanel: React.FC<PanelProps> = ({
  children,
  bodyStyle,
  panelProps,
  label,
  hideToggleIcon = false,
  defaultExpanded = false,
  copyPermission,
  editPermission,
  expanded,
  copyIsDisabled,
  editIsDisabled = false,
  saveIsDisabled,
  panelState,
  formData,
  onChange,
  controls,
  onClickCopy,
  onClickEdit,
  onCancelEdit,
  onSave,
}) => {
  let [innerExpanded, setInnerShow] = React.useState(defaultExpanded)
  const innerToggle = () => setInnerShow(!innerExpanded)

  let actualExpanded = typeof expanded !== 'undefined' ? expanded : innerExpanded
  const actualToggle = typeof onChange !== 'undefined' ? (e) => onChange(e, !expanded) : innerToggle

  return (
    <Box
      py={2}
      mb={4}
      borderRadius={3}
      bg="white"
      boxShadow="md"
      {...panelProps}
      css={{
        '.chakra-collapse': {
          overflow: 'visible !important',
        },
      }}
    >
      <Row
        justifyContent="space-between"
        alignItems="center"
        px={6}
        cursor="pointer"
        onClick={actualToggle}
      >
        <Box flex={1}>
          <Row width="100%" height="62px" justifyContent="space-between" alignItems="center">
            <Row alignItems="center">
              <Box fontSize="18px">{label}</Box>
            </Row>

            {!controls ? (
              <>
                {panelState !== 'editing' && (
                  <Box pr={2}>
                    {hasPermission(copyPermission) && (
                      <Button
                        width="76px"
                        size="sm"
                        colorScheme="secondary"
                        mr={2}
                        isDisabled={IS_DEV_OR_SANDBOX || copyIsDisabled}
                        onClick={(e) => {
                          e.stopPropagation()
                          onClickCopy()
                        }}
                      >
                        Copy
                      </Button>
                    )}

                    {hasPermission(editPermission) && (
                      <Button
                        width="76px"
                        size="sm"
                        colorScheme="primary"
                        isDisabled={editIsDisabled}
                        onClick={(e) => {
                          e.stopPropagation()
                          onClickEdit(e)
                        }}
                      >
                        EDIT
                      </Button>
                    )}
                  </Box>
                )}
              </>
            ) : (
              controls
            )}

            {panelState === 'editing' && (
              <Box pr={2}>
                <Button
                  width="76px"
                  size="sm"
                  colorScheme="secondary"
                  mr={2}
                  isDisabled={formData.isSubmitting}
                  onClick={(e) => {
                    e.stopPropagation()
                    onCancelEdit(e)
                  }}
                >
                  Cancel
                </Button>

                <Button
                  width="76px"
                  size="sm"
                  colorScheme="primary"
                  isDisabled={saveIsDisabled}
                  isLoading={formData.isSubmitting}
                  onClick={(e) => {
                    e.stopPropagation()
                    onSave()
                  }}
                >
                  Save
                </Button>
              </Box>
            )}
          </Row>
        </Box>

        <Box>
          {!hideToggleIcon && (
            <IconButton
              icon={actualExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
              aria-label="Toggle"
              isRound
              fontSize="24px"
              variant="ghost"
              color="#8C9BA5"
            />
          )}
        </Box>
      </Row>

      <Collapse py={2} in={actualExpanded} {...bodyStyle}>
        {children}
      </Collapse>
    </Box>
  )
}

export default ConfigPanel
