import React from 'react'
import * as Yup from 'yup'
import { mapValues } from 'lodash'
import { useQueryClient } from '@tanstack/react-query'
import { Formik, Form, Field, FormikProps } from 'formik'
import { TextField as FormikTextField, JsonEditor as FormikJsonEditor } from 'src/ui/formik'
import { Box, Stack, Placeholders, Button } from 'src/ui'
import { FormControl, FormLabel } from 'src/ui/form-controls'
import ConfigPanel, { PanelState } from 'src/companies/configurations/config-panel'
import { isFaded, convertEmptyStringsToNull } from 'src/companies/configurations/utils'
import { loadCopyDiff, executeCopy } from 'src/companies/configurations/agtrax-api/copy'
import { useCopyDiff } from 'src/companies/copy-utils'
import * as queries from 'src/utils/queries'
import { useAgtraxApi, useSaveAgtraxMutation } from 'src/api/queries/agtrax-api'
import { representValue } from '../utils'
import { useToast } from 'src/utils/toast'
import { Link } from 'react-router-dom'
import { getEnvironment } from 'src/utils'

const newAgtraxApi = {
  id: null,
  translator_id: null,
  shared_key: null,
  provider: '',
  providerPassword: '',
  username: '',
  password: '',
  options: null,
}

// DETAIL
///////////////////////////////////////////////////////////////////////////////

interface AgtraxApiDetailProps {
  agtraxApi: AgtraxApi
}

const AgtraxApiDetail: React.FC<AgtraxApiDetailProps> = ({ agtraxApi }) => {
  const formattedAgtraxApi = mapValues(agtraxApi, (v) => (v === '' ? null : v))
  return (
    <>
      <Stack isInline spacing={4} align="center" height="80px" px={6}>
        <Box flex={1} minWidth={0}>
          <FormControl height="62px" mb={null}>
            <FormLabel title="Username" as="div">
              Username
            </FormLabel>
            <Box
              color={isFaded(formattedAgtraxApi.username) ? 'gray.400' : undefined}
              css={{
                minWidth: 0,
                overflow: 'auto',
                whiteSpace: 'nowrap',
                scrollbarWidth: 'none',
                '::-webkit-scrollbar': {
                  width: 0,
                  height: 0,
                },
              }}
            >
              {representValue(formattedAgtraxApi.username)}
            </Box>
          </FormControl>
        </Box>
        <Box flex={1} minWidth={0}>
          <FormControl height="62px" mb={null}>
            <FormLabel title="Password" as="div">
              Password
            </FormLabel>
            <Box
              color={isFaded(formattedAgtraxApi.password) ? 'gray.400' : undefined}
              css={{
                minWidth: 0,
                overflow: 'auto',
                whiteSpace: 'nowrap',
                scrollbarWidth: 'none',
                '::-webkit-scrollbar': {
                  width: 0,
                  height: 0,
                },
              }}
            >
              {representValue(formattedAgtraxApi.password)}
            </Box>
          </FormControl>
        </Box>
        <Box flex={1} minWidth={0}>
          <FormControl height="62px" mb={null}>
            <FormLabel title="Provider" as="div">
              Provider
            </FormLabel>
            <Box
              color={isFaded(formattedAgtraxApi.provider) ? 'gray.400' : undefined}
              css={{
                minWidth: 0,
                overflow: 'auto',
                whiteSpace: 'nowrap',
                scrollbarWidth: 'none',
                '::-webkit-scrollbar': {
                  width: 0,
                  height: 0,
                },
              }}
            >
              {representValue(formattedAgtraxApi.provider)}
            </Box>
          </FormControl>
        </Box>
        <Box flex={1} minWidth={0}>
          <FormControl height="62px" mb={null}>
            <FormLabel title="Provider Password" as="div">
              Provider Password
            </FormLabel>
            <Box
              color={isFaded(formattedAgtraxApi.providerPassword) ? 'gray.400' : undefined}
              css={{
                minWidth: 0,
                overflow: 'auto',
                whiteSpace: 'nowrap',
                scrollbarWidth: 'none',
                '::-webkit-scrollbar': {
                  width: 0,
                  height: 0,
                },
              }}
            >
              {representValue(formattedAgtraxApi.providerPassword)}
            </Box>
          </FormControl>
        </Box>
      </Stack>
      <Stack isInline spacing={4} align="center" height="62px" px={6} bg="gray.50">
        <Box flex={1} minWidth={0}>
          <FormControl height="62px" mb={null}>
            <FormLabel title="Shared Key" as="div">
              Shared Key
            </FormLabel>
            <Box
              color={isFaded(formattedAgtraxApi.shared_key) ? 'gray.400' : undefined}
              css={{
                minWidth: 0,
                overflow: 'auto',
                whiteSpace: 'nowrap',
                scrollbarWidth: 'none',
                '::-webkit-scrollbar': {
                  width: 0,
                  height: 0,
                },
              }}
            >
              {representValue(formattedAgtraxApi.shared_key)}
            </Box>
          </FormControl>
        </Box>
      </Stack>
      <Stack isInline spacing={4} align="center" px={6} minHeight="120px">
        <FormControl height="auto" mb={null}>
          <FormLabel title="Options" as="div">
            Options
          </FormLabel>
          <Box
            fontSize="sm"
            color={isFaded(agtraxApi.options) ? 'gray.400' : undefined}
            css={{
              minWidth: 0,
              overflow: 'auto',
              whiteSpace: 'nowrap',
              // textOverflow: 'ellipsis',
              scrollbarWidth: 'none',
              '::-webkit-scrollbar': {
                width: 0,
                height: 0,
              },
            }}
          >
            <pre>{JSON.stringify(agtraxApi.options, null, 2)}</pre>
          </Box>
        </FormControl>
      </Stack>
    </>
  )
}

// FORM
///////////////////////////////////////////////////////////////////////////////

const FormSchema = Yup.object().shape(
  {
    username: Yup.string().when('password', ([val], schema) =>
      !!val ? schema.required('Required with Password') : schema
    ),
    password: Yup.string().when('username', ([val], schema) =>
      !!val ? schema.required('Required with Username') : schema
    ),
    provider: Yup.string().when('providerPassword', ([val], schema) =>
      !!val ? schema.required('Required with Provider Password') : schema
    ),
    providerPassword: Yup.string().when('provider', ([val], schema) =>
      !!val ? schema.required('Required with Provider') : schema
    ),
    shared_key: Yup.string().required('Required').nullable(),
    options: Yup.object().nullable(),
  },
  [
    ['username', 'password'],
    ['password', 'username'],
    ['providerPassword', 'provider'],
    ['provider', 'providerPassword'],
  ]
)

interface AgtraxApiFormProps {
  formikProps: FormikProps<any>
  formData: {
    submittedAt: number
    isSubmitting: boolean
  }
}

const AgtraxApiForm: React.FC<AgtraxApiFormProps> = ({ formikProps, formData }) => {
  let { submittedAt } = formData
  let { submitForm } = formikProps

  React.useEffect(() => {
    if (!submittedAt) return
    submitForm()
  }, [submittedAt, submitForm])

  return (
    <Form>
      <Stack isInline spacing={4} align="center" height="80px" px={6}>
        <Box flex={1} minWidth={0}>
          <Field
            name="username"
            label="Username"
            component={FormikTextField}
            formControlProps={{
              height: '62px',
              mb: null,
            }}
          />
        </Box>
        <Box flex={1} minWidth={0}>
          <Field
            name="password"
            label="Password"
            component={FormikTextField}
            formControlProps={{
              height: '62px',
              mb: null,
            }}
          />
        </Box>
        <Box flex={1} minWidth={0}>
          <Field
            name="provider"
            label="Provider"
            component={FormikTextField}
            formControlProps={{
              height: '62px',
              mb: null,
            }}
          />
        </Box>
        <Box flex={1} minWidth={0}>
          <Field
            name="providerPassword"
            label="Provider Password"
            component={FormikTextField}
            formControlProps={{
              height: '62px',
              mb: null,
            }}
          />
        </Box>
      </Stack>

      <Stack isInline spacing={4} align="center" height="80px" px={6}>
        <Box flex={1} minWidth={0}>
          <Field
            name="shared_key"
            label="Shared Key"
            component={FormikTextField}
            formControlProps={{
              height: '62px',
              mb: null,
            }}
          />
        </Box>
      </Stack>

      <Stack isInline spacing={4} align="center" px={6}>
        <Box flex={1} minWidth={0}>
          <Field name="options" label="Options" component={FormikJsonEditor} height={350} />
        </Box>
      </Stack>
    </Form>
  )
}

// PANEL
///////////////////////////////////////////////////////////////////////////////

type AgtraxApiPanelProps = {
  company: Company
  setCopyDialog?: (args: any) => void
}

const AgtraxApiPanel: React.FC<AgtraxApiPanelProps> = ({ company, setCopyDialog }) => {
  let [panelState, setPanelState] = React.useState<PanelState>('collapsed')
  let [formData, setFormData] = React.useState({ submittedAt: null, isSubmitting: false })
  let { saveAgtraxApi } = useSaveAgtraxMutation()
  let toast = useToast()
  const queryClient = useQueryClient()

  let agtraxApiQuery = useAgtraxApi({
    companySlug: company.slug,
  })

  let agtraxApi = agtraxApiQuery?.data?.data || newAgtraxApi

  let isLoading = queries.areAnyLoading(agtraxApiQuery)
  let isFailed = queries.areAnyFailed(agtraxApiQuery)
  let isEditing = panelState === 'editing'
  let hasTranslator = !!company.translator_id

  const stopEditing = () => {
    setPanelState('expanded')
    setFormData({ submittedAt: null, isSubmitting: false })
  }

  let { startCopy } = useCopyDiff({
    company,
    context: { agtraxApi: agtraxApi },
    loadCopyDiff,
    executeCopy,
    setCopyDialog,
  })

  return (
    <ConfigPanel
      label="Agtrax API"
      formData={formData}
      expanded={['expanded', 'editing'].includes(panelState)}
      panelState={panelState}
      onClickCopy={startCopy}
      copyPermission="company_properties_copy"
      copyIsDisabled={!(agtraxApi && agtraxApi.id)}
      editIsDisabled={!company.translator_id || agtraxApiQuery.isLoading}
      saveIsDisabled={panelState === 'editing' && !company.translator_id}
      editPermission="company_properties_edit"
      onChange={(e, expanded) => {
        if (panelState === 'editing') return
        setPanelState(expanded ? 'expanded' : 'collapsed')
      }}
      onClickEdit={() => setPanelState('editing')}
      onCancelEdit={stopEditing}
      onSave={() => {
        setFormData((state) => ({ ...state, submittedAt: new Date().getTime() }))
      }}
    >
      {isLoading && !isEditing && <Placeholders.LoadingState mt="0" />}
      {isFailed && !isEditing && <Placeholders.FailedState mt="0" />}
      {!isLoading && !isFailed && !isEditing && hasTranslator && (
        <AgtraxApiDetail agtraxApi={agtraxApi} />
      )}
      {isEditing && hasTranslator && (
        <Formik
          initialValues={agtraxApi}
          validateOnChange={false}
          validationSchema={FormSchema}
          onSubmit={async (values, _formikActions) => {
            setFormData((state) => ({ ...state, isSubmitting: true }))
            if (!values.translator_id) {
              values = { ...values, translator_id: company.translator_id }
            }

            convertEmptyStringsToNull(values)

            if (!values.options) {
              values = { ...values, options: '' }
            }

            await saveAgtraxApi(
              {
                config: {
                  ...values,
                  _revision: agtraxApi._revision,
                },
              },
              {
                onSuccess: async (_err, { config }) => {
                  await queryClient.invalidateQueries({ queryKey: ['agtrax-apis'] })
                  toast({
                    description: `Successfully ${config.id ? 'updated' : 'created'} Agtrax API`,
                  })
                  stopEditing()
                },
                onError: () => {
                  toast({
                    status: 'error',
                    description: 'Failed to save Agtrax API',
                  })
                  stopEditing()
                },
              }
            )
          }}
        >
          {(formikProps: FormikProps<any>) => (
            <AgtraxApiForm formikProps={formikProps} formData={formData} />
          )}
        </Formik>
      )}
      {!hasTranslator && !isLoading && (
        <Placeholders.EmptyState
          mt={0}
          message={
            <>
              <Box textAlign="center">
                A V1 Translator is required before an Agtrax API can be created.
                <br />
                Please create one for this company before proceeding.
              </Box>
              <Box textAlign="center" mt={3}>
                <Link
                  to={`/companies/${getEnvironment()}/${company.slug}/translator`}
                  state={{ isEdit: true }}
                >
                  <Button colorScheme="yellow" as="span">
                    CREATE TRANSLATOR
                  </Button>
                </Link>
              </Box>
            </>
          }
        />
      )}
    </ConfigPanel>
  )
}

export default AgtraxApiPanel
