import React from 'react'
import { Dispatch } from 'src/store'
import * as queries from 'src/utils/queries'
import {
  useCentreCashBidConfigs,
  useCentreElevators,
  useCentreCashBidConfigMutations,
} from 'src/api/queries/cashbids'
import { hasPermission } from 'src/utils/permissions'
import { DeleteDialog } from 'src/companies/cashbids/dialogs'
import CashBidConfigCard from 'src/companies/cashbids/cash-bid-config-card'
import { Box, Row, Placeholders, Button } from 'src/ui'
import { CASH_BID_TYPES } from './utils'

const NEW_CONFIG: CashBidConfig = {
  retrieved_from: 'api',
  api_type: '',
  api_url: '',
  username: '',
  password: '',
  local_crop_ids: '',
  options: null,
  elevator_mapping: null,
}

const filterConfigs = (configs: CashBidConfig[] | undefined) => {
  if (!configs || configs.length === 0) return []

  return configs.filter((config) => {
    return (
      // filter out configs that don't have a known value for api_type or retrieved_from
      CASH_BID_TYPES.some(
        (cashBid) => cashBid.api_type === config.api_type || config.retrieved_from === 'local'
      ) &&
      (config.retrieved_from === 'local' || config.retrieved_from === 'api')
    )
  })
}

interface CashBidsContainerProps {
  company: Company
  setCopyDialog: (args: any) => void
  errorNotification: Dispatch['notifications']['createError']
  successNotification: Dispatch['notifications']['createSuccess']
}

const CashBidsContainer: React.FC<CashBidsContainerProps> = ({ company }) => {
  let configsQuery = useCentreCashBidConfigs({
    companySlug: company.slug,
  })

  let locationsQuery = useCentreElevators({
    companyId: company.id,
  })

  return (
    <>
      {queries.areAnyLoading(configsQuery, locationsQuery) && <Placeholders.LoadingState />}
      {queries.areAnyFailed(configsQuery, locationsQuery) && <Placeholders.FailedState />}
      {queries.areAllLoaded(configsQuery, locationsQuery) && (
        <CompanyCashBids
          company={company}
          configs={filterConfigs(configsQuery.data)}
          locations={locationsQuery.data}
        />
      )}
    </>
  )
}

interface CompanyCashBidsProps {
  company: Company
  configs: CashBidConfig[]
  locations: BushelLocation[]
}

const CompanyCashBids: React.FC<CompanyCashBidsProps> = ({ company, configs, locations }) => {
  let [deleteDialog, setDeleteDialog] = React.useState(null)
  let [showNewForm, setShowNewForm] = React.useState(() => configs.length === 0)

  let { saveConfig, deleteConfig } = useCentreCashBidConfigMutations()

  return (
    <>
      <Row justifyContent="flex-end" mb={3}>
        <Box>
          <Button
            type="submit"
            size="sm"
            colorScheme="primary"
            isDisabled={!hasPermission('company_cashbid_edit') || showNewForm}
            onClick={() => setShowNewForm(true)}
          >
            Add
          </Button>
        </Box>
      </Row>

      {!showNewForm && configs.length === 0 && (
        <Placeholders.EmptyState message="No configs found" />
      )}

      {showNewForm && (
        <CashBidConfigCard
          key="new-config"
          company={company}
          config={NEW_CONFIG}
          locations={locations}
          saveConfig={saveConfig}
          showDeleteDialog={() => null}
          setShowNewForm={setShowNewForm}
        />
      )}

      {configs.map((config) => (
        <CashBidConfigCard
          key={config.id}
          company={company}
          config={config}
          locations={locations}
          saveConfig={saveConfig}
          showDeleteDialog={() => setDeleteDialog({ config })}
          setShowNewForm={setShowNewForm}
        />
      ))}

      <DeleteDialog
        isOpen={!!deleteDialog}
        onClose={() => setDeleteDialog(null)}
        onConfirm={deleteConfig}
        {...deleteDialog}
      />
    </>
  )
}

export default CashBidsContainer
