import { bidlClient } from 'src/utils/api'

const baseUri = '/api/aggregator'

export const FindOrCreateTenantBySlug = async (slug) => {
  try {
    const response = await bidlClient.post(
      `${baseUri}/config/v1/FindOrCreateTenantBySlug`,
      {},
      {
        headers: {
          'App-Company': slug,
        },
      }
    )
    return [null, response.data]
  } catch (err) {
    return [err, null]
  }
}

export const GetConfigurables = async (slug) => {
  try {
    const response = await bidlClient.post(
      `${baseUri}/config/v1/GetConfigurables`,
      {},
      {
        headers: {
          'App-Company': slug,
        },
      }
    )
    return [null, response.data]
  } catch (err) {
    return [err, null]
  }
}

export const UpdateConfigurables = async (config, slug) => {
  try {
    const response = await bidlClient.post(
      `${baseUri}/config/v1/UpdateTenantConfig`,
      JSON.stringify(config),
      {
        headers: {
          'App-Company': slug,
          'Content-Type': 'application/json',
        },
      }
    )
    return [null, response.data]
  } catch (err) {
    return [err, null]
  }
}
