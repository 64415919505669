import React from 'react'
import { css } from '@emotion/react'
import { useQueryClient } from '@tanstack/react-query'
import * as Yup from 'yup'
import { Formik, Field, FormikProps } from 'formik'
import * as FormikElements from 'src/ui/formik'
import { usePositionMutations } from 'src/api/queries/locations'
import { Box, Row, Column, Button, Dialog, FormDialog, Panel, Table2, useBlockTable } from 'src/ui'
import { hasPermission } from 'src/utils/permissions'
import { useToast } from 'src/utils/toast'

const PositionsSchema = Yup.object().shape({
  display_name: Yup.string().required('Required'),
  remote_id: Yup.string().required('Required'),
})

interface FormFields {
  display_name: string
  remote_id: string
}

type PositionFormDialogProps = DialogProps & {
  location?: BushelLocation
  position?: Position
  savePosition: (
    args: {
      location: BushelLocation
      position: Position
      companyId: any
    },
    handlers: {
      onSuccess: (_res: any, args: any) => void
      onError: (_res: any, args: any) => void
    }
  ) => void
  company?: any
}

export const PositionFormDialog: React.FC<PositionFormDialogProps> = ({
  isOpen,
  onClose,
  location,
  position,
  savePosition,
  company,
}) => {
  let toast = useToast()
  const queryClient = useQueryClient()

  if (!position) return null

  return (
    <FormDialog
      title={position.id ? 'Edit Position' : 'Create Position'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Formik
        key={3}
        enableReinitialize
        initialValues={position}
        validationSchema={PositionsSchema}
        onSubmit={(values, formikBag) => {
          savePosition(
            {
              location,
              position: {
                ...position,
                ...values,
              },
              companyId: company.id,
            },
            {
              onSuccess: async (_res, { position }) => {
                await queryClient.invalidateQueries({
                  queryKey: ['company-positions', { companySlug: company.slug }],
                })
                toast({
                  description: `Successfully ${position.id ? 'updated' : 'created'} position`,
                })
                formikBag.setSubmitting(false)
                onClose()
              },
              onError: (_res, { position }) => {
                toast({
                  status: 'error',
                  description: `Failed to ${position.id ? 'update' : 'create'} position`,
                })
                formikBag.setSubmitting(false)
              },
            }
          )
        }}
      >
        {(formikBag: FormikProps<FormFields>) => (
          <>
            <FormDialog.Body>
              <Column>
                <Field
                  label="Display Name"
                  name="display_name"
                  component={FormikElements.TextField}
                />
                <Field label="Remote ID" name="remote_id" component={FormikElements.TextField} />
              </Column>
            </FormDialog.Body>

            <FormDialog.Footer>
              <Button
                size="sm"
                variant="ghost"
                mr={2}
                onClick={() => {
                  formikBag.resetForm()
                  onClose()
                }}
              >
                Cancel
              </Button>

              <Button
                type="submit"
                size="sm"
                colorScheme="primary"
                isLoading={formikBag.isSubmitting}
                onClick={() => formikBag.submitForm()}
              >
                Save
              </Button>
            </FormDialog.Footer>
          </>
        )}
      </Formik>
    </FormDialog>
  )
}

const DeleteDialog: React.FC<DialogProps & { position: Position }> = ({
  isOpen,
  onClose,
  onConfirm,
  position,
}) => {
  let [isLoading, setIsLoading] = React.useState(false)

  if (!position) return null

  return (
    <Dialog
      title="Delete Position"
      isOpen={isOpen}
      onClose={onClose}
      actions={
        <>
          <Button size="sm" variant="ghost" mr={2} onClick={onClose}>
            Cancel
          </Button>

          <Button
            size="sm"
            colorScheme="primary"
            isLoading={isLoading}
            onClick={async () => {
              setIsLoading(true)
              await onConfirm()
              setIsLoading(false)
            }}
          >
            Delete
          </Button>
        </>
      }
    >
      <Column>
        <Box>
          <Box>Are you sure you want to delete this position?</Box>
          <ul>
            <li>{position.display_name}</li>
          </ul>
        </Box>
      </Column>
    </Dialog>
  )
}

export const LocationPositions = ({ elevator, positions, isEditing = false, company }) => {
  let { savePosition, deletePosition } = usePositionMutations()
  let [isExpanded, setIsExpanded] = React.useState(false)
  let [formDialog, setFormDialog] = React.useState(null)
  let [deleteDialog, setDeleteDialog] = React.useState(null)
  let toast = useToast()
  const queryClient = useQueryClient()

  React.useEffect(() => {
    if (isEditing) setIsExpanded(true)
  }, [isEditing])

  let columns = React.useMemo(
    () => [
      {
        id: 'display_name',
        accessor: 'display_name',
        Header: 'Display Name',
        width: 200,
        minWidth: 200,
      },
      {
        id: 'remote_id',
        accessor: 'remote_id',
        Header: 'Remote ID',
        width: 200,
        minWidth: 200,
      },
      {
        id: '_actions',
        Header: '',
        accessor: '_actions',
        disableSortBy: true,
        isEditing,
        Cell: ({ row, column }) => {
          // if (!column.isEditing) return null

          let position = row.original

          return (
            <>
              {hasPermission('company_positions_edit') && (
                <Row
                  width="100%"
                  justifyContent="flex-end"
                  alignItems="center"
                  css={{ visibility: column.isEditing ? 'visible' : 'hidden' }}
                >
                  <Button
                    size="sm"
                    ml="1px"
                    variant="ghost"
                    color="gray.500"
                    aria-label="Delete Position"
                    onClick={() => setDeleteDialog({ position })}
                  >
                    Delete
                  </Button>

                  <Button
                    size="sm"
                    ml={2}
                    variant="ghost"
                    color="primary.500"
                    aria-label="Edit Position"
                    onClick={() => setFormDialog({ position })}
                  >
                    Edit
                  </Button>
                </Row>
              )}
            </>
          )
        },
      },
    ],
    [isEditing]
  )

  let {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    // page,
    // pagination,
    // totalCount,
  } = useBlockTable({
    data: positions,

    tableOptions: {
      initialState: {
        sortBy: [{ id: 'display_name' }],
      },
    },

    columns,
  })

  return (
    <>
      <Panel
        expanded={isExpanded}
        panelProps={{ px: null }}
        headerProps={{ px: 6 }}
        onChange={(e, expanded) => {
          if (!isEditing) setIsExpanded(expanded)
        }}
        header={
          <Row width="100%" justifyContent="space-between" alignItems="center">
            <Box>Positions ({positions.length})</Box>
            {isEditing && (
              <Box>
                <Button
                  size="sm"
                  width={70}
                  colorScheme="primary"
                  onClick={() => setFormDialog({ position: { display_name: '', remote_id: '' } })}
                >
                  Add
                </Button>
              </Box>
            )}
          </Row>
        }
      >
        {rows.length > 0 && (
          <Table2.Block
            {...getTableProps()}
            striped
            styles={css`
              .tr {
                width: 100% !important;
              }

              .tr .td:nth-of-type(1),
              .tr .th:nth-of-type(1) {
                padding-left: 1.5rem; /* padding: 6 */
              }

              .tr .td:nth-of-type(3),
              .tr .th:nth-of-type(3) {
                flex: 1 1 auto;
                padding-right: 1.5rem; /* padding: 6 */
              }

              .tr .td:nth-of-type(2) {
                flex: 2 0 auto;
              }

              .th {
                height: 40px;
                padding: 0;
              }

              .td {
                height: 50px;
                padding: 0;
              }
            `}
          >
            <Table2.BlockHeader headerGroups={headerGroups} />
            <Table2.BlockBody
              isLoading={false}
              rows={rows}
              getTableBodyProps={getTableBodyProps}
              prepareRow={prepareRow}
            />
          </Table2.Block>
        )}

        {rows.length === 0 && (
          <Box px={6} fontSize="sm" color="gray.500">
            No positions configured for this location
          </Box>
        )}
      </Panel>

      <PositionFormDialog
        isOpen={!!formDialog}
        onClose={() => setFormDialog(null)}
        onConfirm={() => null}
        location={elevator}
        position={formDialog ? formDialog.position : null}
        savePosition={savePosition}
        company={company}
      />

      <DeleteDialog
        isOpen={!!deleteDialog}
        position={deleteDialog ? deleteDialog.position : null}
        onClose={() => setDeleteDialog(null)}
        onConfirm={() =>
          deletePosition(
            {
              position: deleteDialog.position,
            },
            {
              onSuccess: async () => {
                await queryClient.invalidateQueries({
                  queryKey: ['company-positions', { companySlug: company.slug }],
                })
                toast({ description: 'Successfully deleted position' })
                setDeleteDialog(null)
              },
              onError: () => {
                toast({ status: 'error', description: 'Failed to delete position' })
              },
            }
          )
        }
      />
    </>
  )
}
