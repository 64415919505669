import { ICentreTranslator } from 'src/api/api'
import { sequence } from './utils'

export const translators: ICentreTranslator[] = [] //initialized when companies are created in './companies'

export function createTranslator(values: Partial<ICentreTranslator>): ICentreTranslator {
  return {
    authentication_alternate_fields: null,
    authentication_alternate_identifier: null,
    authentication_auto_email: false,
    authentication_auto_phone: false,
    bulk_contract_feature_version: null,
    bulk_split_feature_version: null,
    bulk_ticket_feature_version: null,
    commodity_balance_feature_version: null,
    company_feature_version: null,
    company_user_feature_version: '1.0.0',
    created_at: '2022-08-18T03:12:52+00:00',
    deleted_at: null,
    elevator_feature_version: null,
    id: 1,
    landing_feature: null,
    shared_key: '1234',
    ssl_ca_store: null,
    updated_at: '2022-11-02T22:59:28+00:00',
    url: 'https://bushelpowered.com',
    verification_feature_version: '1.0.2',
    _revision: `fake-revision-${sequence()}`,
    ...values,
  }
}
