import React, { useState, useEffect, useRef } from 'react'
import {
  Switch as ChakraSwitch,
  Checkbox as ChakraCheckbox,
  Box,
  BoxProps,
  Image,
} from '@chakra-ui/react'
import { get, omit } from 'lodash'
import { Field, FieldProps, useField } from 'formik'
import JsonEditorBase from 'src/translators/json-editor'
import { FormControl, FormLabel, FormErrorMessage, Input, Textarea } from 'src/ui/form-controls'
import { Row, Column, InputGroup, Button, DeleteIcon } from 'src/ui'
import SelectBase from 'src/ui/select'
import { css } from '@emotion/react'

type TextFieldProps = {
  [key: string]: any
}
export const TextField: React.FC<TextFieldProps> = ({
  label,
  type = 'input',
  InputProps: inputProps = {},
  formControlProps = {},
  field,
  form,
  disabled = false,
  leftElement,
  rightElement,
  id,
  placeholder,
}) => {
  let isTouched = true === get(form.touched, field.name)
  let errorMessage = get(form.errors, field.name)
  let hasError = (isTouched || form.submitCount > 0) && !!errorMessage

  // inputs can't have null|undef values
  field.value = field.value || ''

  return (
    <FormControl isInvalid={hasError} {...formControlProps}>
      {label && (
        <FormLabel
          fontSize="sm"
          color="gray.500"
          htmlFor={id || field.name}
          title={field.name}
          css={{
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingRight: 0,
          }}
        >
          {label}
        </FormLabel>
      )}

      <InputGroup size="sm">
        {leftElement}
        {rightElement}

        <Input
          id={id || field.name}
          name={field.name}
          type={type}
          placeholder={placeholder}
          value={field.value || ''}
          isDisabled={disabled}
          onBlur={field.onBlur}
          onChange={field.onChange}
          {...inputProps}
        />
      </InputGroup>

      <FormErrorMessage fontSize="sm">{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

////////////////////////////////////////////////////////////////

type TextAreaProps = {
  [key: string]: any
}
export const TextAreaField: React.FC<TextAreaProps> = ({
  label,
  type = 'input',
  InputProps: inputProps = {},
  formControlProps = {},
  field,
  form,
  disabled = false,
  id,
  placeholder,
}) => {
  let isTouched = true === get(form.touched, field.name)
  let errorMessage = get(form.errors, field.name)
  let hasError = (isTouched || form.submitCount > 0) && !!errorMessage

  // inputs can't have null|undef values
  field.value = field.value || ''

  return (
    <FormControl isInvalid={hasError} {...formControlProps}>
      {label && (
        <FormLabel
          fontSize="sm"
          color="gray.500"
          htmlFor={id || field.name}
          title={field.name}
          css={{
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingRight: 0,
          }}
        >
          {label}
        </FormLabel>
      )}
      <Textarea
        id={id || field.name}
        name={field.name}
        type={type}
        size="sm"
        placeholder={placeholder}
        value={field.value || ''}
        isDisabled={disabled}
        onBlur={field.onBlur}
        onChange={field.onChange}
        {...inputProps}
      />
      <FormErrorMessage fontSize="sm">{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

///////////////////////////////////////////////////////////////////////////////

interface SelectProps {
  field: any
  form: any
  id: string
  label?: string
  placeholder?: string
  options: {
    value: string
    label: string
  }[]
  disabled?: boolean
  formControlProps?: object
  isCreateable?: boolean
  isLoading?: boolean
  selectProps?: object
  onChange?: (item: object) => void
}

export const Select: React.FC<SelectProps> = ({
  field,
  form,
  id,
  label,
  options = [],
  disabled,
  placeholder,
  formControlProps = {},
  isCreateable = false,
  isLoading = false,
  selectProps = {},
  onChange,
}) => {
  let isTouched = true === get(form.touched, field.name)
  let errorMessage = get(form.errors, field.name)
  let hasError = (isTouched || form.submitCount > 0) && !!errorMessage
  const { isMulti = false } = selectProps as { isMulti?: boolean }

  return (
    <FormControl isInvalid={hasError} {...formControlProps}>
      {label && (
        <FormLabel
          fontSize="sm"
          color="gray.500"
          htmlFor={id || field.name}
          title={field.name}
          css={{
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingRight: 0,
          }}
        >
          {label}
        </FormLabel>
      )}

      <SelectBase
        id={id}
        name={field.name}
        value={field.value}
        options={options}
        placeholder={placeholder}
        hasError={hasError}
        isDisabled={disabled}
        isCreatable={isCreateable}
        isLoading={isLoading}
        isMulti={isMulti}
        onBlur={field.onBlur}
        onChange={(item) => {
          if (onChange) onChange(item) // allow custom onChange as well
          if (isMulti) {
            const selectedValues = item ? item.map((opt: { value: string }) => opt.value) : []
            form.setFieldValue(field.name, selectedValues)
          } else {
            form.setFieldValue(field.name, item ? item.value : null)
          }
        }}
        {...selectProps}
      />

      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}

///////////////////////////////////////////////////////////////////////////////

type JsonEditorProps = FieldProps & {
  label?: string
  height?: number
}

export const JsonEditor: React.FC<JsonEditorProps> = ({ label, field, form, ...rest }) => (
  <Column>
    {label && (
      <FormLabel htmlFor={field.name} fontSize="sm" color="gray.500">
        {label}
      </FormLabel>
    )}

    <JsonEditorBase
      value={field.value || null}
      onChange={(value) => form.setFieldValue(field.name, value)}
      {...rest}
    />
  </Column>
)

///////////////////////////////////////////////////////////////////////////////

type SwitchProps = FieldProps & {
  id?: string
  label?: string
  timed?: boolean
  disabled?: boolean
  formControlProps: BoxProps
  SwitchBoxProps: BoxProps
}

export const Switch: React.FC<SwitchProps> = ({
  field,
  form,
  id,
  label,
  timed,
  disabled,
  formControlProps = {},
  SwitchBoxProps: switchBoxProps = {},
}) => (
  <Row justifyContent="center" alignItems="center" {...formControlProps}>
    {label && (
      <FormLabel fontSize="sm" color="gray.500" htmlFor={id || field.name}>
        {label}
      </FormLabel>
    )}

    <Box {...switchBoxProps}>
      <ChakraSwitch
        id={id || field.name}
        isChecked={field.value || false}
        isDisabled={disabled}
        children={null}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          let offValue = timed ? null : e.target.checked
          return form.setFieldValue(
            field.name,
            e.target.checked && timed ? new Date().toISOString() : offValue
          )
        }}
      />
    </Box>
  </Row>
)

///////////////////////////////////////////////////////////////////////////////

type CheckboxProps = FieldProps & {
  label?: string
}

export const Checkbox: React.FC<CheckboxProps> = ({ field, form, label }) => (
  <ChakraCheckbox
    isChecked={field.value}
    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
      form.setFieldValue(field.name, e.target.checked)
    }
  >
    {label}
  </ChakraCheckbox>
)

///////////////////////////////////////////////////////////////////////////////

export const FileInput = (props) => {
  const fieldName = props.field.name
  const form = props.form
  const [field, , { setValue }] = useField(fieldName)
  const [file, setFile] = useState(field.value?.file)
  const [src, setSrc] = useState(field.value?.src)
  const [url, setURL] = useState(props.imageURL)
  const imageInput = useRef<HTMLInputElement>()
  const errorMessage = get(form.errors, field.name)

  const clearField = () => {
    setURL(undefined)
    setSrc(undefined)
    setFile(undefined)
    if (props.imageURL) {
      form.setValues({ ...form.values, [fieldName]: { value: null } })
    } else {
      form.setValues(omit(form.values, [fieldName]))
    }
  }

  const _onChange = (e) => {
    let reader = new FileReader()
    let file = e.target.files[0]
    if (file) {
      reader.onloadend = () => {
        setSrc(reader)
      }
      reader.readAsDataURL(file)
      setFile(file)
    } else {
      clearField()
    }
  }

  useEffect(() => {
    if (file && src?.result) {
      setValue({
        value: {
          mimeType: file.type,
          imageStringBase64: src.result.toString().substring(src.result.indexOf(',') + 1),
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, file])

  return (
    <React.Fragment>
      <FormControl h="max-content" maxW="514px" isInvalid={Boolean(props.form.errors[fieldName])}>
        <FormLabel title={field.name} htmlFor={field.name}>
          {props.label}
        </FormLabel>
        <Box
          display="inline-flex"
          alignItems="center"
          justifyContent="space-between"
          border="1px solid #E2E8F0"
          borderRadius="sm"
          w="100%"
          mb={3}
          h="150px"
        >
          <Field
            field={field}
            label={props.label}
            forwardRef={imageInput}
            component={UploadField}
            onChange={_onChange}
          />
          {((file && !!src) || url) && (
            <Button
              position="absolute"
              ml="120px"
              fontSize="sm"
              color="blue.500"
              variant="outline"
              bg="white"
              borderColor="blue.500"
              borderRadius="4px"
              px="8px"
              onClick={() => {
                imageInput.current.value = null
                clearField()
              }}
            >
              <DeleteIcon mr={1} />
              Delete Image
            </Button>
          )}
          <Box h="100%" display="flex" justifyContent="center" alignItems="center" w="100%" p={4}>
            {(file && !!src) || url ? (
              <Image maxH="100%" src={src?.result || url} alt={fieldName} />
            ) : (
              <Box color="gray">null</Box>
            )}
          </Box>
        </Box>
        <FormErrorMessage fontSize="sm">Error: {errorMessage}</FormErrorMessage>
      </FormControl>
    </React.Fragment>
  )
}

const UploadField = ({ label, forwardRef, ...props }) => {
  return (
    <Box
      pl="3"
      css={css`
        input::file-selector-button {
          color: white;
          font-size: 14px;
          line-height: 16px;
          padding: 12px;
          background: #607886;
          border: none;
          border-radius: 4px;
          font-weight: 500;
          margin-right: 8px;
          cursor: pointer;
        }
      `}
    >
      <Field
        name="uploader"
        title={label}
        accept="image/png"
        type="file"
        innerRef={forwardRef}
        style={{
          fontSize: '14px',
          color: '#8C9BA5',
          width: '245px',
        }}
        {...props}
      />
    </Box>
  )
}
