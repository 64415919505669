import { sequence } from './utils'
import { IPosition } from 'src/api/api'

export const positions: IPosition[] = []

export function createPosition(values: Partial<IPosition> = {}): IPosition {
  const id = sequence()
  const company_id = values.company_id ?? id
  const remote_id = values.remote_id ?? `remote_id-${id}`
  const remote_elevator_id = values.remote_elevator_id ?? `remote_elevator_id-${id}`
  return {
    id,
    company_id,
    display_name: `display-name-${id}`,
    remote_elevator_id,
    remote_id,
    _revision: `fakeRevision-${id}`,
    company_id_remote_elevator_id: `${company_id}-${remote_elevator_id}`,
    company_id_remote_id: `${company_id}-${remote_id}`,
    created_at: '2022-05-13T14:58:03+00:00',
    updated_at: '2022-05-13T14:58:03+00:00',
    ...values,
  }
}
