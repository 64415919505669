import React from 'react'
import { css } from '@emotion/react'
import useComponentSize from '@rehooks/component-size'
import { useDropzone } from 'react-dropzone'
import parse from 'csv-parse/lib/sync'
import { Dispatch } from 'src/store'
import {
  Button,
  Box,
  Row,
  Column,
  Card,
  CardContent,
  AttachmentIcon,
  Alert,
  AlertDescription,
} from 'src/ui'
import { DataGrid } from './csv-table'

const locationKey = () =>
  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

const CsvLoader = ({ setState, errorNotification }) => {
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      let reader = new FileReader()

      reader.onabort = () => errorNotification({ message: 'Failed to load file' })
      reader.onerror = () => errorNotification({ message: 'Failed to load file' })
      reader.onload = () => {
        let textResult = reader.result as string
        let records = []

        try {
          records = parse(textResult, { columns: true, skip_empty_lines: true })
        } catch (err) {
          errorNotification({
            message: err.toString(),
            options: {
              // keep it around longer than default 5s
              autoHideDuration: 10 * 1000,
            },
          })
          return
        }

        let locations = records.map((record, index) => {
          return Object.keys(record).reduce(
            (acc, key) => ({ ...acc, [key.trim()]: record[key], locationKey: locationKey() }),
            {
              index,
            }
          )
        })

        let headers = Object.keys(records[0]).reduce(
          (acc, key) => ({ ...acc, [key.trim()]: null }),
          {
            actions: null,
          }
        )

        setState((state) => ({
          ...state,
          locations,
          headers,
        }))
      }

      acceptedFiles.forEach((file) => reader.readAsText(file))
    },
    [errorNotification, setState]
  )

  let { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <Row justifyContent="center" py={1}>
      <div
        {...getRootProps({
          css: css({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 250,
            borderRadius: 4,
            fontWeight: 500,
            color: '#627D98',
            cursor: false ? 'wait' : 'pointer',
            pointerEvents: false ? 'none' : 'auto',

            backgroundColor: isDragActive ? 'rgba(0,0,0,0.04)' : 'inherit',
            padding: 8,
          }),
        })}
      >
        <Row justifyContent="center" mb={1}>
          <Box height="3.5rem">
            {isDragActive && (
              <AttachmentIcon w="2.75em" h="2.75em" css={{ transform: 'rotate(-15deg)' }} />
            )}
            {!isDragActive && <AttachmentIcon w="2.75em" h="2.75em" />}
          </Box>
        </Row>

        <input {...getInputProps()} />

        {isDragActive ? <Box>Drop CSV File</Box> : <Box>Load CSV File</Box>}
      </div>
    </Row>
  )
}

interface LoadFromCsvProps {
  bulkSaveLocations: any
  company: Company
  errorNotification: Dispatch['notifications']['createError']
  successNotification: Dispatch['notifications']['createSuccess']
}

function useCsvData({ company, bulkSaveLocations }) {
  let [{ isLoading, locations, headers, invalidHeaderKeys }, setState] = React.useState({
    invalidHeaderKeys: [],
    isLoading: false,
    locations: [],
    headers: {},
  })

  const updateRow = ({ rowIndex, colKey, value }) => {
    setState((state) => ({
      ...state,
      locations: state.locations.map((location) => {
        if (location.index !== rowIndex) return location
        return {
          ...location,
          [colKey]: value,
        }
      }),
    }))
  }

  const deleteRow = (keyToDelete) => {
    setState((state) => ({
      ...state,
      locations: state.locations.filter((location) => location.locationKey !== keyToDelete),
    }))
  }

  const createRequestBody = ({ locations, headers }) => {
    return locations.map((location) => {
      return Object.keys(headers).reduce(
        (acc, origKey) => {
          if (!origKey || !headers[origKey]) return acc

          let apiKey = headers[origKey]

          return {
            ...acc,
            [apiKey]: location[origKey],
          }
        },
        { company_id: company.id }
      )
    })
  }

  const saveLocations = async () => {
    const invalidHeaderKeys = Object.keys(headers).filter(
      (key) => headers[key] === null && key !== 'actions'
    )

    if (invalidHeaderKeys.length > 0) {
      setState((state) => ({ ...state, invalidHeaderKeys: invalidHeaderKeys }))
      return
    }

    setState((state) => ({
      ...state,
      invalidHeaderKeys: [],
      isLoading: true,
    }))

    // // error/success handling handled in parent
    let [err] = await bulkSaveLocations({
      locations: createRequestBody({ locations, headers }),
    })
    if (err) {
      // if no error, this component will be unmounted, so cannot update state of unmounted component
      setState((state) => ({ ...state, isLoading: false }))
    }
  }

  return {
    setState,
    isLoading,
    locations,
    headers,
    invalidHeaderKeys,
    resetState: () =>
      setState({ isLoading: false, locations: [], headers: {}, invalidHeaderKeys: [] }),
    updateRow,
    deleteRow,
    saveLocations,
  }
}

const LoadFromCsv: React.FC<LoadFromCsvProps> = ({
  bulkSaveLocations,
  company,
  errorNotification,
}) => {
  let {
    setState,
    isLoading,
    locations,
    invalidHeaderKeys,
    headers,
    resetState,
    deleteRow,
    updateRow,
    saveLocations,
  } = useCsvData({ company, bulkSaveLocations })

  const ref = React.useRef<HTMLDivElement>(null)
  const refProp = useComponentSize(ref)
  return (
    <Card style={{ marginTop: 16, marginBottom: 16 }}>
      <CardContent>
        <Column ref={ref}>
          {locations.length === 0 && (
            <CsvLoader setState={setState} errorNotification={errorNotification} />
          )}

          {locations.length > 0 && (
            <Column>
              <Row justifyContent="flex-end" pb={3}>
                {invalidHeaderKeys.length > 0 ? (
                  <Alert status="error" py={0} mr={2}>
                    <AlertDescription>
                      Please delete or assign header values to the following columns
                    </AlertDescription>
                  </Alert>
                ) : null}
                <Box pr={2}>
                  <Button
                    width="76px"
                    size="sm"
                    colorScheme="secondary"
                    mr={2}
                    onClick={resetState}
                  >
                    Cancel
                  </Button>
                  <Button
                    width="76px"
                    size="sm"
                    colorScheme="primary"
                    isLoading={isLoading}
                    onClick={saveLocations}
                  >
                    Save
                  </Button>
                </Box>
              </Row>
              <Row>
                <Box>
                  <DataGrid
                    headers={headers}
                    locations={locations}
                    invalidHeaderKeys={invalidHeaderKeys}
                    setState={setState}
                    updateRow={updateRow}
                    deleteRow={deleteRow}
                    size={refProp}
                  />
                </Box>
              </Row>
            </Column>
          )}
        </Column>
      </CardContent>
    </Card>
  )
}

export default LoadFromCsv
