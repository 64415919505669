import { centreAPIs } from 'src/utils/api'
import { getEnvironment } from 'src/utils'
import { ICommodity } from 'src/api/api'
import { UseToastOptions } from '@chakra-ui/react'

const COPY_EXCLUDED_KEYS = ['_revision', 'id', 'company_id', 'company_id_remote_id']

type CopyContext = {
  commodities: ICommodity[]
  copyId: ICommodity['id']
}

export const loadCopyDiff = async ({
  company,
  context,
  toast,
}: {
  company: Company
  context: CopyContext
  toast: (args: UseToastOptions) => void
}) => {
  let { commodities, copyId } = context
  const destinationEnvironment = getEnvironment() === 'production' ? 'UAT' : 'Production'

  let data = {
    source: {},
    dest: {},
  }

  data.source = Object.assign(
    {},
    commodities.filter((commodity) => {
      return commodity.id === copyId
    })[0]
  )
  COPY_EXCLUDED_KEYS.forEach((key) => delete data.source[key])

  let [err, _targetCompany] = await centreAPIs.otherEnvironment.getCentreCompanyBySlug({
    slug: company.slug,
  })

  if (err) {
    toast({
      status: 'error',
      description: `The Company does not exist in ${destinationEnvironment}`,
    })
  }

  if (!err) {
    let [err, destCommodities] = await centreAPIs.otherEnvironment.getCommodities({
      companySlug: company.slug,
    })

    if (err) {
      toast({
        status: 'error',
        description: `Cannot get Commodities for Company in ${destinationEnvironment}`,
      })
    }

    if (!err) {
      const destCommodity = destCommodities.data.data.filter((commodity) => {
        return commodity.remote_id === data.source['remote_id']
      })[0]

      if (destCommodity) {
        data.dest = Object.assign({}, destCommodity)
        COPY_EXCLUDED_KEYS.forEach((key) => delete data.dest[key])
      }
    }
  }

  return data
}

export const executeCopy = async ({
  company,
  context,
  toast,
}: {
  company: Company
  context: CopyContext
  toast: (args: UseToastOptions) => void
}) => {
  let { commodities, copyId } = context
  const destinationEnvironment = getEnvironment() === 'production' ? 'UAT' : 'Production'

  const data = {
    source: {},
    dest: {},
  }

  data.source = Object.assign(
    {},
    commodities.filter((commodity) => {
      return commodity.id === copyId
    })[0]
  )

  let [destCompanyErr, destCompany] = await centreAPIs.otherEnvironment.getCentreCompanyBySlug({
    slug: company.slug,
  })

  if (!destCompanyErr) {
    let [destCommoditiesErr, destCommodities] = await centreAPIs.otherEnvironment.getCommodities({
      companySlug: destCompany.slug,
    })

    if (!destCommoditiesErr) {
      const destCommodity = destCommodities.data.data.filter((commodity) => {
        return commodity.remote_id === data.source['remote_id']
      })[0]

      if (destCommodity) {
        data.dest = Object.assign({}, destCommodity)
        COPY_EXCLUDED_KEYS.forEach((key) => (data.source[key] = data.dest[key]))
      }
    }
  }

  let [err, commodity] = [null, null]

  // send data to api endpoint
  if ('id' in data.dest) {
    // elevator exists and needs to be updated
    ;[err, commodity] = await centreAPIs.otherEnvironment.updateCommodity({
      id: data.source['id'],
      _revision: data.source['_revision'],
      remote_id: data.source['remote_id'],
      company_id: data.source['company_id'],
      display_name: data.source['display_name'],
    })
  } else {
    // elevator needs to be created
    data.source['company_id'] = destCompany.id
    ;[err, commodity] = await centreAPIs.otherEnvironment.createCommodity({
      remote_id: data.source['remote_id'],
      company_id: data.source['company_id'],
      display_name: data.source['display_name'],
    })
  }

  if (err) {
    toast({
      status: 'error',
      description: 'Failed to copy the commodity, please contact the adminstrator.',
    })
    return []
  }

  toast({
    description: `Copied commodity to ${destinationEnvironment}`,
  })

  return [err, commodity]
}
