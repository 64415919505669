import React, { useState } from 'react'
import { Button } from '@chakra-ui/react'
import { Formik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'
import ReactDiffViewer from 'react-diff-viewer'
import { Box, Row, Column, Dialog, FormDialog } from 'src/ui'
import { TextField as FormikTextField } from 'src/ui/formik'
import { getEnvironment } from 'src/utils'
import { IS_DEV_OR_SANDBOX } from 'src/utils/config'
import { convertEmptyStringsToNull } from 'src/companies/configurations/utils'
import { useFlagr } from 'src/utils/flagr'

const CompanySchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  slug: Yup.string()
    .strict(true)
    .required('Required')
    .lowercase('Must be lowercase')
    .matches(/^[a-zA-Z0-9]+$/, 'May only contain alphanumeric characters'),
  parent_slug: Yup.string()
    .strict(true)
    .lowercase('Must be lowercase')
    .matches(/^[a-zA-Z0-9]+$/, 'May only contain alphanumeric characters'),
})

type CreateCompanyDialogProps = DialogProps & {
  company?: any
}

export function CreateCompanyDialog({
  isOpen,
  onClose,
  onConfirm,
  company,
}: CreateCompanyDialogProps) {
  const [, enabled] = useFlagr('bap_show_parent_slug_on_add_company_dialog')

  return (
    <FormDialog title="Create Company" isOpen={isOpen} onClose={onClose}>
      <Formik
        enableReinitialize
        initialValues={{
          name: company?.name ?? '',
          slug: company?.slug ?? '',
        }}
        validationSchema={CompanySchema}
        onSubmit={(values) => onConfirm(convertEmptyStringsToNull(values))}
      >
        {(formikBag: FormikProps<any>) => (
          <>
            <FormDialog.Body>
              <Form>
                <Column>
                  <Field name="name" label="Name" component={FormikTextField} />
                  <Field name="slug" label="Slug" component={FormikTextField} />
                  {enabled && (
                    <Field name="parent_slug" label="Parent Slug" component={FormikTextField} />
                  )}
                </Column>
              </Form>
            </FormDialog.Body>

            <FormDialog.Footer>
              <Button
                size="sm"
                variant="ghost"
                mr={2}
                onClick={() => {
                  formikBag.resetForm()
                  onClose()
                }}
              >
                Cancel
              </Button>

              <Button
                size="sm"
                colorScheme="primary"
                type="submit"
                isLoading={formikBag.isSubmitting}
                onClick={() => formikBag.submitForm()}
              >
                Create
              </Button>
            </FormDialog.Footer>
          </>
        )}
      </Formik>
    </FormDialog>
  )
}

///////////////////////////////////////////////////////////////////////////////

const maybeStringify = (value: string | object) =>
  typeof value === 'object' ? JSON.stringify(value, null, 2) : value

interface DiffDialogProps extends DialogProps {
  title?: string
  oldValue?: object
  newValue?: object
  message?: string | React.ReactNode
  errorMessage?: string
  maxW?: string
}

export function DiffDialog({
  title = 'Compare Changes',
  isOpen = false,
  oldValue = null,
  newValue = null,
  errorMessage = null,
  onClose,
  onConfirm,
}: DiffDialogProps) {
  const [confirming, setConfirming] = useState<boolean>(false)
  const [copying, setCopying] = useState<boolean>(false)

  const handleClose = () => {
    onClose()
    setConfirming(false)
  }

  const handleConfirm = async () => {
    setCopying(true)
    await onConfirm()
    handleClose()
    setCopying(false)
  }

  return (
    <Dialog
      title={title}
      isOpen={isOpen}
      maxW="100%"
      onClose={handleClose}
      actions={
        !errorMessage ? (
          <>
            <Button size="sm" variant="ghost" mr={2} isDisabled={copying} onClick={handleClose}>
              Cancel
            </Button>

            {!confirming && (
              <Button
                size="sm"
                colorScheme="primary"
                isDisabled={IS_DEV_OR_SANDBOX}
                onClick={() => setConfirming(true)}
              >
                Copy
              </Button>
            )}

            {confirming && (
              <Row alignItems="center">
                <Box pl={1} mr={4}>
                  Are you sure?
                </Box>

                <Button
                  type="button"
                  size="sm"
                  colorScheme="primary"
                  isLoading={copying}
                  isDisabled={IS_DEV_OR_SANDBOX}
                  onClick={handleConfirm}
                >
                  Copy
                </Button>
              </Row>
            )}
          </>
        ) : (
          <Button size="sm" variant="ghost" mr={2} isDisabled={copying} onClick={handleClose}>
            Cancel
          </Button>
        )
      }
    >
      {!errorMessage && (
        <Column
          css={{
            pre: {
              fontSize: 13,
            },
          }}
        >
          <Box mt={-4} pb={4}>
            Copying data from{' '}
            <strong>{getEnvironment() === 'production' ? 'Production' : 'UAT'}</strong> to{' '}
            <strong>{getEnvironment() === 'production' ? 'UAT' : 'Production'}</strong>.
          </Box>

          <Box
            css={{
              overflow: 'auto',

              'table tbody tr:first-of-type td': {
                background: 'transparent',
                border: 'none',
                padding: 4,

                pre: {
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  fontFamily: 'unset',
                  color: '#A0AEC0',
                  textTransform: 'uppercase',
                },
              },
            }}
          >
            <ReactDiffViewer
              oldValue={maybeStringify(oldValue)}
              newValue={maybeStringify(newValue)}
              leftTitle="Old Values"
              rightTitle="New Values"
              splitView
              showDiffOnly={false}
              styles={{
                line: {
                  whiteSpace: 'normal',
                },
              }}
            />
          </Box>
        </Column>
      )}
      <Box mt={-4} pb={4}>
        {errorMessage}
      </Box>
    </Dialog>
  )
}
