import React, { useEffect, useState } from 'react'
import {
  Box,
  Row,
  Button,
  CheckCircleIcon,
  IconButton,
  InfoOutlineIcon,
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  PopoverArrow,
} from 'src/ui'
import { KEYCLOAK, getAuthUsername, getAccessToken, getKeycloakLogoutUrl } from 'src/utils/auth'
import { getUserRoles } from 'src/utils/permissions'

export function UserInfoButton() {
  const [accessTokenExp, setAccessTokenExp] = useState(getAccessTokenExp())
  const [copyAccessTokenButtonText, setCopyAccessTokenButtonText] = useState('Copy')
  const [copyRefreshTokenButtonText, setCopyRefreshTokenButtonText] = useState('Copy')
  useEffect(() => {
    KEYCLOAK.onAuthRefreshSuccess = () => {
      console.log('refresh success, updating token expiration to ', getAccessTokenExp())
      setAccessTokenExp(getAccessTokenExp())
      setCopyAccessTokenButtonText('Copy')
    }
  }, [])

  return (
    <Popover
      onClose={() => {
        setCopyAccessTokenButtonText('Copy')
        setCopyRefreshTokenButtonText('Copy')
      }}
    >
      <PopoverTrigger>
        <IconButton aria-label="user info" colorScheme="white" icon={<InfoOutlineIcon />} />
      </PopoverTrigger>
      <PopoverContent w="244px" px={1}>
        <PopoverArrow />
        <PopoverHeader py={3}>
          <Box fontWeight="bold" mr={1} display="inline">
            Username:
          </Box>
          {getAuthUsername()}
        </PopoverHeader>
        {getUserRoles()?.includes('admin') && (
          <>
            <PopoverHeader py={3}>
              <Box fontWeight="bold">Keycloak Access Token:</Box>
              <Row display="flex" alignItems="center" py={3}>
                {`${getAccessToken()?.substring(0, 10)}...`}
                <Button
                  size="sm"
                  ml={4}
                  w={24}
                  disabled={!getAccessToken()}
                  onClick={() => {
                    navigator.clipboard.writeText(getAccessToken())
                    setCopyAccessTokenButtonText('Copied!')
                    setCopyRefreshTokenButtonText('Copy')
                  }}
                >
                  {copyAccessTokenButtonText}
                  {copyAccessTokenButtonText === 'Copied!' && (
                    <CheckCircleIcon color="green" ml={2} />
                  )}
                </Button>
              </Row>
              <Box fontWeight="bold" mr={1} display="inline">
                Token expires:
              </Box>
              {accessTokenExp}
            </PopoverHeader>
            <PopoverHeader py={3}>
              <Box fontWeight="bold">Keycloak Refresh Token:</Box>
              <Row display="flex" alignItems="center" py={3}>
                {`${KEYCLOAK.refreshToken?.substring(0, 10)}...`}
                <Button
                  size="sm"
                  ml={4}
                  w={24}
                  disabled={!KEYCLOAK.refreshToken}
                  onClick={() => {
                    navigator.clipboard.writeText(KEYCLOAK.refreshToken)
                    setCopyRefreshTokenButtonText('Copied!')
                    setCopyAccessTokenButtonText('Copy')
                  }}
                >
                  {copyRefreshTokenButtonText}
                  {copyRefreshTokenButtonText === 'Copied!' && (
                    <CheckCircleIcon color="green" ml={2} />
                  )}
                </Button>
              </Row>
              <Box fontWeight="bold" mr={1} display="inline">
                Token expires:
              </Box>
              {new Date(
                KEYCLOAK.refreshTokenParsed?.exp * 1000 + KEYCLOAK.timeSkew
              )?.toLocaleDateString()}
            </PopoverHeader>
          </>
        )}
        <PopoverHeader py={3}>
          <Box fontWeight="bold" mr={1} display="inline">
            Roles:
          </Box>
          {getUserRoles()?.join(', ') || 'N/A'}
        </PopoverHeader>
        <PopoverHeader py={3}>
          <Row justifyContent="center">
            <Button
              as="a"
              href={getKeycloakLogoutUrl()}
              size="sm"
              textTransform="uppercase"
              colorScheme="secondary"
            >
              Sign Out
            </Button>
          </Row>
        </PopoverHeader>
      </PopoverContent>
    </Popover>
  )
}

const getAccessTokenExp = () => {
  return new Date(KEYCLOAK.tokenParsed?.exp * 1000 + KEYCLOAK.timeSkew)?.toLocaleTimeString()
}
