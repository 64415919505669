import React, { PropsWithChildren } from 'react'
import { connect } from 'react-redux'
import { RootState, Dispatch } from 'src/store'
import { Box, Row } from 'src/ui'
import Sidebar from 'src/layout/sidebar'
import { Header } from 'src/layout/header'

type LayoutProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>

function Layout({
  children,
  settings,
  resetCompanies,
  setSettingsState,
}: PropsWithChildren<LayoutProps>) {
  return (
    <>
      <Header
        settings={settings}
        resetCompanies={resetCompanies}
        setSettingsState={setSettingsState}
      />

      <Row>
        <Sidebar />

        <Box
          flex={1}
          position="relative"
          height="calc(100vh - 60px)"
          mt="60px"
          overflow="scroll"
          bg="#E5E5E5"
        >
          <Box width={['100%', '87.5%']} maxWidth={1092} mt="1.5rem" mx="auto">
            {children}
          </Box>
        </Box>
      </Row>
    </>
  )
}

const mapState = ({ settings }: RootState) => ({ settings })
const mapDispatch = ({ settings, companies }: Dispatch) => ({
  setSettingsState: settings.setState,
  setCompanySlug: settings.setCompanySlug,
  resetCompanies: companies.reset,
})

export default connect(mapState, mapDispatch)(Layout)
