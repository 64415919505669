import React from 'react'
import { Link, Route } from 'react-router-dom'
import { filter, flow, sortBy, uniqBy } from 'lodash/fp'
import { hasPermission } from 'src/utils/permissions'
import { AuthRoute, FlexRoutes } from 'src/routes'
import * as queries from 'src/utils/queries'
import { IPosition } from 'src/api/api'
import { useCopyDiff } from 'src/companies/copy-utils'
import CompanyPositionForm from 'src/companies/positions/position-form'
import { Box, Button, Placeholders, Row, Table2, useTable } from 'src/ui'
import { getEnvironment } from 'src/utils'
import { IS_DEV } from 'src/utils/config'
import { executeCopy, loadCopyDiff } from 'src/companies/positions/copy'
import { useLocations } from 'src/api/queries/locations'
import { usePositions } from 'src/api/queries/positions'

interface CompanyPositionsProps {
  company: Company
  setCopyDialog: ({ oldValue, newValue, onConfirm }) => void
}

const PositionsHeader = ({ company, showAdd = true }) => (
  <Row justifyContent="flex-end" mb={3} height={8}>
    <Box>
      {showAdd && (
        <Button
          size="sm"
          as={Link}
          to={`/companies/${getEnvironment()}/${company.slug}/positions/new`}
          type="button"
          colorScheme="primary"
          isDisabled={!hasPermission('company_positions_edit')}
        >
          ADD
        </Button>
      )}
    </Box>
  </Row>
)

const CompanyPositions: React.FC<CompanyPositionsProps> = ({ company, setCopyDialog }) => {
  let locationsQuery = useLocations({ companySlug: company.slug })
  let positionsQuery = usePositions({ companySlug: company.slug })

  let locations = locationsQuery?.data
  let positions = positionsQuery?.data

  let elevatorsPerPosition = (remoteId: IPosition['remote_id']): number => {
    const positionGroup = filter<IPosition>({ remote_id: remoteId }, positions)
    return uniqBy<IPosition>('remote_elevator_id', positionGroup).length
  }

  let uniquePositions = flow([
    uniqBy<IPosition>((p) => `${p.remote_id}-${p.display_name}`),
    sortBy<IPosition>((p) => (p.display_name ? p.display_name.toLowerCase() : '')),
  ])(positions)

  let { startCopy } = useCopyDiff({
    company,
    loadCopyDiff,
    executeCopy,
    setCopyDialog,
    context: {
      positions: uniquePositions,
    },
  })

  if (queries.areAnyLoading(locationsQuery, positionsQuery)) return <Placeholders.LoadingState />
  if (queries.areAnyFailed(locationsQuery, positionsQuery)) {
    return <Placeholders.FailedState message="Unable to load positions" />
  }

  return (
    <>
      <FlexRoutes>
        <Route
          path="/:id/edit"
          element={
            <AuthRoute
              as={CompanyPositionForm}
              positions={positions}
              locations={locations}
              company={company}
              header={<PositionsHeader company={company} showAdd={false} />}
            />
          }
        />
        <Route
          path="/new"
          element={
            <AuthRoute
              as={CompanyPositionForm}
              positions={positions}
              locations={locations}
              company={company}
              header={<PositionsHeader company={company} showAdd={false} />}
            />
          }
        />
        {positions && (
          <Route
            path="*"
            element={
              <AuthRoute
                as={PositionsTable}
                positions={uniquePositions.map((position: IPosition) => ({
                  id: position.id,
                  display_name: position.display_name,
                  remote_id: position.remote_id,
                  location_count: elevatorsPerPosition(position.remote_id),
                }))}
                header={<PositionsHeader company={company} />}
                permissions={{
                  copy: 'company_commodities_copy',
                  edit: 'company_commodities_edit',
                }}
                company={company}
                onCopy={startCopy}
              />
            }
          />
        )}
      </FlexRoutes>
    </>
  )
}

export default CompanyPositions

export const PositionsTable = ({ positions, permissions, onCopy, company, header }) => {
  let [copyId, setCopyId] = React.useState()
  let columns = React.useMemo(
    () => [
      {
        id: 'display_name',
        accessor: 'display_name',
        Header: 'Display Name',
      },
      {
        id: 'remote_id',
        accessor: 'remote_id',
        Header: 'Remote ID',
      },
      {
        id: 'location_count',
        accessor: 'location_count',
        Header: 'Locations Count',
      },
      {
        id: '_actions',
        Header: '',
        accessor: '_actions',
        disableSortBy: true,
        Cell: ({ row }) => {
          let position = row.original
          return (
            <Row justifyContent="flex-end">
              <Button
                size="sm"
                variant="ghost"
                color="primary.500"
                aria-label="Copy Position"
                isDisabled={
                  IS_DEV || !hasPermission(permissions.copy) || (copyId && copyId !== position.id)
                }
                isLoading={copyId === position.id}
                onClick={async () => {
                  setCopyId(position.id)
                  await onCopy({
                    context: {
                      copyId: position.id,
                    },
                  })
                  setCopyId(null)
                }}
              >
                Copy
              </Button>

              <Button
                as={Link}
                to={`/companies/${getEnvironment()}/${company.slug}/positions/${position.id}/edit`}
                size="sm"
                variant="ghost"
                color="primary.500"
                aria-label="Edit Position"
                isDisabled={!hasPermission(permissions.edit) || copyId}
              >
                EDIT
              </Button>
            </Row>
          )
        },
      },
    ],
    [permissions.copy, permissions.edit, company.slug, onCopy, copyId, setCopyId]
  )

  let { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    data: positions,
    tableOptions: {
      initialState: {
        sortBy: [],
      },
    },
    columns,
  })
  return (
    <>
      {header}
      {positions.length === 0 ? (
        <Placeholders.EmptyState message="No positions found" />
      ) : (
        <>
          <Box bg="white" py={3}>
            <Table2 {...getTableProps()} style={{ boxShadow: 'none' }} striped>
              <Table2.Header headerGroups={headerGroups} />
              <Table2.Body
                isLoading={false}
                rows={rows}
                getTableBodyProps={getTableBodyProps}
                prepareRow={prepareRow}
              />
            </Table2>
          </Box>
        </>
      )}
    </>
  )
}
