import type { ApiReturn } from 'src/utils/api'
import { commodityRequests } from 'src/api/commodities'
import { connectCustomerRequests } from 'src/api/connect-customers'
import { connectChannelRequests } from 'src/api/connect-channels'
import { connectSubscriptionRequests } from 'src/api/connect-subscriptions'
import { connectUserRequests } from 'src/api/connect-users'
import { positionRequests } from 'src/api/positions'
import { IS_PROD, IS_SANDBOX } from 'src/utils/config'

export type APIRequestFactories = typeof centreAPIRequests
export type CentreApiRequests = {
  [K in keyof APIRequestFactories]: ReturnType<APIRequestFactories[K]>
}

export type CentreEnvironment =
  | 'production'
  | 'uat'
  | 'sandbox'
  | 'dev'
  | 'r0'
  | 'r1'
  | 'r2'
  | 'r3'
  | 'r4'
  | 'r5'
  | 'r6'

export const VALID_CENTRE_ENVIRONMENTS: CentreEnvironment[] = IS_PROD
  ? ['production', 'uat']
  : IS_SANDBOX
    ? ['sandbox']
    : ['dev', 'r0', 'r1', 'r2', 'r3', 'r4', 'r5', 'r6']

export interface CentreDevEnvConfig {
  short: string
  baseUrl: string
  label: string
}

export const CENTRE_DEV_ENVIRONMENTS: CentreDevEnvConfig[] = [
  'dev',
  'r0',
  'r1',
  'r2',
  'r3',
  'r4',
  'r5',
  'r6',
].map((env) => {
  const sub = env === 'dev' ? 'centre' : `bushel-platform-centre-${env}`
  const baseUrl = `https://${sub}.dev.bushelops.com/api/internal`
  return {
    short: env,
    baseUrl,
    label: getEnvLabel(baseUrl),
  }
})

export function getDevEnvConfigById(short: string) {
  return CENTRE_DEV_ENVIRONMENTS.find((env) => env.short === short)
}

/**
 * Known possibilities:
 * http://localhost:8000/api/internal
 * https://bushel-platform-centre-{dev,r0,r1,r2,r3,r3,r4,r5,r6}.dev.bushelops.com/api/internal
 * https://centre.{dev,uat,sandbox}.bushelops.com/api/internal
 * https://centre.bushelops.com/api/internal
 */
export function getEnvLabel(url: string) {
  if (url.indexOf('localhost') !== -1 || url.indexOf('127.0.0.1') !== -1) return 'localhost'

  const match = url.match(/([\w.-]+)\.bushelops\.com/)?.[1]

  // keep the original labels for "real" prod/uat
  if (match === 'centre.sandbox') return 'Sandbox'
  if (match === 'centre') return 'Production'
  if (match === 'centre.uat') return 'UAT'

  return match ?? 'Unknown'
}

///////////////////////////////////////////////////////////////////////////////
// CENTRE API REQUESTS
///////////////////////////////////////////////////////////////////////////////

export const centreAPIRequests = {
  getCentreCompanies: (api) => async () => {
    const url = '/companies'
    try {
      let response = await api.get(`${url}`)
      return [null, response]
    } catch (err) {
      return [err, null]
    }
  },

  getCentreCompanyBySlug:
    (api) =>
    async ({ slug }) => {
      const url = '/company/' + slug
      try {
        let response = await api.get(`${url}`)
        return [null, response.data.data]
      } catch (err) {
        return [err, null]
      }
    },

  createCentreCompany:
    (api) =>
    async ({ company }) => {
      const url = '/company'
      try {
        let response = await api.post(`${url}`, company)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  updateCentreCompany:
    (api) =>
    async ({ company }): ApiReturn<Company> => {
      const url = '/company/' + company.slug
      try {
        let response = await api.put(`${url}`, company)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  getCentreCompanyMetadataByCompanyId:
    (api) =>
    async ({ companyId }) => {
      try {
        let response = await api.get(`/companies-metadata?company_id=${companyId}`)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  createCentreCompanyMetadata:
    (api) =>
    async ({ companyMetadata }) => {
      try {
        let response = await api.post('/company-metadata', companyMetadata)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  updateCentreCompanyMetadata:
    (api) =>
    async ({ companyMetadata }) => {
      try {
        let response = await api.put(`/company-metadata/${companyMetadata.id}`, companyMetadata)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  getCentreCompanyMetadataOptions: (api) => async () => {
    try {
      let response = await api.get('/company-metadata/options')
      return [null, response]
    } catch (err) {
      return [err, null]
    }
  },

  getCentreConfigurables: (api) => async () => {
    try {
      let response = await api.get('/company-config/configurables')
      return [null, response]
    } catch (err) {
      return [err, null]
    }
  },

  getCentreCompanyConfigBySlug:
    (api) =>
    async ({ slug }) => {
      const url = '/company-config/' + slug
      try {
        let response = await api.get(`${url}`)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  updateCentreCompanyConfig:
    (api) =>
    async ({ companyConfig, slug }) => {
      const url = '/company-config/' + slug
      try {
        const body = { config: companyConfig }
        let response = await api.put(`${url}`, body)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  getCentreTranslators: (api) => async () => {
    const url = '/translators'
    try {
      let response = await api.get(`${url}`)
      return [null, response]
    } catch (err) {
      return [err, null]
    }
  },

  getCentreTranslatorById:
    (api) =>
    async ({ id }) => {
      const url = '/translator/' + id
      try {
        let response = await api.get(`${url}`)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  createCentreTranslator:
    (api) =>
    async ({ translator }) => {
      const url = '/translator'
      try {
        // patch for backend not accepting revision on create
        delete translator._revision
        let response = await api.post(`${url}`, translator)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  updateCentreTranslator:
    (api) =>
    async ({ translator }) => {
      const url = '/translator/' + translator.id
      try {
        let response = await api.put(`${url}`, translator)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  getCentreElevators: (api) => async (): ApiReturn<BushelLocation[]> => {
    const url = '/elevators'
    try {
      let response = await api.get(`${url}`)
      return [null, response]
    } catch (err) {
      return [err, null]
    }
  },

  getCentreElevatorsByCompanyId:
    (api) =>
    async ({ companyId }): ApiReturn<BushelLocation[]> => {
      const url = '/elevators?company_id=' + companyId
      try {
        let response = await api.get(`${url}`)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  getCentreElevatorsByCompanySlug:
    (api) =>
    async ({ companySlug }): ApiReturn<BushelLocation[]> => {
      try {
        let [err, response] = await centreAPIRequests.getCentreCompanyBySlug(api)({
          slug: companySlug,
        })

        if (err) return [err, response]

        return await centreAPIRequests.getCentreElevatorsByCompanyId(api)({
          companyId: response.id,
        })
      } catch (err) {
        return [err, null]
      }
    },

  getCentreElevatorById:
    (api) =>
    async ({ id }): ApiReturn<BushelLocation> => {
      const url = '/elevator/' + id
      try {
        let response = await api.get(`${url}`)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  createCentreElevator:
    (api) =>
    async ({ elevator }): ApiReturn<BushelLocation> => {
      const url = '/elevator'
      try {
        let response = await api.post(`${url}`, elevator)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  bulkCreateCentreElevator:
    (api) =>
    async ({ elevators }) => {
      try {
        let response = await api.post('/elevators', elevators)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  updateCentreElevator:
    (api) =>
    async ({ elevator }): ApiReturn<BushelLocation> => {
      const url = '/elevator/' + elevator.id
      try {
        let response = await api.put(`${url}`, elevator)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  getLoggedInUsersReport:
    (api) =>
    async ({ key }) => {
      try {
        let response = await api.get(`/reports/logged-in-users/${key}`)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  getEsignContractsReport:
    (api) =>
    async ({ key }) => {
      try {
        let response = await api.get(`/reports/esign-contracts/${key}`)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  getCentreAgtraxProcessorByCompanyId:
    (api) =>
    async ({ companyId }) => {
      try {
        let response = await api.get(`/agtrax-processors?company_id=${companyId}`)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  createCentreAgtraxProcessor:
    (api) =>
    async ({ agtraxProcessor }) => {
      try {
        let response = await api.post(`/agtrax-processor`, agtraxProcessor)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  updateCentreAgtraxProcessor:
    (api) =>
    async ({ agtraxProcessor }) => {
      try {
        let response = await api.put(`/agtrax-processor/${agtraxProcessor.id}`, agtraxProcessor)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  // CASH BIDS
  ///////////////////////////////////////////////////////////////////////////////

  getCentreCashBidConfigs:
    (api) =>
    async ({ slug }) => {
      try {
        let response = await api.get('/cashbid-configs', {
          params: { company_slug: slug },
        })
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  createCentreCashBidConfigs:
    (api) =>
    async ({ config }) => {
      try {
        let response = await api.post('/cashbid-config', config)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  updateCentreCashBidConfigs:
    (api) =>
    async ({ config }) => {
      try {
        let response = await api.put(`/cashbid-config`, config)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  deleteCentreCashBidConfigs:
    (api) =>
    async ({ config }) => {
      try {
        let response = await api.delete(`/cashbid-config/${config.id}`)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  // ADMIN USERS
  ////////////////////////////////////////////////////////////////////////////////

  createAdminUser:
    (api) =>
    async ({ adminUser }) => {
      try {
        let response = await api.post('/create-cms-user', adminUser)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  getAdminUsers:
    (api) =>
    async ({ companySlug }) => {
      const url = '/cms-users/' + companySlug + '?admins=1'
      try {
        let response = await api.get(`${url}`)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  // AGTRAX API
  ////////////////////////////////////////////////////////////////////////////////

  getCentreAgtraxApiByCompanySlug:
    (api) =>
    async ({ companySlug }) => {
      try {
        let response = await api.get(`/agtrax-api/${companySlug}`)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  createCentreAgtraxApi:
    (api) =>
    async ({ config }) => {
      try {
        let response = await api.post('/agtrax-api', config)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  updateCentreAgtraxApi:
    (api) =>
    async ({ config }) => {
      try {
        let response = await api.put(`/agtrax-api/${config.id}`, config)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },
  ...commodityRequests,
  ...connectCustomerRequests,
  ...connectChannelRequests,
  ...connectSubscriptionRequests,
  ...connectUserRequests,
  ...positionRequests,

  //OMS
  //////////////////////////////////////////////////////////////////////////////////

  // CompanyFuturesConfigs
  ///////////////////////////////////////////////////////////////////////////////////
  getCompanyFuturesConfig:
    (api) =>
    async ({ companySlug }) => {
      try {
        let response = await api.get(
          `/offer-management-configuration/offermanagement-company-configs`,
          {
            headers: { 'company-slug': `${companySlug}` },
          }
        )
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  createCompanyFuturesConfig:
    (api) =>
    async ({ futuresConfig }) => {
      try {
        let response = await api.post(
          `/offer-management-configuration/offermanagement-company-config/create`,
          { futuresConfig }
        )
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  updateCompanyFuturesConfig:
    (api) =>
    async ({ futuresConfig }) => {
      try {
        let response = await api.put(
          `/offer-management-configuration/offermanagement-company-config`,
          { futuresConfig }
        )
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  deleteCompanyFuturesConfig:
    (api) =>
    async ({ companyID }) => {
      try {
        let response = await api.delete(
          `/offer-management-configuration/offermanagement-company-config/${companyID}`
        )
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  // Locations Mapping
  ////////////////////////////////////////////////////////////////////////////////////////////////

  getAllLocationsConfig:
    (api) =>
    async ({ companySlug }) => {
      try {
        let response = await api.get(
          `/offer-management-configuration/offermanagement-location-configs`,
          {
            headers: { 'company-slug': `${companySlug}` },
          }
        )
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  createLocationConfig:
    (api) =>
    async ({ locationConfig }) => {
      try {
        let response = await api.post(
          `/offer-management-configuration/offermanagement-location-config/create`,
          locationConfig
        )
        return [null, response]
      } catch (err) {
        return [null, err]
      }
    },

  updateLocationConfig:
    (api) =>
    async ({ locationConfig }) => {
      try {
        let response = await api.put(
          `/offer-management-configuration/offermanagement-location-config/`,
          locationConfig
        )
        return [null, response]
      } catch (err) {
        return [null, err]
      }
    },

  deleteLocationConfig:
    (api) =>
    async ({ mappingID }) => {
      try {
        let response = await api.delete(
          `/offer-management-configuration/offermanagement-location-config/${mappingID}`
        )
        return [null, response]
      } catch (err) {
        return [null, err]
      }
    },
}
