import { UseToastOptions } from '@chakra-ui/react'
import { centreAPIs } from 'src/utils/api'
import { getEnvironment } from 'src/utils'

const COPY_EXCLUDED_KEYS = ['_revision', 'id', 'company_id', 'hubspot_company_id']

type CopyContext = {
  companyMetadata: Partial<CompanyMetadata>
}

/**
 * Loads the `source` and `dest` values to display in the diff dialog.
 */
export const loadCopyDiff = async ({
  company,
  context,
  toast: _toast,
}: {
  company: Company
  context: CopyContext
  toast: (args: UseToastOptions) => void
}) => {
  let [_destErr, destCompany] = await centreAPIs.otherEnvironment.getCentreCompanyBySlug({
    slug: company.slug,
  })

  let [_destMetaErr, destCompanyMetadataResponse] =
    await centreAPIs.otherEnvironment.getCentreCompanyMetadataByCompanyId({
      companyId: destCompany.id,
    })
  let destCompanyMetadata = destCompanyMetadataResponse.data.data

  return {
    source: Object.keys(context.companyMetadata)
      .filter((key) => !COPY_EXCLUDED_KEYS.includes(key))
      .reduce((acc, key) => ({ ...acc, [key]: context.companyMetadata[key] }), {}),

    dest: destCompanyMetadata
      ? Object.keys(destCompanyMetadata)
          .filter((key) => !COPY_EXCLUDED_KEYS.includes(key))
          .reduce((acc, key) => ({ ...acc, [key]: destCompanyMetadata[key] }), {})
      : null,
  }
}

/**
 * Runs the actual copy routine with error handlers and notifications.
 */
export const executeCopy = async ({
  company,
  toast,
}: {
  company: Company
  toast: (args: UseToastOptions) => void
}) => {
  let destAPI = getEnvironment() === 'production' ? 'uat' : 'production'

  // make calls to the api to get fresh revisions
  let [sourceErr, sourceCompanyMetadataResponse] =
    await centreAPIs.getCentreCompanyMetadataByCompanyId({
      companyId: company.id,
    })

  if (sourceErr) {
    toast({ status: 'error', title: 'Error', description: 'Failed to load source data' })
    return
  }

  let sourceCompanyMetadata = sourceCompanyMetadataResponse.data.data

  let copySource = Object.keys(sourceCompanyMetadata)
    .filter((key) => !COPY_EXCLUDED_KEYS.includes(key))
    .reduce((acc, key) => ({ ...acc, [key]: sourceCompanyMetadata[key] }), {})

  let [destErr, destCompany] = await centreAPIs.otherEnvironment.getCentreCompanyBySlug({
    slug: company.slug,
  })

  let [destMetaErr, destCompanyMetadataResponse] =
    await centreAPIs.otherEnvironment.getCentreCompanyMetadataByCompanyId({
      companyId: destCompany.id,
    })

  if (destErr || destMetaErr) {
    toast({ status: 'error', title: 'Error', description: 'Failed to load destination data' })
    return
  }

  let destCompanyMetadata = destCompanyMetadataResponse.data.data

  if (!destCompanyMetadata || destCompanyMetadata.length === 0) {
    // company metadata does not exist, create it
    let [createErr, createdCompanyMetadata] =
      await centreAPIs.otherEnvironment.createCentreCompanyMetadata({
        companyMetadata: {
          ...copySource,
          company_id: destCompany.id,
        },
      })

    if (createErr) {
      toast({ status: 'error', title: 'Error', description: 'Failed to create company metadata' })
      return [createErr, null]
    }

    toast({ description: `Successfully created company metadata in ${destAPI.toUpperCase()}` })

    return [null, createdCompanyMetadata]
  }

  // company metadata exists, update its properties
  let [updateErr, updatedCompanyMetadata] =
    await centreAPIs.otherEnvironment.updateCentreCompanyMetadata({
      companyMetadata: {
        ...destCompanyMetadata,
        ...copySource,
      },
    })

  if (updateErr) {
    toast({ status: 'error', title: 'Error', description: 'Failed to copy company metadata' })
    return [updateErr, null]
  }

  toast({ description: `Successfully copied company metadata to ${destAPI.toUpperCase()}` })

  return [null, updatedCompanyMetadata]
}
