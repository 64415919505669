import React from 'react'
import { Box } from 'src/ui'
import { Table2 as Table } from 'src/ui'

interface MappingTableProps {
  locationConfig: OfferManagementMapping
}
const MappingTable = ({ locationConfig }: MappingTableProps) => {
  return (
    <Table striped>
      <thead className="thead">
        <tr>
          <th>Commodity</th>
          <th>Cash</th>
          <th>Flat</th>
          <th>Basis</th>
          <th>Hedge</th>
        </tr>
      </thead>
      <tbody>
        {locationConfig.cash_bid_product_map_json.map((product) => (
          <tr key={product.name}>
            <td css={{ width: '200px' }}>
              <Box flex={1} minWidth={0}>
                <p>{product.name}</p>
              </Box>
            </td>
            <td css={{ width: '250px' }}>
              <Box flex={1} minWidth={0}>
                <p>{product.cash === '' ? `-` : product.cash}</p>
              </Box>
            </td>
            <td css={{ width: '250px' }}>
              <Box flex={1} minWidth={0}>
                <p>{product.flat === '' ? '-' : product.flat}</p>
              </Box>
            </td>
            <td css={{ width: '250px' }}>
              <Box flex={1} minWidth={0}>
                <p>{product.basis === '' ? '-' : product.basis}</p>
              </Box>
            </td>
            <td css={{ width: '250px' }}>
              <Box flex={1} minWidth={0}>
                <p>{product.hedge === '' ? '-' : product.hedge}</p>
              </Box>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default MappingTable
