import React from 'react'
import Subscription from 'src/endpoints/form/subscription'
import { AddIcon, Box, Row, Column, IconButton, Tooltip } from 'src/ui'
import { useSubscriptionMutations } from 'src/api/queries/endpoints'

const newSubscription = {
  company_slug: '',
  environment: '',
  features: [],
}

const sortSubscriptions = (subscriptions) =>
  [...subscriptions].sort(({ company_slug: csa }, { company_slug: csb }) =>
    csa < csb ? -1 : csb < csa ? 1 : 0
  )

interface EndpointSubscriptionsProps {
  endpoint: Endpoint
  environments: Environment[]
  companies: ConfiguratorCompany[]
  features: string[]
  subscriptions: EndpointSubscription[]
}

const EndpointSubscriptions: React.FC<EndpointSubscriptionsProps> = ({
  endpoint,
  environments,
  companies,
  features,
  subscriptions,
}) => {
  let [pendingSubscription, setPendingSubscription] = React.useState(null)
  let { addSubscription, removeSubscription } = useSubscriptionMutations()

  const showForm = () => setPendingSubscription(newSubscription)
  const hideForm = () => setPendingSubscription(null)

  return (
    <Column pb={4}>
      <Row
        justifyContent="space-between"
        alignItems="center"
        height="3.5rem"
        bg="#eee"
        borderRadius={3}
        fontWeight="bold"
        pr={2}
        pl={3}
        my={3}
      >
        <Row alignItems="center">Subscriptions</Row>

        <Row alignItems="center">
          <Box>
            <Tooltip label="Add Subscription" aria-label="Add Subscription" placement="top">
              <IconButton
                icon={<AddIcon />}
                aria-label="Add Subscription"
                isRound
                variant="ghost"
                color="gray.600"
                onClick={showForm}
              />
            </Tooltip>
          </Box>
        </Row>
      </Row>

      {!!pendingSubscription && (
        <Subscription
          key="new-subscription"
          endpoint={endpoint}
          isNew={true}
          subscription={pendingSubscription}
          companies={companies}
          features={features}
          environments={environments}
          handleSave={addSubscription}
          handleRemove={hideForm}
        />
      )}

      {sortSubscriptions(subscriptions).map((subscription) => (
        <Subscription
          key={subscription.company_slug}
          endpoint={endpoint}
          isNew={false}
          subscription={subscription}
          companies={companies}
          features={features}
          environments={environments}
          handleSave={addSubscription}
          handleRemove={removeSubscription}
        />
      ))}

      {subscriptions.length === 0 && !pendingSubscription && (
        <Row py={3}>
          <Box px={2}>
            <em>No subscriptions created yet</em>
          </Box>
        </Row>
      )}
    </Column>
  )
}

export default EndpointSubscriptions
