import { oneOf, optional, sequence } from './utils'
import { translators, createTranslator } from './translator'
import { ICompany } from 'src/api/api'

export function createCompany(values: Partial<ICompany> = {}): ICompany {
  const translator_id = optional(sequence())
  if (translator_id) {
    translators.push(createTranslator({ id: translator_id }))
  }
  return {
    address_line_1: optional('503 7th St N'),
    address_line_2: null,
    branding_image: null,
    city: oneOf(['Fargo', 'Miami', 'Harvey', 'Moorhead', 'Toronto']),
    country_code: 'USA',
    created_at: '2022-04-19T17:15:26+00:00',
    datasource_id: null,
    deleted_at: null,
    email: optional('fakeEmail@bushelpowered.com'),
    failed_logins_email: optional('fakeEmail@bushelpowered.com'),
    fax: null,
    feedback_email: optional('fakeEmail@bushelpowered.com'),
    id: values.id ?? 1,
    inactive_at: null,
    name: values.name ?? 'Mock Company',
    news_feature_version: null,
    phone: optional('1234567890'),
    slug: `companyslug${values.id}`,
    state: 'ND',
    tenant_id: null,
    test_recipients: null,
    timezone: null,
    translator_id: translator_id,
    updated_at: '2022-08-18T18:40:57+00:00',
    website: optional('https://bushelpowered.com'),
    zip_code: optional('12345'),
    futures_feature_version: null,
    notifications_feature_version: optional('1.0.0'),
    resources_feature_version: null,
    wait_time_version: null,
    local_cash_bids_version: null,
    failed_logins_feature_version: null,
    _revision: `fake-revision-${sequence()}`,
  }
}

function initializeCompanies(): ICompany[] {
  return [
    'Bushel Sales Demo',
    'Agris',
    'FarmersRUs',
    'Test Company',
    'AgCo',
    'Grain Guys',
    'MFG Ag',
    "McDonald's Ag",
    'Zorbas',
    'Gucci Grain',
    'Jesus Christ Holy Grain',
    'CW Valley',
    'John Deere',
    'ADM',
    "Farmer's Co",
    'Bushel & a Peck',
    'Bushel Grain',
    'Cargill',
    'Brian Elevator Co',
    'Mock Mode Ag',
  ].map((name, index) => createCompany({ id: index + 1, name: name }))
}

export const companies: ICompany[] = initializeCompanies()
