import React, { FC } from 'react'
import { Routes, useLocation as useReactRouterLocation, useNavigate } from 'react-router-dom'
import { Button } from '@chakra-ui/react'
import { Box, Placeholders, Row } from 'src/ui'
import { hasPermission } from 'src/utils/permissions'
import { getKeycloakLogoutUrl } from '../utils/auth'
import { CentreEnvironment, VALID_CENTRE_ENVIRONMENTS } from 'src/api/centre'
import { useSelector } from 'react-redux'

const useLocation = () => {
  const location = useReactRouterLocation()
  const navigate = useNavigate()

  const [state, setState] = React.useState(() => ({
    location,
    navigate,
  }))

  // Update state when location changes
  React.useEffect(() => {
    setState((prevState) => ({ ...prevState, location }))
  }, [location])

  return state
}

export type AuthRouteProps = {
  as: React.ComponentType
  [key: string]: any // maybe lock this down later
}

const NoAuth = () => (
  <Box>
    <Placeholders.FailedState message="Authentication Failure" />

    <Row justifyContent="center" mt={4}>
      <Button size="sm" onClick={() => window.location.reload()}>
        Reload
      </Button>
    </Row>
  </Box>
)

const AuthRoute: FC<AuthRouteProps> = ({ as: RouteComponent, ...rest }) => {
  const auth = useSelector((state: { auth }) => state.auth)

  // Check if user is not authenticated
  if (!auth || (!auth.token && !auth.keycloak_config_url)) {
    // Redirect to an error or no-auth page
    return <NoAuth />
  }

  // Render the authenticated route
  return <RouteComponent {...rest} />
}

const FlexRoutes = ({ children }: { children: React.ReactNode }) => (
  <Routes
    css={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    }}
  >
    {children}
  </Routes>
)

const NotFound: FC = () => (
  <Box position="absolute" top="35%" left="44%" textAlign="center" color="#aaa">
    <Box mb={3}>
      <img src="/images/empty-flat.svg" alt="empty-flat" />
    </Box>

    <Box color="#999" fontSize={36}>
      4 0 4
    </Box>
  </Box>
)

const Empty: FC = () => null

/**
 * The URL pattern breaks down based on `companies/` routes, or `translators/` routes.
 * Path data is parsed out and matched up based on which section we're in.
 * Companies: `/companies/:env/:slug/:route`
 * Translators: `/translators/:route/:slug`
 */
const parseRoutePath = (path: string) => {
  const [_bit0, bit1, bit2, bit3, bit4] = path.split('/')
  const isValidEnv = VALID_CENTRE_ENVIRONMENTS.includes(bit2 as CentreEnvironment)

  return {
    companies:
      bit1 === 'companies'
        ? {
            // bit0 = ""
            // bit1 = "companies"
            env: isValidEnv ? (bit2 ?? null) : null,
            slug: isValidEnv ? (bit3 ?? null) : bit2 || null,
            route: isValidEnv ? (bit4 ?? null) : bit3 || null,
          }
        : null,

    translators:
      bit1 === 'translators'
        ? {
            // bit0 = ""
            // bit1 = "translator"
            route: bit2 || null,
            slug: bit3 || null,
          }
        : null,

    connect:
      bit1 === 'connect'
        ? {
            // bit0 = ""
            // bit1 = "connect"
            env: bit2 || null,
            route: bit3 || null,
          }
        : null,
  }
}

/**
 * When switching environments or companies, this will redirect to the appropriate url.
 * It "parses" the current url, and then allows some passed-in args to override parts of it.
 * Kinda janky, but it works for current use-cases.
 */
const navigateAfterCompanyOrEnvChange = ({
  navigate,
  location,
  env,
  slug,
  route,
  setSettingsState,
}: {
  navigate: any
  location: any
  env?: string
  slug?: string
  route?: string
  setSettingsState?: any //is there a better type for this?
}): void => {
  let { companies, translators, connect } = parseRoutePath(location.pathname)

  if (companies) {
    const nextRoute =
      '/' +
      (companies.env
        ? ['companies', env ?? companies.env, slug ?? companies.slug, route ?? companies.route]
            .filter(Boolean)
            .join('/')
        : ['companies', env, slug ?? companies.slug, route ?? companies.route]
            .filter(Boolean)
            .join('/'))

    navigate(nextRoute)
    return
  }

  if (translators && translators.route !== 'endpoints') {
    navigate(`/translators/${route || translators.route}/${slug || translators.slug || ''}`)
  }

  if (connect) {
    navigate(`/connect/${env || connect.env}/${route || connect.route}`)
    return
  }

  setSettingsState({ companySlug: slug })
}

export {
  useLocation,
  AuthRoute,
  FlexRoutes,
  NotFound,
  Empty,
  parseRoutePath,
  navigateAfterCompanyOrEnvChange,
}

export const defaultRoutePath = () => {
  if (hasPermission('nav_company')) return '/companies'
  if (hasPermission('nav_translators')) return '/translators'
  if (hasPermission('nav_reports')) return '/reports'

  return '/companies'
}

export const InvalidPermissions = () => (
  <Box>
    <Placeholders.FailedState message="You do not have the proper permissions to access the BAP with this user." />

    <Row justifyContent="center" mt={4}>
      <Button size="sm" as="a" href={getKeycloakLogoutUrl()}>
        Sign Out
      </Button>
    </Row>
  </Box>
)
