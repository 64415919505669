import React from 'react'
import * as Yup from 'yup'
import { Formik, Form, Field, FormikProps } from 'formik'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { centreAPIs } from 'src/utils/api'
import { Select } from 'src/ui/formik'
import { FormControl, FormLabel } from 'src/ui/form-controls'
import { Box, Stack, Placeholders, FormRow } from 'src/ui'
import ConfigPanel, { PanelState } from 'src/companies/configurations/config-panel'
import { loadCopyDiff, executeCopy } from 'src/companies/configurations/agtrax-processors/copy'
import { useCopyDiff } from 'src/companies/copy-utils'
import {
  convertEmptyStringsToNull,
  isFaded,
  representValue,
} from 'src/companies/configurations/utils'
import { useToast } from 'src/utils/toast'
import * as queries from 'src/utils/queries'

// DETAIL
///////////////////////////////////////////////////////////////////////////////

interface AgtraxProcessorDetailProps {
  agtraxProcessor: AgtraxProcessor
}

const AgtraxProcessorDetail: React.FC<AgtraxProcessorDetailProps> = ({ agtraxProcessor }) => (
  <>
    <Stack isInline spacing={4} align="center" height="80px" px={6}>
      <Box flex={1} minWidth={0}>
        <FormControl height="62px" mb={null}>
          <FormLabel title="Type">Type</FormLabel>
          <Box
            color={isFaded(agtraxProcessor['type']) ? 'gray.400' : undefined}
            css={{
              minWidth: 0,
              overflow: 'auto',
              whiteSpace: 'nowrap',
              scrollbarWidth: 'none',
              '::-webkit-scrollbar': {
                width: 0,
                height: 0,
              },
            }}
          >
            {representValue(agtraxProcessor['type'])}
          </Box>
        </FormControl>
      </Box>
      <Box flex={1} minWidth={0}>
        <FormControl height="62px" mb={null}>
          <FormLabel title="Last Log Id">Last Log Id</FormLabel>
          <Box
            color={
              isFaded(agtraxProcessor['last_processed_agtrax_log_id']) ? 'gray.400' : undefined
            }
            css={{
              minWidth: 0,
              overflow: 'auto',
              whiteSpace: 'nowrap',
              scrollbarWidth: 'none',
              '::-webkit-scrollbar': {
                width: 0,
                height: 0,
              },
            }}
          >
            {representValue(agtraxProcessor['last_processed_agtrax_log_id'])}
          </Box>
        </FormControl>
      </Box>
    </Stack>
  </>
)

// FORM
///////////////////////////////////////////////////////////////////////////////

const FormSchema = Yup.object().shape({
  type: Yup.string().required('Required').nullable(),
})

interface AgtraxProcessorFormProps {
  formikProps: FormikProps<any>
  formData: {
    submittedAt: number
    isSubmitting: boolean
  }
}

const agtraxProcessorTypeOptions = [
  { value: 'App\\Services\\CommandRunners\\Base', label: 'Base' },
  { value: 'App\\Services\\CommandRunners\\Agtrax', label: 'Agtrax' },
  { value: 'App\\Services\\CommandRunners\\Agvantage', label: 'Agvantage' },
  { value: 'App\\Services\\CommandRunners\\Agvantage_v2', label: 'Agvantage_v2' },
  { value: 'App\\Services\\CommandRunners\\Controlss', label: 'Controlss' },
]

const AgtraxProcessorForm: React.FC<AgtraxProcessorFormProps> = ({ formikProps, formData }) => {
  let { submittedAt } = formData
  let { submitForm } = formikProps
  React.useEffect(() => {
    if (!submittedAt) return
    submitForm()
  }, [submittedAt, submitForm])

  const initialType = formikProps.initialValues.type
  if (initialType && !agtraxProcessorTypeOptions.some((x) => x.value === initialType)) {
    agtraxProcessorTypeOptions.push({
      value: initialType,
      label: initialType,
    })
  }

  return (
    <Form>
      <Stack isInline spacing={4} align="center" height="80px" px={6}>
        <FormRow>
          <Box minWidth="200px">
            <Field
              id="type"
              name="type"
              label="Type"
              placeholder="Not Set"
              component={Select}
              formControlProps={{
                height: '62px',
                mb: null,
              }}
              isCreateable
              options={agtraxProcessorTypeOptions}
            />
          </Box>
        </FormRow>
      </Stack>

      {false && (
        <pre css={{ minWidth: 0, padding: '0 24px', overflow: 'auto', fontSize: 10 }}>
          {JSON.stringify(formikProps, null, 2)}
        </pre>
      )}
    </Form>
  )
}

const useAgtraxProcessor = ({ companyId }) => {
  return useQuery({
    queryKey: ['agtrax-processor', { companyId }],
    queryFn: async () => {
      let [err, response] = await centreAPIs.getCentreAgtraxProcessorByCompanyId({
        companyId,
      })

      if (err) throw err
      return response.data
    },
  })
}

const newAgtraxProcessor = {
  type: null,
  last_processed_agtrax_log_id: null,
}

type AgtraxProcessorPanelProps = {
  company: Company
  setCopyDialog: (args: any) => void
}

const AgtraxProcessorPanel: React.FC<AgtraxProcessorPanelProps> = ({ company, setCopyDialog }) => {
  let [agtraxProcessorState, setAgtraxProcessorState] = React.useState<PanelState>('collapsed')
  let toast = useToast()
  const queryClient = useQueryClient()

  let [formData, setFormData] = React.useState({ submittedAt: null, isSubmitting: false })

  const stopEditing = () => {
    setAgtraxProcessorState('expanded')
    setFormData({ submittedAt: null, isSubmitting: false })
  }

  let agtraxProcessorQuery = useAgtraxProcessor({
    companyId: company.id,
  })

  const saveAgtraxProcessor = async ({ agtraxProcessor }) => {
    let err, response

    if (agtraxProcessor.id) {
      ;[err, response] = await centreAPIs.updateCentreAgtraxProcessor({
        agtraxProcessor,
      })
    } else {
      agtraxProcessor.company_id = company.id
      agtraxProcessor.last_processed_agtrax_log_id = 0
      ;[err, response] = await centreAPIs.createCentreAgtraxProcessor({
        agtraxProcessor,
      })
    }

    if (err) throw err
    return [err, response]
  }

  let { mutateAsync: saveAgtraxProcessorMutation } = useMutation({
    mutationFn: saveAgtraxProcessor,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['agtrax-processor'] })
      toast({ title: 'Success', description: 'Updated company agtrax processor' })
      stopEditing()
    },
    useErrorBoundary: true,
  })

  let agtraxProcessor = agtraxProcessorQuery?.data?.data || newAgtraxProcessor

  let { startCopy } = useCopyDiff({
    company,
    context: { agtraxProcessor: agtraxProcessor },
    loadCopyDiff,
    executeCopy,
    setCopyDialog,
  })

  return (
    <ConfigPanel
      label="Agtrax Processor"
      formData={formData}
      expanded={['expanded', 'editing'].includes(agtraxProcessorState)}
      copyPermission="company_properties_copy"
      copyIsDisabled={!(agtraxProcessor && agtraxProcessor.id)}
      editPermission="company_properties_edit"
      panelState={agtraxProcessorState}
      onChange={(e, expanded) => {
        if (agtraxProcessorState === 'editing') return // ignore changes while editing
        setAgtraxProcessorState(expanded ? 'expanded' : 'collapsed')
      }}
      onClickCopy={startCopy}
      onClickEdit={() => setAgtraxProcessorState('editing')}
      onCancelEdit={stopEditing}
      onSave={() => setFormData((state) => ({ ...state, submittedAt: new Date().getTime() }))}
    >
      {queries.areAnyLoading(agtraxProcessorQuery) && <Placeholders.LoadingState mt={8} />}
      {queries.areAnyFailed(agtraxProcessorQuery) && <Placeholders.FailedState mt={8} />}
      {queries.areAllLoaded(agtraxProcessorQuery) && agtraxProcessorState !== 'editing' && (
        <AgtraxProcessorDetail agtraxProcessor={agtraxProcessor} />
      )}
      {queries.areAllLoaded(agtraxProcessorQuery) && agtraxProcessorState === 'editing' && (
        <Formik
          initialValues={agtraxProcessor}
          validateOnChange={false}
          validationSchema={FormSchema}
          onSubmit={async (values, _formikActions) => {
            setFormData((state) => ({ ...state, isSubmitting: true }))

            try {
              await saveAgtraxProcessorMutation({
                agtraxProcessor: {
                  ...convertEmptyStringsToNull(values),
                  _revision: agtraxProcessor._revision,
                },
              })
            } catch (error) {
              toast({
                status: 'error',
                title: 'Error',
                description: error?.response?.data?.message || 'Failed to update agtrax processor ',
              })
              setFormData({ submittedAt: null, isSubmitting: false })
              _formikActions.setErrors(error?.response?.data?.validation_messages)
            }
          }}
        >
          {(formikProps: FormikProps<any>) => (
            <AgtraxProcessorForm formikProps={formikProps} formData={formData} />
          )}
        </Formik>
      )}
    </ConfigPanel>
  )
}

export default AgtraxProcessorPanel
