import React from 'react'

type FormField = {
  key: string
  label: string
  readOnly?: boolean
  options?: { value: string; label: string }[]
  boolean?: boolean
  timed?: boolean
}

export const convertEmptyStringsToNull = (values) => {
  return Object.keys(values).reduce((acc, key) => {
    return {
      ...acc,
      [key]: values[key] === '' ? null : values[key],
    }
  }, {})
}

export const ensureBoolean = (value: string | boolean | number) => {
  if (typeof value === 'boolean') return value
  return !!value // @todo: explicitly check for `true`, `false`, `1`, `0` ?
}

export const isFaded = (value, field?: FormField) => {
  if (typeof value === 'undefined') return true
  if (value === null && (!field || !field.boolean)) return true

  return false
}

export const representValue = (value, field?: FormField) => {
  if (field && field.boolean) {
    return !!value ? 'true' : 'false'
  }

  if (typeof value === 'undefined') return 'N/A'
  if (typeof value === 'boolean') return JSON.stringify(value)
  if (value === null) return 'null'
  if (value === '') return <>&nbsp;</>
  if (typeof value === 'object') return <pre>{JSON.stringify(value, null, 2)}</pre>

  return value
}

export const padArrayChunks = <T extends {}>(groups: T[][], len: number) => {
  return groups.map((chunk) => {
    if (chunk.length >= len) return chunk
    return new Array(len).fill(null).map((_, index) => (chunk[index] ? chunk[index] : null))
  })
}
