import { rest } from 'msw'
import { configs, createConfig } from 'src/fixtures/offer-management'
import { companies } from 'src/fixtures/companies'

export const handlers = (urls: string[]) => {
  return urls.reduce((acc, url) => {
    url += '/offer-management-configuration'
    return [
      ...acc,
      rest.get(`${url}/offermanagement-location-configs`, (_req, res, ctx) =>
        res(ctx.status(200), ctx.json({ data: [] }))
      ),
      rest.get(`${url}/offermanagement-company-configs`, (req, res, ctx) => {
        const slug = req.headers.get('company-slug')
        const { id } = companies.find((company) => company.slug === slug)
        const responseData = configs.filter((config) => config.company_id === id)
        return res(ctx.status(200), ctx.json({ data: { configs: responseData } }))
      }),
      rest.post(`${url}/offermanagement-company-config/create`, async (req, res, ctx) => {
        const requestBody = await req.json()
        const newConfig = createConfig(requestBody.futuresConfig)
        configs.push(newConfig)
        const responseData = configs.filter((config) => config.company_id === newConfig.company_id)
        return res(ctx.status(201), ctx.json({ data: { configs: responseData } }))
      }),
      rest.put(`${url}/offermanagement-company-config`, async (req, res, ctx) => {
        const requestBody = await req.json()
        const { id, futures_product_map_json } = requestBody.futuresConfig
        const index = configs.findIndex((config) => config.id === id)
        configs[index].futures_product_map_json = futures_product_map_json
        return res(ctx.status(201), ctx.json({ data: { configs: configs[index] } }))
      }),
    ]
  }, [])
}
