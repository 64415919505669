import { UseToastOptions } from '@chakra-ui/react'
import { centreAPIs } from 'src/utils/api'
import { getEnvironment } from 'src/utils'
import { IPosition } from 'src/api/api'

const COPY_EXCLUDED_KEYS = [
  '_revision',
  'id',
  'company_id',
  'company_id_remote_id',
  'company_id_remote_elevator_id',
]

const mapElevatorsToPositions = (positions, elevators) => {
  const elevatorIds = positions.map((position) => position.remote_elevator_id)

  return Object.assign(
    {},
    {
      remote_id: positions[0].remote_id,
      display_name: positions[0].display_name,
      elevators: elevators.data.data
        .filter((elevator) => elevatorIds.includes(elevator.remote_id))
        .map((elevator) => {
          return { [elevator.remote_id]: elevator.name }
        }),
    }
  )
}

type CopyContext = {
  positions: IPosition[]
  copyId: IPosition['id']
}

export const loadCopyDiff = async ({
  company,
  context,
  toast,
}: {
  company: Company
  context: CopyContext
  toast: (args: UseToastOptions) => void
}) => {
  const destinationEnvironment = getEnvironment() === 'production' ? 'UAT' : 'Production'
  let { positions, copyId } = context

  let data = {
    dest: {},
    source: {},
  }

  let [_sourceElevatorErr, elevators] = await centreAPIs.getCentreElevatorsByCompanySlug({
    companySlug: company.slug,
  })

  let [_targetElevatorsErr, targetElevators] =
    await centreAPIs.otherEnvironment.getCentreElevatorsByCompanySlug({
      companySlug: company.slug,
    })

  let missingElevators = elevators.data.data.filter(
    (elevator) =>
      !targetElevators.data.data.some((target) => target.remote_id === elevator.remote_id)
  )

  let remote_id = positions.filter((position) => position.id === copyId)[0].remote_id

  let remote_positions = positions.filter((position) => position.remote_id === remote_id)

  data.source = Object.assign({}, mapElevatorsToPositions(remote_positions, elevators))
  COPY_EXCLUDED_KEYS.forEach((key) => delete data.source[key])

  if (missingElevators) {
    let list = missingElevators.filter((elevator) =>
      positions.some((position) => position.remote_elevator_id === elevator.remote_id)
    )

    data.source['elevators'] = data.source['elevators'].filter(
      (elevator) =>
        !Object.keys(elevator).some((k) =>
          missingElevators.map((missing) => missing.remote_id).includes(k)
        )
    )

    let formattedList = list.map((elevator) => elevator.name)

    if (formattedList.length > 0) {
      toast({
        status: 'error',
        description: `The following Locations are selected but do not exist in ${destinationEnvironment} and will not be copied: ${formattedList.join(
          ', '
        )}`,
      })
    }
  }

  let [targetCompanyErr, _targetCompany] = await centreAPIs.otherEnvironment.getCentreCompanyBySlug(
    {
      slug: company.slug,
    }
  )

  if (targetCompanyErr) {
    toast({
      status: 'error',
      description: `The Company does not exist in ${destinationEnvironment}`,
    })
    return
  }

  let [destErr, destPositions] = await centreAPIs.otherEnvironment.getPositions({
    companySlug: company.slug,
  })

  if (!destErr) {
    if (destPositions.data.data.length > 0) {
      let destPositionsByRemoteId = destPositions.data.data.filter(
        (position) => position.remote_id === copyId
      )
      if (destPositionsByRemoteId.length > 0) {
        data.dest = mapElevatorsToPositions(destPositionsByRemoteId, targetElevators)
        COPY_EXCLUDED_KEYS.forEach((key) => delete data.dest[key])
      }
    }
  }

  return data
}

export const executeCopy = async ({
  company,
  toast,
  context,
}: {
  company: Company
  toast: (args: UseToastOptions) => void
  context: {
    positions: IPosition[]
    copyId: number
  }
}) => {
  const destinationEnvironment = getEnvironment() === 'production' ? 'UAT' : 'Production'
  let [sourceElevatorErr, _sourceElevators] = await centreAPIs.getCentreElevatorsByCompanySlug({
    companySlug: company.slug,
  })
  let [targetElevatorErr, targetElevators] =
    await centreAPIs.otherEnvironment.getCentreElevatorsByCompanySlug({
      companySlug: company.slug,
    })
  let filterByRemoteId = (positions, remoteId) => {
    return positions.filter((position) => position.remote_id === remoteId)
  }
  if (!sourceElevatorErr && !targetElevatorErr) {
    let [sourceErr, sourcePositions] = await centreAPIs.getPositions({
      companySlug: company.slug,
    })
    let [targetErr, targetPositions] = await centreAPIs.otherEnvironment.getPositions({
      companySlug: company.slug,
    })
    let [companyErr, targetCompany] = await centreAPIs.otherEnvironment.getCentreCompanyBySlug({
      slug: company.slug,
    })

    const remoteId = sourcePositions.data.data.filter((position) => {
      return position.id === context.copyId
    })[0].remote_id

    sourcePositions = filterByRemoteId(sourcePositions.data.data, remoteId)
    targetPositions = filterByRemoteId(targetPositions.data.data, remoteId)
    const base = {
      remote_id: remoteId,
      display_name: sourcePositions[0].display_name,
    }
    let errs = null
    if (!sourceErr && !targetErr && !companyErr) {
      // find positions that already exist
      const existingIds = targetPositions
        .filter((position) => {
          return sourcePositions.some((p) => p.remote_id === position.remote_id)
        })
        .map((position) => position.id)
      if (existingIds.length > 0) {
        // bulk update them
        ;[errs] = await centreAPIs.otherEnvironment.bulkUpdatePositions({
          ids: existingIds,
          remote_id: base.remote_id,
          display_name: base.display_name,
        })
      }
      // find positions that don't exist
      const newLocations = sourcePositions
        .filter((position) => {
          return !targetPositions.some((p) => p.remote_id === position.remote_id)
        })
        .map((position) => {
          return targetElevators.data.data
            .filter((elevator) => {
              return elevator.remote_id === position.remote_elevator_id
            })
            .map((elevator) => elevator.id)[0]
        })
        .filter(Number)
      // if there are some with matching elevators
      if (newLocations.length > 0) {
        // create them
        ;[errs] = await centreAPIs.otherEnvironment.bulkCreatePositions({
          remote_id: base.remote_id,
          display_name: base.display_name,
          company_id: targetCompany.id,
          elevators: newLocations,
        })
      }
      if (errs === null) {
        toast({
          description: `Copied position to ${destinationEnvironment}`,
        })
      } else {
        toast({
          status: 'error',
          description: `There was an error during the copy, some of the data may be lost. Please contact an administrator.`,
        })
      }
    } else {
      toast({
        status: 'error',
        description: 'Unable to load positions. Please contact an administrator.',
      })
    }
  } else {
    toast({
      status: 'error',
      description: 'Unable to load elevators. Please contact an administrator.',
    })
  }
}
