import { ILocation } from 'src/api/api'
import { sequence, optional } from './utils'

export function createElevator(values: Partial<ILocation> = {}): ILocation {
  const id = sequence()
  const company_id = id
  const remote_id = `remote-id-${id}`
  return {
    id,
    address_line_1: '101 Fake Street',
    address_line_2: optional('E Brady Ave.'),
    company_id,
    company_id_remote_id: `${company_id}-${remote_id}`,
    deleted_at: null,
    remote_id,
    name: `location-name-${id}`,
    city: `location-name-${id}`,
    scale_image: null,
    scale_image_time: null,
    scale_wait_time: null,
    state: optional('fakeState'),
    today_close: null,
    today_open: null,
    tomorrow_close: null,
    tomorrow_open: null,
    zip_code: optional('12345'),
    country_code: optional('fake-country-code'),
    phone: optional('1234567890'),
    fax: optional('1234567890'),
    email: optional('fakeEmail@email.com'),
    website: optional('https://bushelpowered.com'),
    cash_bids_hidden_at: null,
    push_notifications_hidden_at: null,
    hidden: false,
    business_type: optional(['Plant']),
    _revision: `fakeRevision-${id}`,
    ...values,
  }
}

export const elevators: ILocation[] = [createElevator(), createElevator(), createElevator()]
