import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'src/store'
import { Row } from 'src/ui'

interface CompanyNameHelperProps {
  company_id: number
  companies: RootState['companies']
}

function CompanyNameHelper({ company_id, companies }: CompanyNameHelperProps) {
  return (
    <Row justifyContent="flex-start">
      {companies?.data?.find((item) => item.id === company_id)?.name}
    </Row>
  )
}

const mapState = ({ companies }: RootState) => ({ companies })
export default connect(mapState)(CompanyNameHelper)
