import { useQuery, useMutation } from '@tanstack/react-query'
import * as api from 'src/utils/api'

export const useCompanyTranslators = ({ slug }) => {
  return useQuery({
    queryKey: ['company-translators', { slug }],
    queryFn: async () => {
      let [err, data] = await api.getCompanyTranslators({ slug })
      if (err) throw err
      return data.data.data
    },
    cacheTime: 0,
  })
}

export const useCompanyTranslatorMutations = () => {
  let { mutateAsync: saveTranslator } = useMutation({
    mutationFn: async ({ translator }: { translator: Translator }) => {
      let [err, response] = await api.createTranslator({ translator })
      if (err) throw err

      return [err, response]
    },
  })

  let { mutateAsync: deleteTranslator } = useMutation({
    mutationFn: async ({ translator }: { translator: Translator }) => {
      let [err, response] = await api.deleteTranslator({ translator })
      if (err) throw err

      return [err, response]
    },
  })

  return { saveTranslator, deleteTranslator }
}
