import { useState } from 'react'

export const LS_KEYS = {
  AUTH: 'bap-auth',
  COMPANY: 'bap-company-slug',
  SIDE_NAV: 'bap-side-nav',
  ENV: 'bap-environment',
  METRICS_ENV: 'bap-metrics-environment',
}

export function getLocalStorageKey<T = any>(key: string, defaultValue: T = null): T {
  let stored = window.localStorage.getItem(key)

  if (stored === null) return defaultValue

  return JSON.parse(stored)
}

export const setLocalStorageKey = (key: string, value: any) => {
  window.localStorage.setItem(key, JSON.stringify(value))
}

export const removeLocalStorageKey = (key: string) => {
  window.localStorage.removeItem(key)
}

export function useLocalStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    return getLocalStorageKey(key, initialValue)
  })

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      setLocalStorageKey(key, valueToStore)
    } catch (error) {
      console.log(error)
    }
  }
  return [storedValue, setValue] as const
}
