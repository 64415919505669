import React, { memo, useCallback, useState } from 'react'
import { get } from 'lodash'
import { FastField, FieldArray, FormikProps, ArrayHelpers } from 'formik'
import {
  TextField as FormikTextField,
  Select as FormikSelect,
  JsonEditor as FormikJsonEditor,
} from 'src/ui/formik'
import {
  Box,
  Row,
  Column,
  Panel,
  FormRow,
  InfoIcon,
  InputGroup,
  InputLeftElement,
  IconButton,
  Tooltip,
  DeleteIcon,
  AddIcon,
} from 'src/ui'
import { cronExplained, newSchedule, randKey } from 'src/translators/form/utils'

interface ScheduleProps {
  index: number
  expanded: boolean
  schedule: TranslatorConfigSchedule
  formikProps: FormikProps<any>
  toggleExpanded: (key: string, expanded: boolean) => void
  deleteSchedule: (index: number) => void
}
const Schedule = memo(function Schedule({
  index,
  expanded,
  schedule,
  toggleExpanded,
  deleteSchedule,
  formikProps,
}: ScheduleProps) {
  const scopedFieldName = useCallback(
    (scopedFieldNameKey: string) => `schedules[${index}].${scopedFieldNameKey}`,
    [index]
  )
  const { key, name } = schedule

  return (
    <Panel
      expanded={expanded}
      onChange={(_e, isExpanded) => toggleExpanded(key, isExpanded)}
      header={
        <Row width="100%" justifyContent="space-between" alignItems="center">
          <Box>{name ? <strong>{name}</strong> : <em>new_schedule</em>}</Box>

          <Box>
            <Tooltip label="Remove Schedule" aria-label="Remove Schedule" placement="left">
              <IconButton
                icon={<DeleteIcon />}
                aria-label="Delete"
                isRound
                variant="ghost"
                color="gray.600"
                onClick={(e) => {
                  e.stopPropagation()
                  deleteSchedule(index)
                }}
              />
            </Tooltip>
          </Box>
        </Row>
      }
    >
      <Column width="100%" pr={1} pl={1}>
        <FormRow>
          <FastField
            name={scopedFieldName('name')}
            label="Schedule Name"
            component={FormikTextField}
          />

          <Row justifyContent="center" />
        </FormRow>

        <FormRow>
          <FastField
            name={scopedFieldName('adapter')}
            label="Adapter"
            component={FormikTextField}
          />

          <FastField
            id={scopedFieldName('type')}
            name={scopedFieldName('type')}
            label="Fetch Type"
            component={FormikSelect}
            selectProps={{ isClearable: false }}
            options={[
              { value: 'Bulk', label: 'Bulk' },
              { value: 'ById', label: 'ById' },
              { value: 'ByUser', label: 'ByUser' },
            ]}
          />

          <FastField
            name={scopedFieldName('priority')}
            label="Priority"
            component={FormikTextField}
          />
        </FormRow>

        <FormRow>
          <FastField
            name={scopedFieldName('start_days_ago')}
            label="Start Days Ago"
            component={FormikTextField}
          />

          <FastField
            name={scopedFieldName('interval_minutes')}
            label="Interval Minutes"
            component={FormikTextField}
          />

          <Row>
            <InputGroup>
              <FastField
                name={scopedFieldName('cron')}
                label="CRON Schedule"
                component={FormikTextField}
                leftElement={
                  <InputLeftElement>
                    <Tooltip
                      label={cronExplained(get(formikProps.values, scopedFieldName('cron')))}
                      aria-label={cronExplained(get(formikProps.values, scopedFieldName('cron')))}
                      placement="top"
                    >
                      <InfoIcon />
                    </Tooltip>
                  </InputLeftElement>
                }
              />
            </InputGroup>
          </Row>
        </FormRow>

        <FormRow>
          <FastField
            name={scopedFieldName('start_date')}
            label="Start Date"
            component={FormikTextField}
          />
        </FormRow>

        <FastField
          name={scopedFieldName('adapter_config')}
          label="Adapter Config"
          component={FormikJsonEditor}
          height={180}
        />
      </Column>
    </Panel>
  )
})
///////////////////////////////////////////////////////////////////////////////

const hasErrors = ({ formikProps, index }: { formikProps: FormikProps<any>; index: number }) => {
  if (formikProps.submitCount === 0) return false
  let errs = get(formikProps.errors, `schedules[${index}]`)
  if (!errs) return false
  return Object.keys(errs).length > 0
}

interface ScheduleListProps {
  formikProps: FormikProps<TranslatorConfigSchedule>
}

function ScheduleList({ formikProps }: ScheduleListProps) {
  const [expanded, setExpanded] = useState<string[]>([])

  const toggleExpanded = useCallback((key: string, isExpanded: boolean) => {
    isExpanded
      ? setExpanded((state) => [...state, key])
      : setExpanded((state) => state.filter((k) => k !== key))
  }, [])

  return (
    <FieldArray
      name="schedules"
      render={(arrayHelpers: ArrayHelpers) => (
        <Column width="100%" borderRadius={3} mb={5}>
          <Row
            justifyContent="space-between"
            alignItems="center"
            height="3.5rem"
            bg="#eee"
            borderRadius={3}
            fontWeight="bold"
            pr={2}
            pl={3}
            mb={3}
          >
            <Row alignItems="center">
              <Box>Schedules</Box>
            </Row>

            <Row alignItems="center">
              <Box>
                <Tooltip label="Add Schedule" aria-label="Add Schedule" placement="top">
                  <IconButton
                    icon={<AddIcon />}
                    aria-label="Add Schedule"
                    isRound
                    variant="ghost"
                    color="gray.600"
                    onClick={() => {
                      let schedule = { ...newSchedule, key: randKey() }
                      arrayHelpers.unshift(schedule)
                      setExpanded((state) => {
                        return [...state, schedule.key]
                      })
                    }}
                  />
                </Tooltip>
              </Box>
            </Row>
          </Row>

          {formikProps.values.schedules.map((schedule, index) => (
            <Schedule
              key={schedule.key}
              index={index}
              schedule={schedule}
              formikProps={formikProps}
              expanded={expanded.includes(schedule.key) || hasErrors({ formikProps, index })}
              toggleExpanded={toggleExpanded}
              deleteSchedule={arrayHelpers.remove}
            />
          ))}

          {formikProps.values.schedules.length === 0 && (
            <Row px={3}>
              <em>No schedules added yet</em>
            </Row>
          )}
        </Column>
      )}
    />
  )
}

export default ScheduleList
