import _ from 'lodash'
import { rest } from 'msw'
import { CONFIG } from 'src/utils/config'
import {
  companies,
  createCompany,
  endpoints,
  createEndpoint,
  translators,
  createTranslator,
  createSubscription,
  secrets,
  createSecret,
  features,
} from '../fixtures/configurator'

let token = 'abc123456encryptedtoken'

const noCompanyError = {
  error: {
    message: 'No company found',
    code: 404,
  },
}
function companyInTranslator(companyslug) {
  return companies.findIndex((company) => company.slug === companyslug) !== -1
}

export const handlers = [
  // Configurator companies
  rest.get(`${CONFIG.configurator_api_url}/api/v1/companies`, (_req, res, ctx) =>
    res(ctx.status(200), ctx.json({ data: companies }))
  ),
  rest.post(`${CONFIG.configurator_api_url}/api/v1/companies`, async (req, res, ctx) => {
    const requestBody = await req.json()
    const company = createCompany(requestBody)
    companies.push(company)
    return res(ctx.status(201), ctx.json({ data: company }))
  }),
  // Configurator environments
  rest.get(`${CONFIG.configurator_api_url}/api/v1/system/environments`, (_req, res, ctx) =>
    res(ctx.status(200), ctx.json({ data: [{ slug: 'dev', name: 'Development' }] }))
  ),

  // Translators
  rest.get(
    `${CONFIG.configurator_api_url}/api/v1/companies/:companyslug/translators`,
    (req, res, ctx) => {
      const companyslug = req.params.companyslug
      if (companyInTranslator(companyslug)) {
        const returnData = translators.filter((item) => item.company_slug === companyslug)
        return res(ctx.status(200), ctx.json({ data: returnData }))
      }
      return res(ctx.status(404), ctx.json(noCompanyError))
    }
  ),
  rest.post(`${CONFIG.configurator_api_url}/api/v1/translators`, async (req, res, ctx) => {
    const requestBody = await req.json()
    const newTranslator = createTranslator(requestBody)
    translators.push(newTranslator)
    return res(ctx.status(200), ctx.json({ data: newTranslator }))
  }),
  rest.delete(
    `${CONFIG.configurator_api_url}/api/v1/translators/:translatorId`,
    (req, res, ctx) => {
      const { translatorId } = req.params
      const index = translators.findIndex((translator) => translator.id === translatorId)
      translators.splice(index, 1)
      return res(ctx.status(204))
    }
  ),
  // translator secrets
  rest.get(
    `${CONFIG.configurator_api_url}/api/v1/translators/:translatorId/secrets`,
    (req, res, ctx) => {
      const { translatorId } = req.params
      const returnData = secrets.filter((secret) => secret.translator_id === translatorId)
      return res(ctx.status(200), ctx.json({ data: returnData }))
    }
  ),
  rest.post(
    `${CONFIG.configurator_api_url}/api/v1/translators/:translatorId/secrets`,
    async (req, res, ctx) => {
      const requestBody = await req.json()
      const newSecret = createSecret(requestBody)
      secrets.push(newSecret)
      return res(ctx.status(200), ctx.json({ data: newSecret }))
    }
  ),
  rest.put(
    `${CONFIG.configurator_api_url}/api/v1/translators/:translatorId/secrets/:secretId`,
    async (req, res, ctx) => {
      const { secretId } = req.params
      const { value } = await req.json()
      const index = secrets.findIndex((secret) => secret.id === secretId)
      secrets[index].value = value
      return res(ctx.status(204))
    }
  ),
  rest.delete(
    `${CONFIG.configurator_api_url}/api/v1/translators/:translatorId/secrets/:secretId`,
    (req, res, ctx) => {
      const { secretId } = req.params
      const index = secrets.findIndex((secret) => secret.id === secretId)
      secrets.splice(index, 1)
      return res(ctx.status(204))
    }
  ),
  rest.get(
    `${CONFIG.configurator_api_url}/api/v1/translators/:translatorId/secrets/:secretId`,
    (req, res, ctx) => {
      const { secretId } = req.params
      const viewSecret = secrets.find((secret) => secret.id === secretId)
      return res(ctx.status(200), ctx.json({ data: viewSecret }))
    }
  ),
  // other translator functionality
  rest.get(`${CONFIG.configurator_api_url}/api/v1/deployments`, (_req, res, ctx) =>
    res(ctx.status(200), ctx.json({ data: [] }))
  ),
  rest.get(
    `${CONFIG.configurator_api_url}/api/v1/translators/:translatorId/:env/config`,
    (_req, res, ctx) => res(ctx.status(200), ctx.json({ data: [] }))
  ),
  rest.get(`${CONFIG.configurator_api_url}/api/v1/images/options`, (_req, res, ctx) =>
    res(ctx.status(200), ctx.json({ data: [] }))
  ),
  rest.get(
    `${CONFIG.configurator_api_url}/api/v1/translators/:translatorId/:env/latest-config`,
    (_req, res, ctx) => res(ctx.status(404), ctx.json(noCompanyError))
  ),
  rest.post('https://router.translator.dev.bushelops.com/api/v1/replay', (_req, res, ctx) =>
    res(ctx.status(500), ctx.json({ data: { oops: 'something broke' } }))
  ),

  // tokens
  rest.get(
    `${CONFIG.configurator_api_url}/api/v1/companies/:companyslug/token/:env`,
    (req, res, ctx) =>
      companyInTranslator(req.params.companyslug)
        ? res(ctx.status(200), ctx.json({ data: { token } }))
        : res(ctx.status(404), ctx.json(noCompanyError))
  ),
  rest.put(
    `${CONFIG.configurator_api_url}/api/v1/companies/:companyslug/token/:env`,
    async (req, res, ctx) => {
      const { newToken } = await req.json()
      token = newToken
      return res(ctx.status(200))
    }
  ),

  // endpoints
  rest.get(`${CONFIG.configurator_api_url}/api/v1/endpoints`, (_req, res, ctx) =>
    res(ctx.status(200), ctx.json({ data: endpoints }))
  ),
  rest.post(`${CONFIG.configurator_api_url}/api/v1/endpoints`, async (req, res, ctx) => {
    const requestBody = await req.json()
    const newEndpoint = createEndpoint(requestBody)
    endpoints.push(newEndpoint)
    return res(ctx.status(200), ctx.json({ data: { id: newEndpoint.id } }))
  }),
  rest.put(`${CONFIG.configurator_api_url}/api/v1/endpoints/:endpointId`, async (req, res, ctx) => {
    const requestBody = await req.json()
    const { endpointId } = req.params
    const index = endpoints.findIndex((endpoint) => endpoint.id === endpointId)
    const newEndpoint = createEndpoint(requestBody)
    endpoints[index] = newEndpoint
    return res(ctx.status(200), ctx.json({ data: endpointId }))
  }),
  rest.delete(`${CONFIG.configurator_api_url}/api/v1/endpoints/:endpointId`, (req, res, ctx) => {
    const { endpointId } = req.params
    const endpointIndex = endpoints.findIndex((endpoint) => endpoint.id === endpointId)
    endpoints.splice(endpointIndex, 1)
    return res(ctx.status(204))
  }),
  // endpoint features
  rest.get(`${CONFIG.configurator_api_url}/api/v1/endpoints/features`, (_req, res, ctx) =>
    res(ctx.status(200), ctx.json({ data: features }))
  ),
  // endpoint subscriptions
  rest.post(
    `${CONFIG.configurator_api_url}/api/v1/endpoints/:endpointId/subscription`,
    async (req, res, ctx) => {
      const { endpointId } = req.params
      const requestBody = await req.json()
      const newSubscription = createSubscription(requestBody)
      const index = endpoints.findIndex((endpoint) => endpoint.id === endpointId)
      const alreadyThere = endpoints[index].subscriptions.findIndex(
        (subscription) =>
          subscription.company_slug === newSubscription.company_slug &&
          subscription.environment === newSubscription.environment
      )
      alreadyThere > -1
        ? endpoints[index].subscriptions.splice(alreadyThere, 1, newSubscription)
        : endpoints[index].subscriptions.push(newSubscription)
      return res(ctx.status(204)) // yes, for some reason 204 is returned on this
    }
  ),
  rest.delete(
    `${CONFIG.configurator_api_url}/api/v1/endpoints/:endpointId/subscription`,
    async (req, res, ctx) => {
      const requestBody = await req.json()
      const { endpointId } = req.params
      const endpointIndex = endpoints.findIndex((endpoint) => endpoint.id === endpointId)
      const subscriptionsIndex = endpoints[endpointIndex].subscriptions.findIndex((subscription) =>
        _.isEqual(subscription, createSubscription(requestBody))
      )
      endpoints[endpointIndex].subscriptions.splice(subscriptionsIndex, 1)
      return res(ctx.status(204))
    }
  ),
]
