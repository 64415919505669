import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'

type Tickets = {
  detailsIsEditing: boolean
  setDetailsIsEditing: Dispatch<SetStateAction<boolean>>
  gradeFactorsIsEditing: boolean
  setGradeFactorsIsEditing: Dispatch<SetStateAction<boolean>>
}

const TicketsContext = createContext<Tickets>({
  detailsIsEditing: false,
  setDetailsIsEditing: () => {
    return
  },
  gradeFactorsIsEditing: false,
  setGradeFactorsIsEditing: () => {
    return
  },
})

export function TicketsContextProvider({ children }: Readonly<{ children: ReactNode }>) {
  const [detailsIsEditing, setDetailsIsEditing] = useState(false)
  const [gradeFactorsIsEditing, setGradeFactorsIsEditing] = useState(false)

  const TicketsContextValue = useMemo(
    () => ({
      detailsIsEditing,
      setDetailsIsEditing,
      gradeFactorsIsEditing,
      setGradeFactorsIsEditing,
    }),
    [detailsIsEditing, gradeFactorsIsEditing]
  )
  return <TicketsContext.Provider value={TicketsContextValue}>{children}</TicketsContext.Provider>
}

export function useTicketsContext(): Tickets {
  return useContext(TicketsContext)
}
