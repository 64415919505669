import React, { useEffect, useState } from 'react'
import { Dispatch, RootState } from 'src/store'
import { AuthRoute, FlexRoutes } from 'src/routes'
import { DiffDialog } from 'src/companies/dialogs'
import CompanyConfigurations from 'src/companies/configurations/index'
import CompanyTranslator from 'src/companies/translator'
import CompanyLocations from 'src/companies/locations'
import CompanyCashBids from 'src/companies/cashbids'
import CompanyPositions from 'src/companies/positions'
import OfferManagementSystem from 'src/companies/oms'
import CommoditiesRouter from 'src/companies/commodities/router'
import { Navigate, Route } from 'react-router-dom'

export * from 'src/companies/routes/companies-default'
export * from 'src/companies/routes/helpers'
export * from 'src/companies/routes/validators'

interface CompanyRoutesProps {
  env: string
  slug: string
  company: Company
  companies: RootState['companies']
  setSettingsState: Dispatch['settings']['setState']
}

interface CompanyRoutesState {
  copyDialog: any
}

function CompanyRoutes({ env, slug, company, companies, setSettingsState }: CompanyRoutesProps) {
  useEffect(() => {
    setSettingsState({ showCompanySelectorDialog: true, showCentreEnvironment: true })
  }, [setSettingsState])

  const [copyDialog, setCopyDialog] = useState<CompanyRoutesState>(null)
  return (
    // relative to /companies/:env/:slug/*
    <>
      <FlexRoutes>
        <Route
          path="/oms"
          element={
            <AuthRoute
              as={OfferManagementSystem}
              company={company}
              companies={companies.data}
              setCopyDialog={setCopyDialog}
            />
          }
        />
        <Route
          path="/configurations"
          element={
            <AuthRoute
              as={CompanyConfigurations}
              company={company}
              companies={companies.data}
              setCopyDialog={setCopyDialog}
            />
          }
        />
        <Route
          path="/translator"
          element={
            <AuthRoute
              as={CompanyTranslator}
              company={company}
              companies={companies.data}
              setCopyDialog={setCopyDialog}
            />
          }
        />
        <Route
          path="/locations"
          element={
            <AuthRoute
              as={CompanyLocations}
              company={company}
              companies={companies.data}
              setCopyDialog={setCopyDialog}
            />
          }
        />
        <Route
          path="/cash-bid-config"
          element={
            <AuthRoute
              as={CompanyCashBids}
              company={company}
              companies={companies.data}
              setCopyDialog={setCopyDialog}
            />
          }
        />
        <Route
          path="/positions/*"
          element={
            <AuthRoute as={CompanyPositions} company={company} setCopyDialog={setCopyDialog} />
          }
        />
        <Route
          path="/commodities/*"
          element={
            <AuthRoute
              as={CommoditiesRouter}
              company={company}
              companies={companies.data}
              setCopyDialog={setCopyDialog}
              env={env}
            />
          }
        />
        <Route
          path="*"
          element={
            <AuthRoute
              as={() => <Navigate to={`/companies/${env}/${slug}/configurations`} replace />}
              env={env}
              slug={slug}
            />
          }
        />
      </FlexRoutes>

      <DiffDialog
        isOpen={!!copyDialog}
        onClose={() => setCopyDialog(null)}
        onConfirm={() => setCopyDialog(null)}
        {...copyDialog}
      />
    </>
  )
}

export default CompanyRoutes
