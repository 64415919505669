import React from 'react'
import { Table2 as Table } from 'src/ui'

interface FuturesTableProps {
  companyConfig: OfferManagementMapping
  availableCommodities: Array<string>
}

const newFuturesProductMapJson = (array, availableCommodities): FuturesProductMap[] => {
  return availableCommodities.map((commodity) => {
    let configuredCommodity = array?.find((config) => config.name === commodity)

    if (configuredCommodity) {
      return { ...configuredCommodity }
    }
    return { name: commodity, display_name: '', display: '' }
  })
}

const FuturesTable = ({ companyConfig, availableCommodities }: FuturesTableProps) => {
  const display = newFuturesProductMapJson(
    companyConfig.futures_product_map_json,
    availableCommodities
  )

  return (
    <Table striped>
      <thead>
        <tr>
          <th>CQG Symbol</th>
          <th>Display Name</th>
          <th>Bid Type</th>
          <th>Enabled</th>
        </tr>
      </thead>

      <tbody>
        {display.map((row) => (
          <tr key={row.name}>
            <td css={{ width: '200px' }}>{row.name}</td>
            <td css={{ width: '250px' }}>{row.display_name === '' ? `-` : row.display_name}</td>
            <td css={{ width: '200px' }}>{row.display === '' ? `-` : row.display}</td>
            <td
              css={{
                width: '200px',
                color: `${row.display_name || row.display !== '' ? '' : 'gray'}`,
                opacity: `${row.display_name || row.display !== '' ? '' : '0.5'}`,
              }}
            >
              {row.display_name || row.display !== '' ? 'Enabled' : 'Disabled'}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default FuturesTable
