import React from 'react'
import ReactSelect, { Props as ReactSelectProps } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { useTheme } from '@chakra-ui/react'
import {
  selectTheme,
  selectStyles,
  sortOptions as optionsSorter,
  SelectOption,
} from 'src/ui/select-utils'

interface SelectProps {
  id?: string
  name?: string
  value?: string | number | string[] | number[]
  options: SelectOption[]
  placeholder?: string
  hasError?: boolean
  isDisabled?: boolean
  isLoading?: boolean
  isOptionDisabled?: ReactSelectProps['isOptionDisabled']
  isClearable?: boolean
  isMulti?: boolean
  menuIsOpen?: boolean
  isSearchable?: boolean
  sortOptions?: boolean
  width?: number | string
  onBlur?: any
  onChange: any
  styles?: any
  menuPortalTarget?: any
  menuPosition?: any
  menuPlacement?: any
  theme?: any
  isCreatable?: boolean
  formatCreateLabel?: any
}

const Select: React.FC<SelectProps> = ({
  id,
  name,
  value,
  options,
  width = 'auto',
  placeholder = 'Select...',
  hasError = false,
  isDisabled = false,
  isLoading = false,
  isClearable = true,
  isMulti = false,
  isSearchable = true,
  sortOptions = true,
  isOptionDisabled,
  onChange,
  onBlur,
  styles,
  menuPortalTarget,
  menuPosition,
  menuPlacement,
  isCreatable = false,
  formatCreateLabel,
  ...rest
}) => {
  let chakraTheme = useTheme()
  let selectedValue = null

  if (typeof value !== 'undefined') {
    if (isMulti && Array.isArray(value)) {
      selectedValue = options.filter((opt) => (value as (string | number)[]).includes(opt.value))
    } else {
      selectedValue = options.find((opt) => opt.value === value)
    }
  } else {
    selectedValue = isMulti ? [] : null
  }

  const commonProps = {
    inputId: id,
    name,
    value: selectedValue,
    options: sortOptions ? optionsSorter(options) : options,
    placeholder,
    isDisabled,
    isLoading,
    isClearable,
    isMulti,
    isSearchable,
    isOptionDisabled,
    theme: (theme) => selectTheme({ theme, chakraTheme }),
    styles: {
      ...styles,
      ...selectStyles({ styles, chakraTheme, width, hasError }),
    },
    onChange,
    onBlur,
    menuPortalTarget,
    menuPosition,
    menuPlacement,
  }

  if (isCreatable) {
    return <CreatableSelect {...commonProps} formatCreateLabel={formatCreateLabel} {...rest} />
  }

  return <ReactSelect {...commonProps} menuPlacement={menuPlacement} {...rest} />
}

export default Select
