// This Dialog is for ADDING secrets
import React, { memo, useCallback, useState } from 'react'
import * as Yup from 'yup'
import { useQueryClient } from '@tanstack/react-query'
import { Formik, Form, Field } from 'formik'
import { TextField as FormikTextField } from 'src/ui/formik'
import { Box, IconButton, Column, Row, Dialog, Button, AddIcon } from 'src/ui'
import { createTranslatorSecret } from 'src/utils/api'
import { useToast } from 'src/utils/toast'

const secretFormSchema = Yup.object().shape({
  name: Yup.string().required('Required').max(100, 'cannot exceed 100 characters.'),
  value: Yup.string(),
})

interface SecretDialogProps extends Omit<DialogProps, 'isOpen'> {
  componentTitle: string
  translator: Translator
}

export const SecretDialog: React.FC<SecretDialogProps> = ({
  onClose,
  componentTitle,
  translator,
}) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const [secretCount, setSecretCount] = useState(1)
  const translatorId = translator.id
  const companySlug = translator.company_slug

  const addSecret = useCallback(
    async function ({ name, value }: { name: string; value: string }) {
      const translatorSecret = {
        id: null,
        translatorId,
        companySlug,
        name,
        value,
        createdAt: null,
        updatedAt: null,
      }

      let [err, response] = await createTranslatorSecret({ translatorSecret })

      if (response) {
        await queryClient.invalidateQueries({ queryKey: ['translator-secrets'] })
        toast({ status: 'success', description: translatorSecret.name + ' saved!' })
      } else {
        toast({ status: 'error', description: err + '' })
      }
    },
    [translatorId, companySlug, queryClient, toast]
  )

  return (
    <Dialog
      title={componentTitle}
      isOpen={true}
      onClose={onClose}
      actions={
        <>
          <Button size="sm" colorScheme="primary" backgroundColor="#607886" onClick={onClose}>
            Close
          </Button>
        </>
      }
    >
      {[...Array(secretCount).keys()].map((index) => {
        return <SecretForm key={index} addSecret={addSecret} />
      })}

      <Row>
        <IconButton
          aria-label="Add Secret"
          icon={<AddIcon />}
          isRound
          variant="solid"
          color="green.500"
          fill="blue.500"
          onClick={() => {
            setSecretCount(secretCount + 1)
          }}
        />
        <Column
          marginLeft="8px"
          marginTop="10px"
          fontSize="12px"
          fontStyle="normal"
          fontWeight="700"
          lineHeight="15px"
        >
          Add Secret
        </Column>
      </Row>
    </Dialog>
  )
}

interface SecretFormProps {
  addSecret: (args: { name: string; value: string }) => Promise<void>
}

const SecretForm = memo(function SecretForm({ addSecret }: SecretFormProps) {
  return (
    <Formik
      initialValues={{ name: '', value: '' }}
      validationSchema={secretFormSchema}
      onSubmit={async (values, { resetForm }) => {
        await addSecret(values)
        resetForm({ values })
      }}
    >
      {({ values, dirty }) => {
        console.log('secret-form render', { name: values.name, dirty })

        return (
          <Form>
            <Row alignItems="center">
              <Box flex={1} mr={2}>
                <Field
                  name={'name'}
                  label="Key"
                  placeholder="-Type Secret Key-"
                  component={FormikTextField}
                />
              </Box>

              <Box flex={1} mr={2}>
                <Field
                  name="value"
                  label="Value"
                  placeholder="-Type Secret Value-"
                  component={FormikTextField}
                />
              </Box>

              <Box pt={1}>
                <Button type="submit" isDisabled={!dirty} size="sm" colorScheme="primary">
                  Save
                </Button>
              </Box>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
})
