import React from 'react'
import moment, { Moment } from 'moment'
import { useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { get } from 'lodash'
import {
  Box,
  Row,
  Input,
  InputGroup,
  InputRightElement,
  Placeholders,
  Select,
  Table2,
  useTable,
  SearchIcon,
  CloseIcon,
} from 'src/ui'
import { centreAPIs } from 'src/utils/api'
import { SelectOption } from 'src/ui/select-utils'
import { yearOptions } from 'src/reports/utils'

const REPORT_KEYS = {
  CREATED: 'esign-contracts-created-month-count',
  COMPLETED: 'esign-contracts-signed-month-count',
  UNSIGNED: 'esign-contracts-unsigned-month-count',
}
const FEATURE_ENABLED_DATE = moment('2020-01-01')

export const useEsign = ({ year, month }) => {
  let { data, status, error, refetch, isFetching } = useQuery({
    queryKey: ['esign-report', { year, month }],
    queryFn: async () => {
      let [err, response] = await centreAPIs.getEsignContractsReport({
        key: moment().year(year).month(month).startOf('month').format('YYYY-MM-DD'),
      })
      if (err) throw err
      return response.data.data
    },
    cacheTime: 0,
  })

  return {
    data,
    status,
    error,
    refetch,
    isFetching,
  }
}

const isOptionDisabled = (year: number) => (option: SelectOption) => {
  let enabledDate = FEATURE_ENABLED_DATE
  let currentDate = moment().startOf('month')
  let optionDate = moment().year(year).month(option.value).startOf('month')

  return optionDate.isSameOrAfter(currentDate) || optionDate.isBefore(enabledDate)
}

const navigateToDate = (navigate, date: Moment) => {
  navigate(`/reports/esign/${date.format('YYYY-MM')}`)
}

const EsignReport = ({ companies }) => {
  const { date } = useParams()
  let currentDate = moment(date)
  let month = currentDate.month()
  let year = currentDate.year()
  let [tableData, setTableData] = React.useState([])

  let { data, status, error } = useEsign({
    year,
    month,
  })

  let columns = React.useMemo(
    () => [
      {
        id: 'company_name',
        accessor: 'company_name',
        Header: 'Company',
      },
      {
        id: 'created',
        accessor: 'created',
        Header: 'Created',
      },
      {
        id: 'completed',
        accessor: 'completed',
        Header: 'Completed',
      },
      {
        id: 'unsigned',
        accessor: 'unsigned',
        Header: 'Unsigned',
      },
    ],
    []
  )

  let {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    globalFilter,
    // page,
    // pagination,
    // totalCount,
  } = useTable({
    data: tableData,

    tableOptions: {
      initialState: {
        sortBy: [{ id: 'company_name' }],
      },
    },

    columns,
  })

  React.useEffect(() => {
    setTableData(
      companies.map((company) => ({
        company_name: company.name,
        created: get(data, `${company.id}.${REPORT_KEYS.CREATED}`, '-'),
        completed: get(data, `${company.id}.${REPORT_KEYS.COMPLETED}`, '-'),
        unsigned: get(data, `${company.id}.${REPORT_KEYS.UNSIGNED}`, '-'),
      }))
    )
  }, [data, companies])

  const navigate = useNavigate()

  return (
    <>
      <Row mb={4}>
        <Box width={200} mr={3}>
          <Select
            id="month"
            name="month"
            placeholder="Month"
            value={month}
            options={[
              { value: 0, label: 'January' },
              { value: 1, label: 'February' },
              { value: 2, label: 'March' },
              { value: 3, label: 'April' },
              { value: 4, label: 'May' },
              { value: 5, label: 'June' },
              { value: 6, label: 'July' },
              { value: 7, label: 'August' },
              { value: 8, label: 'September' },
              { value: 9, label: 'October' },
              { value: 10, label: 'November' },
              { value: 11, label: 'December' },
            ]}
            isOptionDisabled={isOptionDisabled(year)}
            isSearchable
            isClearable={false}
            sortOptions={false}
            onChange={(e) => navigateToDate(navigate, currentDate.month(e.value))}
          />
        </Box>

        <Box width={200} mr={3}>
          <Select
            id="year"
            name="year"
            placeholder="Year"
            value={year}
            options={yearOptions(FEATURE_ENABLED_DATE)}
            isSearchable
            isClearable={false}
            sortOptions={false}
            onChange={(e) => navigateToDate(navigate, currentDate.year(e.value))}
          />
        </Box>

        <Row flex={1} justifyContent="flex-end">
          <Box width="100%" maxWidth={300}>
            <InputGroup size="sm" mt="-2px">
              <Input
                variant="flushed"
                value={globalFilter.globalFilter || ''}
                placeholder="Search"
                borderBottom="1px solid"
                borderColor="gray.400"
                _placeholder={{ borderColor: 'gray.500' }}
                _focus={{ borderColor: 'gray.400' }}
                onChange={(e) => globalFilter.setGlobalFilter(e.target.value || undefined)}
              />
              <InputRightElement width="1rem">
                {!!globalFilter.globalFilter ? (
                  <button tabIndex={-1} onClick={() => globalFilter.setGlobalFilter(undefined)}>
                    <CloseIcon color="gray.400" fontSize="0.75rem" />
                  </button>
                ) : (
                  <SearchIcon fontSize="inherit" color="gray.400" />
                )}
              </InputRightElement>
            </InputGroup>
          </Box>
        </Row>
      </Row>

      <Box mb={4}>
        {error && <Placeholders.FailedState />}
        {status === 'loading' && <Placeholders.LoadingState />}
        {!error && status === 'success' && (
          <Table2 {...getTableProps()}>
            <Table2.Header headerGroups={headerGroups} />
            <Table2.Body
              isLoading={false}
              rows={rows}
              getTableBodyProps={getTableBodyProps}
              prepareRow={prepareRow}
            />
          </Table2>
        )}
      </Box>
    </>
  )
}

export default EsignReport
