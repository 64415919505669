import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Formik, Form, Field } from 'formik'
import { Box, Row, Dialog, Button } from 'src/ui'
import { TextField as FormikTextField } from 'src/ui/formik'
import { updateTranslatorSecret } from 'src/utils/api'
import { useToast } from 'src/utils/toast'

interface EditDialogProps extends DialogProps {
  componentTitle: string
  translator: Translator
  secret: TranslatorSecret
  password: string
}

export const EditDialog: React.FC<EditDialogProps> = ({
  isOpen,
  onClose,
  componentTitle,
  translator,
  secret,
  password,
}) => {
  let toast = useToast()
  const queryClient = useQueryClient()

  async function updateSecret(value: string) {
    const translatorSecret = {
      id: secret.id,
      companySlug: translator.company_slug,
      translatorId: translator.id,
      name: secret.name,
      value,
      createdAt: secret.created_at,
      updatedAt: secret.updated_at,
    }

    let [err, response] = await updateTranslatorSecret({ translatorSecret })

    if (response) {
      onClose()
      await queryClient.invalidateQueries({ queryKey: ['translator-secrets'] })
      toast({ status: 'success', description: translatorSecret.name + ' updated!' })
    } else {
      toast({ status: 'error', description: err + '' })
    }
  }

  return (
    <Dialog
      title={componentTitle}
      isOpen={isOpen}
      onClose={onClose}
      actions={
        <>
          <Button
            size="sm"
            colorScheme="primary"
            backgroundColor="#607886"
            color="white"
            mr={2}
            onClick={onClose}
          >
            Close
          </Button>
        </>
      }
    >
      <Formik
        enableReinitialize
        initialValues={{
          name: secret?.name,
          value: password,
        }}
        onSubmit={async (values) => {
          await updateSecret(values.value)
        }}
      >
        {(formikProps) => (
          <Form>
            <Row alignItems="center">
              <Box flex={1} mr={2}>
                <Field name="name" label="Key" disabled={true} component={FormikTextField} />
              </Box>

              <Box flex={1} mr={2}>
                <Field name="value" label="Value" component={FormikTextField} />
              </Box>

              <Box pt={1}>
                <Button
                  size="sm"
                  colorScheme="primary"
                  type="submit"
                  isLoading={formikProps.isSubmitting}
                >
                  Update
                </Button>
              </Box>
            </Row>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}
