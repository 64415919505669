import React, { useState } from 'react'
import { Box, Column, Row, Panel } from 'src/ui'
import { Button } from '@chakra-ui/react'
import { SecretDialog } from '../dialogs/secret-dialog'
import { SecretTable } from './secret-table'
import { useSecrets } from '../../api/queries/translator-secrets'

interface SecretProps {
  translator: Translator
}

const Secret = ({ translator }: SecretProps) => {
  let [isOpen, setIsOpen] = useState(false)

  const secretsQuery = useSecrets({ translatorID: translator.id })

  return (
    <Panel
      header={
        <Row width="100%" justifyContent="space-between">
          <Box fontWeight="bold">Secrets</Box>
          <Button
            size="sm"
            mr={3}
            colorScheme="primary"
            onClick={(e) => {
              e.stopPropagation()
              setIsOpen(true)
            }}
          >
            Add
          </Button>
        </Row>
      }
    >
      <SecretTable secrets={secretsQuery.data ?? []} translator={translator} />

      {isOpen && (
        <Column width="100%">
          <SecretDialog
            onClose={() => setIsOpen(false)}
            onConfirm={null}
            componentTitle="Add Secret"
            translator={translator}
          />
        </Column>
      )}
    </Panel>
  )
}

export default Secret
