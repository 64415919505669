import { useQuery, useMutation } from '@tanstack/react-query'
import * as api from 'src/utils/api'

export const useEndpoints = () => {
  return useQuery({
    queryKey: ['endpoints'],
    queryFn: async () => {
      let [err, response] = await api.getEndpoints()
      if (err) throw err
      return response.data
    },
    cacheTime: 0,
  })
}

export const useFeatures = () => {
  return useQuery({
    queryKey: ['features'],
    queryFn: async () => {
      let [err, response] = await api.getFeatures()
      if (err) throw err
      return response.data
    },
    cacheTime: 0,
  })
}

export const useEndpointMutations = () => {
  let { mutateAsync: saveEndpoint } = useMutation({
    mutationFn: async ({ endpoint }: { endpoint: Endpoint }) => {
      if (endpoint.id) {
        let [err, response] = await api.updateEndpoint({ endpoint })
        if (err) throw err
        return [err, response]
      }

      let [err, response] = await api.createEndpoint({ endpoint })
      if (err) throw err

      return [err, response]
    },
  })

  let { mutateAsync: deleteEndpoint } = useMutation({
    mutationFn: async ({ endpoint }: { endpoint: Endpoint }) => {
      let [err, response] = await api.deleteEndpoint({ endpoint })
      if (err) throw err
      return [err, response]
    },
  })

  return { saveEndpoint, deleteEndpoint }
}

export const useSubscriptionMutations = () => {
  let { mutateAsync: addSubscription } = useMutation({
    mutationFn: async ({
      endpoint,
      subscription,
    }: {
      endpoint: Endpoint
      subscription: EndpointSubscription
    }) => {
      let [err, res] = await api.addEndpointSubscription({ endpoint, subscription })
      if (err) throw err
      return [err, res]
    },
  })

  let { mutateAsync: removeSubscription } = useMutation({
    mutationFn: async ({
      endpoint,
      subscription,
    }: {
      endpoint: Endpoint
      subscription: EndpointSubscription
    }) => {
      let [err, res] = await api.removeEndpointSubscription({ endpoint, subscription })
      if (err) throw err
      return [err, res]
    },
  })

  return { addSubscription, removeSubscription }
}
