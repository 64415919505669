import React from 'react'
import { connect } from 'react-redux'
import { css } from '@emotion/react'
import { RootState, Dispatch } from 'src/store'
import Navigation from 'src/layout/sidebar/navigation'
import CompanyList from 'src/layout/sidebar/company-list'
import {
  Box,
  InputGroup,
  SmallCloseIcon,
  Input,
  InputRightElement,
  Spinner,
  SearchIcon,
} from 'src/ui'
import * as api from 'src/utils/api'
import { hasPermission } from 'src/utils/permissions'

const ESCAPE_KEY_CODE = 27

const getSelectedCompany = (slug: string, { status, data: companies }) =>
  status === api.STATUS.loaded ? companies.find((c) => c.slug === slug) : null

const getFilteredCompanies = (inputValue: string, { data: companies }) => {
  if (!inputValue) return companies
  return companies.filter(
    (c) =>
      c.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 ||
      c.slug.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
  )
}

const Sidebar = ({ companies, settings, setSettingsState }) => {
  let sidebarRef = React.useRef<HTMLDivElement>()

  let [inputValue, setInputValue] = React.useState(null)
  let [isSearching, setIsSearching] = React.useState(false)

  let isLoading = companies.status === api.STATUS.loading
  let companyList = getFilteredCompanies(inputValue, companies)
  let selectedCompany = getSelectedCompany(settings.companySlug, companies)

  const handleBodyClick = (e) => {
    if (sidebarRef.current.contains(e.target)) return

    setIsSearching(false)
  }

  React.useEffect(() => {
    if (isSearching) {
      document.addEventListener('mousedown', handleBodyClick)
    } else {
      document.removeEventListener('mousedown', handleBodyClick)
    }

    return () => document.removeEventListener('mousedown', handleBodyClick)
  }, [isSearching])

  return (
    <Box
      data-cy="sidebar"
      ref={sidebarRef}
      width="235px"
      height="calc(100vh - 60px)"
      pb={4}
      mt="60px"
      overflow="auto"
      bg="#fff"
      boxShadow="md"
      zIndex={1}
    >
      {hasPermission('company_selection') && (
        <InputGroup position="relative" mt="1.75rem" mx={6} mb={4} w="80%">
          <Input
            data-cy="search-input"
            variant="flushed"
            size="md"
            height="2.25rem"
            placeholder={selectedCompany ? selectedCompany.name : isLoading ? '' : 'Company'}
            spellCheck="false"
            value={isSearching ? inputValue : selectedCompany ? selectedCompany.name : ''}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.keyCode === ESCAPE_KEY_CODE) {
                // pressed escape
                setIsSearching(false)
                e.currentTarget.blur()
              }
            }}
            css={css`
              width: 100%;
              font-weight: 600;
              padding-right: 15%;
            `}
            borderBottom={isSearching ? '1px' : '2px'}
            onFocus={() => {
              setInputValue('')
              setIsSearching(true)
            }}
          />

          {!isLoading && (
            <InputRightElement width="1rem">
              <Box color="#a2a2a2" pb="8px">
                {isSearching ? (
                  <button tabIndex={-1} onClick={() => setIsSearching(false)}>
                    <SmallCloseIcon fontSize="1.25rem" color="inherit" />
                  </button>
                ) : (
                  <SearchIcon fontSize="1rem" color="inherit" />
                )}
              </Box>
            </InputRightElement>
          )}

          {isLoading && (
            <Box
              css={{
                position: 'absolute',
                top: '5px',
                width: '100%',
                textAlign: 'center',
                color: '#aaa',
              }}
            >
              <Spinner speed="0.65s" size="sm" />
            </Box>
          )}
        </InputGroup>
      )}

      {hasPermission('company_selection') && isSearching ? (
        <CompanyList
          settings={settings}
          inputValue={inputValue}
          companyList={companyList}
          setIsSearching={setIsSearching}
          setSettingsState={setSettingsState}
        />
      ) : (
        <Navigation settings={settings} setSettingsState={setSettingsState} />
      )}
    </Box>
  )
}

const mapState = ({ settings, companies }: RootState) => ({ settings, companies })
const mapDispatch = ({ settings }: Dispatch) => ({
  setSettingsState: settings.setState,
})

export default connect(mapState, mapDispatch)(Sidebar)
