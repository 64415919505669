import { rest } from 'msw'
import { CONFIG } from 'src/utils/config'
import {
  brands,
  flavors,
  builds,
  createFlavor,
  createBrand,
  updateBrand,
  updateFlavor,
  createBuild,
  updateBuild,
} from 'src/fixtures/branding'

export const handlers = [
  /////////////////////// FLAVOR //////////////////////////////////

  rest.post(`${CONFIG.branding_url}/GetFlavorBySlug`, async (req, res, ctx) => {
    const { slug } = (await req.json()) ?? {}
    const flavor = flavors.find((flavor) => flavor.slug === slug)
    if (flavor) {
      return res(
        ctx.status(200),
        ctx.json({ data: flavor, type: 'api.platform.branding.v1.response#FlavorGetResponse' })
      )
    }
    return res(
      ctx.status(404),
      ctx.json({ type: 'api.common#NotFound', message: 'string', status: 404 })
    )
  }),

  rest.post(`${CONFIG.branding_url}/CreateFlavor`, async (req, res, ctx) => {
    const requestBody = await req.json()
    const newFlavor = createFlavor(requestBody)
    flavors.push(newFlavor)
    return res(
      ctx.status(200),
      ctx.json({ data: newFlavor, type: 'api.platform.branding.v1.response#FlavorGetResponse' })
    )
  }),

  rest.post(`${CONFIG.branding_url}/UpdateFlavor`, async (req, res, ctx) => {
    const requestBody = await req.json()
    const updatedFlavor = updateFlavor(requestBody)
    return res(
      ctx.status(200),
      ctx.json({ data: updatedFlavor, type: 'api.platform.branding.v1.response#FlavorGetResponse' })
    )
  }),

  rest.post(`${CONFIG.branding_url}/GetAllFlavors`, (_req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({ data: flavors, type: 'api.platform.branding.v1.response#FlavorListResponse' })
    )
  }),

  /////////////////////// BRAND /////////////////////////////////

  rest.post(`${CONFIG.branding_url}/GetBrandBySlug`, async (req, res, ctx) => {
    const { slug } = (await req.json()) ?? {}
    const flavor = flavors.find((flavor) => flavor.slug === slug)
    const brand = brands.find((brand) => brand.flavorId === flavor?.id)
    if (flavor && brand) {
      return res(
        ctx.status(200),
        ctx.json({ data: brand, type: 'api.platform.branding.v1.response#BrandGetResponse' })
      )
    }
    return res(
      ctx.status(404),
      ctx.json({ type: 'api.common#NotFound', message: 'string', status: 404 })
    )
  }),

  rest.post(`${CONFIG.branding_url}/CreateBrand`, async (req, res, ctx) => {
    const reqestData = await req.json()
    const newBrand = createBrand(reqestData)
    brands.push(newBrand)
    return res(
      ctx.status(200),
      ctx.json({ data: newBrand, type: 'api.platform.branding.v1#CreateBrandSuccess' })
    )
  }),

  rest.post(`${CONFIG.branding_url}/UpdateBrand`, async (req, res, ctx) => {
    const reqestData = await req.json()
    const updatedBrand = updateBrand(reqestData)
    return res(
      ctx.status(200),
      ctx.json({ data: updatedBrand, type: 'api.platform.branding.v1.response#BrandGetResponse' })
    )
  }),

  ///////////////////////// BUILD ////////////////////////////

  rest.post(`${CONFIG.branding_url}/GetBuildBySlug`, async (req, res, ctx) => {
    // example for a "not found" state
    const { slug } = (await req.json()) ?? {}
    const flavor = flavors.find((flavor) => flavor.slug === slug)
    const build = builds.find((build) => build.flavorId === flavor?.id)
    if (flavor && build) {
      return res(
        ctx.status(200),
        ctx.json({ data: build, type: 'api.platform.branding.v1.response#BuildGetResponse' })
      )
    }
    return res(
      ctx.status(404),
      ctx.json({ type: 'api.common#NotFound', message: 'string', status: 404 })
    )
  }),

  rest.post(`${CONFIG.branding_url}/CreateBuild`, async (req, res, ctx) => {
    const reqestData = await req.json()
    const newBuild = createBuild(reqestData)
    builds.push(newBuild)
    return res(
      ctx.status(200),
      ctx.json({ data: newBuild, type: 'api.platform.branding.v1.response#BuildGetResponse' })
    )
  }),

  rest.post(`${CONFIG.branding_url}/UpdateBuild`, async (req, res, ctx) => {
    const reqestData = await req.json()
    const updatedBuild = updateBuild(reqestData)
    return res(
      ctx.status(200),
      ctx.json({ data: updatedBuild, type: 'api.platform.branding.v1.response#BuildGetResponse' })
    )
  }),

  rest.post(`${CONFIG.branding_url}/CreateMobileBuild`, (_req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        type: 'api.common.response#EmptyResponse',
      })
    )
  }),
]
