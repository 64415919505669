import { ICompany, IPosition } from './api'

const baseUri = '/position'

// GET INTERFACE
export interface GetPositionsRequest {
  companySlug: ICompany['slug']
}

// CREATE INTERFACES
export interface CreatePositionRequest {
  company_id: ICompany['id']
  remote_id: IPosition['remote_id']
  display_name: IPosition['display_name']
  remote_elevator_id: IPosition['remote_elevator_id']
}

export interface BulkCreatePositionRequest {
  company_id: ICompany['id']
  remote_id: IPosition['remote_id']
  display_name: IPosition['display_name']
  elevators: Array<number>
}

// UPDATE INTERFACES
export interface UpdatePositionRequest {
  _revision: string
  id: IPosition['id']
  remote_id: IPosition['remote_id']
  display_name: IPosition['display_name']
  remote_elevator_id: IPosition['remote_elevator_id']
}

export interface BulkUpdatePositionRequest {
  remote_id: IPosition['remote_id']
  display_name: IPosition['display_name']
  elevators?: Array<number>
  ids?: Array<number>
}

// DELETE INTERFACES
export interface DeletePositionRequest {
  id: IPosition['id']
}

export interface BulkDeletePositionRequest {
  remote_id: string
  company_id: ICompany['id']
  ids?: Array<number>
}

//NEW WAY OF DOING THINGS
export const positionRequests = {
  ///////////////////////////////////////////////////////////////////////////////
  // GET
  ///////////////////////////////////////////////////////////////////////////////

  getPositions:
    (api) =>
    async ({ companySlug }: GetPositionsRequest) => {
      try {
        let response = await api.get(`${baseUri}s?company_slug=${companySlug}`)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  ///////////////////////////////////////////////////////////////////////////////
  // CREATE
  ///////////////////////////////////////////////////////////////////////////////

  createPosition:
    (api) =>
    async ({ remote_id, company_id, display_name, remote_elevator_id }: CreatePositionRequest) => {
      try {
        const response = await api.post(baseUri, {
          remote_id,
          company_id,
          display_name,
          remote_elevator_id,
        })
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  bulkCreatePositions:
    (api) =>
    async ({ remote_id, company_id, display_name, elevators }: BulkCreatePositionRequest) => {
      try {
        const response = await api.post(baseUri, {
          remote_id,
          company_id,
          display_name,
          elevators,
        })
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  ///////////////////////////////////////////////////////////////////////////////
  // UPDATE
  ///////////////////////////////////////////////////////////////////////////////

  updatePosition:
    (api) =>
    async ({
      id,
      _revision,
      remote_id,
      display_name,
      remote_elevator_id,
    }: UpdatePositionRequest) => {
      try {
        const response = await api.put(`${baseUri}/${id}`, {
          _revision,
          remote_id,
          display_name,
          remote_elevator_id,
        })
        return [null, response]
      } catch (error) {
        return [error, true]
      }
    },

  bulkUpdatePositions:
    (api) =>
    async ({ remote_id, display_name, ids, elevators }: BulkUpdatePositionRequest) => {
      try {
        const response = await api.put(`${baseUri}s`, {
          remote_id,
          display_name,
          ids,
          elevators,
        })
        return [null, response]
      } catch (error) {
        return [error, true]
      }
    },

  ///////////////////////////////////////////////////////////////////////////////
  // DELETE
  ///////////////////////////////////////////////////////////////////////////////

  deletePosition:
    (api) =>
    async ({ id }: DeletePositionRequest) => {
      try {
        await api.delete(`${baseUri}/${id}`)
        return [null, true]
      } catch (err) {
        return [err, null]
      }
    },

  bulkDeletePositions:
    (api) =>
    async ({ ids, remote_id, company_id }: BulkDeletePositionRequest) => {
      try {
        await api.delete(`${baseUri}`, {
          data: {
            ids: ids,
            remote_id: remote_id,
            company_id: company_id,
          },
        })
        return [null, true]
      } catch (err) {
        return [err, null]
      }
    },
}
