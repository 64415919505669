import React from 'react'
import Row from 'src/ui/row'
import { Box, ChevronDownIcon, ChevronUpIcon, Collapse, IconButton } from 'src/ui'

interface PanelProps {
  header: string | React.ReactNode
  bodyStyle?: any
  panelProps?: object
  headerProps?: object
  hideToggleIcon?: boolean
  defaultExpanded?: boolean
  expanded?: boolean
  onChange?: (...args: any) => void
  children?: React.ReactNode
}

const Panel: ({
  children,
  bodyStyle,
  panelProps,
  headerProps,
  header,
  hideToggleIcon,
  defaultExpanded,
  expanded,
  onChange,
}: PanelProps) => React.JSX.Element = ({
  children,
  bodyStyle,
  panelProps,
  headerProps,
  header,
  hideToggleIcon = false,
  defaultExpanded = false,
  expanded,
  onChange,
}) => {
  let [innerExpanded, setInnerShow] = React.useState(defaultExpanded)
  const innerToggle = () => setInnerShow(!innerExpanded)

  let actualExpanded = typeof expanded !== 'undefined' ? expanded : innerExpanded
  const actualToggle = typeof onChange !== 'undefined' ? (e) => onChange(e, !expanded) : innerToggle

  return (
    <Box px={6} py={2} mb={3} borderRadius={3} bg="white" boxShadow="md" {...panelProps}>
      <Row
        justifyContent="space-between"
        alignItems="center"
        cursor="pointer"
        onClick={actualToggle}
        {...headerProps}
      >
        <Box flex={1}>{header}</Box>
        <Box>
          {!hideToggleIcon && (
            <IconButton
              icon={actualExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
              aria-label="Toggle"
              isRound
              fontSize="24px"
              variant="ghost"
              color="#8C9BA5"
            />
          )}
        </Box>
      </Row>

      <Collapse py={2} in={actualExpanded} {...bodyStyle}>
        {children}
      </Collapse>
    </Box>
  )
}

export default Panel
