import { useQuery, useMutation } from '@tanstack/react-query'
import { centreAPIs } from 'src/utils/api'
import { IConnectCustomer } from 'src/api/api'

export const useConnectCustomers = (env) => {
  return useQuery({
    queryKey: ['connect-customers', { env }],
    queryFn: async () => {
      let [err, response] = await centreAPIs.getConnectCustomers()

      if (err) throw err
      return response.data.data
    },
    cacheTime: 0,
  })
}

export const useConnectCustomerMutations = () => {
  let { mutateAsync: saveConnectCustomer } = useMutation({
    mutationFn: async (params: Partial<IConnectCustomer>) => {
      if (params.id && params._revision) {
        let [err, response] = await centreAPIs.updateConnectCustomer(params)
        if (err) throw err
        return [err, response]
      }

      let [err, response] = await centreAPIs.createConnectCustomer(params)
      if (err) throw err
      return [err, response]
    },
  })

  let { mutateAsync: deleteConnectCustomer } = useMutation({
    mutationFn: async ({ id }: { id: number }) => {
      let [err, response] = await centreAPIs.deleteConnectCustomer({ id })
      if (err) throw err
      return [err, response]
    },
  })

  return { saveConnectCustomer, deleteConnectCustomer }
}
