const ENVS = {
  LOCAL: 'local',
  DEV: 'dev',
  CA: 'ca',
  LABS: 'labs',
  PRODUCTION: 'production',
  UNKNOWN: 'unknown',
}

function getEnv() {
  const href = window.location.href

  if (href.includes('localhost')) return ENVS.LOCAL
  if (href.includes('admin.dev')) return ENVS.DEV
  if (href.includes('admin.ca')) return ENVS.CA
  if (href.includes('admin.labs')) return ENVS.LABS
  if (href.includes('admin.bushelops')) return ENVS.PRODUCTION

  return ENVS.UNKNOWN
}

function getKeycloakRoleClaimPath(): string {
  switch (getEnv()) {
    case ENVS.DEV:
    case ENVS.LOCAL:
      return 'resource_access.bushel-admin-portal-webapp-client-dev.roles'

    case ENVS.LABS:
      return 'resource_access.bushel-admin-portal-webapp-client-labs.roles'

    case ENVS.CA:
      return 'resource_access.bushel-admin-portal-webapp-client-prod-ca.roles'

    default:
      return 'resource_access.bushel-admin-portal-webapp-client-prod.roles'
  }
}

export const CONFIG = {
  env: getEnv(),

  bushel_env: window.appConfig.bushel_env || process.env.REACT_APP_BUSHEL_ENV,
  region: window.appConfig.region || process.env.REACT_APP_REGION,
  domain_suffix: window.appConfig.domain_suffix || process.env.REACT_APP_DOMAIN_SUFFIX,

  // configurator api
  configurator_api_url: window.appConfig.api_url || process.env.REACT_APP_CONFIGURATOR_URL,

  // centre api
  centre_prod_api_url:
    window.appConfig.centre_prod_api_url || process.env.REACT_APP_CENTRE_PROD_URL,
  centre_uat_api_url: window.appConfig.centre_uat_api_url || process.env.REACT_APP_CENTRE_UAT_URL,

  // keycloak
  keycloak_config_url:
    window.appConfig.keycloak_config_url || process.env.REACT_APP_KEYCLOAK_CONFIG_URL,
  keycloak_config_client_id:
    window.appConfig.keycloak_config_client_id || process.env.REACT_APP_KEYCLOAK_CONFIG_CLIENT_ID,
  keycloak_config_realm:
    window.appConfig.keycloak_config_realm || process.env.REACT_APP_KEYCLOAK_CONFIG_REALM,
  keycloak_token_role_claim:
    window.appConfig.keycloak_token_role_claim ||
    process.env.REACT_APP_PERMISSION_CLAIM ||
    getKeycloakRoleClaimPath(),

  // build & branding
  branding_url: window.appConfig.branding_url || process.env.REACT_APP_BRANDING_URL,

  // microservices
  microservice_api_url:
    window.appConfig.microservice_api_url || process.env.REACT_APP_MICROSERVICE_API_URL,

  // reporting base
  reporting_api_url: window.appConfig.reporting_api_url || process.env.REACT_APP_REPORTING_API_URL,

  bidl_api_url: window.appConfig.bidl_api_url || process.env.REACT_APP_BIDL_API_URL,
}

export const IS_PROD = CONFIG.bushel_env === 'prod'
export const IS_SANDBOX = CONFIG.bushel_env === 'sandbox'
export const IS_TESTING = CONFIG.bushel_env === 'test'
export const IS_DEV = CONFIG.bushel_env === 'dev'
export const IS_DEV_OR_SANDBOX = CONFIG.bushel_env === 'dev' || CONFIG.bushel_env === 'sandbox'
