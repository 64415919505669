import React from 'react'
import { Box } from '@chakra-ui/react'
import Row from 'src/ui/row'

const ConfigCard = ({ header, children, ...props }) => (
  <Box p={6} borderRadius={3} bg="white" boxShadow="md" {...props}>
    <Row width="100%" justifyContent="flex-end" alignItems="center">
      {header}
    </Row>
    {children}
  </Box>
)

export default ConfigCard
