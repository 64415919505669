import React from 'react'
import { TextField } from 'src/ui/formik'
import { Field } from 'formik'
import { Box } from 'src/ui'
import { Table2 as Table } from 'src/ui'

interface MappingsFormProps {
  locationConfig: OfferManagementMapping
  availableCommodities: Array<string>
}

const newCashBidProductMapJSON = (array, availableCommodities) => {
  return availableCommodities.map((commodity) => {
    let configuredCommodity = array.find((config) => config.name === commodity)
    if (configuredCommodity) {
      return configuredCommodity
    }
    return { name: commodity, cash: '', flat: '', basis: '', hedge: '' }
  })
}

const MappingsForm = ({ locationConfig, availableCommodities }: MappingsFormProps) => {
  return (
    <Table striped>
      <thead className="thead">
        <tr>
          <th>Commodity</th>
          <th>Cash</th>
          <th>Flat</th>
          <th>Basis</th>
          <th>Hedge</th>
        </tr>
      </thead>
      <tbody>
        {newCashBidProductMapJSON(
          locationConfig.cash_bid_product_map_json,
          availableCommodities
        ).map((product, index) => (
          <tr key={product.name}>
            <td css={{ width: '198px' }}>
              <Box>
                <p>{product.name}</p>
              </Box>
            </td>
            <td css={{ width: '250px' }}>
              <Box flex={1} minWidth={0} height={'40px'} mt={1} mb={0}>
                <Field
                  name={`cash_bid_product_map_json.${index}.cash`}
                  component={TextField}
                  placeholder={product.cash === '' ? product.name : product.cash}
                  mb={0}
                />
              </Box>
            </td>
            <td css={{ width: '250px' }}>
              <Box flex={1} minWidth={0} height={'40px'} mt={1} mb={0}>
                <Field
                  name={`cash_bid_product_map_json.${index}.flat`}
                  component={TextField}
                  placeholder={product.flat === '' ? product.name : product.flat}
                  mb={0}
                />
              </Box>
            </td>
            <td css={{ width: '250px' }}>
              <Box flex={1} minWidth={0} height={'40px'} mt={1} mb={0}>
                <Field
                  name={`cash_bid_product_map_json.${index}.basis`}
                  component={TextField}
                  placeholder={product.basis === '' ? product.name : product.basis}
                  mb={0}
                />
              </Box>
            </td>
            <td css={{ width: '250px' }}>
              <Box flex={1} minWidth={0} height={'40px'} mt={1} mb={0}>
                <Field
                  name={`cash_bid_product_map_json.${index}.hedge`}
                  component={TextField}
                  placeholder={product.hedge === '' ? product.name : product.hedge}
                  mb={0}
                />
              </Box>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default MappingsForm
