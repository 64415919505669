import React, { useState } from 'react'
import { Box, Row, Button } from 'src/ui'
import { Formik, Form } from 'formik'
import FuturesTable from 'src/companies/oms/futures/futures-table'
import FuturesForm from 'src/companies/oms/futures/futures-form'
import { UseMutateAsyncFunction } from '@tanstack/react-query'
import * as api from 'src/utils/api'
import { hasPermission } from 'src/utils/permissions'
import * as Yup from 'yup'

interface FuturesConfigProps {
  futuresConfig: OfferManagementMapping
  availableCommodities: Array<string>
  saveFuturesConfig: UseMutateAsyncFunction<
    api.ApiResponse<OfferManagementMapping>,
    unknown,
    {
      futuresConfig: OfferManagementMapping
      onSuccess?: () => void
    },
    unknown
  >
}

const formatProductMap = (products) => {
  return products
    .filter((product) => product.enabled)
    .map((product) => ({
      name: product.name,
      display_name: product.display_name || product.name,
      display: product.display || 'Trade',
    }))
}

const FormSchema = Yup.object().shape({
  futures_product_map_json: Yup.array()
    .of(
      Yup.object().shape({
        display_name: Yup.string(),
        display: Yup.string(),
        enabled: Yup.boolean(),
      })
    )
    .test('hasAMapping', 'You must enable a future to save', (value) =>
      value.some((mapping) => mapping.enabled)
    ),
})

const newFuturesProductMapJSON = (array, availableCommodities): FuturesProductMap[] => {
  return availableCommodities.map((commodity) => {
    let configuredCommodity = array ? array.find((config) => config.name === commodity) : null

    if (configuredCommodity) {
      return {
        name: configuredCommodity.name,
        display_name: configuredCommodity.display_name,
        display: configuredCommodity.display,
        enabled: true,
      }
    }
    return { name: commodity, display_name: '', display: '' }
  })
}

const FuturesConfig = ({
  futuresConfig,
  saveFuturesConfig,
  availableCommodities,
}: FuturesConfigProps) => {
  const [isEditing, setIsEditing] = useState(false)

  return (
    <>
      <Formik
        initialValues={{
          futures_product_map_json: newFuturesProductMapJSON(
            futuresConfig.futures_product_map_json,
            availableCommodities
          ),
        }}
        validateOnChange
        validationSchema={FormSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)
          values.futures_product_map_json = formatProductMap(values.futures_product_map_json)
          await saveFuturesConfig({
            futuresConfig: {
              cash_bid_product_map_json: null,
              elevator_id: null,
              cqg_location_name: null,
              created_at: futuresConfig.created_at,
              updated_at: null,
              id: futuresConfig.id,
              company_id: futuresConfig.company_id,
              ...values,
            },

            onSuccess: () => {
              setIsEditing(false)
              setSubmitting(false)
            },
          })
        }}
      >
        {({ errors, resetForm, isSubmitting }) => (
          <Form>
            <Box py={2} mb={4} borderRadius={3} bg="white" boxShadow="md">
              <Row justifyContent="space-between" alignItems="center" px={6} cursor="pointer">
                <Box flex={1}>
                  <Row
                    width="100%"
                    height="62px"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {!isEditing ? (
                      <>
                        <Row alignItems="center" mb={5} mt={4}></Row>
                        <Box pr={2}>
                          {' '}
                          <Button
                            key="edit"
                            width="76px"
                            size="sm"
                            colorScheme="primary"
                            onClick={() => setIsEditing(true)}
                            isDisabled={!hasPermission('offer_management_edit')}
                          >
                            Edit
                          </Button>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Row alignItems="center" mb={5} mt={4}></Row>
                        <p css={{ color: 'red' }}>{errors.futures_product_map_json as string}</p>
                        <Box pr={2}>
                          <Button
                            key="cancel"
                            width="76px"
                            size="sm"
                            mr={2}
                            colorScheme="secondary"
                            onClick={() => {
                              resetForm()
                              setIsEditing(false)
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            key="save"
                            type="submit"
                            width="76px"
                            size="sm"
                            colorScheme="primary"
                            isDisabled={isSubmitting}
                            isLoading={isSubmitting}
                          >
                            Save
                          </Button>
                        </Box>
                      </>
                    )}
                  </Row>
                </Box>
              </Row>
              {!isEditing ? (
                <FuturesTable
                  companyConfig={futuresConfig}
                  availableCommodities={availableCommodities}
                />
              ) : (
                <FuturesForm
                  futuresConfig={futuresConfig}
                  availableCommodities={availableCommodities}
                />
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default FuturesConfig
