import { brandingClient } from 'src/utils/api'

export interface CreateBrandRequest {
  colorPrimary: string
  logoStackedImage?: BrandImage
  iosSplashScreenImage?: BrandImage
  iosAppIconImage?: BrandImage
  logoIconImage?: BrandImage
  flavorId: string
  colorSecondary: string
  launcherImage?: BrandImage
  type?: string
  hideBushelBranding: boolean
}
export type UpdateBrandRequest = Omit<CreateBrandRequest, 'flavorId'> & { id: string }

export interface BrandImage {
  value: {
    mimeType: string
    imageStringBase64: string
    type?: string
  }
}

export interface CreateBuildRequest {
  androidBundleId: string
  iosIssuerId: string
  flavorId: string
  iosFastlaneUser: string
  iosDisplayName: string
  versionName: string
  iosCertTeamId: string
  type?: string
  iosKeyId: string
  iosProduceItcTeamName: string
  iosFastlanePassword: string
  iosApiKey: string
  iosBundleId: string
}
export type UpdateBuildRequest = Omit<CreateBuildRequest, 'flavorId'> & { id: string }

export interface CreateMobileBuildRequest {
  emails: string[]
  androidBuildType: MobileBuildType
  flavorIds: string[]
  buildType: MobileBuildType
  iosBranch: string
  type?: string
  platform: MobileBuildType
  androidBranch: string
  lane: MobileBuildType
}
interface MobileBuildType {
  data?: {
    type?: string
  }
  type: string
}

export interface CreateFlavorRequest {
  type?: string
  slug: string
  shortName: string
  fullName: string
}

export type UpdateFlavorRequest = CreateFlavorRequest & { id: string }

export const brandingRequests = {
  ///////////////////////////////////////////////////////////////////////////////
  // Get Flavor By Slug
  // url: https://architecture.bushelops.com/bushel-interfaces/latest/#tag/api.platform.branding.v1/operation/GetFlavorBySlug
  ///////////////////////////////////////////////////////////////////////////////
  getFlavorBySlug: async (params: { type?: string; slug: string }) =>
    brandingClient.post<{ data: Flavor }>('/GetFlavorBySlug', params),

  ///////////////////////////////////////////////////////////////////////////////
  // Create Brand
  // url: https://architecture.bushelops.com/bushel-interfaces/latest/#tag/api.platform.branding.v1/operation/CreateBrand
  ///////////////////////////////////////////////////////////////////////////////jll
  createBrand: async (params: CreateBrandRequest) =>
    brandingClient.post<{ data: Flavor }>('/CreateBrand', params),

  ///////////////////////////////////////////////////////////////////////////////
  // Get All Brands
  // url: https://architecture.bushelops.com/bushel-interfaces/latest/#tag/api.platform.branding.v1/operation/GetAllBrands
  ///////////////////////////////////////////////////////////////////////////////
  getAllBrands: async (params: { type?: string } = {}) =>
    brandingClient.post<{ data: Brand[] }>('/GetAllBrands', params),

  ///////////////////////////////////////////////////////////////////////////////
  // Get Brand By Id
  // url: https://architecture.bushelops.com/bushel-interfaces/latest/#tag/api.platform.branding.v1/operation/GetBrandById
  ///////////////////////////////////////////////////////////////////////////////
  getBrandbyId: async (params: { id: string; type?: string }) =>
    brandingClient.post<{ data: Brand }>('/GetBrandById', params),

  ///////////////////////////////////////////////////////////////////////////////
  // Get Brand By Slug
  // url: https://architecture.bushelops.com/bushel-interfaces/latest/#tag/api.platform.branding.v1/operation/GetBrandBySlug
  ///////////////////////////////////////////////////////////////////////////////
  getBrandbySlug: async (params: { slug: string; type?: string }) =>
    brandingClient.post<{ data: Brand }>('/GetBrandBySlug', params),

  ///////////////////////////////////////////////////////////////////////////////
  // Get All Flavors
  // url: https://architecture.bushelops.com/bushel-interfaces/latest/#tag/api.platform.branding.v1/operation/GetAllFlavors
  ///////////////////////////////////////////////////////////////////////////////
  getAllFlavors: async (params: { type?: string } = {}) =>
    brandingClient.post<{ data: Flavor[] }>('/GetAllFlavors', params),

  ///////////////////////////////////////////////////////////////////////////////
  // Delete Brand By Id
  // url: https://architecture.bushelops.com/bushel-interfaces/latest/#tag/api.platform.branding.v1/operation/DeleteBrandById
  ///////////////////////////////////////////////////////////////////////////////
  deleteBrandById: async (params: { id: string; type?: string }) =>
    brandingClient.post<{ data: Brand }>('/DeleteBrandById', params),

  ///////////////////////////////////////////////////////////////////////////////
  // Get Flavor By Id
  // url: https://architecture.bushelops.com/bushel-interfaces/latest/#tag/api.platform.branding.v1/operation/GetFlavorById
  ///////////////////////////////////////////////////////////////////////////////
  getFlavorById: async (params: { id: string; type?: string }) =>
    brandingClient.post<{ data: Flavor }>('/GetFlavorById', params),

  ///////////////////////////////////////////////////////////////////////////////
  // Delete Flavor By Id
  // url: https://architecture.bushelops.com/bushel-interfaces/latest/#tag/api.platform.branding.v1/operation/DeleteFlavorById
  ///////////////////////////////////////////////////////////////////////////////
  deleteFlavorById: async (params: { id: string; type?: string }) =>
    brandingClient.post<{ data: Flavor }>('/DeleteFlavorById', params),

  ///////////////////////////////////////////////////////////////////////////////
  // Update Brand
  // url: https://architecture.bushelops.com/bushel-interfaces/latest/#tag/api.platform.branding.v1/operation/UpdateBrand
  ///////////////////////////////////////////////////////////////////////////////
  updateBrand: async (params: UpdateBrandRequest) =>
    brandingClient.post<{ data: Brand }>('/UpdateBrand', params),

  ///////////////////////////////////////////////////////////////////////////////
  // Create Flavor
  // url: https://architecture.bushelops.com/bushel-interfaces/latest/#tag/api.platform.branding.v1/operation/CreateFlavor
  ///////////////////////////////////////////////////////////////////////////////
  createFlavor: async (params: CreateFlavorRequest) =>
    brandingClient.post<{ data: Flavor }>('/CreateFlavor', params),

  ///////////////////////////////////////////////////////////////////////////////
  // Update Flavor
  // url: https://architecture.bushelops.com/bushel-interfaces/latest/#tag/api.platform.branding.v1/operation/UpdateFlavor
  ///////////////////////////////////////////////////////////////////////////////
  updateFlavor: async (params: UpdateFlavorRequest) =>
    brandingClient.post<{ data: Flavor }>('/UpdateFlavor', params),
}

export const buildRequests = {
  ///////////////////////////////////////////////////////////////////////////////
  //  Update Build
  //  url: https://architecture.bushelops.com/bushel-interfaces/latest/#tag/api.platform.branding.v1/operation/UpdateBuild
  ///////////////////////////////////////////////////////////////////////////////
  updateBuild: async (params: UpdateBuildRequest) =>
    brandingClient.post<{ data: Build }>('/UpdateBuild', params),

  ///////////////////////////////////////////////////////////////////////////////
  // Get Build By Slug
  //  url: https://architecture.bushelops.com/bushel-interfaces/latest/#tag/api.platform.branding.v1/operation/GetBuildBySlug
  ///////////////////////////////////////////////////////////////////////////////
  getBuildBySlug: async (params: { type?: string; slug: string }) =>
    brandingClient.post<{ data: Build }>('/GetBuildBySlug', params),

  ///////////////////////////////////////////////////////////////////////////////
  // Create Build
  // url: https://architecture.bushelops.com/bushel-interfaces/latest/#tag/api.platform.branding.v1/operation/CreateBuild
  ///////////////////////////////////////////////////////////////////////////////
  createBuild: async (params: CreateBuildRequest) =>
    brandingClient.post<{ data: Build }>('/CreateBuild', params),

  ///////////////////////////////////////////////////////////////////////////////
  // Create Mobile Build
  // url: https://architecture.bushelops.com/bushel-interfaces/latest/#tag/api.platform.branding.v1/operation/CreateMobileBuild
  ///////////////////////////////////////////////////////////////////////////////
  createMobileBuild: async (params: CreateMobileBuildRequest) =>
    brandingClient.post<{ data: Build }>('/CreateMobileBuild', params),

  ///////////////////////////////////////////////////////////////////////////////
  // Delete Build By Id
  //  url: https://architecture.bushelops.com/bushel-interfaces/latest/#tag/api.platform.branding.v1/operation/DeleteBuildById
  ///////////////////////////////////////////////////////////////////////////////
  deleteBuildById: async (params: { id: string; type?: string }) =>
    brandingClient.post<{ data: Build }>('/DeleteBuildById', params),

  ///////////////////////////////////////////////////////////////////////////////
  // Get All Builds
  //  url: https://architecture.bushelops.com/bushel-interfaces/latest/#tag/api.platform.branding.v1/operation/GetAllBuilds
  ///////////////////////////////////////////////////////////////////////////////
  getAllBuilds: async (params: { type?: string } = {}) =>
    brandingClient.post<{ data: Build[] }>('/GetAllBuilds', params),

  ///////////////////////////////////////////////////////////////////////////////
  // Get Build By Id
  //  url: https://architecture.bushelops.com/bushel-interfaces/latest/#tag/api.platform.branding.v1/operation/GetBuildById
  ///////////////////////////////////////////////////////////////////////////////
  getBuildById: async (params: { id: string; type?: string }) =>
    brandingClient.post<{ data: Build }>('/GetBuildById', params),
}
