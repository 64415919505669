import React, { useEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { Dispatch, RootState } from 'src/store'
import { CentreEnvironment } from 'src/api/centre'
import { Placeholders } from 'src/ui'
import { findCompany, validEnvironment } from 'src/companies/routes/helpers'

interface CompaniesDefaultProps {
  companies: RootState['companies']
  settings: RootState['settings']
  loadCompanies: Dispatch['companies']['loadAll']
  setSettingsState: Dispatch['settings']['setState']
}

export function CompaniesDefault({
  settings,
  companies,
  loadCompanies,
  setSettingsState,
}: CompaniesDefaultProps) {
  const { env } = useParams()
  const { companySlug, centreAPIEndpoint } = settings

  useEffect(() => {
    if (env && env !== centreAPIEndpoint) {
      setSettingsState({ centreAPIEndpoint: env })
      loadCompanies()
    }
  }, [centreAPIEndpoint, env, loadCompanies, setSettingsState])

  if (!env || !validEnvironment(env as CentreEnvironment)) {
    return <Navigate to={`/companies/${centreAPIEndpoint}`} replace />
  }

  if (companySlug && !!findCompany(companySlug, companies.data)) {
    return <Navigate to={`/companies/${centreAPIEndpoint}/${companySlug}`} replace />
  }

  return <Placeholders.EmptyState message="Select a company" />
}
