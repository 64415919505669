import React from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Link, LinkProps } from 'react-router-dom'
import { get } from 'lodash'
import { useLocation } from 'src/routes'
import { AddIcon, Box, Button } from 'src/ui'
import { hasPermission } from 'src/utils/permissions'
import { CentreEnvironment, VALID_CENTRE_ENVIRONMENTS } from 'src/api/centre'

// STYLED
///////////////////////////////////////////////////////////////////////////////

const disabledNavLinkStyles = css`
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
`

interface NavLinkProps extends LinkProps {
  getProps: () => { active: boolean; disabled: boolean }
}

const NavLink = styled(Link, { shouldForwardProp: (prop) => prop !== 'getProps' })((
  props: NavLinkProps
) => {
  let { active, disabled } = props.getProps()

  return css`
    height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    padding-left: 40px;
    color: inherit;
    background-color: ${active ? '#e9f2fc' : 'inherit'};
    font-size: 17px;
    font-weight: 500;
    text-decoration: none;

    &:hover {
      background-color: ${active ? undefined : '#f0f3f6'};
    }

    ${disabled && disabledNavLinkStyles}
  `
})

// HELPERS
///////////////////////////////////////////////////////////////////////////////

/**
 * The URL pattern breaks down based on `companies/` routes, or `translators/` routes.
 * Path data is parsed out and matched up based on which section we're in.
 * Companies: `/companies/:env/:slug/:route`
 * Translators: `/translators/:route`
 */
const parseRoutePath = (path: string) => {
  const [_bit0, bit1, bit2, bit3, bit4] = path.split('/')
  const isValidEnv = VALID_CENTRE_ENVIRONMENTS.includes(bit2 as CentreEnvironment)

  return {
    companies:
      bit1 === 'companies'
        ? {
            // bit0 = ""
            // bit1 = "companies"
            env: isValidEnv ? bit2 : null,
            slug: isValidEnv ? bit3 : bit2 || null,
            route: isValidEnv ? bit4 : bit3 || null,
          }
        : null,

    translators:
      bit1 === 'translators'
        ? {
            // bit0 = ""
            // bit1 = "translator"
            route: bit2 || null,
            slug: bit4 === 'edit' ? null : bit3 || null,
          }
        : null,

    reports:
      bit1 === 'reports'
        ? {
            // bit0 = ""
            // bit1 = "reports"
            route: bit2,
          }
        : null,

    connect:
      bit1 === 'connect'
        ? {
            // bit0 = ""
            // bit1 = "connect"
            env: bit2 || null,
            route: bit3 || null,
          }
        : null,
  }
}

/**
 * Company links will use the current route's data if we're already on a company link.
 * If not, use data from redux settings.
 */
const makeCompanyLink = ({ routeData, settings, route }) => {
  let tSlug = get(routeData, 'translators.slug')
  let cSlug = get(routeData, 'companies.slug')
  let cEnv = get(routeData, 'companies.env')
  let { centreAPIEndpoint, companySlug } = settings
  let slugToUse = cSlug || tSlug || companySlug
  let envToUse = cEnv || centreAPIEndpoint

  return `/companies/${envToUse}/${slugToUse}/${route}`
}

const makeTranslatorLink = ({ routeData, settings, route }) => {
  let tSlug = get(routeData, 'translators.slug')
  let cSlug = get(routeData, 'companies.slug')
  let { companySlug } = settings
  let slugToUse = tSlug || cSlug || companySlug

  return `/translators/${route}/${slugToUse}`
}

const makeCompanyWithoutEnvLink = ({ routeData, settings, route }) => {
  let tSlug = get(routeData, 'translators.slug')
  let cSlug = get(routeData, 'companies.slug')
  let { companySlug } = settings
  let slugToUse = tSlug || cSlug || companySlug

  return `/companies/${slugToUse}/${route}`
}

const makeConnectLink = ({ routeData, settings, route }) => {
  let cEnv = get(routeData, 'companies.env')
  let { centreAPIEndpoint } = settings
  let envToUse = cEnv || centreAPIEndpoint

  return `/connect/${envToUse}/${route}`
}

const hasUsableSlug = ({ routeData, settings }) => {
  if (settings.companySlug) return true
  if (routeData.reports) return true
  if (routeData.companies && routeData.companies.slug) return true
  if (routeData.translators && routeData.translators.slug) return true

  return false
}
const isCompanyLinkActive = ({ routeData, route }) =>
  routeData.companies && routeData.companies.route === route
const isTranslatorLinkActive = ({ routeData, route }) =>
  routeData.translators && routeData.translators.route === route
const isConnectLinkActive = ({ routeData, route }) =>
  routeData.connect && routeData.connect.route === route

// DEFAULT EXPORT
///////////////////////////////////////////////////////////////////////////////

const Navigation = ({ settings, setSettingsState }) => {
  let { location } = useLocation()
  let routeData = parseRoutePath(location.pathname)
  let disabled = !hasUsableSlug({ routeData, settings })

  return (
    <>
      {hasPermission('nav_company') && (
        <>
          {[
            {
              label: 'Configurations',
              link: makeCompanyLink({ routeData, settings, route: 'configurations' }),
              active: isCompanyLinkActive({ routeData, route: 'configurations' }),
              disabled,
            },
            {
              label: 'Translator',
              link: makeCompanyLink({ routeData, settings, route: 'translator' }),
              active: isCompanyLinkActive({ routeData, route: 'translator' }),
              disabled,
            },
            {
              label: 'OMS',
              link: makeCompanyLink({ routeData, settings, route: 'oms' }),
              active: isCompanyLinkActive({ routeData, route: 'oms' }),
              disabled,
            },
            {
              label: 'Locations',
              link: makeCompanyLink({ routeData, settings, route: 'locations' }),
              active: isCompanyLinkActive({ routeData, route: 'locations' }),
              disabled,
            },
            {
              label: 'Cash Bid Config',
              link: makeCompanyLink({ routeData, settings, route: 'cash-bid-config' }),
              active: isCompanyLinkActive({ routeData, route: 'cash-bid-config' }),
              disabled,
            },
            {
              label: 'Positions',
              link: makeCompanyLink({ routeData, settings, route: 'positions' }),
              active: isCompanyLinkActive({ routeData, route: 'positions' }),
              disabled,
            },
            {
              label: 'Commodities',
              link: makeCompanyLink({ routeData, settings, route: 'commodities' }),
              active: isCompanyLinkActive({ routeData, route: 'commodities' }),
              disabled,
            },
            {
              label: 'Build Manager',
              link: makeCompanyWithoutEnvLink({ routeData, settings, route: 'build-manager' }),
              active: isCompanyLinkActive({ routeData, route: 'build-manager' }),
              disabled,
            },
            {
              label: 'Branding',
              link: makeCompanyWithoutEnvLink({ routeData, settings, route: 'branding' }),
              active: isCompanyLinkActive({ routeData, route: 'branding' }),
              disabled,
            },
          ].map((item) => (
            <NavLink
              key={item.label}
              to={item.link}
              getProps={() => ({ active: item.active, disabled: item.disabled || false })}
            >
              {item.label}
            </NavLink>
          ))}

          <Box pt={3} px={4}>
            <Button
              size="sm"
              variant="ghost"
              color="primary.500"
              leftIcon={<AddIcon />}
              width="100%"
              justifyContent="flex-start"
              onClick={() => {
                setSettingsState({
                  showCompanyCreateDialog: true,
                  showCompanySelectorDialog: false,
                })
              }}
            >
              Add Company
            </Button>
          </Box>
        </>
      )}

      {hasPermission('nav_translators') && (
        <>
          <Box pt={8} pb={4} px={6} fontSize={17} fontWeight="bold">
            Backend Translator
          </Box>

          {[
            {
              label: 'Translators',
              link: makeTranslatorLink({ routeData, settings, route: 'companies' }),
              active: isTranslatorLinkActive({ routeData, route: 'companies' }),
              disabled,
            },
            {
              label: 'Tokens',
              link: makeTranslatorLink({ routeData, settings, route: 'tokens' }),
              active: isTranslatorLinkActive({ routeData, route: 'tokens' }),
              disabled,
            },
            {
              label: 'Endpoints',
              link: '/translators/endpoints',
              active: isTranslatorLinkActive({ routeData, route: 'endpoints' }),
              disabled: false,
            },
            {
              label: 'Metrics',
              link: makeTranslatorLink({ routeData, settings, route: 'metrics' }),
              active: isTranslatorLinkActive({ routeData, route: 'metrics' }),
              disabled: false,
            },
          ].map((item) => (
            <NavLink
              key={item.label}
              to={item.link}
              getProps={() => ({ active: item.active, disabled: item.disabled || false })}
            >
              {item.label}
            </NavLink>
          ))}
        </>
      )}

      {hasPermission('nav_reports') && (
        <>
          <Box pt={8} pb={4} px={6} fontSize={17} fontWeight="bold">
            Reports
          </Box>

          {[
            {
              label: 'Contracts',
              link: '/reports/contracts',
              active: location.pathname.indexOf('/reports/contracts') === 0,
              disabled,
            },
            {
              label: 'Proof of Yield',
              link: '/reports/proof-of-yield',
              active: location.pathname.indexOf('/reports/proof-of-yield') === 0,
              disabled,
            },
            {
              label: 'Ticket Applications',
              link: '/reports/ticket-applications',
              active: location.pathname.indexOf('/reports/ticket-applications') === 0,
              disabled,
            },
            {
              label: 'Logged In Users',
              link: '/reports/logged-in-users',
              active: location.pathname.indexOf('/reports/logged-in-users') === 0,
              disabled,
            },
            {
              label: 'eSign',
              link: '/reports/esign',
              active: location.pathname.indexOf('/reports/esign') === 0,
              disabled,
            },
          ].map((item) => (
            <NavLink
              key={item.label}
              to={item.link}
              getProps={() => ({ active: item.active, disabled: item.disabled || false })}
            >
              {item.label}
            </NavLink>
          ))}
        </>
      )}

      {hasPermission('nav_connect') && (
        <>
          <Box pt={8} pb={4} px={6} fontSize={17} fontWeight="bold">
            Connect
          </Box>

          {[
            {
              label: 'Customers',
              link: makeConnectLink({ routeData, settings, route: 'customers' }),
              active: isConnectLinkActive({ routeData, route: 'customers' }),
              disabled: false,
            },
          ].map((item) => (
            <NavLink
              key={item.label}
              to={item.link}
              getProps={() => ({ active: item.active, disabled: item.disabled || false })}
            >
              {item.label}
            </NavLink>
          ))}
        </>
      )}
    </>
  )
}

export default Navigation
