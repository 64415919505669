import { rest } from 'msw'
import {
  configurables,
  cmsUsers,
  createCmsUser,
  agtraxProcessor,
  agtraxApi,
  metadataOptions,
  metadata,
  companyConfig,
} from 'src/fixtures/configurations'

export const handlers = (urls: string[]) => {
  return urls.reduce(
    (acc, url) => [
      ...acc,
      // config
      rest.get(`${url}/company-config/configurables`, (_req, res, ctx) =>
        res(ctx.status(200), ctx.json({ configurables: configurables }))
      ),
      rest.get(`${url}/company-config/:companySlug`, (req, res, ctx) =>
        req.params.companySlug === 'undefined'
          ? res(ctx.status(500))
          : res(ctx.status(200), ctx.json({ config: companyConfig }))
      ),
      rest.put(`${url}/company-config/:companySlug`, async (req, res, ctx) => {
        const { config } = await req.json()
        Object.assign(companyConfig, config)
        return res(ctx.status(200), ctx.json({ config: config }))
      }),
      // Agtrax Processor
      rest.get(`${url}/agtrax-processors`, (_req, res, ctx) =>
        res(ctx.status(200), ctx.json({ data: agtraxProcessor }))
      ),
      rest.put(`${url}/agtrax-processor/:id`, async (req, res, ctx) => {
        const requestBody = await req.json()
        Object.assign(agtraxProcessor, requestBody)
        const statusText = `Successfully updated processor: ${requestBody.id}.`
        return res(ctx.status(200), ctx.json({ data: requestBody, status: statusText }))
      }),
      // Agtrax API
      rest.get(`${url}/agtrax-api/:companySlug`, (_req, res, ctx) =>
        res(
          ctx.status(200),
          ctx.json(Object.entries(agtraxApi).length === 0 ? [] : { data: agtraxApi })
        )
      ),
      rest.post(`${url}/agtrax-api`, async (req, res, ctx) => {
        const requestBody = await req.json()
        Object.assign(agtraxApi, requestBody)
        return res(ctx.status(200), ctx.json({ data: requestBody }))
      }),
      // metadata
      rest.get(`${url}/company-metadata/options`, (_req, res, ctx) =>
        res(ctx.status(200), ctx.json({ data: metadataOptions }))
      ),
      rest.get(`${url}/companies-metadata`, (_req, res, ctx) =>
        res(ctx.status(200), ctx.json({ data: metadata }))
      ),
      rest.put(`${url}/company-metadata/:id`, async (req, res, ctx) => {
        const requestBody = await req.json()
        Object.assign(metadata, requestBody)
        return res(ctx.status(200), ctx.json({ data: requestBody }))
      }),
      // cms users
      rest.get(`${url}/cms-users/:companySlug`, (_req, res, ctx) =>
        res(ctx.status(200), ctx.json({ data: cmsUsers }))
      ),
      rest.post(`${url}/create-cms-user`, async (req, res, ctx) => {
        const requestBody = await req.json()
        cmsUsers.push(createCmsUser(requestBody))
        return res(ctx.status(200), ctx.json({ data: cmsUsers }))
      }),
    ],
    []
  )
}
