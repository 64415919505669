import React, { LegacyRef } from 'react'
import { VariableSizeGrid as Grid } from 'react-window'
import { Box, Row, IconButton, Select, Input, CloseIcon } from 'src/ui'

const API_FIELDS = [
  'remote_id',
  'name',
  'address_line_1',
  'address_line_2',
  'city',
  'state',
  'zip_code',
  'phone',
  'fax',
  'email',
  'website',
  'hidden',
]

const StickyGridContext = React.createContext(null)
const DataCell = ({ style, data }) => {
  const { columnIndex, rowIndex } = data
  const locationRow = data.locations[rowIndex - 1]
  const headerKey = Object.keys(data.headers)[columnIndex]
  const locationItem = locationRow ? locationRow[Object.keys(data.headers)[columnIndex]] : null

  if (rowIndex === 0) return null

  if (headerKey === 'actions') {
    return (
      <Row style={style}>
        <Box width={30}>
          <IconButton
            icon={<CloseIcon />}
            aria-label="Remove"
            size="xs"
            isRound
            variant="ghost"
            color="gray.600"
            onClick={() => {
              data.deleteRow(data.locations[rowIndex - 1].locationKey)
            }}
          />
        </Box>
      </Row>
    )
  }

  return (
    <Row style={style}>
      <Box width={200} border="1px solid #d3d3d3">
        <Input
          value={locationItem}
          placeholder={locationItem ? locationItem : headerKey}
          onChange={(e) =>
            data.updateRow({ rowIndex: rowIndex - 1, colKey: headerKey, value: e.target.value })
          }
          fontSize="0.75rem"
          size="sm"
          border="none"
          borderRadius="0px"
        />
      </Box>
    </Row>
  )
}

const StickyCell = ({ value, setState, headerKey, hasError }) => {
  if (headerKey === 'actions') {
    return (
      <Row backgroundColor="white" py="7px">
        <Box width={30}>
          <p>&nbsp;</p>
        </Box>
      </Row>
    )
  }
  return (
    <Row
      width={200}
      minWidth={200}
      backgroundColor="white"
      border="1px solid #d3d3d3"
      borderBottom="3px solid #d3d3d3"
      py="7px"
    >
      <Box flex={1}>
        <Select
          options={API_FIELDS.map((field) => ({ value: field, label: field }))}
          value={value || ''}
          placeholder={value}
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: 9999,
            }),
            control: {
              border: 'none',
            },
          }}
          menuPortalTarget={document.body}
          menuPosition="absolute"
          menuPlacement="bottom"
          hasError={hasError}
          onChange={(e) => {
            setState((state) => ({
              ...state,
              headers: {
                ...state.headers,
                [headerKey]: e.value,
              },
              invalidHeaderKeys: state.invalidHeaderKeys.filter((header) => header !== value),
            }))
          }}
        />
      </Box>
      <Box>
        <IconButton
          icon={<CloseIcon />}
          aria-label="Remove"
          size="xs"
          variant="ghost"
          isRound
          color="#808080"
          width="40px"
          onClick={() => {
            setState((state) => ({
              ...state,
              headers: Object.keys(state.headers)
                .filter((header) => header !== headerKey)
                .reduce((acc, key) => ({ ...acc, [key]: state.headers[key] }), {}),
              invalidHeaderKeys: state.invalidHeaderKeys.filter((header) => header !== headerKey),
            }))
          }}
        />
      </Box>
    </Row>
  )
}

const innerElementType = React.forwardRef(
  ({ children, ...rest }: { children: React.ReactNode }, ref) => {
    return (
      <StickyGridContext.Consumer>
        {({ headers, setState, invalidHeaderKeys }) => {
          const headerKeys = Object.keys(headers)
          return (
            <Box ref={ref as LegacyRef<HTMLDivElement>} {...rest}>
              <Row position="sticky" top={0} left={0} width="100%" zIndex={2}>
                {headerKeys.map((index) => (
                  <StickyCell
                    headerKey={index}
                    value={index}
                    key={index}
                    setState={setState}
                    hasError={invalidHeaderKeys.includes(index)}
                  />
                ))}
              </Row>
              {children}
            </Box>
          )
        }}
      </StickyGridContext.Consumer>
    )
  }
)
const ItemWrapper = ({ columnIndex, rowIndex, data, index, style }) => {
  const indexData = { ...data, columnIndex, rowIndex }
  const { ItemRenderer } = data
  if (rowIndex === 0) {
    return null
  }
  return <ItemRenderer index={index} style={style} data={indexData} />
}

const StickyGrid = ({ children, ...rest }) => {
  const { headers, setState, invalidHeaderKeys } = rest.itemData
  return (
    <StickyGridContext.Provider
      value={{ ItemRenderer: children, headers, setState, invalidHeaderKeys }}
    >
      <Grid
        {...rest}
        itemData={{
          ...rest.itemData,
          ItemRenderer: children,
        }}
      >
        {ItemWrapper}
      </Grid>
    </StickyGridContext.Provider>
  )
}

export const DataGrid = ({
  headers,
  locations,
  setState,
  updateRow,
  deleteRow,
  size,
  invalidHeaderKeys,
}) => {
  return (
    <StickyGrid
      columnCount={Object.keys(headers).length}
      columnWidth={(index) => {
        if (index === 0) {
          return 30
        } else {
          return 200
        }
      }}
      height={locations.length + 1 > 5 ? 300 : (locations.length + 1) * 50}
      innerElementType={innerElementType}
      rowCount={locations.length + 1}
      rowHeight={(index) => (index === 0 ? 50 : 34)}
      // rowHeight={() => 90}
      width={size.width}
      itemData={{ headers, locations, setState, updateRow, deleteRow, invalidHeaderKeys }}
      paddingTop="30px"
    >
      {DataCell}
    </StickyGrid>
  )
}
