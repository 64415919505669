import React from 'react'
import { Badge } from '@chakra-ui/react'

interface ChipProps {
  label: string
  color?: string
}

const Chip: React.FC<ChipProps> = ({ label, color }) => (
  <Badge variant="solid" colorScheme={color} py={1} px={3}>
    {label}
  </Badge>
)

export default Chip
