import React from 'react'
import { connect } from 'react-redux'
import { Dispatch, RootState } from 'src/store'
import { AuthRoute, FlexRoutes } from 'src/routes'
import TranslatorList from 'src/translators/translator-list'
import TranslatorConfigForm from 'src/translators/form'
import CompanyCreateHelper from 'src/translators/company-create-helper'
import { Placeholders } from 'src/ui'
import { useFeatures } from 'src/api/queries/features'
import { useCompanyTranslatorMutations, useCompanyTranslators } from 'src/api/queries/translators'
import { Route, useParams } from 'react-router-dom'

type CompanyTranslatorsProps = ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch> & {
    companies: ConfiguratorCompany[]
    environments: Environment[]
  }

const CompanyTranslators: React.FC<CompanyTranslatorsProps> = ({
  settings,
  setSettingsState,
  environments,
  companies,
  createConfiguratorCompany,
  loadConfiguratorCompanies,
  errorNotification,
  successNotification,
}) => {
  const { slug } = useParams()
  let featuresQuery = useFeatures()
  let translatorsQuery = useCompanyTranslators({ slug })
  let { saveTranslator, deleteTranslator } = useCompanyTranslatorMutations()
  let company = companies.find((c) => c.slug === slug)

  React.useEffect(() => {
    if (settings.companySlug !== slug) {
      setSettingsState({
        showCompanySelector: true,
        companySlug: slug,
        showCentreEnvironment: false,
      })
    } else {
      setSettingsState({ showCompanySelector: true, showCentreEnvironment: false })
    }
  }, [slug, settings.companySlug, setSettingsState])

  if (!company) {
    return (
      <>
        <Placeholders.FailedState message={`Company slug "${slug}" not found in configurator.`} />
        <CompanyCreateHelper
          slug={slug}
          loadTranslators={translatorsQuery.refetch}
          createConfiguratorCompany={createConfiguratorCompany}
          loadConfiguratorCompanies={loadConfiguratorCompanies}
        />
      </>
    )
  }

  if (translatorsQuery.status === 'loading') return <Placeholders.LoadingState />
  if (translatorsQuery.status === 'error') return <Placeholders.FailedState />

  return (
    <FlexRoutes>
      <Route
        path="/:environment/:translator_id"
        element={
          <AuthRoute as={TranslatorConfigForm} slug={slug} translators={translatorsQuery.data} />
        }
      />
      <Route
        path="/:environment/:translator_id/copy/:copy_id/*"
        element={
          <AuthRoute as={TranslatorConfigForm} slug={slug} translators={translatorsQuery.data} />
        }
      />
      <Route
        path="*"
        element={
          <AuthRoute
            as={TranslatorList}
            slug={slug}
            features={featuresQuery.data}
            translators={translatorsQuery.data}
            company={company}
            environments={environments}
            saveTranslator={saveTranslator}
            deleteTranslator={deleteTranslator}
            errorNotification={errorNotification}
            successNotification={successNotification}
          />
        }
      />
    </FlexRoutes>
  )
}

const mapState = ({ settings }: RootState) => ({
  settings,
})

const mapDispatch = ({ settings, notifications, configuratorCompanies }: Dispatch) => ({
  setCompanySlug: settings.setCompanySlug,
  setSettingsState: settings.setState,
  loadConfiguratorCompanies: configuratorCompanies.loadAll,
  createConfiguratorCompany: configuratorCompanies.createCompany,
  successNotification: notifications.createSuccess,
  errorNotification: notifications.createError,
})

export default connect(mapState, mapDispatch)(CompanyTranslators)
