import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
  GetAllTicketsGradeFactorsRequest,
  GetTicketConfigurationBySlugRequest,
  GetTicketConfigurationBySlugResponse,
  ticketsRequests,
  SyncTicketsGradeFactorsRequest,
  TicketsUpsertConfigurationRequest,
  TicketsIsEnabledForCompanySlugRequest,
  TicketsIsEnabledForCompanySlugResponse,
} from 'src/api/ticket-applications'

// Get All Grade Factors
export const GET_ALL_TICKETS_GRADE_FACTORS_QUERY_KEY = 'get-all-ticket-applications-grade-factors'
export function useGetAllTicketsGradeFactors(params: GetAllTicketsGradeFactorsRequest) {
  return useQuery({
    queryKey: [GET_ALL_TICKETS_GRADE_FACTORS_QUERY_KEY, params],
    queryFn: async () =>
      ticketsRequests.getAllGradeFactors(params).then((response) => response.data),
  })
}

// Get Configuration By Slug
export const GET_TICKET_CONFIGURATION_BY_SLUG_QUERY_KEY =
  'get-ticket-applications-configuration-by-slug'
export function useGetConfigurationBySlug(params: GetTicketConfigurationBySlugRequest) {
  return useQuery<GetTicketConfigurationBySlugResponse, AxiosError>({
    queryKey: [GET_TICKET_CONFIGURATION_BY_SLUG_QUERY_KEY, params],
    queryFn: async () =>
      ticketsRequests.getConfigurationBySlug(params).then((response) => response.data),
  })
}

// Sync Grade Factors
export const SYNC_TICKETS_GRADE_FACTORS_MUTATION_KEY = 'sync-ticket-applications-grade-factors'
export function useSyncGradeFactors({ slug }: Pick<SyncTicketsGradeFactorsRequest, 'slug'>) {
  return useMutation({
    mutationKey: [SYNC_TICKETS_GRADE_FACTORS_MUTATION_KEY, slug],
    mutationFn: async ({ gradeFactors }: Omit<SyncTicketsGradeFactorsRequest, 'slug'>) =>
      ticketsRequests.syncGradeFactors({ gradeFactors, slug }).then((response) => response.data),
  })
}

// Upsert Configuration
export const TICKETS_UPSERT_CONFIGURATION_MUTATION_KEY = 'ticket-applications-upsert-configuration'
export function useUpsertConfiguration({ slug }: Pick<TicketsUpsertConfigurationRequest, 'slug'>) {
  return useMutation({
    mutationKey: [TICKETS_UPSERT_CONFIGURATION_MUTATION_KEY, slug],
    mutationFn: async ({
      enabled,
      title,
      truckLicenseEnabled,
      truckNameEnabled,
      truckDriverEnabled,
    }: Omit<TicketsUpsertConfigurationRequest, 'slug'>) =>
      ticketsRequests
        .upsertConfiguration({
          slug,
          enabled,
          title,
          truckDriverEnabled,
          truckLicenseEnabled,
          truckNameEnabled,
        })
        .then((response) => response.data),
  })
}

// Tickets is Enabled for Company Slug Check
export const TICKETS_IS_ENABLED_FOR_COMPANY_SLUG_QUERY_KEY =
  'ticket-applications-is-enabled-for-company-slug'
export function useTicketsIsEnabledForCompanySlug(params: TicketsIsEnabledForCompanySlugRequest) {
  return useQuery<TicketsIsEnabledForCompanySlugResponse, AxiosError>({
    queryKey: [TICKETS_IS_ENABLED_FOR_COMPANY_SLUG_QUERY_KEY, params],
    queryFn: async () =>
      ticketsRequests.ticketsIsEnabledForCompanySlug(params).then((response) => response.data),
  })
}
