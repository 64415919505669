import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useStore } from 'react-redux'
import {
  GET_CONFIGURATION_BY_SLUG_QUERY_KEY,
  useGetConfigurationBySlug,
  useUpsertConfiguration,
} from 'src/api/queries/proof-of-yield'
import {
  ProofOfYieldContextProvider,
  useProofOfYieldContext,
} from 'src/reports/proof-of-yield/context'
import DetailsCard from 'src/reports/proof-of-yield/details-card'
import GradeFactorsCard from 'src/reports/proof-of-yield/grade-factors-card'
import ConfirmationModal from 'src/reports/proof-of-yield/confirmation-modal'
import { EmptyState, FailedState } from 'src/ui/placeholders'
import {
  Button,
  Card,
  Center,
  HStack,
  Skeleton,
  Spinner,
  Stack,
  Switch,
  Text,
  useDisclosure,
  useToast,
} from 'src/ui'

function ProofOfYield() {
  const queryClient = useQueryClient()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const companySlug = useStore().getState().settings.companySlug
  const toast = useToast()
  const { setDetailsIsEditing, setGradeFactorsIsEditing } = useProofOfYieldContext()

  const {
    data: configurationResponse,
    isLoading: getConfigBySlugIsLoading,
    isFetching: getConfigBySlugIsFetching,
    error: getConfigBySlugError,
  } = useGetConfigurationBySlug({
    slug: companySlug,
  })
  const { mutateAsync: upsertConfigurationAsync, isLoading: upsertConfigIsLoading } =
    useUpsertConfiguration({ slug: companySlug })

  if (getConfigBySlugIsLoading || getConfigBySlugIsFetching) {
    return (
      <Stack spacing={8}>
        <Skeleton h="56px" />
        <Skeleton h="154px" />
        <Skeleton h="238px" />
      </Stack>
    )
  }

  if (getConfigBySlugError) {
    if (getConfigBySlugError.response.status === 404)
      return (
        <Center flexDirection="column">
          <EmptyState message="Your company hasn’t setup Proof of Yield reporting. Click below to start!">
            Error
          </EmptyState>
          <Button
            size="sm"
            colorScheme="primary"
            maxW="143px"
            onClick={() => {
              upsertConfigurationAsync({ title: 'Proof of Yield', enabled: false })
                .then(() => {
                  queryClient.refetchQueries({ queryKey: [GET_CONFIGURATION_BY_SLUG_QUERY_KEY] })
                  toast({
                    status: 'success',
                    title: 'Success',
                    isClosable: true,
                    description: 'Successfully added company slug',
                    position: 'top-right',
                  })
                })
                .catch(() => {
                  toast({
                    status: 'error',
                    title: 'Error',
                    isClosable: true,
                    description: 'Failed to add company slug',
                    position: 'top-right',
                  })
                })
            }}
            isDisabled={upsertConfigIsLoading}
            isLoading={upsertConfigIsLoading}
          >
            Add Company
          </Button>
        </Center>
      )
    else {
      return <FailedState />
    }
  }

  const {
    data: { enabled, title },
  } = configurationResponse

  return (
    <>
      {isOpen && (
        <ConfirmationModal
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={() => {
            upsertConfigurationAsync({ title, enabled: !enabled })
              .then(() => {
                queryClient.refetchQueries({ queryKey: [GET_CONFIGURATION_BY_SLUG_QUERY_KEY] })
                toast({
                  status: 'success',
                  title: 'Success',
                  isClosable: true,
                  description: 'Successfully turned off Proof of Yield',
                  position: 'top-right',
                })
                onClose()
                setDetailsIsEditing(false)
                setGradeFactorsIsEditing(false)
              })
              .catch(() => {
                toast({
                  status: 'error',
                  title: 'Error',
                  isClosable: true,
                  description: 'Failed to turn off Proof of Yield',
                  position: 'top-right',
                })
                onClose()
              })
          }}
          isLoading={upsertConfigIsLoading}
        />
      )}
      <Stack spacing={8}>
        <Card>
          <HStack justify="space-between">
            <Text fontWeight="bold">Proof Of Yield Report</Text>
            {upsertConfigIsLoading && !enabled ? (
              <Spinner />
            ) : (
              <Switch
                isChecked={enabled}
                onChange={() => {
                  if (enabled) {
                    onOpen()
                  } else {
                    upsertConfigurationAsync({ title, enabled: !enabled })
                      .then(() => {
                        queryClient.refetchQueries({
                          queryKey: [GET_CONFIGURATION_BY_SLUG_QUERY_KEY],
                        })
                        toast({
                          status: 'success',
                          title: 'Success',
                          isClosable: true,
                          description: 'Successfully turned on Proof of Yield',
                          position: 'top-right',
                        })
                      })
                      .catch(() => {
                        toast({
                          status: 'error',
                          title: 'Error',
                          isClosable: true,
                          description: 'Failed to turn off Proof of Yield',
                          position: 'top-right',
                        })
                      })
                  }
                }}
              />
            )}
          </HStack>
        </Card>
        {enabled && (
          <>
            <DetailsCard title={title} />
            <GradeFactorsCard />
          </>
        )}
      </Stack>
    </>
  )
}

function ProofOfYieldWithContext() {
  return (
    <ProofOfYieldContextProvider>
      <ProofOfYield />
    </ProofOfYieldContextProvider>
  )
}

export default ProofOfYieldWithContext
