import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { Formik, Form, Field, useFormikContext } from 'formik'
import * as FormikFields from 'src/ui/formik'
import { FormLabel } from 'src/ui/form-controls'
import { Box, Grid, Tooltip, Row, Button, InfoOutlineIcon } from 'src/ui'
import ConfigPanel, { PanelState } from 'src/companies/configurations/config-panel'
import { loadCopyDiff, executeCopy } from './copy'
import { useCopyDiff } from 'src/companies/copy-utils'
import { isFaded, representValue } from 'src/companies/configurations/utils'
import { Dispatch } from 'src/store'
import { SaveCompanyConfig } from 'src/companies/configurations.d'
import { flow, sortBy } from 'lodash/fp'
import { createIcon } from '@chakra-ui/react'

// Utilities

const convertEmptyStringsToNull = (values: object) => {
  return Object.keys(values).reduce((acc, key) => {
    return {
      ...acc,
      [key]: values[key] === '' ? null : values[key],
    }
  }, {})
}

const prepareInitialValues = (values: object, formFields: ConfigurableFormField[]) => {
  return formFields.reduce(
    (acc, field) => ({
      ...acc,
      [field.key]: values[field.key],
    }),
    {}
  )
}

const padToMultipleOf = (multiple: number) => (array: any[]) => {
  const actualLength = array.length
  const targetLength = Math.ceil(actualLength / multiple) * multiple
  const extraElements = new Array(targetLength - actualLength).fill(null)
  return [...array, ...extraElements]
}

// Panel Content

const FormSubmitter = ({ submittedAt }: { submittedAt: number }) => {
  let { submitForm } = useFormikContext()

  useEffect(() => {
    if (!submittedAt) return
    submitForm()
  }, [submittedAt, submitForm])

  return <div />
}

interface PanelContentProps {
  panelState: PanelState
  formFields: ConfigurableFormField[]
  companyConfig: CompanyConfig
  onStartEdit: () => void
  numColumns: number
  submittedAt?: number
}

const PanelContent = ({
  numColumns,
  onStartEdit,
  companyConfig,
  formFields,
  panelState,
}: PanelContentProps) => {
  return (
    <Grid templateColumns={`repeat(${numColumns}, 1fr)`}>
      {padToMultipleOf(numColumns)(formFields).map((field, index) => {
        const bgColor =
          panelState === 'editing'
            ? undefined
            : Math.floor(index / numColumns) % 2 === 1
              ? 'gray.50'
              : undefined

        if (!field)
          return <Box key={`${index}-padder-element`} flex="1" minWidth="0" bg={bgColor} />

        return (
          <Box
            key={field.key}
            bg={bgColor}
            flex="1"
            minWidth="0"
            px="6"
            py="3"
            height={
              // Setting the height to 85px makes the labels stay still when switching between view and edit
              formFields.some((field) => field.type === 'json') ? 'auto' : '85px'
            }
            css={{
              '&:hover .config-edit-button': {
                opacity: 1,
              },
            }}
          >
            <LabelWithTooltip
              panelState={panelState}
              fieldKey={field.key}
              labelText={field.label}
              description={field.description}
              flaggable={field.flaggable}
            />

            {panelState === 'editing' ? (
              ['timestamp', 'boolean'].includes(field.type) ? (
                <Field
                  id={field.key}
                  name={field.key}
                  component={FormikFields.Switch}
                  timed={field.type === 'timestamp'}
                  formControlProps={{
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    height: 'auto',
                    mb: null,
                  }}
                  SwitchBoxProps={{ height: '32px', flex: 1 }}
                />
              ) : field.type === 'string' && field.options ? (
                <Field
                  id={field.key}
                  name={field.key}
                  placeholder="Not Set"
                  component={FormikFields.Select}
                  formControlProps={{
                    height: 'auto',
                    mb: null,
                  }}
                  options={field.options.map((opt) => ({ value: opt, label: opt }))}
                />
              ) : field.type === 'json' || field.type === 'json_array' ? (
                <Field
                  id={field.key}
                  name={field.key}
                  component={FormikFields.JsonEditor}
                  height={350}
                />
              ) : (
                <Field
                  id={field.key}
                  name={field.key}
                  component={FormikFields.TextField}
                  formControlProps={{ mb: null }}
                />
              )
            ) : (
              <Row alignItems="baseline">
                <Row
                  color={isFaded(companyConfig[field.key]) ? 'gray.400' : undefined}
                  css={{
                    minWidth: 0,
                    overflow: 'auto',
                    whiteSpace: 'nowrap',
                    scrollbarWidth: 'none',
                    '::-webkit-scrollbar': {
                      width: 0,
                      height: 0,
                    },
                  }}
                  alignItems="baseline"
                >
                  <Box>{representValue(companyConfig[field.key])}</Box>
                </Row>
                <Button
                  className="config-edit-button"
                  opacity={0}
                  color="blue.500"
                  variant="link"
                  ml="2"
                  aria-label="Edit"
                  onClick={onStartEdit}
                  size="sm"
                >
                  Edit
                </Button>
              </Row>
            )}
          </Box>
        )
      })}
    </Grid>
  )
}

const LabelWithTooltip = ({
  panelState,
  fieldKey,
  labelText,
  description,
  flaggable = false,
}: {
  panelState: string
  fieldKey: string
  labelText: string
  description: string
  flaggable?: boolean
}) => {
  const ref = useRef(null)
  const [isOverflown, setIsOverflown] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      const element = ref.current!
      setIsOverflown(element.children[0].scrollWidth > element.children[0].clientWidth)
    }, 50) //have to wait just a bit for the panel to expand
  }, [panelState])

  return (
    <Row ref={ref} alignItems="baseline" width="100%" mb="2">
      <FormLabel
        as={panelState === 'editing' ? 'label' : 'div'}
        htmlFor={fieldKey}
        width="auto"
        p={0}
        m={0}
      >
        {labelText}
      </FormLabel>
      {(isOverflown || description) && (
        <Tooltip
          label={
            (
              <Box mt="1" mb="2">
                <Box mb="1" fontWeight="bold">
                  {labelText}
                </Box>
                {description}
              </Box>
            ) as any
          }
          aria-label="Describes usage of the config"
          placement="bottom-start"
          hasArrow={true}
          shouldWrapChildren={true}
        >
          <InfoOutlineIcon alignSelf="center" ml={1} color="gray.400" />
        </Tooltip>
      )}
      {flaggable && (
        <Tooltip
          label={
            (
              <Box mt="1" mb="2">
                This is overridable via feature flag
              </Box>
            ) as any
          }
          aria-label="Informs that this config can be overridden by a feature flag"
          placement="bottom-start"
          hasArrow={true}
          shouldWrapChildren={true}
        >
          <FlagStandardIcon alignSelf="center" ml={1} color="gray.400" />
        </Tooltip>
      )}
    </Row>
  )
}

const FormSchema = Yup.object().shape({})

// PANEL
///////////////////////////////////////////////////////////////////////////////

type CompanyConfigurationProps = {
  company: Company
  companyConfig: CompanyConfig
  formFields: ConfigurableFormField[]
  setCopyDialog: (args: any) => void
  saveCompanyConfig: SaveCompanyConfig
  numColumns: number
  label: string
} & Partial<ReturnType<typeof mapDispatch>>

const CompanyConfiguration: React.FC<CompanyConfigurationProps> = ({
  company,
  companyConfig,
  formFields,
  saveCompanyConfig,
  setCopyDialog,
  numColumns = 4,
  label,
}) => {
  const sortedFormFields = flow(
    sortBy('key'),
    sortBy((field: ConfigurableFormField) => ['boolean', 'timestamp'].includes(field.type))
  )(formFields)
  let [panelState, setPanelState] = React.useState<PanelState>('collapsed')
  let [formData, setFormData] = React.useState({ submittedAt: null, isSubmitting: false })
  let { startCopy } = useCopyDiff({
    company,
    loadCopyDiff,
    executeCopy,
    setCopyDialog,
    context: {
      companyConfig: prepareInitialValues(companyConfig, formFields),
      whitelist: formFields.map((field) => field.key),
    },
  })

  const stopEditing = () => {
    setPanelState('expanded')
    setFormData({ submittedAt: null, isSubmitting: false })
  }

  const content = (
    <PanelContent
      formFields={sortedFormFields}
      panelState={panelState}
      companyConfig={companyConfig}
      onStartEdit={() => setPanelState('editing')}
      numColumns={numColumns}
      submittedAt={formData.submittedAt}
    />
  )

  return (
    <>
      <ConfigPanel
        label={label}
        expanded={['expanded', 'editing'].includes(panelState)}
        panelState={panelState}
        formData={formData}
        copyPermission="company_config_copy"
        editPermission="company_config_edit"
        onChange={(e, expanded) => {
          if (panelState === 'editing') return // ignore changes while editing
          setPanelState(expanded ? 'expanded' : 'collapsed')
        }}
        onClickCopy={startCopy}
        onClickEdit={() => setPanelState('editing')}
        onCancelEdit={stopEditing}
        onSave={() => setFormData((state) => ({ ...state, submittedAt: new Date().getTime() }))}
      >
        {panelState === 'editing' ? (
          <Formik
            initialValues={prepareInitialValues(companyConfig, formFields)}
            validateOnChange={false}
            validationSchema={FormSchema}
            onSubmit={async (values, _formikActions) => {
              setFormData((state) => ({ ...state, isSubmitting: true }))

              await saveCompanyConfig({
                companyConfig: {
                  ...convertEmptyStringsToNull(values),
                  _revision: companyConfig._revision,
                },
                onSuccess: () => {
                  stopEditing()
                },
              })
              setFormData({ submittedAt: null, isSubmitting: false })
            }}
          >
            <Form>
              <FormSubmitter submittedAt={formData.submittedAt} />
              {content}
            </Form>
          </Formik>
        ) : (
          content
        )}
      </ConfigPanel>
    </>
  )
}

const mapDispatch = ({ notifications }: Dispatch) => ({
  errorNotification: notifications.createError,
  successNotification: notifications.createSuccess,
})

export const FlagStandardIcon = createIcon({
  defaultProps: {
    'aria-hidden': true,
    fill: 'currentcolor',
  },
  displayName: 'FlagStandardIcon',
  viewBox: '0 0 24 24',
  d: 'M3.75 22.5a.75.75 0 0 1-.75-.75V3.19c0-.4.22-.77.56-.97.56-.33 1.7-.72 3.94-.72 1.74 0 3.7.69 5.42 1.3 1.38.49 2.7.95 3.58.95 1.15 0 2.27-.23 3.33-.68a.85.85 0 0 1 1.17.78v10.28c0 .37-.22.7-.56.86-.4.17-1.9.76-3.94.76a19.4 19.4 0 0 1-4.05-.69c-1.68-.4-3.43-.81-4.95-.81-1.72 0-2.6.26-3 .43v7.07c0 .41-.34.75-.75.75Zm3.75-9.75c1.7 0 3.6.45 5.3.85 1.4.33 2.74.65 3.7.65 1.38 0 2.44-.3 3-.5V4.79c-.97.3-1.97.46-3 .46-1.15 0-2.57-.5-4.09-1.04C10.73 3.61 9 3 7.5 3a7.8 7.8 0 0 0-3 .43v9.66c.66-.19 1.62-.34 3-.34Z',
})

export default connect<CompanyConfigurationProps>(
  null, // mapState
  mapDispatch
)(CompanyConfiguration)
