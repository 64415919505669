import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import {
  Box,
  Button,
  Column,
  ConfigCard,
  Dialog,
  FormRow,
  InfoOutlineIcon,
  Row,
  Stack,
  Tooltip,
} from 'src/ui'
import * as Yup from 'yup'
import { Field, Form, Formik, FormikProps } from 'formik'
import { FormLabel } from 'src/ui/form-controls'
import {
  JsonEditor as FormikJsonEditor,
  Select as FormikSelect,
  Switch as FormikSwitch,
  TextField as FormikTextField,
} from 'src/ui/formik'
import { IConnectChannel } from 'src/api/api'
import { useConnectChannelMutations } from 'src/api/queries/connect-channels'
import { useToast } from 'src/utils/toast'
import { hasPermission } from 'src/utils/permissions'
import { getEnvironment } from 'src/utils'

interface ConnectChannelFormProps {
  connectCustomerId: string
  connectChannels: IConnectChannel[]
  header: () => void
}

interface ChannelFormProps {
  id: number
  connect_customer_id: number
  name: string
  description: string
  features: FeatureProps
  configuration: object
  _revision?: string
}

interface FeatureProps {
  accounts: boolean
  commodity_balances: boolean
  contracts: boolean
  tickets: boolean
  settlements: boolean
}
const isEmpty = (value) => {
  return value ? false : value.length === 0
}
const isEmailSchema = Yup.string().email()
const channelFormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  configuration: Yup.object().shape({
    schedule: Yup.string().when(['delivery_method'], ([delivery_method], schema) =>
      delivery_method === deliveryMethods.csv || delivery_method === deliveryMethods.webhook
        ? schema.required('Required')
        : schema
    ),
    token: Yup.string().when(['delivery_method'], ([delivery_method], schema) =>
      delivery_method === deliveryMethods.csv || delivery_method === deliveryMethods.webhook
        ? schema.min(16, 'Must be 16 digits long').required('Required')
        : schema
    ),
    emails: Yup.string().when(['delivery_method'], ([delivery_method], schema) =>
      delivery_method === deliveryMethods.csv || delivery_method === deliveryMethods.csvportal
        ? schema.required('Required').test({
            name: 'emails',
            test: function (value) {
              const firstInvalidEmail = !value
                ? false
                : value
                    .split(',')
                    .map((email) => email.trim())
                    .filter((v) => !isEmpty(v))
                    .find((v) => !isEmailSchema.isValidSync(v))

              return !firstInvalidEmail
                ? true
                : this.createError({
                    message: `The email address '${firstInvalidEmail}' is invalid.`,
                  })
            },
          })
        : schema
    ),
    base_url: Yup.string().when(['delivery_method'], ([delivery_method], schema) =>
      delivery_method === deliveryMethods.webhook ||
      delivery_method === deliveryMethods.webhookportal
        ? schema.url('Invalid URL').required('Required')
        : schema
    ),
  }),
})

const textProps = {
  height: '62px',
  mb: null,
}

const toggleProps = {
  flexDirection: 'column',
  alignItems: 'flex-start',
  height: '62px',
  mb: null,
}

const defaultFeatures = {
  accounts: false,
  commodity_balances: false,
  contracts: false,
  tickets: false,
  settlements: false,
}

const deliveryMethods = {
  webhook: 'webhook',
  csv: 'csv',
  portal: 'portal',
  webhookportal: 'webhookportal',
  csvportal: 'csvportal',
}

const targetFormats = {
  default: 'default',
  agris: 'agris',
}

const defaultConfiguration = {
  delivery_method: deliveryMethods.webhook,
  schedule: '0 0 1 11 *',
  timezone: 'America/Chicago',
  uses_catchup_jobs: false,
  content_type: 'json',
}

export const ChannelForm: React.FC<ConnectChannelFormProps> = ({
  connectCustomerId,
  connectChannels,
  header,
}) => {
  const { connectChannelId } = useParams()
  let { saveConnectChannel, deleteConnectChannel } = useConnectChannelMutations()
  let toast = useToast()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  let [isDeleting, setIsDeleting] = React.useState(false)
  let [isSubmitting, setIsSubmitting] = React.useState(false)
  let [showDeleteDialog, setShowDeleteDialog] = React.useState(false)

  let currentChannel = connectChannels?.find((channel) => channel.id === parseInt(connectChannelId))
  let [deliveryMethod, setDeliveryMethod] = React.useState(
    currentChannel
      ? currentChannel.configuration.delivery_method
      : defaultConfiguration.delivery_method
  )

  return (
    <>
      <Formik
        initialValues={{
          id: parseInt(connectChannelId),
          connect_customer_id: parseInt(connectCustomerId),
          name: currentChannel?.name,
          description: currentChannel ? currentChannel.description : '',
          features: currentChannel ? currentChannel.features : { ...defaultFeatures },
          configuration: currentChannel
            ? currentChannel.configuration
            : { ...defaultConfiguration },
          _revision: currentChannel?._revision,
        }}
        validationSchema={channelFormSchema}
        onSubmit={async (
          { id, connect_customer_id, name, description, features, configuration, _revision },
          _formikActions
        ) => {
          setIsSubmitting(true)
          await saveConnectChannel(
            {
              id,
              connect_customer_id,
              name,
              description,
              features,
              configuration,
              _revision,
            },
            {
              onError: (error) => {
                // @ts-ignore
                _formikActions.setErrors(error?.response?.data?.validation_messages)
                toast({
                  status: 'error',
                  description: `Failed to ${id ? 'update' : 'create'} channel`,
                })
              },
              onSuccess: () => {
                toast({
                  description: `Successfully ${id ? 'updated' : 'created'} channel `,
                })
              },
              onSettled: async (res) => {
                await queryClient.invalidateQueries({ queryKey: ['connect-channels'] })
                setIsSubmitting(false)
                // res is undefined onError, redirect when defined
                if (res) {
                  navigate(`/connect/${getEnvironment()}/customers/${connectCustomerId}/channels`)
                }
              },
            }
          )
        }}
      >
        {(formikProps: FormikProps<ChannelFormProps>) => (
          <>
            {header}
            <ConfigCard
              header={
                <Box mb={6}>
                  <Button
                    width="76px"
                    size="sm"
                    mr={2}
                    colorScheme="secondary"
                    isDisabled={
                      !hasPermission('connect_channels_edit') ||
                      isDeleting ||
                      !currentChannel ||
                      isSubmitting
                    }
                    onClick={() => {
                      setShowDeleteDialog(true)
                    }}
                  >
                    DELETE
                  </Button>
                  <Button
                    as={Link}
                    to={`/connect/${getEnvironment()}/customers/${connectCustomerId}/channels`}
                    width="76px"
                    size="sm"
                    mr={2}
                    colorScheme="secondary"
                    isDisabled={isSubmitting}
                    isLoading={false}
                  >
                    CANCEL
                  </Button>
                  <Button
                    width="76px"
                    size="sm"
                    mr={2}
                    colorScheme="primary"
                    isDisabled={!hasPermission('connect_channels_edit') || isSubmitting}
                    isLoading={isSubmitting}
                    onClick={() => {
                      formikProps.submitForm()
                    }}
                  >
                    SAVE
                  </Button>
                </Box>
              }
            >
              <Form>
                <Stack spacing={2}>
                  <FormRow rowProps={{ mb: 5 }}>
                    <Field
                      name="name"
                      label="Name"
                      component={FormikTextField}
                      formControlProps={{ ...textProps }}
                    />
                    <Field
                      name="configuration.delivery_method"
                      label="Delivery Method"
                      component={FormikSelect}
                      options={[
                        { value: deliveryMethods.webhook, label: 'Webhook' },
                        { value: deliveryMethods.csv, label: 'CSV' },
                        { value: deliveryMethods.portal, label: 'Portal' },
                        { value: deliveryMethods.csvportal, label: 'CSV & Portal' },
                        { value: deliveryMethods.webhookportal, label: 'Webhook & Portal' },
                      ]}
                      onChange={(e) => setDeliveryMethod(e.value)}
                      selectProps={{ isClearable: false }}
                    />
                  </FormRow>
                  <FormRow rowProps={{ mb: 5 }}>
                    <Field
                      name="description"
                      label="Description"
                      component={FormikTextField}
                      formControlProps={{ ...textProps }}
                    />
                  </FormRow>
                  {deliveryMethod === deliveryMethods.csv && (
                    <>
                      <SubFormSchedule namespace="configuration" />
                      <SubFormCSV namespace="configuration" />
                      <SubFormFeeds
                        namespace="features"
                        accountsDisabled={true}
                        commodityBalancesDisabled={true}
                        contractsDisabled={true}
                        settlementsDisabled={true}
                      />
                    </>
                  )}
                  {deliveryMethod === deliveryMethods.csvportal && (
                    <>
                      <SubFormSchedule namespace="configuration" />
                      <SubFormCSV namespace="configuration" />
                      <SubFormCSVPortal namespace="configuration" />
                      <SubFormFeeds
                        namespace="features"
                        accountsDisabled={true}
                        commodityBalancesDisabled={true}
                        contractsDisabled={true}
                        settlementsDisabled={true}
                      />
                    </>
                  )}
                  {deliveryMethod === deliveryMethods.webhook && (
                    <>
                      <SubFormSchedule namespace="configuration" />
                      <SubFormWebhook namespace="configuration" />
                      <SubFormFeeds namespace="features" />
                    </>
                  )}
                  {deliveryMethod === deliveryMethods.webhookportal && (
                    <>
                      <SubFormSchedule namespace="configuration" />
                      <SubFormWebhook namespace="configuration" />
                      <SubFormPortal namespace="configuration" />
                      <SubFormFeeds namespace="features" />
                    </>
                  )}
                  {deliveryMethod === deliveryMethods.portal && (
                    <>
                      <SubFormPortal namespace="configuration" />
                    </>
                  )}
                </Stack>
              </Form>
            </ConfigCard>
          </>
        )}
      </Formik>
      <Dialog
        title="Delete Connect Channel"
        isOpen={showDeleteDialog}
        onClose={() => setIsDeleting(false)}
        actions={
          <>
            <Button size="sm" variant="ghost" onClick={() => setShowDeleteDialog(false)} mr={2}>
              Cancel
            </Button>

            <Button
              size="sm"
              colorScheme="primary"
              isLoading={isDeleting}
              onClick={async () => {
                setIsDeleting(true)
                await deleteConnectChannel(currentChannel, {
                  onSuccess: async () => {
                    await queryClient.invalidateQueries({ queryKey: ['connect-channels'] })
                    toast({ description: 'Successfully deleted Connect Channel' })
                    navigate(`/connect/${getEnvironment()}/customers/${connectCustomerId}/channels`)
                  },
                  onError: () => {
                    toast({ status: 'error', description: 'Failed to delete Connect Channel' })
                  },
                  onSettled: () => {
                    setShowDeleteDialog(false)
                  },
                })
              }}
            >
              Delete
            </Button>
          </>
        }
      >
        <Column>
          <Box>
            <Box>Are you sure you want to delete this Connect Channel</Box>

            {currentChannel && (
              <ul>
                <li>{currentChannel.name}</li>
              </ul>
            )}
          </Box>
        </Column>
      </Dialog>
    </>
  )
}

const SubFormSchedule = ({ namespace }) => {
  const withNamespace = addNamespace(namespace)

  return (
    <>
      <FormRow rowProps={{ mb: 5 }}>
        <Field
          name={withNamespace('schedule')}
          label="Schedule"
          component={FormikTextField}
          formControlProps={{ ...textProps }}
        />
        <Field
          name={withNamespace('timezone')}
          label="Timezone"
          component={FormikSelect}
          options={[
            { value: 'America/Chicago', label: 'Central' },
            { value: 'America/New_York', label: 'Eastern' },
            { value: 'America/Denver', label: 'Mountain' },
            { value: 'America/Phoenix', label: 'Mountain no DST' },
            { value: 'America/Los_Angeles', label: 'Pacific' },
          ]}
          selectProps={{ isClearable: false }}
        />
        <Field
          name={withNamespace('uses_catchup_jobs')}
          label="Uses Catchup Jobs"
          component={FormikSwitch}
          formControlProps={{ ...toggleProps }}
        />
      </FormRow>
      <FormRow rowProps={{ mb: 5 }}>
        <Field
          name={withNamespace('token')}
          label="Token"
          component={FormikTextField}
          formControlProps={{ ...textProps }}
        />
      </FormRow>
    </>
  )
}

const SubFormCSV = ({ namespace }) => {
  const withNamespace = addNamespace(namespace)

  return (
    <>
      <FormRow rowProps={{ mb: 5 }}>
        <Field
          name={withNamespace('emails')}
          label="Email(s)"
          component={FormikTextField}
          formControlProps={{ ...textProps }}
        />
        <Field
          name={withNamespace('target_format')}
          label="Target Format"
          component={FormikSelect}
          options={[
            { value: targetFormats.default, label: 'Default' },
            { value: targetFormats.agris, label: 'Agris' },
          ]}
          selectProps={{ isClearable: false }}
        />
      </FormRow>
      <FormRow rowProps={{ mb: 5 }}>
        <Box>
          <Row alignItems="baseline" width="100%" mb="2">
            <FormLabel htmlFor={withNamespace('commodity_map')} width="auto" pb="0">
              CSV Commodity Map
            </FormLabel>
            <Tooltip
              label={
                (
                  <Box mt="1" mb="2">
                    <Box mb="1" fontWeight="bold">
                      CSV Commodity Map
                    </Box>
                    A Bushel-ID-to-Customer-ID map of reference codes used by the customer's ERP to
                    identify the tickets' commodity.
                    <br />
                    <br />
                    <code css={{ fontSize: '0.8em' }}>
                      <strong>Keys</strong> should be lowercase and either the
                      commodity.display_name or crop.name.
                      <br />
                      Prefix with company_id and a dash to disambiguate. e.g. 123-corn
                      <br />
                      <br />
                      <strong>Values</strong> should be the code provided by the customer.
                    </code>
                  </Box>
                ) as any
              }
              aria-label="Detailed description"
              placement="bottom-start"
            >
              <InfoOutlineIcon alignSelf="center" ml="1" color="gray.400" />
            </Tooltip>
          </Row>
          <Field name={withNamespace('commodity_map')} component={FormikJsonEditor} height={250} />
        </Box>
        <Box>
          <Row alignItems="baseline" width="100%" mb="2">
            <FormLabel htmlFor={withNamespace('customer_map')} width="auto" pb="0">
              Customer ID Map
            </FormLabel>
            <Tooltip
              label={
                (
                  <Box mt="1" mb="2">
                    <Box mb="1" fontWeight="bold">
                      Customer ID Map
                    </Box>
                    A Bushel-ID-to-Customer-ID map of reference codes used by the customer's ERP to
                    identify the tickets' origin.
                    <br />
                    <br />
                    <code css={{ fontSize: '0.8em' }}>
                      <strong>Keys</strong> should be lowercase and either:
                      <br />
                      The company_id, for company-wide codes.
                      <br />
                      The company_id_remote_elevator_id, for location-specific codes.
                      <br />
                      <br />
                      <strong>Values</strong> should be the code provided by the customer.
                    </code>
                  </Box>
                ) as any
              }
              aria-label="Detailed description"
              placement="bottom-start"
            >
              <InfoOutlineIcon alignSelf="center" ml="1" color="gray.400" />
            </Tooltip>
          </Row>
          <Field name={withNamespace('customer_map')} component={FormikJsonEditor} height={250} />
        </Box>
      </FormRow>
      <FormRow rowProps={{ mb: 5 }}>
        <Box>
          <Row alignItems="baseline" width="100%" mb="2">
            <FormLabel htmlFor={withNamespace('remarks_map')} width="auto" pb="0">
              Remarks Map
            </FormLabel>
            <Tooltip
              label={
                (
                  <Box mt="1" mb="2">
                    <Box mb="1" fontWeight="bold">
                      Remarks Map
                    </Box>
                    A Bushel-ID-to-Customer-ID map of reference codes used by the customer's ERP to
                    identify the tickets' grade factors.
                    <br />
                    <br />
                    <code css={{ fontSize: '0.8em' }}>
                      <strong>Keys</strong> should be lowercase and either:
                      <br />
                      The grade_factor_code, for company-wide codes.
                      <br />
                      The company_id_remote_elevator_id and grade_factor_code joined by a dash, for
                      location-specific codes. e.g. 123-far-mst
                      <br />
                      <br />
                      <strong>Values</strong> should be the code provided by the customer.
                    </code>
                  </Box>
                ) as any
              }
              aria-label="Detailed description"
              placement="bottom-start"
            >
              <InfoOutlineIcon alignSelf="center" ml="1" color="gray.400" />
            </Tooltip>
          </Row>
          <Field name={withNamespace('remarks_map')} component={FormikJsonEditor} height={250} />
        </Box>
        <Box>
          <Row alignItems="baseline" width="100%" mb="2">
            <FormLabel htmlFor={withNamespace('transformer_settings')} width="auto" pb="0">
              Transformer Settings
            </FormLabel>
            <Tooltip
              label={
                (
                  <Box mt="1" mb="2">
                    <Box mb="1" fontWeight="bold">
                      Transformer Settings
                    </Box>
                    A place for settings that will be used by the transformer to generate the file.
                  </Box>
                ) as any
              }
              aria-label="Detailed description"
              placement="bottom-start"
            >
              <InfoOutlineIcon alignSelf="center" ml="1" color="gray.400" />
            </Tooltip>
          </Row>
          <Field
            name={withNamespace('transformer_settings')}
            component={FormikJsonEditor}
            height={250}
          />
        </Box>
      </FormRow>
    </>
  )
}

const SubFormCSVPortal = ({ namespace }) => {
  const withNamespace = addNamespace(namespace)

  return (
    <FormRow rowProps={{ mb: 5 }}>
      <Box>
        <Row alignItems="baseline" width="100%" mb="2">
          <FormLabel htmlFor={withNamespace('portal_commodity_map')} width="auto" pb="0">
            Portal Commodity Map
          </FormLabel>
          <Tooltip
            label={
              (
                <Box mt="1" mb="2">
                  <Box mb="1" fontWeight="bold">
                    Portal Commodity Map
                  </Box>
                  A mapping of standard codes to an array of commodity remote ids.
                  <br />
                  <br />
                  <code css={{ fontSize: '0.8em' }}>
                    <strong>Keys</strong> should match the 3 support commodities, wheat, soybeans,
                    and corn.
                    <br />
                    <br />
                    <strong>Values</strong> should match the commodity.remote_id and
                    tickets.remote_commodity_id and listed in array.
                    <br />
                    Example: "wheat":["wh","wt"]
                  </code>
                </Box>
              ) as any
            }
            aria-label="Detailed description"
            placement="bottom-start"
          >
            <InfoOutlineIcon alignSelf="center" ml="1" color="gray.400" />
          </Tooltip>
        </Row>
        <Field
          name={withNamespace('portal_commodity_map')}
          component={FormikJsonEditor}
          height={400}
        />
      </Box>
    </FormRow>
  )
}

const SubFormWebhook = ({ namespace }) => {
  const withNamespace = addNamespace(namespace)

  return (
    <>
      <FormRow rowProps={{ mb: 5 }}>
        <Field
          name={withNamespace('base_url')}
          label="URL"
          component={FormikTextField}
          formControlProps={{ ...textProps }}
        />
      </FormRow>
      <FormRow rowProps={{ mb: 5 }}>
        <Field
          name={withNamespace('content_type')}
          label="Content Type"
          component={FormikSelect}
          options={[{ value: 'json', label: 'JSON' }]}
          selectProps={{ isClearable: false, width: '144px' }}
        />
      </FormRow>
    </>
  )
}

const SubFormPortal = ({ namespace }) => {
  const withNamespace = addNamespace(namespace)

  return (
    <>
      <FormRow rowProps={{ mb: 5 }}>
        <Box>
          <Row alignItems="baseline" width="100%" mb="2">
            <FormLabel htmlFor={withNamespace('remarks_map')} width="auto" pb="0">
              Remarks Map
            </FormLabel>
            <Tooltip
              label={
                (
                  <Box mt="1" mb="2">
                    <Box mb="1" fontWeight="bold">
                      Remarks Map
                    </Box>
                    A Bushel-ID-to-Customer-ID map of reference codes used by the customer's ERP to
                    identify the tickets' grade factors.
                    <br />
                    <br />
                    <code css={{ fontSize: '0.8em' }}>
                      <strong>Keys</strong> should be lowercase and either:
                      <br />
                      The grade_factor_code, for company-wide codes.
                      <br />
                      The company_id_remote_elevator_id and grade_factor_code joined by a dash, for
                      location-specific codes. e.g. 123-far-mst
                      <br />
                      <br />
                      <strong>Values</strong> should be the code provided by the customer.
                    </code>
                  </Box>
                ) as any
              }
              aria-label="Detailed description"
              placement="bottom-start"
            >
              <InfoOutlineIcon alignSelf="center" ml="1" color="gray.400" />
            </Tooltip>
          </Row>
          <Field name={withNamespace('remarks_map')} component={FormikJsonEditor} height={400} />
        </Box>
        <Box>
          <Row alignItems="baseline" width="100%" mb="2">
            <FormLabel htmlFor={withNamespace('portal_commodity_map')} width="auto" pb="0">
              Portal Commodity Map
            </FormLabel>
            <Tooltip
              label={
                (
                  <Box mt="1" mb="2">
                    <Box mb="1" fontWeight="bold">
                      Portal Commodity Map
                    </Box>
                    A mapping of standard codes to an array of commodity remote ids.
                    <br />
                    <br />
                    <code css={{ fontSize: '0.8em' }}>
                      <strong>Keys</strong> should match the 3 support commodities, wheat, soybeans,
                      and corn.
                      <br />
                      <br />
                      <strong>Values</strong> should match the commodity.remote_id and
                      tickets.remote_commodity_id and listed in array.
                      <br />
                      Example: "wheat":["wh","wt"]
                    </code>
                  </Box>
                ) as any
              }
              aria-label="Detailed description"
              placement="bottom-start"
            >
              <InfoOutlineIcon alignSelf="center" ml="1" color="gray.400" />
            </Tooltip>
          </Row>
          <Field
            name={withNamespace('portal_commodity_map')}
            component={FormikJsonEditor}
            height={400}
          />
        </Box>
      </FormRow>
    </>
  )
}

const SubFormFeeds = ({
  namespace,
  accountsDisabled = false,
  commodityBalancesDisabled = false,
  contractsDisabled = false,
  settlementsDisabled = false,
  ticketsDisabled = false,
}) => {
  const withNamespace = addNamespace(namespace)

  return (
    <>
      <h2>Features</h2>
      <FormRow rowProps={{ mb: 5 }}>
        <Field
          name={withNamespace('accounts')}
          label="Accounts"
          component={FormikSwitch}
          formControlProps={{ ...toggleProps }}
          disabled={accountsDisabled}
        />
        <Field
          name={withNamespace('commodity_balances')}
          label="Commodity Balances"
          component={FormikSwitch}
          formControlProps={{ ...toggleProps }}
          disabled={commodityBalancesDisabled}
        />
        <Field
          name={withNamespace('contracts')}
          label="Contracts"
          component={FormikSwitch}
          formControlProps={{ ...toggleProps }}
          disabled={contractsDisabled}
        />
        <Field
          name={withNamespace('settlements')}
          label="Settlements"
          component={FormikSwitch}
          formControlProps={{ ...toggleProps }}
          disabled={settlementsDisabled}
        />
        <Field
          name={withNamespace('tickets')}
          label="Tickets"
          component={FormikSwitch}
          formControlProps={{ ...toggleProps }}
          disabled={ticketsDisabled}
        />
      </FormRow>
    </>
  )
}

function addNamespace(namespace = '') {
  return function withNamespace(fieldName) {
    return namespace ? `${namespace}.${fieldName}` : fieldName
  }
}
