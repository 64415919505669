import { rest } from 'msw'
import { loggedInUsers, eSignContracts } from 'src/fixtures/reports'

export const handlers = (urls: string[]) => {
  return urls.reduce(
    (acc, url) => [
      ...acc,
      rest.get(`${url}/reports/logged-in-users/:date`, (_req, res, ctx) =>
        res(ctx.status(200), ctx.json({ data: loggedInUsers() }))
      ),
      rest.get(`${url}/reports/esign-contracts/:date`, (_req, res, ctx) =>
        res(ctx.status(200), ctx.json({ data: eSignContracts() }))
      ),
    ],
    []
  )
}
