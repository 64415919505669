import axios, { AxiosResponse } from 'axios'
import { useQuery } from '@tanstack/react-query'
import { CONFIG } from 'src/utils/config'
import { getAuthUsername } from 'src/utils/auth'

export interface FlagrResponse {
  evalContext: {
    entityContext: Record<string, string>
    entityID: string
    flagKey: string
    enableDebug: boolean
    entityType: string
  }
  flagID: number
  flagKey: string
  flagSnapshotID: number
  segmentID: number
  timestamp: string
  variantAttachment: Record<string, any>
  variantID: number
  variantKey: string
}

const flagrClient = axios.create({ baseURL: 'https://flagr.dev.bushelops.com/api/v1' })
const DEFAULT_VARIANT_KEY = 'enabled'

export function useFlagr<EvalType = boolean>(
  flagKey: string,
  options: { variantKey?: string; defaultValue?: EvalType; context?: Record<string, any> } = {}
) {
  const { variantKey, defaultValue, context } = options

  const flagQuery = useQuery({
    queryKey: ['flags-hook', { flagKey, variantKey, context }],
    queryFn: async () => flag({ flagKey, variantKey, context }),
    staleTime: Infinity,
  })

  return [flagQuery, flagQuery.isSuccess ? flagQuery.data : defaultValue] as const
}

export const flagrEvaluate = async (
  body: Record<string, any>
): Promise<[Error, AxiosResponse<FlagrResponse>]> => {
  try {
    const response = await flagrClient.post<FlagrResponse>('evaluation', body)

    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

interface FlagArgs {
  flagKey: string
  variantKey?: string
  context?: { [key: string]: string }
}

export const flag = async ({ flagKey, variantKey = DEFAULT_VARIANT_KEY, context }: FlagArgs) => {
  const [err, response] = await flagrEvaluate({
    flagKey,
    entityContext: makeEntityContext({ context }),
  })

  if (err) {
    console.error({
      message: `flagr error during evaluation ${err}`,
      context: [{ flagKey, variantKey, context }],
    })
    return false
  }

  return response.data?.variantKey === variantKey
}

/**
 * An "entity context" is used to provide the contextual information that Flagr
 * uses to perform its evaluation.
 */
function makeEntityContext({ context }: { context?: Record<string, any> }) {
  return {
    env: CONFIG.env,
    username: getAuthUsername(),
    ...context,
  }
}
