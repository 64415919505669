import React, { useState } from 'react'
import { Box, Row, Button, Dialog, Column, ChevronDownIcon, ChevronUpIcon } from 'src/ui'
import { Collapse, IconButton } from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'
import { UseMutateAsyncFunction } from '@tanstack/react-query'
import * as api from 'src/utils/api'
import { TextField, Select } from 'src/ui/formik'
import MappingsForm from 'src/companies/oms/mapping/mapping-form'
import MappingTable from 'src/companies/oms/mapping/mapping-table'
import { FormLabel } from 'src/ui/form-controls'
import { representValue, isFaded } from 'src/companies/configurations/utils'
import { hasPermission } from 'src/utils/permissions'
import * as Yup from 'yup'

interface MappingConfigProps {
  availableCommodities: Array<string>
  availableLocations: Array<object>
  configuredLocation: BushelLocation
  locationConfig: OfferManagementMapping
  saveLocationConfig: UseMutateAsyncFunction<
    api.ApiResponse<OfferManagementMapping>,
    unknown,
    {
      locationConfig: OfferManagementMapping
      onSuccess?: () => void
    },
    unknown
  >
  deleteLocationConfig: UseMutateAsyncFunction<
    api.ApiResponse<any>,
    unknown,
    {
      mappingID: string | number
      onSuccess?: () => void
    },
    unknown
  >
}

const FormSchema = Yup.object().shape({
  elevator_id: Yup.number().nullable().required('Required'),
  cqg_location_name: Yup.string().required('Required'),
  cash_bid_product_map_json: Yup.array()
    .of(
      Yup.object().shape({
        cash: Yup.string(),
        flat: Yup.string(),
        basis: Yup.string(),
        hedge: Yup.string(),
      })
    )
    .test('hasAMapping', 'You must set a mapping for a commodity to submit', (value) =>
      value.some((mapping) => mapping.cash || mapping.flat || mapping.basis || mapping.hedge)
    ),
})

const finalCashBidProductMapFormat = (array): Array<object> => {
  let cash_bid_product_map_json = []
  array.forEach((product) => {
    if (product.cash || product.flat || product.basis || product.hedge !== '') {
      cash_bid_product_map_json.push(product)
    }
  })
  return cash_bid_product_map_json
}

const newCashBidProductMapJSON = (array, availableCommodities) => {
  return availableCommodities.map((commodity) => {
    let configuredCommodity = array.find((config) => config.name === commodity)
    if (configuredCommodity) {
      return configuredCommodity
    }
    return { name: commodity, cash: '', flat: '', basis: '', hedge: '' }
  })
}

const DeleteDialog: React.FC<DialogProps & { locationConfig: OfferManagementMapping }> = ({
  isOpen,
  onClose,
  onConfirm,
  locationConfig,
}) => {
  let [isLoading, setIsLoading] = React.useState(false)
  if (!locationConfig) return null
  return (
    <Dialog
      title="Delete Location Config"
      isOpen={isOpen}
      onClose={onClose}
      actions={
        <>
          <Button size="sm" variant="ghost" mr={2} onClick={onClose}>
            Cancel
          </Button>

          <Button
            size="sm"
            colorScheme="primary"
            isLoading={isLoading}
            onClick={async () => {
              setIsLoading(true)
              await onConfirm()
              setIsLoading(false)
            }}
          >
            Delete
          </Button>
        </>
      }
    >
      <Column>
        <Box>
          <Box>Are you sure you want to delete this position?</Box>
          <ul>
            <li>{locationConfig.cqg_location_name}</li>
          </ul>
        </Box>
      </Column>
    </Dialog>
  )
}

const MappingConfig = ({
  availableCommodities,
  locationConfig,
  saveLocationConfig,
  deleteLocationConfig,
  availableLocations,
  configuredLocation,
}: MappingConfigProps) => {
  const [panelState, setPanelState] = useState('show')
  let [deleteDialog, setDeleteDialog] = useState(null)

  const handleShowToggle = () => {
    if (panelState === 'show' || panelState === 'edit' || panelState === 'duplicate') {
      setPanelState('collapsed')
    } else {
      setPanelState('show')
    }
  }

  return (
    <Formik
      initialValues={{
        id: locationConfig.id,
        company_id: locationConfig.company_id,
        elevator_id: locationConfig.elevator_id,
        cash_bid_product_map_json: newCashBidProductMapJSON(
          locationConfig.cash_bid_product_map_json,
          availableCommodities
        ),
        cqg_location_name: locationConfig.cqg_location_name,
      }}
      validateOnChange={true}
      validationSchema={FormSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true)
        values.cash_bid_product_map_json = finalCashBidProductMapFormat(
          values.cash_bid_product_map_json
        )
        if (panelState === 'duplicate') {
          values.id = null
          await saveLocationConfig({
            locationConfig: {
              futures_product_map_json: null,
              created_at: locationConfig.created_at,
              updated_at: null,
              ...values,
            },
            onSuccess: () => setPanelState('show'),
          })
        } else {
          await saveLocationConfig({
            locationConfig: {
              futures_product_map_json: null,
              created_at: locationConfig.created_at,
              updated_at: null,
              ...values,
            },
            onSuccess: () => setPanelState('show'),
          })
        }

        //send them to the server
      }}
    >
      {({ errors, setFieldValue, resetForm, isSubmitting }) => (
        <Form>
          <Box py={2} mb={4} borderRadius={3} bg="white" boxShadow="md">
            <Row justifyContent="space-between" alignItems="center" px={6} cursor="pointer" mb={2}>
              <Box flex={1}>
                <Row width="100%" height="62px" justifyContent="space-between" alignItems="center">
                  {panelState === 'collapsed' && (
                    <>
                      <Row alignItems="center" mb={6} mt={4}>
                        <Box fontSize="15px" mr={5}>
                          <FormLabel title="Bushel Location Name">Bushel Location Name</FormLabel>
                          <Box
                            mt="6px"
                            color={isFaded(configuredLocation.name) ? 'gray.400' : undefined}
                            css={{
                              minWidth: 0,
                              overflow: 'auto',
                              whiteSpace: 'nowrap',
                              // textOverflow: 'ellipsis',
                              scrollbarWidth: 'none',
                              '::-webkit-scrollbar': {
                                width: 0,
                                height: 0,
                              },
                            }}
                          >
                            {representValue(configuredLocation.name)}
                          </Box>
                        </Box>
                        <Box fontSize="15px">
                          <FormLabel title="CQG Company Name">CQG Location Name</FormLabel>
                          <Box
                            mt="6px"
                            color={
                              isFaded(locationConfig.cqg_location_name) ? 'gray.400' : undefined
                            }
                            css={{
                              minWidth: 0,
                              overflow: 'auto',
                              whiteSpace: 'nowrap',
                              // textOverflow: 'ellipsis',
                              scrollbarWidth: 'none',
                              '::-webkit-scrollbar': {
                                width: 0,
                                height: 0,
                              },
                            }}
                          >
                            {representValue(locationConfig.cqg_location_name)}
                          </Box>
                        </Box>
                      </Row>
                      <Box pr={1}>
                        <Button
                          width="76px"
                          colorScheme="secondary"
                          size="sm"
                          mr={2}
                          onClick={() => setDeleteDialog({ locationConfig })}
                          isDisabled={!hasPermission('offer_management_edit')}
                        >
                          Delete
                        </Button>
                        <Button
                          size="sm"
                          colorScheme="secondary"
                          mr={2}
                          onClick={() => {
                            setPanelState('duplicate')
                            setFieldValue('elevator_id', null)
                            setFieldValue('cqg_location_name', null)
                          }}
                          isDisabled={
                            !hasPermission('offer_management_edit') || availableLocations.length < 1
                          }
                        >
                          Duplicate
                        </Button>{' '}
                        <Button
                          key="edit"
                          width="76px"
                          size="sm"
                          colorScheme="primary"
                          onClick={() => setPanelState('edit')}
                          isDisabled={!hasPermission('offer_management_edit')}
                        >
                          Edit
                        </Button>
                      </Box>
                    </>
                  )}
                  {panelState === 'show' && (
                    <>
                      <Row alignItems="center" mb={6} mt={4}>
                        <Box fontSize="15px" mr={5}>
                          <FormLabel title="Bushel Location Name">Bushel Location Name</FormLabel>
                          <Box
                            mt="6px"
                            color={isFaded(configuredLocation.name) ? 'gray.400' : undefined}
                            css={{
                              minWidth: 0,
                              overflow: 'auto',
                              whiteSpace: 'nowrap',
                              // textOverflow: 'ellipsis',
                              scrollbarWidth: 'none',
                              '::-webkit-scrollbar': {
                                width: 0,
                                height: 0,
                              },
                            }}
                          >
                            {representValue(configuredLocation.name)}
                          </Box>
                        </Box>
                        <Box fontSize="15px">
                          <FormLabel title="CQG Company Name">CQG Location Name</FormLabel>
                          <Box
                            mt="6px"
                            color={
                              isFaded(locationConfig.cqg_location_name) ? 'gray.400' : undefined
                            }
                            css={{
                              minWidth: 0,
                              overflow: 'auto',
                              whiteSpace: 'nowrap',
                              // textOverflow: 'ellipsis',
                              scrollbarWidth: 'none',
                              '::-webkit-scrollbar': {
                                width: 0,
                                height: 0,
                              },
                            }}
                          >
                            {representValue(locationConfig.cqg_location_name)}
                          </Box>
                        </Box>
                      </Row>
                      <Box pr={1}>
                        <Button
                          width="76px"
                          colorScheme="secondary"
                          size="sm"
                          mr={2}
                          onClick={() => setDeleteDialog({ locationConfig })}
                          isDisabled={!hasPermission('offer_management_edit')}
                        >
                          Delete
                        </Button>
                        <Button
                          size="sm"
                          colorScheme="secondary"
                          mr={2}
                          onClick={() => {
                            setPanelState('duplicate')
                            setFieldValue('elevator_id', null)
                            setFieldValue('cqg_location_name', null)
                          }}
                          isDisabled={
                            !hasPermission('offer_management_edit') || availableLocations.length < 1
                          }
                        >
                          Duplicate
                        </Button>{' '}
                        <Button
                          key="edit"
                          width="76px"
                          size="sm"
                          colorScheme="primary"
                          onClick={() => setPanelState('edit')}
                          isDisabled={!hasPermission('offer_management_edit')}
                        >
                          Edit
                        </Button>
                      </Box>
                    </>
                  )}
                  {panelState === 'edit' && (
                    <>
                      <Row alignItems="center" mb={6} mt={4}>
                        <Box fontSize="15px" mr={5} mt={6}>
                          <Field
                            name={`elevator_id`}
                            label={'Bushel Location Name'}
                            value={locationConfig.elevator_id}
                            component={Select}
                            placeholder={configuredLocation.name}
                            options={availableLocations.map((location: BushelLocation) => ({
                              value: location.id,
                              label: location.name,
                              key: location.id,
                            }))}
                          />
                        </Box>
                        <Box fontSize="15px" mt={6}>
                          <Field
                            label={'CQG Location Name'}
                            name={`cqg_location_name`}
                            component={TextField}
                            placeholder={locationConfig.cqg_location_name}
                          />
                        </Box>
                      </Row>
                      <p style={{ color: 'red' }}>{errors.cash_bid_product_map_json as string}</p>
                      <Box pr={2}>
                        <Button
                          key="cancel"
                          width="76px"
                          size="sm"
                          colorScheme="secondary"
                          mr={2}
                          onClick={() => {
                            resetForm()
                            setPanelState('show')
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          key="save"
                          type="submit"
                          width="76px"
                          size="sm"
                          colorScheme="primary"
                          isDisabled={isSubmitting}
                          isLoading={isSubmitting}
                        >
                          Save
                        </Button>
                      </Box>
                    </>
                  )}
                  {panelState === 'duplicate' && (
                    <>
                      <Row alignItems="center" mb={6} mt={4}>
                        <Box fontSize="15px" mr={5} mt={6}>
                          <Field
                            name={`elevator_id`}
                            label={'Bushel Location Name'}
                            value={locationConfig.elevator_id}
                            component={Select}
                            placeholder={'Select New Location'}
                            options={availableLocations.map((location: BushelLocation) => ({
                              value: location.id,
                              label: location.name,
                              key: location.id,
                            }))}
                          />
                        </Box>
                        <Box fontSize="15px" mt={6}>
                          <Field
                            label={'CQG Location Name'}
                            name={`cqg_location_name`}
                            component={TextField}
                            placeholder={'Enter a new Location Name'}
                          />
                        </Box>
                      </Row>
                      <p style={{ color: 'red' }}>{errors.cash_bid_product_map_json as string}</p>
                      <Box pr={2}>
                        <Button
                          key="cancel"
                          width="76px"
                          size="sm"
                          colorScheme="secondary"
                          mr={2}
                          onClick={() => {
                            resetForm()
                            setPanelState('show')
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          key="save"
                          type="submit"
                          width="76px"
                          size="sm"
                          colorScheme="primary"
                          isDisabled={isSubmitting}
                          isLoading={isSubmitting}
                        >
                          Save
                        </Button>
                      </Box>
                    </>
                  )}
                </Row>
              </Box>
              <Box>
                <IconButton
                  aria-label="Toggle"
                  icon={panelState !== 'collapsed' ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  isRound
                  fontSize="24px"
                  variant="ghost"
                  color="#8CPBA5"
                  onClick={() => handleShowToggle()}
                >
                  hide
                </IconButton>
              </Box>
            </Row>
            <Collapse
              in={panelState === 'show' || panelState === 'edit' || panelState === 'duplicate'}
            >
              {panelState === 'show' && <MappingTable locationConfig={locationConfig} />}
              {panelState === 'edit' && (
                <MappingsForm
                  locationConfig={locationConfig}
                  availableCommodities={availableCommodities}
                />
              )}
              {panelState === 'duplicate' && (
                <MappingsForm
                  locationConfig={locationConfig}
                  availableCommodities={availableCommodities}
                />
              )}
            </Collapse>
            <DeleteDialog
              isOpen={!!deleteDialog}
              locationConfig={deleteDialog ? deleteDialog.locationConfig : null}
              onClose={() => setDeleteDialog(null)}
              onConfirm={async () =>
                await deleteLocationConfig({
                  mappingID: locationConfig.id,
                  onSuccess: () => setDeleteDialog(null),
                })
              }
            />
          </Box>
        </Form>
      )}
    </Formik>
  )
}
export default MappingConfig
