import Keycloak from 'keycloak-js'
import { CONFIG } from './config'

export const KEYCLOAK = Keycloak({
  url: CONFIG.keycloak_config_url,
  realm: CONFIG.keycloak_config_realm,
  clientId: CONFIG.keycloak_config_client_id,
})

export function getKeycloakLogoutUrl() {
  let logoutURL = undefined
  if (getAccessToken()) {
    const { createLogoutUrl } = KEYCLOAK
    logoutURL = createLogoutUrl ? createLogoutUrl() : undefined
    if (logoutURL) {
      logoutURL += `&id_token_hint=${KEYCLOAK.idToken}`
    }
  }
  return logoutURL
}

export function getAccessToken() {
  return sessionStorage.getItem('kc-access-token')
}

export const getAuthUser = () => {
  const accessToken = getAccessToken()
  if (!accessToken) return null
  let decoded = null

  try {
    decoded = decodeJwt(accessToken)
  } catch (err) {
    console.error('[auth] failed to decode jwt from cookie', err)
  }
  return decoded
}

export const getAuthUsername = (): string => {
  const authUser = KEYCLOAK.tokenParsed || getAuthUser()

  return authUser?.email?.split('@')?.[0] || 'system'
}

const decodeJwt = (token: string) => {
  let base64Url = token.split('.')[1]
  let base64 = decodeURIComponent(
    atob(base64Url)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(base64)
}
