import { useNavigate } from 'react-router-dom'
import axios, { AxiosError, AxiosResponse } from 'axios'
import store from 'src/store'
import { CONFIG, IS_DEV_OR_SANDBOX, IS_PROD, IS_SANDBOX } from 'src/utils/config'
import { centreAPIRequests, CentreApiRequests, getDevEnvConfigById } from 'src/api/centre'
import { getEnvironment } from 'src/utils'
import { getInitPersistedCentreEnv } from 'src/store/models/settings'
import { useEffect } from 'react'

///////////////////////////////////////////////////////////////////////////////
// TYPES
///////////////////////////////////////////////////////////////////////////////
export { STATUS } from './api-status'
export * from 'src/api/configurator'

type MyAxiosError = AxiosError

export type ApiDataBody<T> = { data: T }
export type ApiResponse<T> = [MyAxiosError, AxiosResponse<ApiDataBody<T>>]
export type ApiReturn<T> = Promise<ApiResponse<T>>

///////////////////////////////////////////////////////////////////////////////
// INSTANCE SETUP & HELPERS
///////////////////////////////////////////////////////////////////////////////

const selectedDevEnv = getDevEnvConfigById(getInitPersistedCentreEnv())

export const routerClient = axios.create({})
export const configuratorClient = axios.create({ baseURL: CONFIG.configurator_api_url })
const centreDevClient = axios.create({ baseURL: selectedDevEnv?.baseUrl ?? undefined })
const centreSandboxClient = axios.create({ baseURL: CONFIG.centre_prod_api_url })
const centreUatClient = axios.create({ baseURL: CONFIG.centre_uat_api_url })
const centreProdClient = axios.create({ baseURL: CONFIG.centre_prod_api_url })
export const brandingClient = axios.create({ baseURL: CONFIG.branding_url })
export const microservicesClient = axios.create({ baseURL: CONFIG.microservice_api_url })
export const reportingClient = axios.create({ baseURL: CONFIG.reporting_api_url })
export const bidlClient = axios.create({ baseURL: CONFIG.bidl_api_url })

let authInterceptor: number

export const addAxiosAuthInterceptor = (navigate) => {
  authInterceptor = configuratorClient.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response.status === 401) {
        store.dispatch.auth.clearAuth()
        navigate('/no-auth') // @todo: not stoked about this being here
      }

      return Promise.reject(error)
    }
  )
}

export const removeAxiosAuthInterceptor = () => {
  if (authInterceptor) configuratorClient.interceptors.request.eject(authInterceptor)
  authInterceptor = undefined
}

export const AxiosInterceptorProvider = ({ children }) => {
  const navigate = useNavigate()

  useEffect(() => {
    addAxiosAuthInterceptor(navigate)

    return () => {
      removeAxiosAuthInterceptor()
    }
  }, [navigate])

  return children
}

export const setAxiosHeader = (key: string, value: string) => {
  configuratorClient.defaults.headers.common[key] = value
}

export const removeAxiosHeader = (key: string) =>
  delete configuratorClient.defaults.headers.common[key]

export const setJwtAuthHeaders = (accessToken: string) => {
  const authHeader = `Bearer ${accessToken}`

  configuratorClient.defaults.headers['Authorization'] = authHeader
  brandingClient.defaults.headers['Authorization'] = authHeader
  microservicesClient.defaults.headers['Authorization'] = authHeader
  centreProdClient.defaults.headers['Authorization'] = authHeader
  centreUatClient.defaults.headers['Authorization'] = authHeader
  centreSandboxClient.defaults.headers['Authorization'] = authHeader
  centreDevClient.defaults.headers['Authorization'] = authHeader
  routerClient.defaults.headers['Authorization'] = authHeader
  reportingClient.defaults.headers['Authorization'] = authHeader
  bidlClient.defaults.headers['Authorization'] = authHeader
}

export function updateCentreDevClientBaseUrl(baseUrl: string) {
  centreDevClient.defaults.baseURL = baseUrl
}

///////////////////////////////////////////////////////////////////////////////

interface CentreNamedApis {
  prod: CentreApiRequests
  uat: CentreApiRequests
}

/**
 * All possible ways to make a Centre API request.
 *
 * "Current" environment (77 usages):  `centreAPIs.getUsers()`
 * "Other" environment (9 usages):     `centreAPIs.otherEnvironment.getUsers()`
 * Static access to prod (1 use):      `centreAPIs.prod.getUsers()`
 * Static access to uat (1 use):       `centreAPIs.uat.getUsers()`
 */
export const centreAPIs = (() => {
  if (IS_PROD) {
    return setupProdClients()
  }
  if (IS_SANDBOX) {
    return setupSandboxClient()
  }

  return setupDevClient()
})()

function setupDevClient() {
  const aliasesToCurrentAPI = Object.keys(centreAPIRequests).reduce((requests, requestName) => {
    return {
      ...requests,
      [requestName]: centreAPIRequests[requestName](centreDevClient),
    }
  }, {} as CentreApiRequests)

  const namedAPIs: CentreNamedApis = {
    prod: aliasesToCurrentAPI,
    uat: aliasesToCurrentAPI,
  }

  // Use named APIs based on context of currently selected environment
  const contextAwareAPIs = {
    get currentEnvironment() {
      return aliasesToCurrentAPI
    },
    get otherEnvironment() {
      if (IS_DEV_OR_SANDBOX) {
        throw new Error(
          'The `otherEnvironment` property is not supported in BUSHEL_ENV=dev|sandbox'
        )
      }

      return aliasesToCurrentAPI
    },
  }

  return Object.assign(contextAwareAPIs, namedAPIs, aliasesToCurrentAPI)
}

function setupSandboxClient() {
  const aliasesToCurrentAPI = Object.keys(centreAPIRequests).reduce((requests, requestName) => {
    return {
      ...requests,
      [requestName]: centreAPIRequests[requestName](centreSandboxClient),
    }
  }, {} as CentreApiRequests)

  const namedAPIs: CentreNamedApis = {
    prod: aliasesToCurrentAPI,
    uat: aliasesToCurrentAPI,
  }

  // Use named APIs based on context of currently selected environment
  const contextAwareAPIs = {
    get currentEnvironment() {
      return aliasesToCurrentAPI
    },
    get otherEnvironment() {
      if (['dev', 'sandbox'].includes(CONFIG.bushel_env)) {
        throw new Error('The `otherEnvironment` property is not supported in BUSHEL_ENV=dev')
      }

      return aliasesToCurrentAPI
    },
  }

  return Object.assign(contextAwareAPIs, namedAPIs, aliasesToCurrentAPI)
}

///////////////////////////////////////////////////////////////////////////////////////////////////

function setupProdClients() {
  // Create objects with environment specific api methods
  const namedAPIs = [
    { name: 'prod', api: centreProdClient },
    { name: 'uat', api: centreUatClient },
  ].reduce((acc, { name, api }) => {
    return {
      ...acc,
      [name]: Object.keys(centreAPIRequests).reduce((requests, requestName) => {
        return {
          ...requests,
          [requestName]: centreAPIRequests[requestName](api),
        }
      }, {}),
    }
  }, {}) as CentreNamedApis

  // Use named APIs based on context of currently selected environment
  const contextAwareAPIs = {
    get currentEnvironment() {
      return getEnvironment() === 'production' ? namedAPIs.prod : namedAPIs.uat
    },
    get otherEnvironment() {
      return getEnvironment() === 'production' ? namedAPIs.uat : namedAPIs.prod
    },
  }

  // Assign current environment calls to root object for easy default access
  const aliasesToCurrentAPI = Object.keys(centreAPIRequests).reduce((acc, requestName) => {
    acc[requestName] = (...args) => contextAwareAPIs.currentEnvironment[requestName](...args)
    return acc
  }, {} as CentreApiRequests)

  return Object.assign(contextAwareAPIs, namedAPIs, aliasesToCurrentAPI)
}
