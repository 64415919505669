import { theme as chakraTheme } from '@chakra-ui/react'

const theme = {
  ...chakraTheme,

  colors: {
    ...chakraTheme.colors,

    secondary: {
      50: '#e3f2fc',
      100: '#cdd8e4',
      200: '#b2c0cc',
      300: '#96a8b5',
      400: '#79909f',
      500: '#607886',
      600: '#4a5b69',
      700: '#32404c',
      800: '#1b2431',
      900: '#010818',
    },

    primary: {
      50: '#e0efff',
      100: '#b8d1f9',
      200: '#8eb6f0',
      300: '#639de7',
      400: '#3986df',
      500: '#4A90E2',
      600: '#15429b',
      700: '#0b2870',
      800: '#021246',
      900: '#00031d',
    },
  },
}

export default theme
