import React from 'react'
import { css } from '@emotion/react'
import { Box } from 'src/ui'
import styled from '@emotion/styled'
import { isFaded, representValue } from 'src/companies/configurations/utils'

export const DetailBox = styled(Box)({
  minWidth: 0,
  overflow: 'auto',
  scrollbarWidth: 'none',
  '::-webkit-scrollbar': {
    width: 0,
    height: 0,
  },
})

export const DetailLabel: React.FC<{ label: string }> = ({ label }) => {
  return (
    <Box title={label} fontSize="0.875rem" color="#718096">
      {label}
    </Box>
  )
}

export const DetailValue: React.FC<{ value: any; title: any }> = ({ value, title }) => {
  return (
    <DetailBox
      title={title}
      w="100%"
      whiteSpace="nowrap"
      css={css`
        text-overflow: ellipsis;
      `}
      color={isFaded(value) ? 'gray.400' : undefined}
    >
      {representValue(value)}
    </DetailBox>
  )
}
