import { ReactText } from 'react'

export type SelectOption = {
  value: ReactText
  label: ReactText
  isDisabled?: boolean
}

export const selectTheme = ({ theme, chakraTheme }) => {
  return {
    ...theme,

    colors: {
      ...theme.colors,

      primary25: chakraTheme.colors['blue']['100'],
      primary50: chakraTheme.colors['blue']['300'],
      primary75: chakraTheme.colors['blue']['400'],
      primary: chakraTheme.colors['blue']['500'],
    },
  }
}

export const selectStyles = ({ styles = {}, chakraTheme, width, hasError }) => {
  const { control } = styles as any
  let blue = chakraTheme.colors['blue']['500']
  let red = chakraTheme.colors['red']['500']

  return {
    container: (base) => ({
      ...base,
      width,
      fontSize: '0.875rem',
    }),

    control: (base, { isFocused, _isSelected }) => ({
      ...base,
      width,
      height: 32, // small styles
      minHeight: 32, // small styles
      borderColor: hasError ? red : isFocused ? blue : '#E2E8F0',
      borderRadius: '2px',
      boxShadow: hasError ? `0 0 0 1px ${red}` : isFocused ? `0 0 0 1px ${blue}` : undefined,
      '&:hover': hasError && {
        borderColor: red,
      },

      // small styles
      '& > div:first-of-type': {
        padding: '0 10px',
      },
      ...control,
    }),

    clearIndicator: (base) => ({
      ...base,
      color: '#E2E8F0',
      padding: '0 6px', // small styles
    }),

    dropdownIndicator: (base) => ({
      ...base,
      color: '#E2E8F0',
      padding: '0 6px', // small styles
    }),

    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: '#E2E8F0',
    }),

    option: (base) => ({
      ...base,
      padding: '6px 10px', // small styles
    }),

    menuList: (base) => ({
      ...base,
      width,
    }),

    noOptionsMessage: (base) => ({
      ...base,
      width,
      padding: '6px 10px', // small styles
    }),

    placeholder: (base) => ({
      ...base,
      whiteSpace: 'pre',
    }),
  }
}

export const sortOptions = (options) => {
  return [...options].sort(({ label: la }, { label: lb }) => {
    if (typeof la === 'string' && typeof lb === 'string') {
      return la.toLowerCase() < lb.toLowerCase() ? -1 : 1
    }

    return la < lb ? -1 : 1
  })
}
