import React from 'react'
import { Link } from 'react-router-dom'
import { Button, ButtonProps } from '@chakra-ui/react'

type ButtonLinkProps = Omit<ButtonProps, 'css'> & {
  disabled?: boolean
  scale?: string
  to: string
}

const ButtonLink: React.FC<ButtonLinkProps> = React.forwardRef(
  ({ variant, ...props }, ref: any) => (
    <Button
      as={Link}
      ref={ref}
      variant={variant}
      css={{
        '&:hover': {
          textDecoration: variant !== 'link' ? 'none' : undefined,
        },
      }}
      {...props}
    />
  )
)

export default ButtonLink
