import React from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { Formik, Form, Field, FormikProps } from 'formik'
import {
  TextField as FormikTextField,
  Switch as FormikSwitch,
  Select as FormikSelect,
} from 'src/ui/formik'
import { FormControl, FormLabel } from 'src/ui/form-controls'
import { Box, Stack } from 'src/ui'
import ConfigPanel, { PanelState } from 'src/companies/configurations/config-panel'
import { loadCopyDiff, executeCopy } from 'src/companies/configurations/properties/copy'
import { useCopyDiff } from 'src/companies/copy-utils'
import {
  convertEmptyStringsToNull,
  isFaded,
  representValue,
} from 'src/companies/configurations/utils'
import { useToast } from 'src/utils/toast'
import { Dispatch } from 'src/store'

type FormField = {
  key: string
  label: string
  readOnly?: boolean
  boolean?: boolean
  timed?: boolean
  type?: string
  options?: { value: string; label: string }[]
}

// FORM
///////////////////////////////////////////////////////////////////////////////

const FormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  branding_image: Yup.string().url().nullable(),
  phone: Yup.string()
    .matches(/\d{10}/, 'Number must be 10 digits')
    .nullable(),
  fax: Yup.string()
    .matches(/\d{10}/, 'Number must be 10 digits')
    .nullable(),
  email: Yup.string().email().nullable(),
  feedback_email: Yup.string().email().nullable(),
  failed_logins_email: Yup.string().email().nullable(),
  website: Yup.string().url().nullable(),
  test_recipients: Yup.string()
    .matches(/^\d{10}(?:,\d{10})*$/s, 'Numbers with 10 digits separated with commas')
    .nullable(),
  inactive_at: Yup.string().nullable(),
})

interface CompanyPropertiesFormProps {
  formikProps: FormikProps<any>
  formData: {
    submittedAt: number
    isSubmitting: boolean
  }
  formFields: FormField[][]
}

const CompanyPropertiesForm: React.FC<CompanyPropertiesFormProps> = ({
  formikProps,
  formData,
  formFields,
}) => {
  let { submittedAt } = formData
  let { submitForm } = formikProps

  React.useEffect(() => {
    if (!submittedAt) return
    submitForm()
  }, [submittedAt, submitForm])

  return (
    <Form>
      {formFields.map((group, index) => (
        <Stack key={index} isInline spacing={4} align="center" height="80px" px={6}>
          {group.map((field, index) => {
            if (!field) return <Box key={index} flex={1} />

            return (
              <Box key={field.key} flex={1} minWidth={0}>
                {field.boolean ? (
                  <Field
                    name={field.key}
                    label={field.label}
                    timed={field.timed}
                    component={FormikSwitch}
                    formControlProps={{
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      height: '62px',
                      mb: null,
                    }}
                    SwitchBoxProps={{ height: '32px', flex: 1 }}
                  />
                ) : field.type && field.type === 'select' ? (
                  <Field
                    name={field.key}
                    label={field.label}
                    component={FormikSelect}
                    formControlProps={{
                      height: '62px',
                      mb: null,
                    }}
                    options={field.options}
                    selectProps={{ sortOptions: false }}
                  />
                ) : (
                  <Field
                    name={field.key}
                    label={field.label}
                    component={FormikTextField}
                    formControlProps={{
                      height: '62px',
                      mb: null,
                    }}
                    InputProps={{
                      isDisabled: field.readOnly,
                    }}
                  />
                )}
              </Box>
            )
          })}
        </Stack>
      ))}

      {false && (
        <pre css={{ minWidth: 0, padding: '0 24px', overflow: 'auto', fontSize: 10 }}>
          {JSON.stringify(formikProps, null, 2)}
        </pre>
      )}
    </Form>
  )
}

// DETAIL
///////////////////////////////////////////////////////////////////////////////

interface CompanyPropertiesDetailProps {
  company: Company
  formFields: FormField[][]
}

const CompanyPropertiesDetail: React.FC<CompanyPropertiesDetailProps> = ({
  company,
  formFields,
}) => (
  <>
    {formFields.map((group, index) => {
      return (
        <Stack
          key={index}
          isInline
          spacing={4}
          align="center"
          height="80px"
          px={6}
          bg={index % 2 !== 0 ? 'gray.50' : undefined}
        >
          {group.map((field, index) => {
            if (!field) return <Box key={index} flex={1} />

            return (
              <Box key={field.key} flex={1} minWidth={0}>
                <FormControl height="62px" mb={null}>
                  <FormLabel as="div" title={field.label}>
                    {field.label}
                  </FormLabel>
                  <Box
                    color={isFaded(company[field.key]) ? 'gray.400' : undefined}
                    css={{
                      minWidth: 0,
                      overflow: 'auto',
                      whiteSpace: 'nowrap',
                      scrollbarWidth: 'none',
                      '::-webkit-scrollbar': {
                        width: 0,
                        height: 0,
                      },
                    }}
                  >
                    {representValue(company[field.key])}
                  </Box>
                </FormControl>
              </Box>
            )
          })}
        </Stack>
      )
    })}
  </>
)

// PANEL
///////////////////////////////////////////////////////////////////////////////
export const PROPERTIES_FORM_FIELDS = [
  [
    { key: 'id', label: 'ID', readOnly: true },
    { key: 'slug', label: 'Slug', readOnly: true },
    { key: 'tenant_id', label: 'Tenant Id', readOnly: true },
    { key: 'datasource_id', label: 'Data Source Id', readOnly: true },
  ],
  [
    { key: 'name', label: 'Name' },
    { key: 'translator_id', label: 'Translator ID' },
    { key: 'branding_image', label: 'Branding Image' },
    { key: 'email', label: 'Email' },
  ],
  [
    { key: 'feedback_email', label: 'Feedback Email' },
    { key: 'failed_logins_email', label: 'Failed Logins Email' },
    { key: 'wallet_admin_email', label: 'Wallet Admin Email' },
    { key: 'address_line_1', label: 'Address Line 1' },
  ],
  [
    { key: 'address_line_2', label: 'Address Line 2' },
    { key: 'city', label: 'City' },
    { key: 'state', label: 'State' },
    { key: 'zip_code', label: 'ZIP Code' },
  ],
  [
    {
      key: 'country_code',
      label: 'Country',
      type: 'select',
      options: [
        { label: 'USA', value: 'USA' },
        { label: 'CAN', value: 'CAN' },
      ],
    },
    { key: 'timezone', label: 'Timezone' },
    { key: 'phone', label: 'Phone' },
    { key: 'fax', label: 'Fax' },
  ],
  [
    { key: 'website', label: 'Website' },
    { key: 'test_recipients', label: 'Test Recipients' },
    { key: 'news_feature_version', label: 'News Feature Version' },
    { key: 'inactive_at', label: 'Inactive', boolean: true, timed: true },
  ],
]

type CompanyPropertiesPanelProps = {
  company: Company
  companyConfig?: CompanyConfig
  setCopyDialog: (args: any) => void
} & ReturnType<typeof mapDispatch>

const CompanyPropertiesPanel: React.FC<CompanyPropertiesPanelProps> = ({
  company,
  setCopyDialog,
  updateCompany,
}) => {
  let toast = useToast()
  let [propertiesState, setPropertiesState] = React.useState<PanelState>('expanded')
  let [formData, setFormData] = React.useState({ submittedAt: null, isSubmitting: false })
  let { startCopy } = useCopyDiff({
    company,
    loadCopyDiff,
    executeCopy,
    setCopyDialog,
  })

  const stopEditing = () => {
    setPropertiesState('expanded')
    setFormData({ submittedAt: null, isSubmitting: false })
  }

  return (
    <ConfigPanel
      label="Properties"
      expanded={['expanded', 'editing'].includes(propertiesState)}
      panelState={propertiesState}
      formData={formData}
      copyPermission="company_properties_copy"
      editPermission="company_properties_edit"
      onChange={(e, expanded) => {
        if (propertiesState === 'editing') return // ignore changes while editing
        setPropertiesState(expanded ? 'expanded' : 'collapsed')
      }}
      onClickCopy={startCopy}
      onClickEdit={() => setPropertiesState('editing')}
      onCancelEdit={stopEditing}
      onSave={() => setFormData((state) => ({ ...state, submittedAt: new Date().getTime() }))}
    >
      {propertiesState !== 'editing' && (
        <CompanyPropertiesDetail company={company} formFields={PROPERTIES_FORM_FIELDS} />
      )}
      {propertiesState === 'editing' && (
        <Formik
          initialValues={company}
          validateOnChange={false}
          validationSchema={FormSchema}
          onSubmit={async (values, _formikActions) => {
            delete values.configView // triggers api validation err otherwise

            setFormData((state) => ({ ...state, isSubmitting: true }))

            let [err] = await updateCompany({ company: convertEmptyStringsToNull(values) })
            if (err) {
              toast({
                status: 'error',
                title: 'Error',
                description: 'Failed to update company properties',
              })
              setFormData({ submittedAt: null, isSubmitting: false })
              return
            }

            toast({ title: 'Success', description: 'Updated company properties' })

            stopEditing()
          }}
        >
          {(formikProps: FormikProps<any>) => (
            <CompanyPropertiesForm
              formikProps={formikProps}
              formData={formData}
              formFields={PROPERTIES_FORM_FIELDS}
            />
          )}
        </Formik>
      )}
    </ConfigPanel>
  )
}

const mapDispatch = ({ companies, notifications }: Dispatch) => ({
  updateCompany: companies.updateCompany,
  errorNotification: notifications.createError,
  successNotification: notifications.createSuccess,
})

export default connect(
  null, // mapState
  mapDispatch
)(CompanyPropertiesPanel)
