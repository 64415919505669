import { handleApiError } from 'src/store/utils'
import { RootState } from 'src/store'
import { STATUS } from 'src/utils/api-status'
import { centreAPIs } from 'src/utils/api'
import { IS_DEV } from 'src/utils/config'

export interface CompaniesState {
  status: STATUS
  data: Company[]
}

const Companies = {
  state: {
    status: STATUS.idle,
    data: [] as Company[],
  },

  reducers: {
    setState(state: CompaniesState, payload): CompaniesState {
      return { ...state, ...payload }
    },

    reset() {
      return {
        status: STATUS.idle,
        data: [],
      }
    },
  },

  effects: (dispatch) => ({
    async loadAll(_payload = {}) {
      dispatch.companies.setState({ status: STATUS.loading })

      let [err, response] = await centreAPIs.getCentreCompanies()

      if (err) {
        dispatch.companies.setState({
          status: STATUS.failed,
        })

        return handleApiError(dispatch, err)
      }

      dispatch.companies.setState({
        status: STATUS.loaded,
        data: response.data.data,
      })
    },

    async createCompany({ company }, _rootState: RootState) {
      if (IS_DEV) {
        const [devErr, devResponse] = await centreAPIs.createCentreCompany({ company })

        if (devErr) {
          handleApiError(dispatch, devErr)
          return [devErr, devResponse]
        }

        await dispatch.companies.loadAll()

        dispatch.settings.setState({
          showCompanyCreateDialog: false,
          showCompanySelectorDialog: true,
        })

        return [devErr, devResponse]
      }

      let [uatErr, uatResp] = await centreAPIs.uat.createCentreCompany({ company })

      if (uatErr) {
        handleApiError(dispatch, uatErr)
        return [uatErr, uatResp]
      }

      let [prodErr, prodResp] = await centreAPIs.prod.createCentreCompany({ company })

      if (prodErr) {
        handleApiError(dispatch, prodErr)
        return [prodErr, prodResp]
      }

      await dispatch.companies.loadAll()

      dispatch.settings.setState({
        showCompanyCreateDialog: false,
        showCompanySelectorDialog: true,
      })

      return [prodErr, prodResp]
    },

    async updateCompany({ company }, rootState: RootState) {
      let [err, response] = await centreAPIs.updateCentreCompany({ company })

      if (err) {
        handleApiError(dispatch, err)
        return [err, response]
      }

      dispatch.companies.setState({
        status: STATUS.loaded,
        data: rootState.companies.data.map((c) => (c.id === company.id ? response.data.data : c)),
      })

      return [err, response]
    },
  }),
}

export default Companies
