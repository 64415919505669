import React from 'react'
import * as Yup from 'yup'
import { Formik, Form, Field, FormikProps } from 'formik'
import { UseMutateAsyncFunction } from '@tanstack/react-query'
import ConfigPanel, { PanelState } from 'src/companies/configurations/config-panel'
import { LocationPositions } from 'src/companies/locations/location-positions'
import {
  TextField as FormikTextField,
  Switch as FormikSwitch,
  Select as FormikSelect,
} from 'src/ui/formik'
import { FormControl, FormLabel } from 'src/ui/form-controls'
import { Box, Stack } from 'src/ui'
import { isFaded, representValue } from 'src/companies/configurations/utils'
import * as api from 'src/utils/api'

// FORM
///////////////////////////////////////////////////////////////////////////////

type FormField = {
  key: string
  label: string
  readOnly?: boolean
  boolean?: boolean
  timed?: boolean
  type?: string
  options?: { value: string; label: string }[]
}

const FormSchema = Yup.object().shape({
  remote_id: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  phone: Yup.string()
    .matches(/\d{10}/, 'Must be 10 digits')
    .nullable(),
  fax: Yup.string()
    .matches(/\d{10}/, 'Must be 10 digits')
    .nullable(),
  email: Yup.string().email('Invalid email').nullable(),
  website: Yup.string().url('Invalid URL').nullable(),
})

interface LocationFormProps {
  formikProps: FormikProps<any>
  formData: {
    submittedAt: number
    isSubmitting: boolean
  }
  formFieldGroups: FormField[][]
}

const LocationForm: React.FC<LocationFormProps> = ({ formikProps, formData, formFieldGroups }) => {
  let { submittedAt } = formData
  let { submitForm } = formikProps

  React.useEffect(() => {
    if (!submittedAt) return
    submitForm()
  }, [submittedAt, submitForm])

  return (
    <>
      <Form>
        {formFieldGroups.map((group, index) => (
          <Stack key={index} isInline spacing={4} align="center" m={4} px={6}>
            {group.map((field, index) => {
              if (!field) return <Box key={index} flex={1} />

              return (
                <Box key={field.key || index} flex={1} minWidth={0}>
                  {field.boolean && (
                    <Field
                      name={field.key}
                      label={field.label}
                      component={FormikSwitch}
                      timed={field.timed}
                      formControlProps={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        height: '62px',
                        mb: null,
                      }}
                      SwitchBoxProps={{ height: '32px' }}
                    />
                  )}
                  {field.type && field.type === 'select' && (
                    <Field
                      name={field.key}
                      label={field.label}
                      component={FormikSelect}
                      formControlProps={{ height: '62px', mb: null }}
                      options={field.options}
                      selectProps={{ sortOptions: false }}
                    />
                  )}
                  {field.type && field.type === 'multiselect' && (
                    <Field
                      name={field.key}
                      label={field.label}
                      component={FormikSelect}
                      formControlProps={{ height: 'auto', mb: null }}
                      options={field.options}
                      selectProps={{
                        isMulti: true,
                        sortOptions: false,
                        styles: {
                          control: {
                            height: 'auto',
                          },
                        },
                      }}
                    />
                  )}
                  {!field.boolean && !field.type && (
                    <Field
                      name={field.key}
                      label={field.label}
                      component={FormikTextField}
                      formControlProps={{ height: '62px', mb: null }}
                      InputProps={{ isDisabled: field.readOnly }}
                    />
                  )}
                </Box>
              )
            })}
          </Stack>
        ))}
      </Form>

      {/* <pre css={{ fontSize: 10, overflow: 'auto' }}>{JSON.stringify(formikProps, null, 2)}</pre> */}
    </>
  )
}

// DETAIL
///////////////////////////////////////////////////////////////////////////////

interface LocationDetailProps {
  location: BushelLocation
  formFieldGroups: FormField[][]
}

const LocationDetail: React.FC<LocationDetailProps> = ({ location, formFieldGroups }) => (
  <>
    {formFieldGroups.map((group, index) => {
      return (
        <Stack
          key={index}
          isInline
          spacing={4}
          align="center"
          py={4}
          px={6}
          bg={index % 2 !== 0 ? 'gray.50' : undefined}
        >
          {group.map((field, index) => {
            if (!field) return <Box key={index} flex={1} />

            return (
              <Box key={field.key || index} flex={1} minWidth={0}>
                <FormControl height="auto" mb={null}>
                  <FormLabel as="div" title={field.label}>
                    {field.label}
                  </FormLabel>

                  <Box
                    color={isFaded(location[field.key], field) ? 'gray.400' : undefined}
                    css={{
                      minWidth: 0,
                      overflow: 'auto',
                      whiteSpace: 'nowrap',
                      scrollbarWidth: 'none',
                      '::-webkit-scrollbar': {
                        width: 0,
                        height: 0,
                      },
                    }}
                  >
                    {representValue(location[field.key], field)}
                  </Box>
                </FormControl>
              </Box>
            )
          })}
        </Stack>
      )
    })}
  </>
)

///////////////////////////////////////////////////////////////////////////////

const FORM_FIELD_KEYS = [
  [
    { key: 'id', label: 'ID', readOnly: true },
    { key: 'remote_id', label: 'Remote ID' },
    { key: 'name', label: 'Name' },
    null,
  ],
  [
    { key: 'address_line_1', label: 'Address Line 1' },
    { key: 'address_line_2', label: 'Address Line 2' },
  ],
  [
    { key: 'city', label: 'City' },
    { key: 'state', label: 'State' },
    { key: 'zip_code', label: 'ZIP Code' },
    {
      key: 'country_code',
      label: 'Country',
      type: 'select',
      options: [
        { label: 'USA', value: 'USA' },
        { label: 'CAN', value: 'CAN' },
      ],
    },
  ],
  [
    { key: 'phone', label: 'Phone' },
    { key: 'fax', label: 'Fax' },
    { key: 'email', label: 'Email' },
    { key: 'website', label: 'Website' },
  ],
  [
    {
      key: 'cash_bids_hidden_at',
      label: 'Hide in Cash Bids',
      boolean: true,
      timed: true,
    },
    {
      key: 'push_notifications_hidden_at',
      label: 'Hide in Push Notifications',
      boolean: true,
      timed: true,
    },
    {
      key: 'hidden',
      label: 'Hidden',
      boolean: true,
    },
    {
      key: 'business_type',
      label: 'Business Type',
      type: 'multiselect',
      options: [
        { label: 'Grain Delivery', value: 'Grain Delivery' },
        { label: 'Agronomy', value: 'Agronomy' },
        { label: 'Office', value: 'Office' },
        { label: 'Plant', value: 'Plant' },
        { label: 'Seed', value: 'Seed' },
        { label: 'Feed', value: 'Feed' },
      ],
    },
  ],
]

interface LocationCardProps {
  location: BushelLocation
  positions: Position[]
  mb?: string | number
  setShowNewForm?: (val: boolean) => void
  startCopy: (args: any) => void
  saveLocation: UseMutateAsyncFunction<
    api.ApiResponse<BushelLocation>,
    unknown,
    {
      location: BushelLocation
      onSuccess?: () => void
    },
    unknown
  >
  allPanelsPropertiesState: PanelState
  company?: Company
}

const LocationCard: React.FC<LocationCardProps> = ({
  location,
  positions,
  mb = null, // from <Stack />
  setShowNewForm = null,
  startCopy,
  saveLocation,
  allPanelsPropertiesState,
  company,
}) => {
  let [propertiesState, setPropertiesState] = React.useState<PanelState>(
    !location.id ? 'editing' : 'collapsed'
  )
  let [formData, setFormData] = React.useState({ submittedAt: null, isSubmitting: false })

  React.useEffect(() => {
    if (!allPanelsPropertiesState) return
    if (propertiesState === 'editing') return
    setPropertiesState(allPanelsPropertiesState)
  }, [allPanelsPropertiesState, propertiesState])

  const stopEditing = () => {
    if (setShowNewForm) {
      setShowNewForm(false)
      return
    }

    setPropertiesState('expanded')
    setFormData({ submittedAt: null, isSubmitting: false })
  }

  return (
    <ConfigPanel
      label={location.name}
      expanded={['expanded', 'editing'].includes(propertiesState)}
      panelState={propertiesState}
      panelProps={{ mb }}
      formData={formData}
      copyPermission="company_locations_copy"
      editPermission="company_locations_edit"
      onChange={(e, expanded) => {
        if (propertiesState === 'editing') return // ignore changes while editing
        setPropertiesState(expanded ? 'expanded' : 'collapsed')
      }}
      onClickCopy={() => startCopy({ context: { location } })}
      onClickEdit={() => setPropertiesState('editing')}
      onCancelEdit={stopEditing}
      onSave={() => setFormData((state) => ({ ...state, submittedAt: new Date().getTime() }))}
    >
      {propertiesState !== 'editing' && (
        <LocationDetail location={location} formFieldGroups={FORM_FIELD_KEYS} />
      )}

      {propertiesState === 'editing' && (
        <Formik
          initialValues={location}
          validateOnChange={false}
          validationSchema={FormSchema}
          onSubmit={async (values, _formikActions) => {
            setFormData((state) => ({ ...state, isSubmitting: true }))

            await saveLocation({
              location: {
                ...location,
                ...values,
              },
              onSuccess: () => {
                stopEditing()
              },
            })

            setFormData({ submittedAt: null, isSubmitting: false })
          }}
        >
          {(formikProps: FormikProps<any>) => (
            <LocationForm
              formikProps={formikProps}
              formData={formData}
              formFieldGroups={FORM_FIELD_KEYS}
            />
          )}
        </Formik>
      )}

      {location.id && (
        <Box px={6} pt={4}>
          <LocationPositions
            elevator={location}
            positions={positions}
            isEditing={propertiesState === 'editing'}
            company={company}
          />
        </Box>
      )}
    </ConfigPanel>
  )
}

export default LocationCard
