import React from 'react'
import { FastField, Field, FieldArray, Form, Formik, FormikProps } from 'formik'
import { sortBy } from 'lodash'
import { useQueryClient } from '@tanstack/react-query'
import { FormControl, FormLabel } from 'src/ui/form-controls'
import { hasPermission } from 'src/utils/permissions'
import {
  Box,
  CardContent,
  Column,
  FormRow,
  InputRightElement,
  Button,
  Row,
  SmallAddIcon,
  Stack,
  Table2,
  Tooltip,
  useBlockTable,
  InfoOutlineIcon,
  CloseIcon,
} from 'src/ui'
import { PanelState } from 'src/companies/configurations/config-panel'
import * as FormikElements from 'src/ui/formik'
import {
  convertEmptyStringsToNull,
  isFaded,
  representValue,
} from 'src/companies/configurations/utils'
import { CashBidConfigSchema, configTypeDisplayName, configTypeHasField } from './utils'
import { useToast } from 'src/utils/toast'
import MappingPanel from './mapping-panel'
import MappingConfigPanel from './mapping-config-panel'

/**
 * The elevator mapping list shouldn't use numerical index keys, and the label can be changed.
 * It's just easiest to generate a random key for them on initial load and addition.
 */
export const randKey = () =>
  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

const cashBidFieldGroups = [
  [
    { key: 'username', label: 'Username' },
    { key: 'password', label: 'Password' },
  ],
  [
    { key: 'api_type', label: 'API Type' },
    { key: 'api_url', label: 'API URL' },
  ],
  [
    { key: 'local_crop_ids', label: 'Local Crop IDs' },
    { key: 'retrieved_from', label: 'Retrieved From' },
  ],
]

export interface FormValues {
  retrieved_from: 'local' | 'api'
  api_type: string
  api_url: string
  username: string
  password: string
  local_crop_ids: string
  elevator_mapping: { key?: string; provider_location_id: string; locations: string[] }[] | null
  options: { [key: string]: any } | null
}

const CashBidConfigDetail = ({ config, locations }) => {
  let { options, elevator_mapping, retrieved_from, api_type } = config
  let elevatorMappingKeys = elevator_mapping ? Object.keys(elevator_mapping) : []

  return (
    <CardContent>
      {cashBidFieldGroups.map((group, index) => {
        return (
          <div key={index}>
            {(configTypeHasField({ retrieved_from, api_type, field: group[0].key }) ||
              configTypeHasField({ retrieved_from, api_type, field: group[1].key })) && (
              <Stack key={index} isInline spacing={2} align="center" h="80px" px={6}>
                {group.map((field, index) => (
                  <Box
                    key={field.key || index}
                    flex={1}
                    css={{
                      minWidth: 0,
                      overflow: 'auto',
                      whiteSpace: 'nowrap',
                      scrollbarWidth: 'none',
                      '::-webkit-scrollbar': {
                        width: 0,
                        height: 0,
                      },
                    }}
                  >
                    <FormControl>
                      <FormLabel title={field.label}>{field.label}</FormLabel>
                      <Box color={isFaded(config[field.key]) ? 'gray.400' : undefined}>
                        {representValue(config[field.key])}
                      </Box>
                    </FormControl>
                  </Box>
                ))}
              </Stack>
            )}
          </div>
        )
      })}
      {configTypeHasField({ retrieved_from, api_type, field: 'options' }) && options && (
        <Stack isInline spacing={4} align="flex-start" minHeight="120px" px={6} py={2}>
          <Box minWidth={0} flex={1}>
            <FormControl height="auto" mb={null}>
              <FormLabel title="Options">Options</FormLabel>
              <Box
                fontSize="sm"
                css={{
                  minWidth: 0,
                  overflow: 'auto',
                  whiteSpace: 'nowrap',
                  scrollbarWidth: 'none',
                  '::-webkit-scrollbar': {
                    width: 0,
                    height: 0,
                  },
                }}
              >
                <pre>{JSON.stringify(options, null, 2)}</pre>
              </Box>
            </FormControl>
          </Box>
        </Stack>
      )}
      {configTypeHasField({
        retrieved_from,
        api_type,
        field: 'elevator_mapping',
      }) && (
        <>
          {elevatorMappingKeys.length === 0 ? (
            <Box px={6} py={4}>
              <FormLabel>No Mappings</FormLabel>
            </Box>
          ) : (
            <Box px={6} py={4}>
              <FormLabel title="Mapping">Mapping</FormLabel>
              {elevatorMappingKeys.map((key) => (
                <ElevatorMappingDetailContainer
                  mapping={key}
                  key={key}
                  locations={elevator_mapping[key].map((mapping) => {
                    return (
                      locations.find((location) => location.remote_id === mapping) || {
                        name: 'The remote ID changed for this location',
                        remote_id: '',
                      }
                    )
                  })}
                />
              ))}
            </Box>
          )}
        </>
      )}
    </CardContent>
  )
}

const CashBidConfigForm = ({ formikBag, formData, locations }) => {
  let { retrieved_from, api_type } = formikBag.values
  let { submittedAt } = formData
  let { submitForm } = formikBag

  React.useEffect(() => {
    if (!submittedAt) return
    submitForm()
  }, [submittedAt, submitForm])

  return (
    <Form>
      {configTypeHasField({ retrieved_from, api_type, field: 'username' }) && (
        <FormRow rowProps={{ mb: 0 }}>
          <Field name="username" label="Username" component={FormikElements.TextField} />

          <Field name="password" label="Password" component={FormikElements.TextField} />
        </FormRow>
      )}

      {configTypeHasField({ retrieved_from, api_type, field: 'api_url' }) && (
        <FormRow rowProps={{ mb: 0 }}>
          <Field
            name="api_url"
            label="URL"
            placeholder="Paste URL Here"
            component={FormikElements.TextField}
          />
        </FormRow>
      )}

      {configTypeHasField({
        retrieved_from,
        api_type,
        field: 'local_crop_ids',
      }) && (
        <FormRow rowProps={{ mb: 0 }}>
          <Field
            name="local_crop_ids"
            label="Local Crop IDs"
            component={FormikElements.TextField}
          />
        </FormRow>
      )}

      {configTypeHasField({ retrieved_from, api_type, field: 'options' }) && (
        <FormRow rowProps={{ mt: 3 }}>
          <FastField
            id="options"
            name="options"
            label="Options"
            component={FormikElements.JsonEditor}
            height={180}
          />
        </FormRow>
      )}

      {configTypeHasField({
        retrieved_from,
        api_type,
        field: 'elevator_mapping',
      }) && <LocationMappings formikBag={formikBag} locations={locations} />}
    </Form>
  )
}

interface CashBidConfigCardProps {
  company: Company
  config: CashBidConfig
  locations: BushelLocation[]
  showDeleteDialog: () => void
  saveConfig: (
    config: CashBidConfig,
    handlers: {
      onSuccess: (_data: any, { id }: any) => Promise<void>
      onError: (any) => void
    }
  ) => void
  setShowNewForm: (value: boolean) => void
}

const CashBidConfigCard: React.FC<CashBidConfigCardProps> = ({
  company,
  config,
  locations,
  saveConfig,
  setShowNewForm,
  showDeleteDialog,
}) => {
  let [propertiesState, setPropertiesState] = React.useState<PanelState>('collapsed')
  let [formData, setFormData] = React.useState({ submittedAt: null, isSubmitting: false })
  let toast = useToast()
  const queryClient = useQueryClient()

  const stopEditing = (formReset) => {
    if (!config.id) {
      setShowNewForm(false)
      return
    }
    formReset()
    setPropertiesState('expanded')
    setFormData({ submittedAt: null, isSubmitting: false })
  }

  React.useEffect(() => {
    if (!config.id) {
      setPropertiesState('editing')
    }
  }, [config])

  return (
    <Formik
      initialValues={{
        ...config,
        options: config.options ? config.options : null,
        elevator_mapping: config.elevator_mapping
          ? Object.keys(config.elevator_mapping).map((provider_location_id) => ({
              key: randKey(),
              provider_location_id,
              locations: config.elevator_mapping[provider_location_id],
            }))
          : [],
      }}
      validationSchema={CashBidConfigSchema}
      validateOnChange={false}
      onSubmit={(values, _formikBag) => {
        setFormData((state) => ({ ...state, isSubmitting: true }))
        let isLocal = values.retrieved_from === 'local'

        if (isLocal) {
          values.elevator_mapping = null
        }

        let configToSave = {
          id: config.id,
          company_id: company.id,
          retrieved_from: values.retrieved_from,
          api_type: isLocal ? '' : values.api_type,
          api_url: configTypeHasField({
            retrieved_from: values.retrieved_from,
            api_type: values.api_type,
            field: 'api_url',
          })
            ? values.api_url
            : '',
          username: configTypeHasField({
            retrieved_from: values.retrieved_from,
            api_type: values.api_type,
            field: 'username',
          })
            ? values.username
            : null,
          password: configTypeHasField({
            retrieved_from: values.retrieved_from,
            api_type: values.api_type,
            field: 'password',
          })
            ? values.password
            : null,
          local_crop_ids: configTypeHasField({
            retrieved_from: values.retrieved_from,
            api_type: values.api_type,
            field: 'local_crop_ids',
          })
            ? values.local_crop_ids
            : null,
          options: configTypeHasField({
            retrieved_from: values.retrieved_from,
            api_type: values.api_type,
            field: 'options',
          })
            ? values.options
            : null,
          elevator_mapping: configTypeHasField({
            retrieved_from: values.retrieved_from,
            api_type: values.api_type,
            field: 'elevator_mapping',
          })
            ? values.elevator_mapping.reduce(
                (acc, mapping) => ({
                  ...acc,
                  [mapping.provider_location_id]: mapping.locations,
                }),
                {}
              )
            : null,
        }

        saveConfig(configToSave, {
          onSuccess: async (_data, { id }) => {
            await queryClient.invalidateQueries({ queryKey: ['centre-cashbid-configs'] })
            toast({
              description: `Successfully ${id ? 'updated' : 'created'} config`,
            })
            stopEditing(_formikBag.resetForm)
          },
          onError: () => {
            toast({ status: 'error', description: 'Failed to save config' })
            setFormData({ submittedAt: null, isSubmitting: false })
          },
        })
      }}
    >
      {(formikBag: FormikProps<FormValues>) => {
        return (
          <MappingConfigPanel
            displayName={configTypeDisplayName({
              retrieved_from: formikBag.values.retrieved_from,
              api_type: formikBag.values.api_type,
            })}
            formikBag={formikBag}
            expanded={['expanded', 'editing'].includes(propertiesState)}
            copyPermission="company_cashbid_copy"
            editPermission="company_cashbid_edit"
            formData={formData}
            hideToggleIcon={true}
            panelState={propertiesState}
            onSave={() => setFormData((state) => ({ ...state, submittedAt: new Date().getTime() }))}
            onCancelEdit={stopEditing}
            onChange={(e, expanded) => {
              if (propertiesState === 'editing') return
              setPropertiesState(expanded ? 'expanded' : 'collapsed')
            }}
            controls={
              propertiesState !== 'editing' && (
                <Box pr={2}>
                  <Button
                    marginRight="16px"
                    width="76px"
                    size="sm"
                    color="white"
                    colorScheme="secondary"
                    backgroundColor="secondary.500"
                    isDisabled={!hasPermission('company_cashbid_edit')}
                    onClick={(e) => {
                      e.stopPropagation()
                      setPropertiesState('expanded')
                      showDeleteDialog()
                    }}
                  >
                    DELETE
                  </Button>
                  <Button
                    width="76px"
                    size="sm"
                    colorScheme="primary"
                    onClick={(e) => {
                      e.stopPropagation()
                      setPropertiesState('editing')
                    }}
                  >
                    EDIT
                  </Button>
                </Box>
              )
            }
          >
            {propertiesState !== 'editing' && (
              <CashBidConfigDetail
                config={convertEmptyStringsToNull(config)}
                locations={locations ? locations : []}
              />
            )}

            {propertiesState === 'editing' && (
              <Box px={6}>
                <CashBidConfigForm
                  formikBag={formikBag}
                  formData={formData}
                  locations={locations}
                />
              </Box>
            )}
          </MappingConfigPanel>
        )
      }}
    </Formik>
  )
}

export default CashBidConfigCard

interface LocationMappingProps {
  formikBag: FormikProps<FormValues>
  locations: BushelLocation[]
}

const LocationMappings: React.FC<LocationMappingProps> = ({ formikBag, locations }) => {
  let [expanded, setExpanded] = React.useState(
    formikBag.values.elevator_mapping
      ? formikBag.values.elevator_mapping
          .filter((item) => {
            return item.locations.length <= 5
          })
          .map((item) => item.key)
      : []
  )
  let { elevator_mapping } = formikBag.values
  let locationSelectOptions = sortBy(
    locations
      .filter((location) => !!location.remote_id)
      .map((location) => ({
        value: location.remote_id,
        label: location.name || location.remote_id,
      })),
    'label'
  )
  return (
    <>
      <FieldArray
        name="elevator_mapping"
        render={(mappingsArrayHelpers) => {
          return (
            <Column my={4}>
              <Row alignItems="center" justifyContent="space-between">
                <Box color="#8C9BA5" fontSize={14} fontWeight={500} px={3}>
                  {elevator_mapping && elevator_mapping.length >= 1 ? 'MAPPING' : 'No Mappings'}
                  {elevator_mapping && elevator_mapping.length >= 1 && (
                    <Tooltip
                      label="Mappings are used to map Company Locations to Cash Bid Provider Locations. Fill in the Cash Bid Provider Location Id and then select the Company Locations that should be mapped to it."
                      aria-label="Mapping"
                    >
                      <InfoOutlineIcon marginLeft="8px" />
                    </Tooltip>
                  )}
                </Box>

                <Box>
                  <Button
                    size="sm"
                    variant="ghost"
                    onClick={() => {
                      let newKey = randKey()
                      setExpanded((state) => [...state, newKey])
                      mappingsArrayHelpers.unshift({
                        key: newKey,
                        provider_location_id: '',
                        locations: [''],
                      })
                    }}
                  >
                    <SmallAddIcon mr={2} color="white" bg="primary.500" rounded="8px" boxSize={4} />
                    Add Map
                  </Button>
                </Box>
              </Row>

              {elevator_mapping &&
                elevator_mapping.map((mapping, mappingIndex) => {
                  let isExpanded = expanded.includes(mapping.key)
                  if (mapping.locations.length === 0) {
                    //if no locations in mapping, start with an empty select box
                    mapping.locations = ['']
                  }
                  return (
                    <Box key={mappingIndex} py={3}>
                      <MappingPanel
                        header={isExpanded ? undefined : mapping.provider_location_id}
                        mappingCount={mapping.locations.length}
                        expanded={isExpanded}
                        onChange={(e, expanded) => {
                          setExpanded((state) =>
                            expanded
                              ? [...state, mapping.key]
                              : state.filter((k) => k !== mapping.key)
                          )
                        }}
                        onDelete={() => {
                          mappingsArrayHelpers.remove(mappingIndex)
                          setExpanded((state) => state.filter((k) => k !== mapping.key))
                        }}
                      >
                        <Column width="100%" mt={isExpanded ? '-40px' : undefined}>
                          <Row alignItems="flex-start">
                            <Column width={200}>
                              <Field
                                name={`elevator_mapping.${mappingIndex}.provider_location_id`}
                                label="Provider Location Id"
                                component={FormikElements.TextField}
                                rightElement={
                                  <InputRightElement>
                                    <CloseIcon
                                      color="gray.400"
                                      aria-label="Clear"
                                      onClick={() => {
                                        formikBag.setFieldValue(
                                          `elevator_mapping.${mappingIndex}.provider_location_id`,
                                          ''
                                        )
                                      }}
                                    />
                                  </InputRightElement>
                                }
                              />
                            </Column>

                            <Box my="30px" px={2}>
                              <img
                                src="/images/mapping-indicator.svg"
                                alt="right-arrow-mapping-indicator"
                              />
                            </Box>

                            <FieldArray
                              name={`elevator_mapping.${mappingIndex}.locations`}
                              render={(locationsArrayHelpers) => (
                                <Column width={250}>
                                  {mapping.locations &&
                                    mapping.locations.map((location, locationIndex) => {
                                      let isFirst = locationIndex === 0
                                      const filteredLocationSelectOptions =
                                        locationSelectOptions.filter(
                                          (locationOption) =>
                                            !mapping.locations
                                              .filter(
                                                (mappedLocation) => mappedLocation !== location
                                              )
                                              .includes(locationOption.value)
                                        )

                                      return (
                                        <Row key={location} alignItems="start">
                                          <Box mt={isFirst ? 0 : 3} width={200}>
                                            <Field
                                              name={`elevator_mapping.${mappingIndex}.locations.${locationIndex}`}
                                              id={`elevator_mapping.${mappingIndex}.locations.${locationIndex}`}
                                              label={isFirst ? 'Locations' : undefined}
                                              placeholder=""
                                              options={filteredLocationSelectOptions}
                                              component={FormikElements.Select}
                                              formControlProps={{ height: 'auto' }}
                                              selectProps={{ isClearable: false }}
                                            />
                                          </Box>

                                          <Row
                                            width={50}
                                            justifyContent="center"
                                            alignItems="center"
                                            pt={isFirst ? '22px' : '10px'}
                                          >
                                            {
                                              // if there's only one left, don't let it be removed
                                              mapping.locations.length > 1 && (
                                                <Button
                                                  size="sm"
                                                  variant="unstyled"
                                                  textAlign="center"
                                                  aria-label="Delete Location"
                                                  onClick={() =>
                                                    locationsArrayHelpers.remove(locationIndex)
                                                  }
                                                >
                                                  <CloseIcon
                                                    cursor="pointer"
                                                    p={1}
                                                    rounded="8px"
                                                    background="#ACBCC6"
                                                    w={4}
                                                    h={4}
                                                    color="white"
                                                  />
                                                </Button>
                                              )
                                            }
                                          </Row>
                                        </Row>
                                      )
                                    })}

                                  {
                                    // if there already an empty row, don't allow adding another. (keeps keys unique)
                                    !mapping.locations.includes('') && (
                                      <Row justifyContent="flex-end">
                                        <Row justifyContent="center">
                                          <Button
                                            size="sm"
                                            variant="unstyled"
                                            textAlign="center"
                                            aria-label="Add Location"
                                            mr={2}
                                            onClick={() => locationsArrayHelpers.push('')}
                                          >
                                            <SmallAddIcon
                                              mr={2}
                                              color="white"
                                              bg="primary.500"
                                              rounded="8px"
                                              boxSize={4}
                                            />
                                          </Button>
                                        </Row>
                                      </Row>
                                    )
                                  }
                                </Column>
                              )}
                            />
                          </Row>
                        </Column>
                      </MappingPanel>
                    </Box>
                  )
                })}
            </Column>
          )
        }}
      />
    </>
  )
}

const ElevatorMappingDetailContainer = ({ mapping, locations }) => {
  let [isExpanded, setIsExpanded] = React.useState(locations.length <= 5)

  return (
    <MappingPanel
      expanded={isExpanded}
      panelProps={{ px: null }}
      headerProps={{ px: 6 }}
      onChange={() => {
        setIsExpanded(!isExpanded)
      }}
      mappingCount={locations.length}
      header={
        <Row width="100%" justifyContent="space-between" alignItems="center">
          <Box>{mapping}</Box>
        </Row>
      }
    >
      {isExpanded && <ElevatorMappingDetail locations={locations} />}
    </MappingPanel>
  )
}

const ElevatorMappingDetail = ({ locations }) => {
  let columns = React.useMemo(
    () => [
      {
        id: 'name',
        accessor: 'name',
        Header: 'Location',
        width: '50%',
      },
      {
        id: 'remote_id',
        accessor: 'remote_id',
        Header: 'Remote ID',
        width: '50%',
      },
    ],
    []
  )

  let { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useBlockTable({
    data: locations,

    tableOptions: {},

    columns,
  })

  return (
    <>
      <Box mb={4}>
        <Table2.Block styles={`.th, .td {padding: 0 1.5rem;}`} {...getTableProps()} striped>
          <Table2.BlockHeader headerGroups={headerGroups} />
          <Table2.BlockBody
            isLoading={false}
            rows={rows}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
          />
        </Table2.Block>
      </Box>
    </>
  )
}
