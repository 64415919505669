import React from 'react'
import { deleteTranslatorSecret } from 'src/utils/api'
import { Box, Row, Button, Dialog } from 'src/ui'
import { useToast } from 'src/utils/toast'
import { useQueryClient } from '@tanstack/react-query'

interface DeleteDialogProps extends DialogProps {
  componentTitle: string
  translator: Translator
  secretDelete: TranslatorSecret
}

export const DeleteDialog: React.FC<DeleteDialogProps> = ({
  isOpen,
  onClose,
  componentTitle,
  translator,
  secretDelete,
}) => {
  const translatorID = translator?.id
  const secretID = secretDelete?.id
  let toast = useToast()
  const queryClient = useQueryClient()

  async function deleteSecret() {
    let [err, response] = await deleteTranslatorSecret({ translatorID, secretID })

    if (response) {
      await queryClient.invalidateQueries({ queryKey: ['translator-secrets'] })
      toast({ status: 'success', description: 'Secret Deleted!' })
    } else {
      toast({ status: 'error', description: 'Something went wrong: ' + err })
    }
  }

  return (
    <Dialog
      title={componentTitle}
      isOpen={isOpen}
      onClose={onClose}
      actions={
        <Row justifyContent="flex-end">
          <>
            <Button size="sm" colorScheme="primary" backgroundColor="#607886" onClick={onClose}>
              Cancel
            </Button>
          </>
          <>&nbsp;&nbsp;</>
          <>
            <Button
              size="sm"
              colorScheme="red"
              backgroundColor="#E53E3E"
              onClick={async () => {
                await deleteSecret()
                onClose()
              }}
            >
              Delete
            </Button>
          </>
        </Row>
      }
    >
      <Row>
        <Box>
          Are you sure you want to delete secret: <b>{secretDelete?.name}</b>
        </Box>
      </Row>
    </Dialog>
  )
}
