import React from 'react'
import { useConnectCustomers } from 'src/api/queries/connect-customers'
import { Link, Route } from 'react-router-dom'
import { hasPermission } from 'src/utils/permissions'
import { Box, Button, Placeholders, Row, Table2, useTable } from 'src/ui'
import { CustomerForm } from 'src/connect/customers/customer-editor'
import * as queries from 'src/utils/queries'
import { AuthRoute, FlexRoutes } from 'src/routes'
import { getEnvironment } from 'src/utils'
import ConnectChannels from 'src/connect/channels'

const ConnectCustomersHeader = ({ showAdd = true }) => (
  <Row justifyContent="flex-end" height={8} mb={3}>
    <Box>
      {showAdd && (
        <Button
          as={Link}
          to={
            !hasPermission('connect_customers_edit')
              ? ''
              : `/connect/${getEnvironment()}/customers/new`
          }
          size="sm"
          type="button"
          colorScheme={!hasPermission('connect_customers_edit') ? 'secondary' : 'primary'}
          isDisabled={!hasPermission('connect_customers_edit')}
        >
          ADD
        </Button>
      )}
    </Box>
  </Row>
)

const ConnectCustomers: React.FC = () => {
  let connectCustomersQuery = useConnectCustomers(getEnvironment())
  let connectCustomers = connectCustomersQuery?.data

  if (queries.areAnyLoading(connectCustomersQuery)) return <Placeholders.LoadingState />
  if (queries.areAnyFailed(connectCustomersQuery)) return <Placeholders.FailedState />

  // relative to connect/:env/customers/
  return (
    <FlexRoutes>
      <Route path="/:connectCustomerId/channels/*" element={<AuthRoute as={ConnectChannels} />} />
      <Route
        path="/:connectCustomerId/edit"
        element={
          <AuthRoute
            as={CustomerForm}
            connectCustomers={connectCustomers}
            header={<ConnectCustomersHeader showAdd={false} />}
          />
        }
      />
      <Route
        path="/new"
        element={
          <AuthRoute
            as={CustomerForm}
            connectCustomers={null}
            header={<ConnectCustomersHeader showAdd={false} />}
          />
        }
      />
      <Route
        path="*"
        element={
          <AuthRoute
            as={ConnectCustomersTable}
            connectCustomers={connectCustomers}
            permissions={{
              edit: 'connect_customers_edit',
            }}
            header={<ConnectCustomersHeader showAdd={true} />}
          />
        }
      />
    </FlexRoutes>
  )
}

export default ConnectCustomers

const ConnectCustomersTable = ({ connectCustomers, permissions, header }) => {
  let columns = React.useMemo(
    () => [
      {
        id: 'id',
        accessor: 'id',
        Header: 'ID',
        disableSortBy: true,
      },
      {
        id: 'name',
        accessor: 'name',
        Header: 'Name',
        disableSortBy: true,
      },
      {
        id: '_actions',
        Header: '',
        accessor: '_actions',
        disableSortBy: true,
        Cell: ({ row }) => {
          let connectCustomer = row.original

          return (
            <Row justifyContent="flex-end">
              <Button
                as={Link}
                to={`/connect/${getEnvironment()}/customers/${connectCustomer.id}/edit`}
                size="sm"
                variant="ghost"
                color="primary.500"
                aria-label="Edit Connect Customer"
                isDisabled={!hasPermission(permissions.edit)}
              >
                EDIT
              </Button>
              <Button
                as={Link}
                to={`/connect/${getEnvironment()}/customers/${connectCustomer.id}/channels`}
                size="sm"
                variant="ghost"
                color="primary.500"
                aria-label="View Channels"
                isDisabled={!hasPermission(permissions.edit)}
              >
                CHANNELS
              </Button>
            </Row>
          )
        },
      },
    ],
    [permissions.edit]
  )

  let { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    data: connectCustomers,
    tableOptions: {
      initialState: {
        sortBy: [],
      },
    },
    columns,
  })

  return (
    <>
      {header}

      {connectCustomers.length === 0 ? (
        <Placeholders.EmptyState message="No customers found" />
      ) : (
        <Box bg="white" pt={3}>
          <Table2 {...getTableProps()} style={{ boxShadow: 'none' }} striped>
            <Table2.Header headerGroups={headerGroups} />
            <Table2.Body
              rows={rows}
              getTableBodyProps={getTableBodyProps}
              prepareRow={prepareRow}
            />
          </Table2>
        </Box>
      )}
    </>
  )
}
