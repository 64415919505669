import React from 'react'
import CompanyCommodities from 'src/companies/commodities'
import { AuthRoute } from 'src/routes'
import MicroserviceCompanyCommodities from 'src/companies/commodities/v2'
import { IS_DEV_OR_SANDBOX, IS_PROD } from 'src/utils/config'
import { getEnvironment } from 'src/utils'

const CommoditiesRouter = ({ company, companies, setCopyDialog }) => {
  // If you are in the dev, sandbox, or production BAP, using the production environment
  // In those environments, we use the commodity microservice
  // Otherwise we use the old centre APIs, mostly for UAT since we don't have a UAT environment for the microservices

  if (IS_DEV_OR_SANDBOX || (IS_PROD && getEnvironment() === 'production')) {
    return (
      <AuthRoute
        as={MicroserviceCompanyCommodities}
        company={company}
        companies={companies}
        setCopyDialog={setCopyDialog}
      />
    )
  }

  return (
    <AuthRoute
      as={CompanyCommodities}
      company={company}
      companies={companies}
      setCopyDialog={setCopyDialog}
    />
  )
}

export default CommoditiesRouter
