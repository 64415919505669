import React, { SetStateAction, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Box, Row, Button, Stack, Placeholders } from 'src/ui'
import { Form, Field, Formik } from 'formik'
import {
  TextField as FormikTextField,
  FileInput as FormikFileInput,
  Switch as FormikSwitch,
} from 'src/ui/formik'
import { FormControl, FormLabel } from 'src/ui/form-controls'
import {
  useGetBrandBySlug,
  useCreateBrandMutation,
  useUpdateBrandMutation,
} from 'src/api/queries/branding'
import { useErrorToast, useToast } from 'src/utils/toast'
import { Image } from '@chakra-ui/react'
import { omit } from 'lodash'
import { FlavorWrapper } from './flavor-create-helper'
import { Dispatch } from 'src/store'
import { useQueryClient } from '@tanstack/react-query'
import { UpdateBrandRequest } from 'src/api/branding'
import { AxiosError } from 'axios'

interface CompanyBrandingProps {
  setSettingsState: Dispatch['settings']['setState']
  slug: string
  companies: Company[]
}

function CompanyBranding({ setSettingsState, slug, companies }: CompanyBrandingProps) {
  useEffect(() => {
    setSettingsState({ showCompanySelectorDialog: true, showCentreEnvironment: false })
  }, [setSettingsState])

  return <FlavorWrapper slug={slug} companies={companies} component={BrandingPage} />
}

export default CompanyBranding

interface BrandingPageProps {
  flavor: Flavor
}

function BrandingPage({ flavor }: BrandingPageProps) {
  const [editMode, setEditMode] = useState(false)
  const brandQuery = useGetBrandBySlug({ slug: flavor.slug })
  const brand = brandQuery?.data?.data?.data
  const errorStatusCode = (brandQuery?.error as AxiosError)?.response?.status

  if (brandQuery.isLoading) return <Placeholders.LoadingState />
  if (!brand && errorStatusCode !== 404) return <Placeholders.FailedState />

  return (
    <>
      {editMode || errorStatusCode === 404 ? (
        <BrandingFormEdit
          formData={formData}
          brand={brand}
          setEditMode={setEditMode}
          flavor={flavor}
        />
      ) : (
        <BrandingFormView formData={formData} brand={brand} setEditMode={setEditMode} />
      )}
    </>
  )
}

////////////////////////////////////////////////////////////////////////////////

interface FormField {
  name: string
  editName: string
  label: string
  type: 'color' | 'image' | 'switch'
}

interface BrandingFormProps {
  formData: FormField[][]
  brand: Brand
  setEditMode: React.Dispatch<SetStateAction<boolean>>
  flavor?: Flavor
}

function BrandingFormView({ formData, brand, setEditMode }: BrandingFormProps) {
  return (
    <Box mt={8} mb={16} borderRadius={3} bg="white" boxShadow="md">
      <Row px={6} pt={6} justifyContent="space-between" alignItems="center">
        <Box fontSize="18px">App Branding</Box>
        <Button
          size="sm"
          fontWeight="700"
          colorScheme="primary"
          borderRadius="4px"
          onClick={() => {
            setEditMode(true)
          }}
        >
          Edit
        </Button>
      </Row>
      <Box py={3}>
        {formData.map((group, index) => {
          return (
            <Stack
              key={index}
              isInline
              spacing={4}
              pt={2}
              px={6}
              bg={index % 2 !== 0 ? 'gray.50' : undefined}
              w="100%"
            >
              {group.map((field, index) => {
                if (!field) return <Box key={index} flex={1} />
                return (
                  <Box key={field.name || index} flex={1} minWidth={0}>
                    <FormControl height="max-content">
                      <FormLabel as="div" title={field.label}>
                        {field.label}
                      </FormLabel>
                      <Box
                        display="inline-flex"
                        alignItems="center"
                        mb={3}
                        css={{
                          minWidth: 0,
                          overflow: 'auto',
                          whiteSpace: 'nowrap',
                          scrollbarWidth: 'none',
                          '::-webkit-scrollbar': {
                            width: 0,
                            height: 0,
                          },
                        }}
                      >
                        {field.type === 'image' && (
                          <Box color="gray" h="150px" display="flex" alignItems="center">
                            {brand[field.name] ? (
                              <Image
                                maxW="250px"
                                maxH="100%"
                                src={brand[field.name]}
                                alt={field.editName}
                              />
                            ) : (
                              <>null</>
                            )}
                          </Box>
                        )}
                        {field.type !== 'image' && (
                          <>
                            {brand[field.name]?.toString() || <Box color="gray">null</Box>}

                            {field.type === 'color' && (
                              <Box
                                display="inline-flex"
                                ml={4}
                                w={20}
                                h={8}
                                bg={brand[field.name]}
                              />
                            )}
                          </>
                        )}
                      </Box>
                    </FormControl>
                  </Box>
                )
              })}
            </Stack>
          )
        })}
      </Box>
    </Box>
  )
}

/////////////////////////////////////////////////////////////////////////////

function BrandingFormEdit({ formData, brand, setEditMode, flavor }: BrandingFormProps) {
  const createMode = !brand
  const toast = useToast()
  const errorToast = useErrorToast()
  const queryClient = useQueryClient()
  const { mutateAsync: createBrand, isLoading: isLoadingCreate } = useCreateBrandMutation()
  const { mutateAsync: updateBrand, isLoading: isLoadingUpdate } = useUpdateBrandMutation()

  return (
    <Box mt={8} mb={16} borderRadius={3} bg="white" boxShadow="md">
      <Row px={6} pt={6} justifyContent="space-between" alignItems="center">
        <Box fontSize="18px">{createMode ? <>Create Branding Info</> : <>App Branding</>}</Box>
        <Box>
          {!createMode && (
            <Button
              size="sm"
              fontWeight="700"
              colorScheme="secondary"
              mr={2}
              borderRadius="4px"
              onClick={() => {
                setEditMode(false)
              }}
            >
              Cancel
            </Button>
          )}
          <Button
            size="sm"
            fontWeight="700"
            colorScheme="primary"
            borderRadius="4px"
            type="submit"
            form="brandingForm"
            isLoading={isLoadingCreate || isLoadingUpdate}
          >
            {createMode ? <>Create</> : <>Save</>}
          </Button>
        </Box>
      </Row>
      <Formik
        initialValues={
          createMode
            ? {
                colorPrimary: '',
                colorSecondary: '',
                flavorId: flavor.id,
                hideBushelBranding: false,
              }
            : brand
        }
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={async (values) => {
          if (createMode) {
            console.log('Creating new Brand...', values)
            await createBrand(values, {
              onSuccess: (createdBrand) => {
                toast({ title: 'Success', description: 'Company brand created' })
                queryClient.setQueryData(['get-brand-by-slug', { slug: flavor.slug }], createdBrand)
              },
              onError: (error) => errorToast(error, 'create', 'company brand'),
            })
          } else {
            const extrasGone = omit(values, [
              'launcherUrl',
              'iosAppIconUrl',
              'logoStackedUrl',
              'iosSplashScreenUrl',
              'logoIdUrl',
              'flavorId',
              'createdAt',
              'deletedAt',
              'updatedAt',
              'type',
            ]) as UpdateBrandRequest
            console.log('Updating Brand...', extrasGone)
            await updateBrand(extrasGone, {
              onSuccess: (updatedBrand) => {
                toast({ title: 'Success', description: 'Company brand updated' })
                queryClient.setQueryData(['get-brand-by-slug', { slug: flavor.slug }], updatedBrand)
              },
              onError: (error) => errorToast(error, 'update', 'company brand'),
            })
          }
          setEditMode(false)
        }}
      >
        <Form id="brandingForm" css={{ width: '100%' }}>
          <Box pt={3} pb={6}>
            {formData.map((group, index) => (
              <Stack key={index} px={6} pt={2} isInline spacing={4}>
                {group.map((field, index) => {
                  if (!field) return <Box key={index} flex={1} />
                  const commonProps = { key: index, name: field.editName, label: field.label }
                  return (
                    <Box key={field.editName || index} flex={1} minWidth={0}>
                      {field.type === 'color' && (
                        <Field {...commonProps} component={FormikTextField} />
                      )}
                      {field.type === 'image' && (
                        <Field
                          {...commonProps}
                          component={FormikFileInput}
                          imageURL={createMode ? undefined : brand[field.name]}
                        />
                      )}
                      {field.type === 'switch' && (
                        <Field
                          {...commonProps}
                          component={FormikSwitch}
                          formControlProps={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            height: '62px',
                          }}
                          SwitchBoxProps={{ height: '32px' }}
                        />
                      )}
                    </Box>
                  )
                })}
              </Stack>
            ))}
          </Box>
        </Form>
      </Formik>
    </Box>
  )
}

//make sure these names are the same as what is in the api (that's why there's 2)
const formData: FormField[][] = [
  [
    { name: 'colorPrimary', editName: 'colorPrimary', label: 'Primary Color', type: 'color' },
    { name: 'colorSecondary', editName: 'colorSecondary', label: 'Secondary Color', type: 'color' },
  ],
  [
    {
      name: 'launcherUrl',
      editName: 'launcherImage',
      label: 'Android / drawable-xxxhdpi / ic_launcher.png',
      type: 'image',
    },
    {
      name: 'iosAppIconUrl',
      editName: 'iosAppIconImage',
      label: 'iOS / ic_launcher_1024.png',
      type: 'image',
    },
  ],
  [
    {
      name: 'logoStackedUrl',
      editName: 'logoStackedImage',
      label: 'Android / drawable-xxxhdpi / ic_logo_stacked.png',
      type: 'image',
    },
    {
      name: 'iosSplashScreenUrl',
      editName: 'iosSplashScreenImage',
      label: 'iOS / splash_1242x2208.png',
      type: 'image',
    },
  ],
  [
    {
      name: 'logoIdUrl',
      editName: 'logoIconImage',
      label: 'Android / drawable-xxxhdpi / ic_logo.png',
      type: 'image',
    },
  ],
  [
    {
      name: 'hideBushelBranding',
      editName: 'hideBushelBranding',
      label: 'Hide Bushel Branding (iOS)',
      type: 'switch',
    },
  ],
]

const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
const hexColorMessage = 'Hexidecimal color code must be #nnn or #nnnnnn where n is 0-9,a-f,A-F'
const validationSchema = Yup.object().shape({
  colorPrimary: Yup.string().required('Required').matches(hexColorRegex, hexColorMessage),
  colorSecondary: Yup.string().required('Required').matches(hexColorRegex, hexColorMessage),
})
