import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'

type Contracts = {
  detailsIsEditing: boolean
  setDetailsIsEditing: Dispatch<SetStateAction<boolean>>
}

const ContractsContext = createContext<Contracts>({
  detailsIsEditing: false,
  setDetailsIsEditing: () => {
    return
  },
})

export function ContractsContextProvider({ children }: Readonly<{ children: ReactNode }>) {
  const [detailsIsEditing, setDetailsIsEditing] = useState(false)

  const ContractsContextValue = useMemo(
    () => ({
      detailsIsEditing,
      setDetailsIsEditing,
    }),
    [detailsIsEditing]
  )
  return (
    <ContractsContext.Provider value={ContractsContextValue}>{children}</ContractsContext.Provider>
  )
}

export function useContractsContext(): Contracts {
  return useContext(ContractsContext)
}
