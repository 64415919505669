import { UseToastOptions } from '@chakra-ui/react'
import { centreAPIs } from 'src/utils/api'
import { getEnvironment } from 'src/utils'

const COPY_CREATE_EXCLUDED_KEYS = ['id', 'translator_id', '_revision']
const COPY_UPDATE_EXCLUDED_KEYS = ['id', 'translator_id', 'shared_key', '_revision']

type CopyContext = {
  agtraxApi: Partial<AgtraxApi>
}

export const loadCopyDiff = async ({
  company,
  context,
}: {
  company: Company
  context: CopyContext
  toast: (args: UseToastOptions) => void
}) => {
  const [_destErr, destCompany] = await centreAPIs.otherEnvironment.getCentreCompanyBySlug({
    slug: company.slug,
  })

  const destinationEnvironment = getEnvironment() === 'production' ? 'UAT' : 'Production'

  if (!destCompany) {
    return {
      source: null,
      dest: null,
      errorMessage: `Company ${company.name} not found in ${destinationEnvironment}. Please create the company and try again`,
    }
  }

  if (!destCompany.translator_id) {
    return {
      source: null,
      dest: null,
      errorMessage: `Company ${company.name} does not have a Translator ID in ${destinationEnvironment}. Please create a Translator ID and try again`,
    }
  }

  const [_destAgtraxApiErr, destAgtraxApiResponse] =
    await centreAPIs.otherEnvironment.getCentreAgtraxApiByCompanySlug({
      companySlug: destCompany.slug,
    })

  const destAgtraxApi = destAgtraxApiResponse?.data?.data

  return {
    source: Object.keys(context.agtraxApi)
      .filter(
        (key) =>
          !(destAgtraxApi ? COPY_UPDATE_EXCLUDED_KEYS : COPY_CREATE_EXCLUDED_KEYS).includes(key)
      )
      .reduce((acc, key) => ({ ...acc, [key]: context.agtraxApi[key] }), {}),

    dest: destAgtraxApi
      ? Object.keys(destAgtraxApi)
          .filter(
            (key) =>
              !(destAgtraxApi ? COPY_UPDATE_EXCLUDED_KEYS : COPY_CREATE_EXCLUDED_KEYS).includes(key)
          )
          .reduce((acc, key) => ({ ...acc, [key]: destAgtraxApi[key] }), {})
      : null,
  }
}

export const executeCopy = async ({
  company,
  toast,
}: {
  company: Company
  toast: (args: UseToastOptions) => void
}) => {
  const destinationEnvironment = getEnvironment() === 'production' ? 'UAT' : 'Production'
  const [sourceErr, sourceAgtraxApiResponse] = await centreAPIs.getCentreAgtraxApiByCompanySlug({
    companySlug: company.slug,
  })

  if (sourceErr) {
    toast({ status: 'error', description: 'Failed to load source data' })
    return
  }

  const sourceAgtraxApi = sourceAgtraxApiResponse.data.data

  const [destErr, destCompany] = await centreAPIs.otherEnvironment.getCentreCompanyBySlug({
    slug: company.slug,
  })

  const [destAgtraxApiErr, destAgtraxApiResponse] =
    await centreAPIs.otherEnvironment.getCentreAgtraxApiByCompanySlug({
      companySlug: destCompany.slug,
    })

  if ((destErr || destAgtraxApiErr) && destAgtraxApiErr.response.status !== 404) {
    toast({ status: 'error', description: 'Failed to load destination data' })
    return
  }

  const destAgtraxApi = destAgtraxApiResponse?.data?.data

  const copySource = Object.keys(sourceAgtraxApi)
    .filter(
      (key) =>
        !(destAgtraxApi ? COPY_UPDATE_EXCLUDED_KEYS : COPY_CREATE_EXCLUDED_KEYS).includes(key)
    )
    .reduce((acc, key) => ({ ...acc, [key]: sourceAgtraxApi[key] }), {})

  if (!destAgtraxApi || destAgtraxApi.length === 0) {
    const [createErr, createdAgtraxApi] = await centreAPIs.otherEnvironment.createCentreAgtraxApi({
      config: {
        ...copySource,
        translator_id: destCompany.translator_id,
      },
    })

    if (createErr) {
      toast({ status: 'error', description: 'Failed to create Agtrax API' })
      return [createErr, null]
    }

    toast({ description: `Sucessfully created Agtrax API in ${destinationEnvironment}` })

    return [null, createdAgtraxApi]
  }

  const [updateErr, updatedAgtraxApi] = await centreAPIs.otherEnvironment.updateCentreAgtraxApi({
    config: {
      ...destAgtraxApi,
      ...copySource,
    },
  })

  if (updateErr) {
    toast({ status: 'error', description: 'Failed to copy Agtrax API' })
    return [updateErr, null]
  }

  toast({ description: `Successfully copied Agtrax API to ${destinationEnvironment}` })

  return [null, updatedAgtraxApi]
}
