import { rest } from 'msw'
import { createElevator, elevators } from 'src/fixtures/bushel-location'

export const handlers = (urls: string[]) => {
  return urls.reduce(
    (acc, url) => [
      ...acc,
      rest.get(`${url}/elevators`, (_req, res, ctx) =>
        res(ctx.status(200), ctx.json({ data: elevators }))
      ),
      rest.post(`${url}/elevator`, async (req, res, ctx) => {
        const requestBody = await req.json()
        elevators.push(createElevator(requestBody))
        const statusText = `Successfully added with elevator: ${requestBody.remote_id} to the system.`
        return res(ctx.status(201), ctx.json({ data: requestBody, status: statusText }))
      }),
      rest.put(`${url}/elevator/:id`, async (req, res, ctx) => {
        const requestBody = await req.json()
        const id = parseInt(req.params.id as string)
        const index = elevators.findIndex((elevator) => elevator.id === id)
        elevators[index] = createElevator(requestBody)
        const statusText = `Successfully updated ${requestBody.remote_id}.`
        return res(ctx.status(200), ctx.json({ data: requestBody, status: statusText }))
      }),
    ],
    []
  )
}
