import {
  IConnectChannel,
  IConnectCustomer,
  IConnectSubscription,
  ICreateConnectSubscription,
  ISubscribableCompanyUser,
} from 'src/api/api'
import { sequence, oneOf } from '../fixtures/utils'
import { companies } from './companies'

export const customers: IConnectCustomer[] = initializeCustomers()

export function createCustomer(values?: Partial<IConnectCustomer>): IConnectCustomer {
  return {
    id: sequence(),
    name: values?.name ?? 'Sample Name',
    _revision: '10f00069348729fab36524523af76ea114ba6e34d1ea7d8c4005f4b3a2da889a',
  }
}

function initializeCustomers(): IConnectCustomer[] {
  return [
    'Firstname Lastname',
    'Bob the Builder',
    'Brian Friedt',
    'Santa Claus',
    'Larry the Cable Guy',
  ].map((name) => createCustomer({ name: name }))
}

export function createChannel(values: any): IConnectChannel {
  return {
    id: values.id ?? sequence(),
    connect_customer_id: values.connect_customer_id,
    name: values.name.toLowerCase().replaceAll(' ', '-') ?? 'channel-name',
    description: values.description ?? oneOf(['test', 'testing', 'description']),
    features: values.features ?? {
      accounts: oneOf([true, false]),
      commodity_balances: oneOf([true, false]),
      contracts: oneOf([true, false]),
      tickets: oneOf([true, false]),
      settlements: oneOf([true, false]),
    },
    configuration:
      values.configuration ??
      oneOf([
        {
          delivery_method: 'webhook',
          schedule: '0 * * * *',
          timezone: 'America/Chicago',
          uses_catchup_jobs: oneOf([true, false]),
          content_type: 'json',
          token: '1234123412341234',
          base_url: 'http://test.com',
        },
        {
          delivery_method: 'csv',
          schedule: '0 1 * * *',
          timezone: 'America/Chicago',
          uses_catchup_jobs: oneOf([true, false]),
          content_type: 'json',
          token: '1234567890123456',
          emails: 'fake@email.com',
          target_format: 'default',
          transformer_settings: {
            apply_type: 'xx',
          },
        },
      ]),
    subscribable_type: null,
    _revision: '30028c2e5b11fdc2e3bbc35276a65391070225412ed23dad6dfc8378861d5b9b',
  }
}

function initializeChannels(): IConnectChannel[] {
  return customers.reduce((prev, customer) => {
    const randomNumber = oneOf([0, 1, 2, 3])
    for (let i = 0; i < randomNumber; i++) {
      prev.push(
        createChannel({
          name: `${customer.name} Channel ${i + 1}`,
          connect_customer_id: customer.id,
        })
      )
    }
    return prev
  }, [])
}

export const channels: IConnectChannel[] = initializeChannels()

export const companyUsers: ISubscribableCompanyUser[] = initializeCompanyUsers()

function createCompanyUser(values: Partial<ISubscribableCompanyUser>): ISubscribableCompanyUser {
  return {
    id: 1,
    company_id: values.company_id,
    company_id_remote_id: `${values.company_id}-remoteId1`,
    full_name: 'FirstName LastName',
    _revision: '35ca6f22f6e3123923dca494f3a0833cf3d89c656a4f7d89a12c34e7ed2dafb5',
    ...values,
  }
}
function initializeCompanyUsers(): ISubscribableCompanyUser[] {
  return ['Brian Friedt', 'Paul Blart', 'Neil Armstrong'].map((name, index) =>
    createCompanyUser({ id: index + 1, company_id: oneOf(companies).id, full_name: name })
  )
}
export const subscriptions: IConnectSubscription[] = []

export function createSubscriptions(values: ICreateConnectSubscription): any[] {
  channels.find((channel) => channel.id === values.connect_channel_id).subscribable_type =
    values.subscribable_type
  return values.subscribable_ids.map(({ id }) => {
    const newSubscription: IConnectSubscription = {
      id: sequence(),
      connect_customer_id: values.connect_customer_id,
      connect_channel_id: values.connect_channel_id,
      subscribable:
        values.subscribable_type === 'companies'
          ? {
              id: id,
              name: companies.find((company) => company.id === id).name ?? 'Steve Test Company',
              _revision: `fake-revision-${sequence()}`,
            }
          : {
              id: id,
              company_id: companyUsers.find((user) => user.id === id).company_id ?? 1,
              company_id_remote_id:
                companyUsers.find((user) => user.id === id).company_id_remote_id ?? 'test',
              full_name: companyUsers.find((user) => user.id === id).full_name ?? 'Full Name',
              _revision: `fake-revision-${sequence()}`,
            },
      subscribable_type: values.subscribable_type ?? 'companies',
      subscribed_at: '2022-05-12T20:25:41+00:00',
      _revision: `fake-revision-${sequence()}`,
    }
    subscriptions.push(newSubscription)
    return {
      connect_channel_id: values.connect_channel_id,
      connect_customer_id: values.connect_customer_id,
      details: {
        start_year: 2016,
        external_id: '',
        source: 'bap',
      },
      external_id: '',
      is_new: true,
      subscribable_id: newSubscription.id,
      subscribable_type: values.subscribable_type,
      subscribed_at: '2022-10-26T22:35:08.313567Z',
    }
  })
}

function initializeSubscriptions(): void {
  channels.forEach((channel) => {
    const type = oneOf(['companies', 'company_users'])
    const subscribable_ids: { id: number }[] = []
    if (type === 'companies') {
      const randomNumber = oneOf([0, 1, 2, 4, 5, 7])
      for (let i = 0; i < randomNumber; i++) {
        subscribable_ids.push({ id: oneOf(companies).id })
      }
    } else {
      subscribable_ids.push({ id: oneOf(companyUsers).id })
    }
    if (subscribable_ids.length > 0) {
      createSubscriptions({
        connect_customer_id: channel.connect_customer_id,
        connect_channel_id: channel.id,
        subscribable_type: type,
        subscribable_ids,
      })
    }
  })
}

initializeSubscriptions()
