import { CONFIG } from 'src/utils/config'

const { domain_suffix } = CONFIG

export const copyRoute = ({ company_slug, environment_slug, translator_id, copy_id }) =>
  `/translators/companies/${company_slug}/${environment_slug}/${translator_id}/copy/${copy_id}`

export const latestConfigRoute = ({ company_slug, environment_slug, translator_id }) =>
  `/translators/companies/${company_slug}/${environment_slug}/${translator_id}`

export const translatorEnvironmentUrl = ({ translator_id, environment_slug }) =>
  `https://${translator_id}.adapters.translator${
    // prod url does not have environment bit
    environment_slug === 'prod'
      ? ''
      : environment_slug === 'sand'
        ? '.sandbox'
        : `.${environment_slug}`
  }.${domain_suffix}/api`
