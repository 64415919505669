import { useQuery, useMutation } from '@tanstack/react-query'
import { centreAPIs } from 'src/utils/api'
import { ICreateConnectSubscription } from 'src/api/api'

export const useConnectSubscriptions = (env, channelId) => {
  return useQuery({
    queryKey: ['connect-subscriptions', { env, channelId }],
    queryFn: async () => {
      let [err, response] = await centreAPIs.getConnectSubscriptions({ channelId })
      if (err) throw err
      return response.data.data
    },
    cacheTime: 0,
  })
}

export const useConnectCompanyUsers = ({ phonenumber, connect_channel_id }) => {
  return useQuery({
    queryKey: ['connect-company-users', { phonenumber, connect_channel_id }],
    queryFn: async () => {
      if (phonenumber === null || phonenumber === '') return []
      let [err, response] = await centreAPIs.getCompanyUsersByPhone({
        phonenumber,
        connect_channel_id,
      })
      if (err) throw err
      return response.data.data
    },
    cacheTime: 0,
  })
}

export const useConnectSubscriptionMutations = () => {
  let { mutateAsync: saveConnectSubscription } = useMutation({
    mutationFn: async (params: Partial<ICreateConnectSubscription>) => {
      let [err, response] = await centreAPIs.createConnectSubscription(params)
      if (err) throw err
      return [err, response]
    },
  })

  let { mutateAsync: deleteConnectSubscription } = useMutation({
    mutationFn: async ({ id }: { id: number }) => {
      let [err, response] = await centreAPIs.deleteConnectSubscription({ id })
      if (err) throw err
      return [err, response]
    },
  })

  return { saveConnectSubscription, deleteConnectSubscription }
}
