import { rest, MockedRequest } from 'msw'

type FlagrEvaluationRequestBody = MockedRequest<{
  flagKey: string
  entityContext: Record<string, string>
}>

export const handlers = [
  //  Evaluation
  rest.post(
    'https://flagr.bushelops.com/api/v1/evaluation',
    (req: FlagrEvaluationRequestBody, res, ctx) => {
      const { flagKey } = req.body
      return res(ctx.status(200), ctx.json(makeFlagrResponse(flagKey, 'enabled')))
    }
  ),
]

function makeFlagrResponse(flagKey: string, variantKey?: string) {
  return {
    evalContext: {
      enableDebug: true,
      entityContext: {
        env: 'test',
        username: 'bfriedt',
      },
      entityID: 'randomly_generated_1715540776',
      flagID: 101,
      flagKey,
    },
    evalDebugLog: {
      segmentDebugLogs: [],
    },
    flagID: 101,
    flagKey,
    flagSnapshotID: 2078,
    segmentID: 151,
    timestamp: '2021-05-19T05:07:39Z',
    variantAttachment: {},
    variantID: 99,
    variantKey,
  }
}
