import { AxiosError } from 'axios'
import { get } from 'lodash'

export const handleApiError = ({ notifications }, err: AxiosError) => {
  // lower-level network failures don't have a `response`
  let status = get(err, 'response.status')

  // lack of auth is handled by an axios interceptor
  if (status === 401) return

  if (status === 0) {
    notifications.addNotification({
      title: 'Error!',
      message: `Could not connect to ${err.config.baseURL}` || 'There was a problem',
      options: {
        variant: 'error',
        autoHideDuration: 5000,
      },
    })
  } else {
    notifications.addNotification({
      title: 'Error!',
      message: get(err, 'response.data.title') || 'There was a problem',
      options: {
        variant: 'error',
        autoHideDuration: 5000,
      },
    })
  }
  return false
}
