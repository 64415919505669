import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { Box, Button, FormRow, Stack, Row } from 'src/ui'
import * as Yup from 'yup'
import { Field, Formik, FormikProps, Form } from 'formik'
import { TextField as FormikTextField, Select as FormikSelect } from 'src/ui/formik'
import { IConnectChannel, IConnectSubscription } from 'src/api/api'
import {
  useConnectSubscriptionMutations,
  useConnectCompanyUsers,
} from 'src/api/queries/connect-subscriptions'
import { useToast } from 'src/utils/toast'
import { hasPermission } from 'src/utils/permissions'
import { getEnvironment } from 'src/utils'
import CompanyListTable from 'src/connect/subscriptions/company-list-table'
import CompanyUserListTable from 'src/connect/subscriptions/company-user-list-table'

interface ConnectSubscriptionFormProps {
  connectCustomerId: string
  connectChannelId: string
  connectChannels: IConnectChannel[]
  connectSubscriptions: IConnectSubscription[]
  header: () => void
}

interface SubscriptionFormProps {
  phonenumber: string
  connect_channel_id: number
  subscribable_type: string
}

const textProps = {
  height: '62px',
  width: '270px',
  mb: null,
}

const selectProps = {
  width: '270px',
}

const searchFormSchema = Yup.object().shape({
  phonenumber: Yup.string().min(10, 'Must be 10 digits long').required('Required'),
})

export const SubscriptionAdd: React.FC<ConnectSubscriptionFormProps> = ({
  connectChannelId,
  connectCustomerId,
  connectChannels,
  connectSubscriptions,
  header,
}) => {
  let [queryArgs, setQueryArgs] = React.useState({ phonenumber: null, connect_channel_id: null })
  let companyUsersQuery = useConnectCompanyUsers(queryArgs)
  let { saveConnectSubscription } = useConnectSubscriptionMutations()
  let toast = useToast()
  const queryClient = useQueryClient()

  let isSearching = companyUsersQuery.isLoading
  let results = companyUsersQuery.data || []

  let [selected, setSelected] = React.useState([])
  let [isSubmitting, setIsSubmitting] = React.useState(false)
  let currentChannel = connectChannels.find((channel) => channel.id === parseInt(connectChannelId))

  let connect_channel_id = parseInt(connectChannelId)
  let connect_customer_id = parseInt(connectCustomerId)

  let [subscribableType, setSubscribableType] = React.useState(
    currentChannel.subscribable_type ? currentChannel.subscribable_type : 'company_users'
  )

  const navigate = useNavigate()

  return (
    <>
      <Formik
        initialValues={{
          phonenumber: '',
          connect_channel_id,
          subscribable_type: subscribableType,
        }}
        validationSchema={searchFormSchema}
        onSubmit={async ({ phonenumber, connect_channel_id }, _formikActions) => {
          setQueryArgs({ phonenumber, connect_channel_id })
        }}
      >
        {(formikProps: FormikProps<SubscriptionFormProps>) => (
          <>
            {header}
            <Box p={6} borderRadius={3} bg="white" boxShadow="md">
              <Row width="100%" justifyContent="flex-start">
                <Field
                  name="subscribable_type"
                  label="Subscription Type"
                  component={FormikSelect}
                  options={[
                    { value: 'company_users', label: 'Grower (Company User)' },
                    { value: 'companies', label: 'Grain Buyer (Company)' },
                  ]}
                  onChange={(e) => {
                    setSubscribableType(e.value)
                    setSelected([])
                  }}
                  selectProps={{
                    isClearable: false,
                    isDisabled: currentChannel.subscribable_type ? true : false,
                  }}
                  formControlProps={{ ...selectProps }}
                />
              </Row>
            </Box>
            {subscribableType === 'company_users' && (
              <Box p={6} borderRadius={3} bg="white" boxShadow="md" mt={6}>
                <Form>
                  <Stack spacing={2}>
                    <FormRow rowProps={{ mb: 1 }}>
                      <Field
                        name="phonenumber"
                        label="Phone Number"
                        component={FormikTextField}
                        formControlProps={{ ...textProps }}
                      />
                    </FormRow>
                  </Stack>
                </Form>
                <Row width="100%" justifyContent="flex-end" alignItems="center">
                  <Box mb={2}>
                    <Button
                      width="76px"
                      size="sm"
                      mr={2}
                      colorScheme="primary"
                      isDisabled={!hasPermission('connect_subscriptions_edit') || isSearching}
                      isLoading={isSearching}
                      onClick={() => {
                        formikProps.submitForm()
                      }}
                    >
                      Search
                    </Button>
                  </Box>
                </Row>
              </Box>
            )}
          </>
        )}
      </Formik>
      {subscribableType === 'company_users' ? (
        <CompanyUserListTable
          results={results}
          isLoading={isSearching}
          selectedItems={selected}
          setSelectedItems={setSelected}
          onRowClick={onRowClick}
        />
      ) : (
        <CompanyListTable
          selectedItems={selected}
          setSelectedItems={setSelected}
          onRowClick={onRowClick}
          connectSubscriptions={connectSubscriptions}
        />
      )}
      <Row width="100%" justifyContent="flex-end" alignItems="center" bg="white">
        <Box m={5}>
          <Button
            width="104px"
            size="sm"
            mb={3}
            mr={3}
            colorScheme="primary"
            onClick={async () => {
              setIsSubmitting(true)
              let ids = selected.map((item) => {
                return { id: item.id }
              })
              await saveConnectSubscription(
                {
                  connect_channel_id,
                  connect_customer_id,
                  subscribable_type: subscribableType,
                  subscribable_ids: ids,
                },
                {
                  onError: (error) => {
                    console.log(error)
                    toast({ status: 'error', description: 'Failed to create subscriptions' })
                  },
                  onSuccess: async () => {
                    await queryClient.invalidateQueries({ queryKey: ['connect-subscriptions'] })
                    // If we are adding a subscription and existing array is empty we need to reload channel
                    // to get the subscribable_type
                    if (connectSubscriptions.length === 0) {
                      await queryClient.invalidateQueries({ queryKey: ['connect-channels'] })
                    }
                    toast({ description: 'Successfully created subscriptions' })
                    navigate(
                      `/connect/${getEnvironment()}/customers/${connectCustomerId}/channels/${connectChannelId}/subscriptions`
                    )
                  },
                  onSettled: () => {
                    setIsSubmitting(false)
                  },
                }
              )
            }}
            isDisabled={
              selected.length === 0 || !hasPermission('connect_subscriptions_edit') || isSubmitting
            }
            isLoading={isSubmitting}
          >
            Subscribe
          </Button>
        </Box>
      </Row>
    </>
  )
}

function onRowClick(item, selectedItems, setSelectedItems) {
  if (selectedItems.find((selectedItem) => selectedItem.id === item.id)) {
    if (selectedItems.length === 1) {
      setSelectedItems([])
    } else {
      let index = selectedItems.indexOf(item)
      setSelectedItems([...selectedItems.slice(0, index), ...selectedItems.slice(index + 1)])
    }
  } else {
    setSelectedItems([...selectedItems, item])
  }
}
