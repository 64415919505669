import React from 'react'
import { Link, Route } from 'react-router-dom'
import { hasPermission } from 'src/utils/permissions'
import { Box, Button, Row } from 'src/ui'
import { CommodityForm } from 'src/companies/commodities/v2/commodity-editor'
import { AuthRoute, FlexRoutes } from 'src/routes'
import { getEnvironment } from 'src/utils'
import CommodityList from 'src/companies/commodities/v2/commodity-list'
import { CommodityProvider } from 'src/companies/commodities/v2/commodity-context'

const CommoditiesHeader = ({ company, showAdd = true }) => (
  <Row justifyContent="flex-end" height={8} mb={3}>
    <Box>
      {showAdd && (
        <Button
          as={Link}
          to={
            !hasPermission('company_commodities_edit')
              ? ''
              : `/companies/${getEnvironment()}/${company.slug}/commodities/new`
          }
          size="sm"
          type="button"
          colorScheme={!hasPermission('company_commodities_edit') ? 'secondary' : 'primary'}
          isDisabled={!hasPermission('company_commodities_edit')}
        >
          ADD
        </Button>
      )}
    </Box>
  </Row>
)

interface CompanyCommoditiesProps {
  company: Company
}

const MicroserviceCompanyCommodities: React.FC<CompanyCommoditiesProps> = ({ company }) => {
  return (
    <CommodityProvider company={company}>
      <FlexRoutes>
        <Route
          path="/:id/edit"
          element={
            <AuthRoute
              as={CommodityForm}
              header={<CommoditiesHeader company={company} showAdd={false} />}
            />
          }
        />
        <Route
          path="/new"
          element={
            <AuthRoute
              as={CommodityForm}
              header={<CommoditiesHeader company={company} showAdd={false} />}
            />
          }
        />
        <Route
          path="*"
          element={
            <AuthRoute
              as={CommodityList}
              permissions={{
                copy: 'company_commodities_copy',
                edit: 'company_commodities_edit',
              }}
              header={<CommoditiesHeader company={company} showAdd={true} />}
            />
          }
        />
      </FlexRoutes>
    </CommodityProvider>
  )
}

export default MicroserviceCompanyCommodities
