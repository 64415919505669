import { ICashBidConfig } from 'src/api/api'
import { sequence, oneOf } from './utils'

export const cashBidConfigs: ICashBidConfig[] = []

export function createCashBidConfig(values: Partial<ICashBidConfig> = {}): ICashBidConfig {
  const id = sequence()
  return {
    id,
    retrieved_from: oneOf(['local', 'api']),
    api_type: `api-type-${id}`,
    api_url: `remote-id-${id}`,
    company_id: id,
    username: `remote-id-${id}`,
    password: `remote-id-${id}`,
    local_crop_ids: `remote-id-${id}`,
    options: {},
    elevator_mapping: {},
    _revision: `fakeRevision-${id}`,
    ...values,
  }
}
